import { listAdminAnnouncement } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { IAnnouncement } from 'app/models/adminAnnouncement';
import { PreviousVersionsPopUpRef } from 'app/screens/articles/components/PreviousVersionsPopUp';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';

const useAnnouncementListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IAnnouncement>({
    listFn: listAdminAnnouncement,
  });

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const categoryTags = useAppState(appStateSelectors.categoryTags);
  const designations = useAppState(appStateSelectors.designations);
  const statuses = useAppState(appStateSelectors.statuses);

  const previousModalRef = useRef<PreviousVersionsPopUpRef>(null);

  // TODO: Sample data, remove this once api is integrated
  _pagination.data = sampleData;

  const onNewAnnounceClick = () => navigate(ROUTES.ADMIN_ANNOUNCEMENT_EDITOR);

  const onToggleActive = (article: IAnnouncement) => (checked: boolean) => {};

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIOUS:
        previousModalRef.current?.show(rowId);
        break;

      default:
        break;
    }
  };

  return {
    ..._pagination,
    gradeBands,
    categoryTags,
    designations,
    statuses,
    previousModalRef,
    onNewAnnounceClick,
    onToggleActive,
    onGridAction,
    onSearchTextChange: () => {
      console.log('Need to add handler');
    },
  };
};

export default useAnnouncementListing;

const sampleData: IAnnouncement[] = [
  {
    announcementId: 2343,
    announcementDescription: 'Public Educaton Vol. 1',
    categoryTags: ['Tag 1', 'tag 2'],
    viewDates: '12/02/2020',
    approvalStatus: 'Approved',
    notificationPlan: 'Notification',
    isActive: true,
  },
];
