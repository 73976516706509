import { alphabets } from "app/constants/common";
import React from "react";
import TextButton from "../button/TextButton";
import "./filter.scss";

const AlphabeticFilter: React.FC<IAlphabeticFilterProps> = ({
  eltId,
  selected = "",
  className = "",
  onSelectionChange,
}) => {
  return (
    <div className={`alphabetic-filter ${className}`}>
      {alphabets.map((alphabet) => (
        <TextButton
          textBtnId={`${eltId}-${alphabet}`}
          key={`${eltId}-${alphabet}`}
          size="sm"
          label={alphabet.toUpperCase()}
          active={alphabet.toUpperCase() === selected}
          onClick={onSelectionChange}
        />
      ))}
    </div>
  );
};

export default AlphabeticFilter;

interface IAlphabeticFilterProps {
  eltId: string;
  selected?: string;
  className?: string;
  onSelectionChange?: (selected: string) => void;
}
