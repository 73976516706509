import React from "react";
import If from "../If";
import "./folder.scss";
import { ReactComponent as IcFolder } from "app/assets/icons/folder.svg";
import { ReactComponent as IcFolderOpen } from "app/assets/icons/folder-open.svg";

const Folder: React.FC<IFolderProps> = ({
  open,
  folderName,
  onClick,
  folderStateId,
}) => {
  return (
    <div className="folder" id={folderStateId} onClick={onClick}>
      <If condition={open}>
        <IcFolderOpen />
      </If>
      <If condition={!open}>
        <IcFolder />
      </If>
      <p className="folder-name">{folderName}</p>
    </div>
  );
};

export default Folder;

interface IFolderProps {
  folderStateId: string;
  open?: boolean;
  folderName: string;
  onClick: () => void;
}
