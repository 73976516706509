import {
  IAPIResponse,
  IAPIResponseV2,
  IPaginationParams,
  IPaginationResponse,
} from 'app/models/api';
import { IAuthResponseV2, ILoginv2, IUser, IUserV2 } from 'app/models/user';
import { IProgram } from 'app/models/program';
import { Api } from '.';

const unauthenticatedApi = new Api('', true);
const api = new Api('', false);

export const adminLogin = (values: ILoginv2) =>
  unauthenticatedApi.post<ILoginv2, IAuthResponseV2>('/tokens', values);

export const listProgramAll = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IProgram>>('/programListing', params);

export const logout = () => api.get('/logout');

// export const getUser = () => api.get<IAPIResponse<IUser>>('/currentUser');
export const getUser = () => api.get<IAPIResponse<IUserV2>>('/users/me');
