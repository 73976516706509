import React from "react";
import {
  DATA_GRID_ACTIONS,
  IDataColumnActionProps,
  DataColumnProps,
  IDataColumnValueProps,
} from "./DataColumn";

export const getColumns = <T>(
  children: React.ReactElement<DataColumnProps<T>>[]
): [IDataColumnValueProps<T>[], IDataColumnActionProps<T>[]] => {
  const props = children.map(({ props }) => props);
  return [
    props.filter(
      (prop) => prop.attributeName !== "GRID.ACTIONS"
    ) as IDataColumnValueProps<T>[],

    props.filter(
      (prop) => prop.attributeName === "GRID.ACTIONS"
    ) as IDataColumnActionProps<T>[],
  ];
};
