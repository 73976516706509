import React from 'react';
import './search-bar.scss';
import { ReactComponent as IcSearch } from 'app/assets/icons/search.svg';

const SearchBar: React.FC<ISearchBarProps> = ({
  placeHolder = 'Search',
  onChangeText,
  onSearch,
  searchBarId,
  liveSearch = false,
}) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeText?.(e.target.value);
    liveSearch && onSearch?.();
  };

  const _onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && onSearch?.();

  return (
    <div className={`search-bar${liveSearch ? ' mini' : ''}`}>
      <div className='search-bar-container'>
        <IcSearch />
        <input
          id={searchBarId}
          onKeyUp={_onKeyUp}
          onChange={_onChange}
          type='text'
          placeholder={placeHolder}
        />
      </div>
      {!liveSearch && (
        <button
          className='search-btn'
          type='button'
          id={`${searchBarId}_btn`}
          onClick={onSearch}
        >
          Search
        </button>
      )}
    </div>
  );
};

export default SearchBar;

interface ISearchBarProps {
  searchBarId: string;
  placeHolder?: string;
  liveSearch?: boolean;
  onChangeText?: (searchText: string) => void;
  onSearch?: () => void;
}
