import React from "react";
import "./uploadFilesManager.scss";
import { Row, Col } from "react-bootstrap";
import DropDown from "app/components/dropDown/DropDown";
import useDataUploadManager, {
  IDropdownItem,
} from "../../hooks/DataUploadManager";
import "./columnsManager.scss";

import { Api } from "app/api";
import exp from "constants";
const api = new Api("");

interface ColumnsManagerProps {
  setValidation: (validation: any) => void;
  setDataUpload: (dataUpload: any) => void;
  dataUpload: any;
}
const ColumnsManager: React.FC<ColumnsManagerProps> = (props: any) => {
  const _dataMangerHandle = useDataUploadManager();

  const [mapping, setMapping] = React.useState<any>({});
  const [idMapping, setIdMapping] = React.useState<any>({});
  const [columnHeaders, setColumnHeaders] = React.useState<any>([]);
  const [expectedFields, setExpectedFields] = React.useState<any>([]);

  React.useEffect(() => {
    //set expected fields
    if (!props.dataUpload) return;
    let expected: any = [];
    Object.keys(props.dataUpload.upload_fields).forEach(
      (key: any, i: number) => {
        expected.push({
          label: key,
          id: i,
          required: props.dataUpload.upload_fields[key].indexOf("required") !== -1 ? true : false,
        });
      }
    );
    setExpectedFields(expected);

    //set columns
    let columns: any = [{ label: "none", id: -1 }];
    props.dataUpload.column_headers.forEach((header: any, i: number) => {
      columns.push({
        label: header,
        id: i,
      });
    });
    setColumnHeaders(columns);

    // Set default mapping by matching column headers and expected fields
    if (JSON.stringify(mapping) == '{}') {
      let newMapping: any = {};
      let newIdMapping: any = {};
      expectedFields.forEach((field: any, i: number) => {
        let matchIndex = columnHeaders.findIndex((colHeader: any) =>
          colHeader.label.toLowerCase().replace(/\s+/g, '_') === field.label.toLowerCase().replace(/\s+/g, '_')
        );
        if (matchIndex >= 0) {
          newMapping[field.label] = columnHeaders[matchIndex].label;
          newIdMapping[i] = columnHeaders[matchIndex].id;
        }
      });
      //update id mapping
      setIdMapping(newIdMapping);
      setMapping(newMapping);
    }
  }, [props.dataUpload]);

  const updateMapping = (e: any, index: number) => {
    let fieldName = expectedFields[index].label;
  
    let newMapping = { ...mapping };
    let newIdMapping = { ...idMapping };
    if (e === -1) {
      if (expectedFields[index].required) {
        return;
      }

      newMapping[expectedFields[index].label] = null;
      newIdMapping[index] = e
    } else {
        // Set the new mapping
      let field = columnHeaders.find((field: any) => field.id === e);
      newMapping[expectedFields[index].label] = field.label;
      newIdMapping[index] = e
    }
  
    setMapping(newMapping);
    setIdMapping(newIdMapping);
  };

  const validateRequiredFields = (fieldIndex: number, value: any) => {
    const field = expectedFields[fieldIndex];
    if (field.required && value === -1) {
      return false;
    }
    return true;
  }  
  

  React.useEffect(() => {
    if (props.setDataUpload && props.dataUpload) {
      const formData = new FormData();
      formData.append("field_mappings", JSON.stringify(mapping));
      api.post<FormData, any>("admin/data-upload/" + props.dataUpload.id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          props.setDataUpload(res.data);
        });
    }
  }, [mapping]);

  React.useEffect(() => {
    if (props.setValidation) {
      props.setValidation([() => {
        //check if all required fields are mapped and valid
        let isValid = true;
        expectedFields.forEach((field: any, index: number) => {
          if (field.required && (!mapping[field.label] || !validateRequiredFields(index, idMapping[index]))) {
            isValid = false;
          }
        });
        return isValid;
      }]);
    }
  }, [mapping, idMapping]);
  

  return (
    <Row className="column-content f18-l-28">
      <p className="content-desc mb-6">
        This page will map your import file to data in our system. Your Columns
        are listed on the left, use the dropdown under Columns Guide to select
        the appropiate column to import to.
      </p>
      <Row className="f18-l-28">
        <Col
          className="mb-4 text-center"
          md={{ span: 3, offset: 3 }}
          lg={{ span: 3, offset: 3 }}
        >
          <b>Your Columns</b>
        </Col>
        <Col className="mb-4 text-center" md={{ span: 3 }} lg={{ span: 3 }}>
          <b>Columns Guide</b>
        </Col>
      </Row>
      {expectedFields.map((item: any, i: number) => {
        return (
          <Row className="f18-l-28">
            <Col md={{ span: 3, offset: 3 }} lg={{ span: 3, offset: 3 }}>
              <DropDown<IDropdownItem>
                label=""
                placeholder={columnHeaders[i].label}
                options={columnHeaders}
                id={`filetypes`}
                valueAttribute="id"
                labelAttribute="label"
                selected={idMapping[i] !== undefined ? idMapping[i] : 0}
                onOptionChange={(e: any) => updateMapping(e, i)}
              />
            </Col>
            <Col md={{ span: 3 }} lg={{ span: 3 }} style={{position:'relative'}}>
              <DropDown<IDropdownItem>
                label=""
                placeholder={item.label}
                options={expectedFields}
                id={`${item}-filetypes`}
                valueAttribute="id"
                labelAttribute="label"
              />
              {item.required && <div style={{position:'absolute',right:0,top:'10%'}}><span className="text-danger">*</span></div>}
            </Col>
          </Row>
        );
      })}
    </Row>
  );
};

export default ColumnsManager;
