import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';

import Input from 'app/components/input/Input';
import StandardButton from 'app/components/button/StandardButton';
import DropDown from 'app/components/dropDown/DropDown';
import ContentEditor from 'app/components/contentEditor/HtmlContentEditor';
import ScreenNotification from 'app/components/screenNotification/ScreenNotification';
import Section from 'app/components/section/Section';
import Switch from 'app/components/switch/Switch';
import { IEmailTemplate } from 'app/models/email';
import { IGradeBand, ISubscriptionType } from 'app/models/program';
import { IPreference } from 'app/models/preference';
import { ICategoryTag, IDesignation } from 'app/models/common';
import useEmailEditor from 'app/hooks/EmailEditor';
import PrivateLayout from 'app/layout/PrivateLayout';
import EmailMergeFieldsPopup from './components/emailTemplates/EmailTemplateMergeFields';
import { getErrors } from 'app/utils/data';
import './email.scss';

const EmailTemplateEditor: React.FC = () => {
  const _editor = useEmailEditor();

  const params = useParams();

  return (
    <PrivateLayout contentClassName='email-editor'>
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {({ values, isValid, dirty, errors, touched }) => {
          console.log('Email Template Values: ', values);
          return (
            <>
              <Form>
                <Row className='my-5'>
                  <Col>
                    <h3 className='title'>Email Builder</h3>
                  </Col>
                  <Col>
                    <div className='top-button-container'>
                      <StandardButton
                        standardBtnId='article-editor-save-btn'
                        label='Save Updates'
                        variant='outlined'
                        type='submit'
                        disabled={false}
                      />
                      <StandardButton
                        standardBtnId='article-editor-preview-btn'
                        label='Preview'
                        variant='secondary'
                        onClick={() => _editor.previewEmail()}
                      />
                    </div>
                  </Col>
                </Row>
                <ScreenNotification
                  label='Errors'
                  variant='danger'
                  styles={{ container: { marginTop: '2rem' } }}
                />
                <Row>
                  <Col>
                    <Section
                      title=''
                      sectionId='staffBuilderGeneral'
                      titleSize='lg'
                      className='staff-builder-general mt-4'
                      isEditable={true}
                    >
                      <Row className='user-select'>
                        <Col>
                          <Input
                            id='email-builder-title'
                            variant='secondary'
                            label='Email Title'
                            required
                            placeholder='Email Title'
                            name='name'
                            error={getErrors(touched, errors, 'name')}
                          />
                        </Col>
                      </Row>
                      <Row className='user-select'>
                        <Col>
                          <Input
                            id='email-builder-description'
                            variant='secondary'
                            label='Short Email Description'
                            required
                            placeholder='Short Email Description'
                            name='description'
                            error={getErrors(touched, errors, 'description')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id='email-builder-subject'
                            variant='secondary'
                            label='Subject Line'
                            required
                            placeholder='Enter the subject'
                            name='subject'
                            error={getErrors(touched, errors, 'subject')}
                          />
                        </Col>
                        <Col md={3}>
                          <Input
                            id='email-builder-email_id'
                            variant='secondary'
                            label='Email ID'
                            placeholder='Email ID'
                            name='id'
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Input
                            id='email-builder-pre-header-text'
                            variant='secondary'
                            label='Pre-Header Text'
                            placeholder='Pre-Header Text'
                            name='preheader'
                            error={getErrors(touched, errors, 'preheader')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <DropDown<ICategoryTag>
                            label='Content Category'
                            id='email-editor-category'
                            options={_editor.contentCategories}
                            selected={
                              values.categories
                                ? values.categories.map((c) => c.id)
                                : []
                            }
                            multiple
                            valueAttribute='id'
                            labelAttribute='categoryTag'
                            placeholder='Select Category'
                            onOptionChange={_editor.onDropdownChange<ICategoryTag>(
                              'category'
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DropDown<IDesignation>
                            label='Content Type'
                            id='email-editor-designation'
                            labelAttribute='designationType'
                            options={_editor.contentDesignations}
                            selected={values.content_designation_id}
                            valueAttribute='id'
                            placeholder='Select Content Type'
                            onOptionChange={_editor.onChange(
                              'content_designation_id'
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <DropDown<IPreference>
                            label='Subscription Type'
                            id='email-editor-subscription-type'
                            labelAttribute='subcategory'
                            options={_editor.preferences}
                            selected={
                              values.notification_type_id ?? values.type?.id
                            }
                            valueAttribute='id'
                            placeholder='Select Subscription Type'
                            onOptionChange={_editor.onPreferenceChange}
                          />
                        </Col>
                        <Col md={3}>
                          <DropDown<IGradeBand>
                            label='Grade Bands'
                            id='email-editor-grade-bands'
                            labelAttribute='gradeBand'
                            options={_editor.gradeBands}
                            selected={
                              values.grade_bands
                                ? values.grade_bands.map((gb) => gb.id)
                                : []
                            }
                            valueAttribute='id'
                            placeholder='Select Grade Bands'
                            onOptionChange={_editor.onDropdownChange<IGradeBand>(
                              'grade_band'
                            )}
                            multiple
                          />
                        </Col>
                      </Row>
                      <hr className='mb-5 mt-4' />
                    </Section>

                    <Section
                      title=''
                      sectionId='emailTemplateBuilderContent'
                      titleSize='sm'
                      className='email-builder-content'
                    >
                      <Row>
                        <Col>
                          <ContentEditor
                            id='email-editor-content'
                            height='32rem'
                            html={values.body ? values.body : undefined}
                            autoloadHtml={!!params.id}
                            onEditorChange={_editor.onTemplateContentChange}
                          />
                        </Col>
                        <Col md={3}>
                          <div className='approval-section box-container'>
                            <h6>Status</h6>
                            <div className='d-flex align-items-center'>
                              <span>Pending</span>
                              <Switch
                                switchId='email-editor-status'
                                className='mx-2'
                                checked={
                                  values.content_approval_status_id === 1
                                }
                                onChange={_editor.onApprovalStatusChange}
                              />
                              <span>Approved</span>
                            </div>
                          </div>
                          <div className='merge-fields-section box-container'>
                            <StandardButton
                              standardBtnId='email-editor-merge-fields-btn'
                              label='Merge Fields'
                              variant='primary'
                              onClick={
                                _editor.mergeTemplatePopupRef?.current?.show
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </Section>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
      <EmailMergeFieldsPopup
        id='email-templates-merge-fields'
        ref={_editor.mergeTemplatePopupRef}
      />
    </PrivateLayout>
  );
};

export default EmailTemplateEditor;
