import ROUTES from 'app/constants/navigation';

type FeatureType = {
  [key: string]: string;
};
export const FEATURES: FeatureType = {
  [ROUTES.ARTICLE_CONTENT_MANAGER]: 'article',
  [ROUTES.ARTICLE_EDITOR]: 'article',
  [ROUTES.Q_AND_A_MANAGER]: 'qa',
  [ROUTES.Q_AND_A_EDITOR]: 'qa',
  [ROUTES.USER_PROFILE_MANAGER]: 'user_profile',
  [ROUTES.USER_PROFILE_EDITOR]: 'user_profile',
  [ROUTES.FAMILY_MANAGER]: 'family',
  [ROUTES.FAMILY_EDITOR]: 'family',
  [ROUTES.STAFF_MANAGER]: 'staff',
  [ROUTES.STAFF_EDITOR]: 'staff',
  [ROUTES.AMBASSADOR_PROFILE_MANAGER]: 'ambassador',
  [ROUTES.AMBASSADOR_EDITOR]: 'ambassador',
  [ROUTES.ADMIN_ANNOUNCEMENT_MANAGER]: 'admin',
  [ROUTES.ADMIN_ANNOUNCEMENT_EDITOR]: 'admin',
  [ROUTES.CONFIGURATION_MANAGER]: 'configuration',
  [ROUTES.CONFIGURATION_EDITOR]: 'configuration',
  [ROUTES.CLUBS_MANAGER]: 'club',
  [ROUTES.CLUBS_EDITOR]: 'club',
  [ROUTES.ADMIN_MANAGER]: 'admin',
  [ROUTES.ADMIN_EDITOR]: 'admin',
  [ROUTES.OUTCOMES_MANAGER]: 'outcome',
  [ROUTES.HOMEPAGE_MANAGER]: 'homepage',
  [ROUTES.PHOTOS_MANAGER]: 'photo_library',
  [ROUTES.EMAIL_MANAGER]: 'email',
  [ROUTES.EMAIL_EDITOR]: 'email',
  [ROUTES.NEW_EMAIL]: 'email',
  [ROUTES.GRADUATE_MANAGER]: 'graduate',
  [ROUTES.GRADUATE_PROFILE_EDITOR]: 'graduate',
  [ROUTES.EMAIL_SCHEDULE_MANAGER]: 'email_schedule',
  [ROUTES.EMAIL_SCHEDULE_EDITOR]: 'email_schedule',
  [ROUTES.EMAIL_REPORTING]: 'email_reporting',
  [ROUTES.ADMIN_TRIGGERED_EMAIL_MANAGER]: 'email_trigger',
  [ROUTES.PROGRAM_REPORTING]: 'program_reporting',
  [ROUTES.MESSAGES_MANAGER]: 'message',
  [ROUTES.PROGRAM_MANAGER]: 'program_listing',
  [ROUTES.DATA_UPLOAD_MANAGER]: 'data_upload',
  [ROUTES.NEW_PROGRAM]: 'program_listing',
  [ROUTES.PROGRAM_BUILDER]: 'program_listing',
};
