import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Section from 'app/components/section/Section';
import Input from 'app/components/input/Input';
import { IStaffBuilder } from 'app/models/staff';

interface IMoreAboutSection {
  editor: any;
  touched: FormikTouched<IStaffBuilder>;
  errors: FormikErrors<IStaffBuilder>;
  values: IStaffBuilder;
}

const ABOUT_OPTIONS = [
  {
    label: 'Favorite Book',
    name: 'favorite_book',
    placeholder: 'Enter your Favorite book here',
  },
  {
    label: 'Favorite Movie',
    name: 'favorite_movie',
    placeholder: 'Enter your favorite movie here',
  },
  {
    label: 'Favorite Food',
    name: 'favorite_food',
    placeholder: 'Enter your favorite food here',
  },
  {
    label: 'Favorite Hobbies',
    name: 'favorite_hobbies',
    placeholder: 'Enter your favorite hobby here',
  },
  {
    label: 'Favorite Sport',
    name: 'favorite_sport',
    placeholder: 'Enter your favorite sport here',
  },
  {
    label: 'Favorite Weekend Plan',
    name: 'favorite_weekend_plan',
    placeholder: 'Enter your favorite Weekend Plan here',
  },
];

export const MoreAboutSection: React.FC<IMoreAboutSection> = ({
  editor,
  touched,
  errors,
  values,
}) => {
  return (
    <Section
      title='More about me'
      sectionId='staffBuilderAboutMe'
      titleSize='lg'
      className='staff-builder-aboutme'
      isEditable={true}
    >
      <Row>
        {ABOUT_OPTIONS.map((option, index) => {
          return (
            <Col key={option.name} xs={6}>
              <Input
                id={`staff-builder-${option.name}`}
                variant='secondary'
                label={option.label}
                placeholder={option.placeholder}
                value={
                  (values?.about ?? []).find(
                    (about) => about.name === option.name
                  )?.value ?? ''
                }
                onChange={(e: any) => editor.onChangeAboutEntry(option.name, e)}
              />
            </Col>
          );
        })}
      </Row>
      <Input
        id='staff-builder-favorite-school-memory'
        variant='secondary'
        as='textarea'
        maxLength={150}
        label='Favorite School Memory'
        placeholder='Share a fun memory from school'
        value={
          (values?.about ?? []).find(
            (about) => about.name === 'favorite_school_memory'
          )?.value ?? ''
        }
        onChange={(e: any) =>
          editor.onChangeAboutEntry('favorite_school_memory', e)
        }
      />
      <Input
        id='staff-builder-advice-academic-success'
        variant='secondary'
        as='textarea'
        maxLength={150}
        label='Advice for Academic Success'
        placeholder='Share tips or things to keep in mind about your class'
        value={
          (values?.about ?? []).find((about) => about.name === 'advice')
            ?.value ?? ''
        }
        onChange={(e: any) => editor.onChangeAboutEntry('advice', e)}
      />
    </Section>
  );
};
