import React, { useImperativeHandle, useRef, useState } from 'react';

import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import EmailInputChips from 'app/components/emailInputWithChips/EmailInputChips';
import { TestSendStatus } from 'app/hooks/EmailManagerListing';
import { ReactComponent as CheckCircleIcon } from 'app/assets/icons/check-circle.svg';
import { ReactComponent as ExclamationCircleIcon } from 'app/assets/icons/exclamation-circle.svg';

const EmailManagerTestPopup = React.forwardRef<
  EmailManagerTestPopupRef,
  IEmailManagerTestPopupRefProps
>(({ id, onTestEmailSend, testSendStatus, testSendErrorMessage }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const emailIdRef = useRef<number | null>(null);

  const [emailList, setEmailList] = useState<string[]>([]);

  useImperativeHandle(
    ref,
    () => ({
      show: (id: number) => {
        emailIdRef.current = id;
        modalRef.current?.show();
      },
      close: () => {
        setEmailList([]);
        modalRef.current?.close();
      },
    }),
    []
  );

  function _onConfirm() {
    if (emailIdRef.current) {
      onTestEmailSend?.(emailIdRef.current, emailList);
    }
  }

  function _onCancel() {
    setEmailList([]);
  }

  function getTestSendStatusClass() {
    let str = 'email-test-send-status';
    str +=
      testSendStatus === 'SUCCESS'
        ? ' success'
        : testSendStatus === 'FAILED'
        ? ' error'
        : '';
    return str;
  }

  return (
    <T12Modal
      modalId={id}
      size='lg'
      ref={modalRef}
      title='Email Title Here'
      className='test-email-popup'
      showHeaderBorder
      confirmButtonLabel='Send'
      onConfirm={_onConfirm}
      onCancel={_onCancel}
      onHide={_onCancel}
      closeOnConfirm={false}
    >
      <div className={getTestSendStatusClass()}>
        {testSendStatus === 'SUCCESS' && <CheckCircleIcon />}
        {testSendStatus === 'FAILED' && <ExclamationCircleIcon />}
      </div>
      <div className={getTestSendStatusClass()}>
        {testSendErrorMessage && testSendErrorMessage}
      </div>
      <EmailInputChips
        label='Email*'
        emailList={emailList}
        setEmailList={setEmailList}
      />
    </T12Modal>
  );
});

export default EmailManagerTestPopup;

interface IEmailManagerTestPopupRefProps {
  id: string;
  onTestEmailSend: (emailId: number, recipients: string[]) => void;
  testSendStatus: TestSendStatus;
  testSendErrorMessage: string | null;
}

export type EmailManagerTestPopupRef = {
  show: (id: number) => void;
  close: () => void;
} | null;
