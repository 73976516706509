import { ReactComponent as IcT12Logo } from 'app/assets/icons/t12-logo.svg';
import BackgroundCircle from 'app/components/background-circle/BackgroundCircle';
import StandardButton from 'app/components/button/StandardButton';
import Input from 'app/components/input/Input';
import DropDown from 'app/components/dropDown/DropDown';
import useSignIn from 'app/hooks/SignIn';
import { IProgram } from 'app/models/program';
import { loginValidationSchema } from 'app/utils/validations';
import { Form, Formik } from 'formik';
import React from 'react';
import './sign-in.scss';

const T12Admin: React.FC = () => {
  const _signIn = useSignIn();

  return (
    <div className='t12-admin-login'>
      <IcT12Logo width={259} height={82} />
      <div className='form-container'>
        <h1>Welcome back!</h1>
        <h4>Log into your account and start editing content</h4>
        {_signIn.loginError && (
          <div className='login-error'>
            <h6>{_signIn.loginError}</h6>
          </div>
        )}
        <Formik
          innerRef={_signIn.formRef}
          initialValues={_signIn.initialValues}
          onSubmit={_signIn.onT12AdminSignin}
          validationSchema={loginValidationSchema}
        >
          {({ values, touched, errors, isValid, dirty }) => {
            return (
              <Form>
                <Input
                  id='t12-admin-signin-email'
                  label='Email Address'
                  name='email'
                  error={touched.email && errors.email ? errors.email : ''}
                  success={touched.email && !errors.email}
                />
                <Input
                  id='t12-admin-signin-password'
                  label='Password'
                  name='password'
                  type='password'
                  error={
                    touched.password && errors.password ? errors.password : ''
                  }
                  success={touched.password && !errors.password}
                />
                <button type='button' id='forgot-password'>
                  Forgot Password?
                </button>
                <StandardButton
                  standardBtnId='t12-admin-signin-btn'
                  variant='secondary'
                  label='Sign In'
                  type='submit'
                  disabled={!dirty || !isValid}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <BackgroundCircle
        color='#a2cbc3'
        top={335}
        left={1197}
        radius={77}
        width={30}
      />
      <BackgroundCircle
        color='#a2cbc3'
        top={1020}
        left={62}
        radius={170}
        width={40}
      />
    </div>
  );
};

export default T12Admin;
