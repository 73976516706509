import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import { IFolder } from 'app/models/folders';
import { IMedia, IMediaResponse } from 'app/models/media';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';
import { ReactComponent as IcFolder } from 'app/assets/icons/folder.svg';

type FolderView = {
  folders: any[];
  selectedFolder: any;
  selectedFile: any;
  fileMedia: any;
  mode: string;
  onExpand: (param: any | null) => void;
  onFileSelected: (param: any | null) => void;
};

const FolderView = ({
  folders = [],
  selectedFolder,
  selectedFile,
  fileMedia,
  mode,
  onExpand,
  onFileSelected,
}: FolderView) => {
  function renderFolders(
    folder: any,
    selectedFolder: any = {},
    selectedFile: any = {},
    medias: IMediaResponse[],
    level = 0
  ) {
    const subFolders = folder.subFolders;
    const isSelected = selectedFolder && selectedFolder.id === folder.id;
    const subElements = (subFolders || []).map((subFolder: any) => {
      renderFolders(subFolder, selectedFolder, selectedFile, medias, level + 1);
    });

    const folderName = level === 0 ? folder.name : folder.subFolderName;
    const files =
      isSelected && mode === 'SELECT'
        ? (medias || []).map((mediaItem: IMediaResponse) => {
            const fileSelected =
              selectedFile && selectedFile.id === mediaItem.id;
            return (
              <div style={{ marginLeft: 20 }}>
                <div className={`file-row ${fileSelected ? ' selected' : ''}`}>
                  <IcEdit style={{ width: 16, height: 16 }} />
                  <span
                    style={{
                      marginLeft: 10,
                      cursor: 'pointer',
                    }}
                    onClick={() => onFileSelected(mediaItem)}
                  >
                    {getMediaFileName(mediaItem)}
                  </span>
                </div>
              </div>
            );
          })
        : null;

    return (
      <div style={{ marginLeft: level * 10 }}>
        <div
          onClick={() =>
            ifExpanded(folder, 'top')
              ? onExpand(null)
              : onExpand({ id: folder.id, type: 'top' })
          }
          className={`folder-row ${isSelected ? ' selected' : ''}`}
        >
          <IcFolder style={{ width: 16, height: 16 }} />
          <span style={{ marginLeft: 10 }}>{folderName}</span>
        </div>
        {subElements}
        {files}
      </div>
    );
  }

  function getMediaFileName(media: IMediaResponse) {
    if (!media) return null;

    const fileNameHasExtension = /.*\.[a-zA-Z0-9]+$/.test(media.name);
    const mimeTypeExtension = (media.mimetype ?? '/').split('/')?.[1] ?? '';

    return `${media.name}${
      fileNameHasExtension ? '' : `.${mimeTypeExtension}`
    }`;
  }

  function ifExpanded(folder: IFolder, type: string) {
    if (!selectedFolder) return false;
    return selectedFolder.id === folder.id && selectedFolder.type === type;
  }

  return (
    <div>
      {folders.map((folder: any) => {
        return renderFolders(folder, selectedFolder, selectedFile, fileMedia);
      })}
    </div>
  );
};

export default FolderView;
