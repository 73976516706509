import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Col, Row } from 'react-bootstrap';
import StandardButton from 'app/components/button/StandardButton';
import PrivateLayout from 'app/layout/PrivateLayout';
import { getReport } from 'app/api/reporting';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { IGradeBand } from 'app/models/program';
import { getUser } from 'app/api/authApis';
import { IUser } from 'app/models/user';
import ReportingChart from './ReportingChart';



import { ReactComponent as CalendarIcon } from 'app/assets/icons/calander.svg';
import { ReactComponent as DownArrowIcon } from 'app/assets/icons/down-arrow.svg';

type Props = {
	graph: any;
	pages: any;
	count: number;
	title: string;

};

const ReportingSection = (props: Props) => {
  const graph = props.graph;
  const pages = props.pages ? props.pages : [];
  const count = props.count;
  const title = props.title;
  const [expand, setExpand] = useState(false);
  return (
    <div className='detail-container'>
      <div className='detail-summary'>
        <div className='left'>
          <h5>{title}</h5>
          <div className='graph-container'>
            <ReportingChart data={graph} maxData={5} type='Bar' />
          </div>
          <div className='total-views'>
            Total Views
            <br />
            <span>{count}</span>
          </div>
        </div>
        <div className='right'>
          {pages.slice(0,4).map((item: any, i: number) => {
            return (
              <div key={i}>
                <span className="value">{item.value}</span> <span className="label">{item.label}</span>
              </div>
            );
          })}
        </div>
        <div className={'view-all ' + ((expand ? "expanded" : ''))} onClick={() => setExpand(!expand)}>
          View All <span className="view-all-arrow"><DownArrowIcon/></span>
        </div>
      </div>
      {expand ? (
        <div className='detail-expanded'>
          <ReportingChart
            data={graph}
            type='Bar'
            className='bar-fullwidth'
          />
          <ReportingChart data={pages} type='Pie' className="pie-small"/>
          <div className='items'>
              {pages.slice(0,6).map((item: any, i: number) => {
                return (
                  <div className='item' key={i}>
                    <span>{item.pageviews}</span> {item.label}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        []
      )}
    </div>
  );
};
export default ReportingSection;
