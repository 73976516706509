import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import StandardButton from 'app/components/button/StandardButton';
// import ContentEditor from 'app/components/contentEditor/ContentEditor';
import ContentEditor from 'app/components/contentEditor/HtmlContentEditor';
import DragFileUpload from 'app/components/dragFileUpload/DragFileUpload';
import DropDown from 'app/components/dropDown/DropDown';
import Input from 'app/components/input/Input';
import Switch from 'app/components/switch/Switch';
import useArticleEditor from 'app/hooks/ArticleEditor';
import PrivateLayout from 'app/layout/PrivateLayout';
import useModifiedState from 'app/hooks/ModifiedState';
import UploadPopupV2 from '../sitePhotos/uploadPopup/UploadPopupV2';
import UploadPopup, {
  UploadPopUpRef,
} from '../sitePhotos/uploadPopup/UploadPopup';
import { IArticle, IArticleVersion } from 'app/models/article';
import {
  ICategoryTag,
  IDesignation,
  IStatus,
  IUploadResponse,
} from 'app/models/common';
import ArticlePreviewPopup from './components/ArticlePreviewPopup';
import { IGradeBand, ISchoolType } from 'app/models/program';
import { articleSchema } from 'app/utils/validations';
import { getArticleVersions } from 'app/api/adminApis';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as IcImage } from 'app/assets/icons/images.svg';
import { ReactComponent as IcPreview } from 'app/assets/icons/preview.svg';
import { useParams } from 'react-router-dom';
import './articles.scss';

const ArticleContentEditor: React.FC = (props) => {
  const _editor = useArticleEditor();
  const params = useParams();

  const [state, dispatch] = useModifiedState<IPreviousVersionsState>({
    versions: [],
    currentPage: 1,
  });

  useEffect(() => {
    params.id && _getVersions(+params.id);
    return () => {
      _editor.resetForm();
    };
  }, []);

  const _getVersions = async (id: number) => {
    4;
    try {
      const { data } = await getArticleVersions(id);
      const versionData = (data || [])
        .sort((a, b) => (+a.version > +b.version ? -1 : 1))
        .map((item, index) => {
          let formattedDate = '';
          try {
            formattedDate = moment(item.updatedDate).format('MM.DD.YYYY');
            formattedDate = ` · ${formattedDate}`;
          } catch (err) {
            console.log('Error formatting the version date: ', err);
          }

          return {
            ...item,
            dropdownLabel: `Version ${item.version}${formattedDate}`,
          };
        });

      dispatch({ versions: versionData });
    } catch (error) {
      console.log(
        '🚀 ~ file: PreviousVersionsPopUp.tsx  ~ const_getVersions= ~ error',
        error
      );
    }
  };

  function getFeaturedImage(initialValues: IArticle, values: IArticle) {
    const defaultImage = values?.featuredImageUrl;
    const tmpImage = values.tmpFeaturedImage;
    const currentImage = tmpImage?.base64Str || defaultImage;

    return currentImage ? (
      <img src={currentImage} style={{ width: '100%', borderRadius: 10 }} />
    ) : (
      <IcImage className='p-1 drop-icon' />
    );
  }

  function getSelectedVersion(values: IArticle) {
    return values.versionId || 0;
  }

  return (
    <PrivateLayout contentClassName='article-editor'>
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
        validationSchema={articleSchema}
      >
        {({ values, touched, errors, isValid, dirty }) => {
          console.log('Article Content Values : ', values);
          return (
            <Form>
              <Row className='align-items-center'>
                <Col>
                  <h3 className='font-weight-600 my-0'>
                    Article Content Editor
                  </h3>
                </Col>
                <Col>
                  <div className='top-button-container'>
                    <StandardButton
                      standardBtnId='article-editor-save-btn'
                      label='Save Updates'
                      variant='outlined'
                      type='submit'
                      disabled={!dirty || !isValid || !!values.versionId}
                    />
                    <StandardButton
                      standardBtnId='article-editor-preview-btn'
                      label='Preview'
                      variant='secondary'
                      onClick={() => {
                        _editor.previewModalRef?.current?.show(values);
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row className='mt-5'>
                <Col>
                  <Input
                    id='article-editor-title'
                    label='Article Title'
                    variant='secondary'
                    name='articleTitle'
                    error={
                      touched.articleTitle && errors.articleTitle
                        ? errors.articleTitle
                        : ''
                    }
                  />
                </Col>
                <Col md={3}>
                  <Input
                    id='article-editor-id'
                    label='Article Id'
                    variant='secondary'
                    name='articleId'
                    disabled
                  />
                </Col>
              </Row>
              <Row className=''>
                <Col>
                  <Input
                    id='article-editor-url'
                    label='Article Url'
                    variant='secondary'
                    name='articleUrl'
                    disabled
                  />
                </Col>
                <Col md={3}>
                  <DropDown<IStatus>
                    label='Status'
                    id='article-editor-approval-status'
                    labelAttribute='status'
                    options={_editor.contentApprovals}
                    selected={values.approvalStatusId}
                    valueAttribute='id'
                    placeholder='Select Status'
                    onOptionChange={(value: number | string) =>
                      _editor.onSelectionChange('approvalStatusId')(
                        value as number
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Input
                    id='article-editor-description'
                    label='Content Description'
                    variant='secondary'
                    as='textarea'
                    maxLength={150}
                    className='content-description'
                    name='contentDescription'
                  />
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <DropDown<ICategoryTag>
                        label='Content Category'
                        id='article-editor-content-category'
                        labelAttribute='categoryTag'
                        options={_editor.contentCategories}
                        selected={values.categoryIds}
                        multiple
                        valueAttribute='id'
                        placeholder='Select Category'
                        onOptionChange={_editor.onSelectionChange(
                          'categoryIds'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown<IDesignation>
                        label='Content Designation'
                        id='article-editor-content-designation'
                        labelAttribute='designationType'
                        options={_editor.contentDesignations}
                        selected={values.designationId}
                        valueAttribute='id'
                        placeholder='Select Designation'
                        onOptionChange={(value: string | number) =>
                          _editor.onSelectionChange('designationId')(
                            value as number
                          )
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown<ISchoolType>
                        label='School Type'
                        id='article-editor-school-type'
                        labelAttribute='schoolType'
                        options={_editor.schoolTypes}
                        selected={values.schoolTypeIds}
                        multiple
                        valueAttribute='id'
                        placeholder='Select Type'
                        onOptionChange={_editor.onSelectionChange(
                          'schoolTypeIds'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <DropDown<IGradeBand>
                        label='Grade Band'
                        id='article-editor-grade-band'
                        labelAttribute='gradeBand'
                        options={_editor.gradeBands}
                        selected={values.gradeBandIds}
                        multiple
                        valueAttribute='id'
                        placeholder='Select Grade'
                        onOptionChange={_editor.onSelectionChange(
                          'gradeBandIds'
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className='mb-5' />
              <Row>
                <Col>
                  <ContentEditor
                    id='article-editor-content'
                    height='32rem'
                    onEditorChange={(_, editor) => {
                      const editorText = editor.getContent();
                      _editor.onContentEditorChange(editorText);
                    }}
                    value={values.articleContent}
                  />
                </Col>
                <Col md={3}>
                  <div className='box-container'>
                    <h6>Status</h6>
                    <div className='d-flex align-items-center'>
                      <span>Inactive</span>
                      <Switch
                        switchId='article-editor-status'
                        className='mx-2'
                        checked={values.activeStatus}
                        onChange={_editor.onActiveStatusChange}
                      />
                      <span>Active</span>
                    </div>
                  </div>
                  <div className='box-container'>
                    <h6>Featured Image</h6>
                    <div
                      className='drop-file-container'
                      onClick={() =>
                        _editor.featuredImagePopupRef?.current?.show()
                      }
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        cursor: 'pointer',
                      }}
                    >
                      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {getFeaturedImage(_editor.initialValues, values)}
                        {<div>Set Featured Image</div>}
                      </div>
                    </div>
                  </div>
                  {!!values.versionId && (
                    <div
                      className='d-flex align-items-center version-reset'
                      onClick={_editor.onBackToCurrentVersion}
                    >
                      <span>View Current Version</span>
                      <IcPreview style={{ marginLeft: '0.5em' }} />
                    </div>
                  )}
                  <DropDown<IArticleVersion>
                    id='article-editor-previous-versions'
                    labelAttribute='dropdownLabel'
                    options={state.versions || []}
                    selected={getSelectedVersion(values)}
                    valueAttribute='id'
                    placeholder={
                      state.versions && state.versions.length > 0
                        ? 'Previous Versions'
                        : 'No Previous Versions'
                    }
                    onOptionChange={(selectedId: string | number) =>
                      _editor.onVersionChange(state.versions)
                    }
                  />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <UploadPopup
        id='featured-image'
        onConfirm={(uploadedMedia: IUploadResponse) => {
          if (uploadedMedia) {
            const id = uploadedMedia.mediaId;
            const tempImage = uploadedMedia.fileUrl;
            _editor.onFeaturedImageChange(id, tempImage);
          }
        }}
        ref={_editor.featuredImagePopupRef}
      />
      <ArticlePreviewPopup
        ref={_editor.previewModalRef}
        id='article-editor-preview'
      />
    </PrivateLayout>
  );
};

export default ArticleContentEditor;

interface IPreviousVersionsState {
  versions: IArticleVersion[];
  currentPage: number;
}
