import PrivateFooter from "app/components/footer/PrivateFooter";
import PrivateHeader from "app/components/header/PrivateHeader";
import SideNavBar from "app/components/navbar/SideNavBar";
import React from "react";
import { Col, Row } from "react-bootstrap";
import "./layout.scss";

const PrivateLayout: React.FC<IPrivateLayout> = ({
  children,
  contentClassName = "",
}) => {
  return (
    <div className="private-layout">
      <PrivateHeader />
      <Row className="mx-0">
        <Col md="auto" className="px-0">
          <SideNavBar />
        </Col>
        <Col className={`content-container ${contentClassName}`}>
          {children}
        </Col>
      </Row>
      <PrivateFooter />
    </div>
  );
};

export default PrivateLayout;

interface IPrivateLayout {
  contentClassName?: string;
}
