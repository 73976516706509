import React from 'react';
import { FormCheckProps } from 'react-bootstrap';

interface RadioButtonOption {}

const RadioButtonOption = <T,>({}: IRadioButtonOptionProps<T>) => {
  return <></>;
};

export default RadioButtonOption;

export interface IRadioButtonOptionProps<T> extends FormCheckProps {}
