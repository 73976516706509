import React from 'react';
import { FormikTouched, FormikErrors } from 'formik';

import Section from 'app/components/section/Section';
import Input from 'app/components/input/Input';
import { IStaffBuilder } from 'app/models/staff';

interface IAboutSection {
  editor: any;
  touched: FormikTouched<IStaffBuilder>;
  errors: FormikErrors<IStaffBuilder>;
  values: IStaffBuilder;
}

export const AboutSection: React.FC<IAboutSection> = ({
  editor,
  touched,
  errors,
  values,
}) => {
  return (
    <Section
      title='About'
      sectionId='staffBuilderAbout'
      titleSize='lg'
      className='staff-builder-about'
      isEditable={true}
    >
      <Input
        id='staff-builder-about-me'
        variant='secondary'
        as='textarea'
        maxLength={1024}
        label='About Me'
        placeholder=''
        value={
          values.about?.find((entry) => entry.name === 'about_me')?.value ?? ''
        }
        onChange={(e: any) => editor.onChangeAboutEntry('about_me', e)}
      />
      <Input
        id='staff-builder-commitment'
        variant='secondary'
        as='textarea'
        maxLength={1024}
        label='My Commitment to Your Child'
        placeholder=''
        value={
          values.about?.find((entry) => entry.name === 'my_commitment')
            ?.value ?? ''
        }
        onChange={(e: any) => editor.onChangeAboutEntry('my_commitment', e)}
      />
    </Section>
  );
};
