import React from 'react';
import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import Switch from 'app/components/switch/Switch';
import useAnnouncementListing from 'app/hooks/AdminAnnouncementListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import {
  IAnnouncement,
  IActiveStatus,
  IAnnouncementPeriod,
} from 'app/models/adminAnnouncement';
import { ICategoryTag, IStatus } from 'app/models/common';
import './announcement-profile-manager.scss';

const GRID_ID = 'announcement-account-listing';

const AdminAnnouncementManager: React.FC = () => {
  const _adminAnnouncementListing = useAnnouncementListing();
  return (
    <PrivateLayout contentClassName='announcement-listing'>
      <StandardButton
        label='+ New Announcement'
        variant='secondary'
        className='new-announcement-button'
        onClick={_adminAnnouncementListing.onNewAnnounceClick}
        standardBtnId='announcement-listing-new-btn'
      />
      <h3 className='title'>Admin Announcement Manager</h3>
      <SearchBar
        placeHolder='Search by user name, email, access permissions, or account designation'
        searchBarId='announcement-listing-search'
        onChangeText={_adminAnnouncementListing.onSearchTextChange}
        onSearch={_adminAnnouncementListing.onSearch}
      />
      {/* TODO: This is for sample only. Remove this after filter is created in other pages */}
      <Filter
        id='admin-announcement-listing-filter'
        onFilterChange={_adminAnnouncementListing.onFilterChange}
        selectedFilters={_adminAnnouncementListing.filters}
      >
        <FilterOption<ICategoryTag>
          // labelAttribute="category"
          labelAttribute='categoryTag'
          valueAttribute='id'
          name='category'
          options={_adminAnnouncementListing.categoryTags}
          placeholder='Category Tags'
        />
        <FilterOption<IAnnouncementPeriod>
          labelAttribute='announcementPeriod'
          valueAttribute='id'
          name='announcementViewPeriod'
          options={[]}
          placeholder='Announcement View Period'
        />
        <FilterOption<IActiveStatus>
          labelAttribute='activeStatus'
          valueAttribute='id'
          name='activeStatus'
          options={[]}
          placeholder='active status'
        />
      </Filter>
      <DataGrid<IAnnouncement>
        data={_adminAnnouncementListing.data}
        gridId={GRID_ID}
        rowIdAttribute='announcementId'
        currentPage={_adminAnnouncementListing.currentPage}
        onPageChange={_adminAnnouncementListing.onPageChange}
        totalItems={_adminAnnouncementListing.totalItems}
        onAction={_adminAnnouncementListing.onGridAction}
      >
        <DataGrid.Column<IAnnouncement>
          attributeName='announcementId'
          header='Announcement ID'
        />
        <DataGrid.Column<IAnnouncement>
          attributeName='announcementDescription'
          header='Announcement Description'
        />
        <DataGrid.Column<IAnnouncement>
          attributeName='categoryTags'
          header='Category Tags'
          transform={(announcement) => (
            <p className='pt-3' key={`tag_${announcement.announcementId}`}>
              {announcement.categoryTags.join(',')}
            </p>
          )}
        />
        <DataGrid.Column<IAnnouncement>
          attributeName='viewDates'
          header='View Dates'
        />
        <DataGrid.Column<IAnnouncement>
          attributeName='notificationPlan'
          header='Notification Plan '
        />
        <DataGrid.Column<IAnnouncement>
          attributeName='isActive'
          header='ACTIVE STATUS'
          transform={(announcement) => (
            <Switch
              checked={announcement.isActive}
              onChange={_adminAnnouncementListing.onToggleActive(announcement)}
              switchId='announcement-listing-live-btn'
            />
          )}
        />
        <DataGrid.Column<IAnnouncement>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IAnnouncement>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
    </PrivateLayout>
  );
};

export default AdminAnnouncementManager;
