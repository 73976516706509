import React from 'react';

import { IQAResponse } from 'app/models/qa';
import VideoPlay from 'app/components/videoPlay/VideoPlay';
import VideoGrid from 'app/screens/home/videoGrid/VideoGrid';
import RoundButton from 'app/components/button/RoundButton';
import { ReactComponent as IcPreview } from 'app/assets/icons/preview.svg';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';
import './admin-qa-video-card.scss';

const AdminQAVideoCard: React.FC<IAdminQAVideoCardProps> = ({
  topic,
  responses,
  quoteText,
  quoteUser,
  quoteTitle,
  quoteUserId,
  previewLink,
  className = '',
  onViewClick,
  onEditClick,
}) => {
  const urls = (responses || []).map((response) => ({
    videoURL: response.responseUrl,
    previewImage: '',
    name: response.responseName,
    title: response.responseTitle,
    thumbnail: response.thumbnail,
  }));
  return (
    <div className={`admin-qa-video-card-container ${className}`}>
      <VideoGrid
        urls={urls}
        topic={topic}
        quoteText={quoteText}
        quoteTitle={quoteTitle}
        quoteUser={quoteUser}
        quoteUserId={quoteUserId}
      />
      {/* <div className='right-container'>
        <div className='question-options'>
          <div className='question-options-btns'>
            <RoundButton
              icon={<IcPreview />}
              className='preview-icon'
              onClick={onViewClick}
              roundBtnId='admin-video-card-view-btn'
            />
            <RoundButton
              icon={<IcEdit />}
              className='preview-icon'
              onClick={onEditClick}
              roundBtnId='admin-video-card-edit-btn'
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AdminQAVideoCard;

interface IAdminQAVideoCardProps {
  topic: string;
  responses: IQAResponse[];
  quoteText: string;
  quoteUser?: string;
  quoteTitle: string;
  quoteUserId?: number;
  id?: string;
  videoLink?: string;
  previewLink?: string;
  className?: string;
  onViewClick?: () => void;
  onEditClick?: () => void;
}
