import React, { useRef, useState } from 'react';
import DropdownList from 'react-widgets/DropdownList';

import If from '../If';
import './DropDownInput.scss';

const DropDownInput = <T,>({
  id,
  options,
  data,
  label,
  value,
  dataKey,
  textField,
  onSelect,
  onChange,
  onCreate,
  onSearch,
  searchTerm,
  className = '',
  required = false,
}: any) => {
  return (
    <div className={`t12-drop-down-input ${className}`}>
      <If condition={!!label}>
        <label htmlFor=''>
          {label} {required ? '*' : ''}
        </label>
      </If>
      <DropdownList
        data={data || []}
        value={value}
        dataKey={dataKey || 'id'}
        textField={textField}
        searchTerm={searchTerm}
        onSelect={onSelect}
        onChange={onChange}
        onCreate={onCreate}
        onSearch={onSearch}
        onBlur={() => console.log('On Blur called')}
      />
    </div>
  );
};

export default DropDownInput;
