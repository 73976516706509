import React from "react";
import { Row, Col } from "react-bootstrap";
import DropDown from "app/components/dropDown/DropDown";
import UploadFile from "app/components/uploadFile/UploadFile";
import { upload } from "app/api/commonApis";
import "./uploadFilesManager.scss";
import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import { Api } from "app/api";
const api = new Api('');
import useDataUploadManager, {
  IDropdownItem,
} from "../../hooks/DataUploadManager";
interface UploadfilesManagerProps {
  setValidation: (validation: any) => void;
  setDataUpload: (dataUpload: any) => void;
  dataUpload: any;
}
const UploadFilesManager: React.FC<UploadfilesManagerProps> = (props: any) => {
  const _dataMangerHandle = useDataUploadManager();
  
  const [file, setFile] = React.useState<any>(null);
  const [selectedFileType, setSelectedFileType] = React.useState<number>(0);
  const [fileTypes, setFileTypes] = React.useState<any[]>([]);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    loadFileTypes();
  }, []);

  const loadFileTypes = () => {
    api.get("admin/data-upload/file-types").then((res: any) => {
      let types: any[] = res.data;
      console.log(types);
      //give the types ids to be used in the dropdown
      types = Object.keys(types).map((type: any, index) => {
        return {
          id: index + 1,
          type: type,
          ...types[type]
        };
      });
      setFileTypes(types);
    });
  };

  const updateUpload = () => {
    const formData = new FormData();
    formData.append('file', file);
    const fileType = fileTypes.find((type) => type.id === selectedFileType);
    if (fileType) {
      formData.append('upload_type', fileType.type);
    }
    
    if (!props.dataUpload) {
      return api.post<FormData, any>('admin/data-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      return api.post<FormData, any>('admin/data-upload/' + props.dataUpload.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  };

  const onFileChange = (files: any) => {
    const file:any = files[0];

    //check if file is a csv file 
    const isCSV = file && file.name.endsWith(".csv");
    //check if file is a xlsx file
    const isXLSX = file && file.name.endsWith(".xlsx");

    if (!isCSV && !isXLSX) {
      setError("Please choose a .csv or .xlsx file");
      return;
    }

    setFile(file);
  };

  const onFileTypeSelected = (id: number) => {
    setSelectedFileType(id);
  }; 

  React.useEffect(() => {
    if (file) {
      updateUpload().then((res) => {
        props.setDataUpload(res.data);
      })
    }
  }, [file, selectedFileType]);

  React.useEffect(() => {
    if (props.setValidation) {
      props.setValidation([() => {
        return file && selectedFileType > 0;
      }]);
    }
  }, [file, selectedFileType])

  return (
    <div className="upload-content text-center f18-l-28 mb-6">
      <p className="mb-5">
        Upload the files you would like to upload. Keep in that the system will
        only accept <b>XLSX</b> and <b>CSV</b> files:
      </p>

      <Row className="flex-va-middle mb-4 f18-l-28">
        Select the type of file
        <Col xs="4" md="8" lg="8">
          <DropDown<IDropdownItem>
            label=""
            placeholder="Type of file"
            options={fileTypes}
            id="upload-manager-filetypes"
            valueAttribute="id"
            labelAttribute="label"
            selected={selectedFileType}
            onOptionChange={(item) => onFileTypeSelected(Number(item))}
          />
        </Col>
      </Row>
      <Row className="flex-va-middle f18-l-28">
        Upload File
        <Col xs="4" md="8" lg="8" className="text-left">
          <input type="file" onChange={(e) => onFileChange(e.target.files)} />
        </Col>
      </Row>

      <Row className="flex-va-middle f18-l-28 " style={{marginTop:'30px'}}>
        <Col xs="12" md="12" lg="12" className="text-left">
          {error && <p className="text-danger">{error}</p>}
        </Col>
      </Row>
    </div>
  );
};

export default UploadFilesManager;
