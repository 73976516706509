import { IClubEditor } from 'app/models/clubs';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { FormikHelpers, FormikProps } from 'formik';
import { useRef } from 'react';
import { Api } from './../api';
import { useNavigate } from 'react-router-dom';

const api = new Api('');

const useClubEditor = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IClubEditor>>(null);
  const grades = useAppState(appStateSelectors.gradeBands);

  const onSubmit = async (
    club: IClubEditor,
    helpers: FormikHelpers<IClubEditor>
  ) => {
    let vals = formRef.current?.values;
    if (!vals) return;

    if (vals.id) {
      api.put(`/admin/club/${vals.id}`, vals).then((res) => {
          navigate('/clubs-manager');
      });
    } else {
      api.post('/admin/club', vals).then((res: any) => {
        navigate('/clubs-manager');
      });
    }
  };

  const setData = (key: string, val: any) => {
    formRef.current?.setFieldValue(key, val);
  };

  return { initialValues, formRef, grades, onSubmit, setData };
};

export default useClubEditor;

const initialValues: IClubEditor = {
  about: '',
  clubName: '',
  status: '',
  clubLeaderName: '',
  clubActiveStatus: false,
  featured: false,
  num_students: '',
  meeting_times: '',
  profile_image_url: '',
  start_time: '',
  announcements: '',
  gallery: [],
  events: [],
  members: [],
  leaders: [],
};
