import React from 'react';
import Form from 'react-bootstrap/Form';
import { get } from 'lodash';

import { IRadioButtonOptionProps } from './RadioButtonOption';
import { getRadioButtonOptions } from './RadioButton.utils';
import './radio-button-group.scss';

interface RadioButtonGroup {}

const RadioButtonGroup = <T,>({
  id,
  className,
  onSelect,
  children,
}: IRadioGroupProps<T>) => {
  const _onSelect = (option: React.ChangeEvent<HTMLInputElement>) => {
    const id = get(option, 'target.value') as T;
    console.log(' -- id ', option);
    onSelect && onSelect(id);
  };

  const _options = getRadioButtonOptions(children);

  return (
    <div id={id} className={`radio-button-group ${className}`}>
      <div className='filters'>
        <div className='filters-options'>
          {_options.map((option, idx) => (
            <Form.Check
              type='radio'
              className='radio-button-group-option'
              onChange={_onSelect}
              {...option}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RadioButtonGroup;

interface IRadioGroupProps<T> {
  id: string;
  className?: string;
  children:
    | React.ReactElement<IRadioButtonOptionProps<any>>
    | React.ReactElement<IRadioButtonOptionProps<any>>[];
  onSelect?: (option: T) => void;
}
