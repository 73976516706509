import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Section from 'app/components/section/Section';
import SearchBar from 'app/components/searchBar/SearchBar';
import DataGrid from 'app/components/dataGrid/DataGrid';
import { IEmailManager, IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';

interface IEmailTemplateSelectionSection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
}

export const EmailTemplateSelectionSection: React.FC<
  IEmailTemplateSelectionSection
> = ({ editor, values }) => {
  return (
    <Section
      title='Select the email you would like to schedule'
      sectionId='emailScheduleTemplates'
      titleSize='md'
      className='email-schedule-templates mt-4'
    >
      <Row>
        <Col>
          <SearchBar
            placeHolder='Search by description, keyword or email ID'
            searchBarId='email-schedule-template-search'
            onChangeText={editor.onEmailTemplateSearch}
            liveSearch
          />
          <DataGrid<IEmailManager>
            data={editor.selectableEmailTemplates}
            gridId='email-schedule-email-templates mt4'
            // loading={editor.selectableEmailTemplatesLoading}
            rowIdAttribute='id'
            selectionType='multiple'
            selectedRows={values.emailTemplateIds}
            totalItems={editor.selectableEmailTemplates?.length || 0}
            onSelectionChange={editor.onEmailTemplateSelection}
            gridHeight={400}
          >
            <DataGrid.Column<IEmailManager>
              attributeName='emailListing'
              header='Email Listing'
            />
            <DataGrid.Column<IEmailManager>
              attributeName='emailDescription'
              header='Description'
            />
            <DataGrid.Column<IEmailManager>
              attributeName='emailId'
              header='Email ID'
            />
          </DataGrid>
        </Col>
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};
