import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Calendar from 'react-widgets/Calendar';

import './calendar-filter.scss';

const CalendarFilter: React.FC<ICalendarFilter> = ({
  id,
  values,
  multiple = false,
  disabled,
  onCustomComponentChange: onChange,
}) => {
  function onStartDateChange(date: Date) {
    let params: IDateFilterValues = { startDate: [date] };
    if (multiple && values?.endDate?.[0] && date > values.endDate[0]) {
      params['endDate'] = [date];
    }

    onChange && onChange(params);
  }

  function onEndDateChange(date: Date) {
    onChange && onChange({ endDate: [date] });
  }

  const startDate = values.startDate
    ? new Date(values.startDate[0])
    : new Date();
  const endDate = values.endDate ? new Date(values.endDate[0]) : new Date();

  return (
    <div id={id} className='calendar-filter'>
      {!multiple && (
        <Calendar
          id='calendar-filter-1'
          className='calendar-widget'
          value={startDate}
          onChange={onStartDateChange}
          disabled={disabled}
        />
      )}
      {multiple && (
        <Row className='calendar-filter-range-wrapper'>
          <Col md={5} className='calendar-filter-widget'>
            <h5 className='title'>Start Date</h5>
            <Calendar
              defaultValue={new Date()}
              value={startDate}
              onCurrentDateChange={onStartDateChange}
              onChange={onStartDateChange}
              disabled={disabled}
            />
          </Col>
          <Col md={5} className='calendar-filter-widget'>
            <h5 className='title'>End Date</h5>
            <Calendar
              defaultValue={new Date()}
              value={endDate}
              min={startDate || undefined}
              onChange={onEndDateChange}
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export interface IDateFilterValues {
  startDate?: Date[];
  endDate?: Date[];
}

export interface ICalendarFilter {
  id: string;
  values?: any;
  disabled?: boolean;
  multiple?: boolean;
  open?: boolean;
  onCustomComponentChange?: (params: Record<string, any>) => void;
}

export default CalendarFilter;
