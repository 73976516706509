import React from "react";
import "./uploadFilesManager.scss";
import { Row, Col } from "react-bootstrap";
import DropDown from "app/components/dropDown/DropDown";
import useDataUploadManager, {
  IDropdownItem,
} from "../../hooks/DataUploadManager";
import "./columnsManager.scss";
import { ReactComponent as  DownloadIcon } from 'app/assets/icons/upload.svg';

import { Api } from "app/api";
const api = new Api("");

interface ImportSummaryProps {
  dataUpload: any;
}
const ImportSummary: React.FC<ImportSummaryProps> = (props: any) => {

    const getPreviewCsv = (success: any) => {
        let rowIndexes = success.join(",");
        api.get<any>("admin/data-upload/" + props.dataUpload.id + "/preview?rows=" + rowIndexes)
          .then((res) => {
            const rows = res.data;
      
            const header = Object.keys(rows[0]).join(","); // Get header row as comma-separated string
            const data = rows.map((row: any) => {
              return Object.values(row).join(",");
            }).join("\n");
      
            const csvData = header + "\n" + data;
      
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
      
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "data.csv"); // Set download attribute to desired file name
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          });
      };
      

  return (
    <div className="import-summary">
      
      {props.dataUpload.results?.errors.length > 0 && <h4>Errors</h4>} 
      {props.dataUpload.results?.errors.map((error: string) => {
        return (
          <div>
            <span className="error">{error}</span>
          </div>
        );
      })}
    <br/>
    {props.dataUpload.results?.success && <h4>Summary</h4>}
      {Object.keys(props.dataUpload.results?.success).map((key1: string) => {
        const success = props.dataUpload.results.success[key1];
        return Object.keys(success).map((key: string) => {
          const map: { [key: string]: string } = {
            created: "create",
            updated: "update",
            deactivated: "deactivate",
            skipped: "skip",
          };
          let verb = key1.charAt(0).toUpperCase() + key1.slice(1);
          if (!props.dataUpload.confirmed) verb = map[key1];
          if (success[key].length === 0) return null;
          let plural = success[key].length > 1 ? "s" : "";
          return (
            <div>
              {(props.dataUpload.confirmed) ? "" : "Import will "}
              {verb} {success[key].length} {key} entities
              <span onClick={() => getPreviewCsv(success[key])} style={{cursor:'pointer'}}> {<DownloadIcon style={{marginTop:'-.4em'}}/>} </span>
            </div>
          );
        });
      })}
    </div>
  );
};
export default ImportSummary;
