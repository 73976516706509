import React, { useRef } from "react";
import StandardButton from "../button/StandardButton";
import { T12ModalRef } from "../modal/T12Modal";
import "./import-global.scss";
import ImportContentPopUp, {
  IImportContentPopUpProps,
} from "./ImportContentPopUp";

const ImportGlobal: React.FC<IImportGlobalProps> = ({
  id,
  contentType,
  buttonLabel,
  description,
  contentCount,
  popupDescription,
  className = "",
  onConfirm,
}) => {
  const ref = useRef<T12ModalRef>(null);

  const _onClick = () => ref.current?.show();

  return (
    <>
      <div className={`import-global ${className}`}>
        <h5>Import Global {contentType}</h5>
        <h6>{description}</h6>
        <StandardButton
          label={buttonLabel}
          onClick={_onClick}
          variant="secondary"
          standardBtnId="import-global-btn"
        />
      </div>
      <ImportContentPopUp
        ref={ref}
        id={id}
        onConfirm={onConfirm}
        contentCount={contentCount}
        popupDescription={popupDescription}
        contentType={contentType}
      />
    </>
  );
};

export default ImportGlobal;

interface IImportGlobalProps extends IImportContentPopUpProps {
  id: string;
  description: string;
  buttonLabel: string;
  className?: string;
}
