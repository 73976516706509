enum ROUTES {
  SIGN_IN = '/',
  HOME = '/home',
  T12_ADMIN_SIGN_IN = 't12-admin',

  TIPS_AND_ADVICE = '/tips-and-advice',
  FAMILY_NETWORK = '/family-network',
  QUESTION_AND_ANSWER = '/question-and-answer',
  OUTCOMES = '/outcomes',
  STAFF_DIRECTORY = '/staff-directory',
  CLUB_HUB = '/club-hub',

  FAMILY_PROFILE = '/family-profile',
  ADMIN_DASHBOARD = '/admin-dashboard',
  ADMIN_REPORTING = '/admin-reporting',

  ARTICLE_CONTENT_MANAGER = '/article-content-manager',
  ARTICLE_EDITOR = '/article-editor',
  ARTICLE_PREVIEW = '/article-preview',
  Q_AND_A_MANAGER = '/q-and-a-manager',
  Q_AND_A_EDITOR = '/q-and-a-editor',
  FAMILY_MANAGER = '/family-manager',
  FAMILY_EDITOR = '/family-editor',
  STAFF_MANAGER = '/staff-manager',
  STAFF_EDITOR = '/staff-editor',
  AMBASSADOR_PROFILE_MANAGER = '/ambassador-profile-manager',
  AMBASSADOR_EDITOR = '/ambassador-editor',
  USER_PROFILE_MANAGER = '/user-profile-manager',
  USER_PROFILE_EDITOR = '/user-profile-editor',
  ADMIN_ANNOUNCEMENT_MANAGER = '/admin-announcement-manager',
  ADMIN_ANNOUNCEMENT_EDITOR = '/admin-announcement-editor',

  CONFIGURATION_MANAGER = '/configuration-manager',
  CONFIGURATION_EDITOR = '/configuration-editor',
  CLUBS_MANAGER = '/clubs-manager',
  CLUBS_EDITOR = '/clubs-editor',
  NEW_TICKET = '/new-ticket',
  ADMIN_MANAGER = '/admin-manager',
  ADMIN_EDITOR = '/admin-editor',
  OUTCOMES_MANAGER = '/outcomes-manager',
  HOMEPAGE_MANAGER = '/homepage-manager',
  PHOTOS_MANAGER = '/photos-manager',
  EMAIL_MANAGER = '/email-manager',
  EMAIL_PREVIEW = '/email-preview',
  EMAIL_EDITOR = '/email-editor',
  GRADUATE_MANAGER = '/graduate-manager',
  GRADUATE_PROFILE_EDITOR = '/graduate-profile-editor',
  NEW_EMAIL = '/new-email',
  EMAIL_SCHEDULE_MANAGER = '/email-schedule-manager',
  EMAIL_SCHEDULE_EDITOR = '/email-schedule-editor',
  EMAIL_REPORTING = '/email-reporting',
  ADMIN_TRIGGERED_EMAIL_MANAGER = '/admin-triggered-email-manager',
  PROGRAM_REPORTING = '/program-reporting',
  MESSAGES_MANAGER = '/messages-manager',
  PROGRAM_MANAGER = '/programs-manager',
  DATA_UPLOAD_MANAGER = '/data-upload-manager',
  NEW_PROGRAM = '/new-program',
  PROGRAM_BUILDER = '/program-builder',
  FEATURE_NOT_ACTIVE = '/feature-not-active',
  NO_ACCESS = '/no-access',
}

export default ROUTES;
