import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Switch from 'app/components/switch/Switch';
import RoundButton from 'app/components/button/RoundButton';
import { IConfigurationEditor } from 'app/hooks/ConfigurationEditor';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import {
  IProgramFeatureConnection,
  IProgramFeatureBuilder,
  IProgramFeatureResponse,
} from 'app/models/program';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';

type FeatureSection = {} & IConfigurationEditorSection;

const FeatureSection: React.FC<FeatureSection> = ({
  editor,
  values,
  touched,
  errors,
}) => {
  return (
    <Section
      title='Features'
      sectionId='configuration-editor-features'
      titleSize='md'
      className='configuration-editor-features mt-4'
    >
      <DataGrid<IProgramFeatureResponse>
        data={values.features ?? []}
        gridId='configuration-editor-feature-grid'
        loading={false}
        rowIdAttribute='id'
        currentPage={1}
      >
        <DataGrid.Column attributeName='name' header='Feature' />
        <DataGrid.Column<any>
          attributeName='is_active'
          header='Active'
          transform={(feature) => {
            return (
              <Switch
                checked={feature.is_active}
                onChange={(checked: boolean) =>
                  editor?.onFeatureChange?.(
                    feature.id,
                    'active',
                    checked ? 1 : 0
                  )
                }
                switchId='feature-is-active-switch'
              />
            );
          }}
        />
        <DataGrid.Column<any>
          attributeName='is_enabled'
          header='Enabled'
          transform={(feature) => {
            return (
              <Switch
                checked={feature.is_permitted}
                onChange={(checked: boolean) =>
                  editor?.onFeatureChange?.(
                    feature.id,
                    'permitted',
                    checked ? 1 : 0
                  )
                }
                switchId='feature-is-is_permitted-switch'
              />
            );
          }}
        />
      </DataGrid>
      <hr className='mt-5' />
    </Section>
  );
};

export default FeatureSection;
