import React from 'react';
import DOMPurify from 'dompurify';
import './article-card.scss';

const ArticleCard: React.FC<IArticleCardProps> = ({
  articleTitle,
  articleDescription,
  imageUrl,
  className = '',
}) => {
  function createMarkup(content: string) {
    const sanitized = sanitizeArticleContent(content);
    return { __html: `<p>${sanitized}</p>` };
  }

  function sanitizeArticleContent(content: string) {
    if (!content) return '';
    return DOMPurify.sanitize(content);
  }

  return (
    <div className={`article-card-container ${className}`}>
      {imageUrl && (
        <img className='article-card-image' src={imageUrl} alt='article-img' />
      )}
      <label className='article-card-title'>{articleTitle}</label>
      <div dangerouslySetInnerHTML={createMarkup(articleDescription)} />
    </div>
  );
};

export default ArticleCard;

interface IArticleCardProps {
  articleTitle: string;
  articleDescription: string;
  imageUrl: string;
  className?: string;
}
