import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const CustomHtmlEditor: React.FC<{
  value: string;
  onEditorChange: (value: string) => void;
}> = ({ value, onEditorChange }) => {
  const [mode, setMode] = useState("editor");

  const modes = ["editor", "source", "preview"];
  const currentModeIndex = modes.indexOf(mode);

  const switchMode = (direction: any) => {
    const newIndex =
      (currentModeIndex + direction + modes.length) % modes.length;
    setMode(modes[newIndex]);
  };

  return (
    <div className="outcomes-custom-html">
      <div className="editor-top">
        <div>Custom HTML Editor</div>
        <div className="mode-container">
          <div className="arrow left-arrow" onClick={() => switchMode(-1)}>
            &lt;
          </div>
          <span className="mode-label">{mode.toUpperCase()}</span>
          <div className="arrow right-arrow" onClick={() => switchMode(1)}>
            &gt;
          </div>
        </div>
      </div>
      {mode === "editor" && (
        <Editor
          apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
          value={value}
          init={{
            height: 500,
            menubar: false,
            plugins: "link image",
            toolbar:
              "undo redo | formatselect | bold italic | alignleft aligncenter alignright | image link",
          }}
          onEditorChange={onEditorChange}
        />
      )}
      {mode === "source" && (
        <textarea
          className="source-html-textarea"
          value={value}
          onChange={(e) => onEditorChange(e.target.value)}
        />
      )}
      {mode === "preview" && (
        <div
          className="html-preview"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
    </div>
  );
};
export default CustomHtmlEditor;
