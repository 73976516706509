import If from "app/components/If";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import useAccordion from "../components/accordion";
import AccordionListItem from "../components/AccordionListItem";
import "./accordion.scss";
import CustomAccordion from "./CustomAccordion";

const AccordionView: React.FC = () => {
  const [header, setHeader] = useState("Standard Emails");
  const { permissionOptions } = useAccordion(header);
  return (
    <>
      {Permission_Options.map((item) => (
        <>
          <div className="accordion-view-title">{item.title}</div>
          <If condition={!item.title}>
            <div className="accordion-view-title">{item.options}</div>
          </If>
          {item.options.map((item) => (
            <CustomAccordion header={item} onClick={() => setHeader(item)}>
              <AccordionListItem options={permissionOptions()} />
            </CustomAccordion>
          ))}
        </>
      ))}
      <Accordion className="additional-permissions" defaultActiveKey="0">
        <CustomAccordion
          header="Integrations"
          onClick={() => setHeader("Integrations")}
        >
          <AccordionListItem options={permissionOptions()} />
        </CustomAccordion>
        <CustomAccordion
          header="Data Management"
          onClick={() => setHeader("Data Management")}
        >
          <AccordionListItem options={permissionOptions()} />
        </CustomAccordion>
      </Accordion>
    </>
  );
};

export default AccordionView;

const Permission_Options = [
  { title: "Communications", options: ["Standard Emails", "Triggered Emails"] },
  {
    title: "User Management",
    options: [
      "Admins",
      "Families",
      "Ambassadors",
      "Staff",
      "Graduates",
      "Club Leaders",
    ],
  },
  {
    title: "Content Management",
    options: ["Articles", "Q&A", "Outcomes"],
  },
  {
    title: "Settings",
    options: ["Homepage", "Configuration"],
  },
  {
    title: "Reporting",
    options: ["Email Reporting", "Program Reporting"],
  },
];
