import { useRef } from 'react';
import { listAmbassadorProfile, updateAmbassador } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import { IAmbassadorV2 } from 'app/models/ambassador';
import { IFamilyAccountSubscriptionStatus } from 'app/models/family';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import ROUTES from 'app/constants/navigation';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';
import { IAccountSubscriptionStatus } from 'app/models/accountNotification';
import { IPreference } from 'app/models/preference';
import { AccountNotificationPopupRef } from 'app/components/accountNotification/AccountNotificationPopup';

const useAmbassadorProfileListing = () => {
  const navigate = useNavigate();
  const subscriptionModalRef = useRef<AccountNotificationPopupRef>(null);
  const _pagination = usePagination<IAmbassadorV2>({
    listFn: listAmbassadorProfile,
  });
  const subscriptionStatuses = useAppState(
    appStateSelectors.subscriptionStatuses
  );
  const userPermissions = useAppState(appStateSelectors.userPermissions);
  const activeStatuses = useAppState(appStateSelectors.activeStatuses);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const roles = useAppState(appStateSelectors.roles);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const grades = useAppState(appStateSelectors.grades);

  const onNewAmbassadorClick = () => navigate(ROUTES.AMBASSADOR_EDITOR);

  const updateFamilySubscriptionStatus = async (
    updatedPreferences: IPreference[],
    id: number
  ) => {
    try {
      showLoader();
      if (id) {
        // Update member subscriptions via Api endpoint
        // const response = await updateFamilyPreferences(id, updatedPreferences);

        // Update local family object
        const family = _pagination.data.find((item) => item.id === id);
        if (family) {
          let updates = {} as Partial<IAmbassadorV2>;

          if (family?.head) {
            const updatedHeadOfFamily = { ...family?.head };
            updatedHeadOfFamily.preferences = updatedPreferences;
            updates.head = updatedHeadOfFamily;
          }

          if (family?.members) {
            let updatedMembers = [...family?.members];
            updatedMembers = (updatedMembers || []).map((member) => {
              return {
                ...member,
                preferences: updatedPreferences,
              };
            });
            updates.members = updatedMembers;
          }

          _pagination.updateData(id, 'id', updates);
        }
        return true;
      }
    } catch (error) {
      console.error('Error saving notification subscription settings', error);
      return false;
    } finally {
      hideLoader();
    }

    return false;
  };

  const onToggleActive =
    (ambassador: IAmbassadorV2) => async (checked: boolean) => {
      const response = await updateAmbassador(ambassador.id!, {
        is_enabled: checked,
      });
      if (response?.data) {
        _pagination.updateData(ambassador.id!, 'id', {
          is_enabled: checked,
        });
      }
    };

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.AMBASSADOR_EDITOR}/${id}`);
        break;
      case DATA_GRID_ACTIONS.UNSUBSCRIBE:
        const ambassador = _pagination.data.find(
          (item: IAmbassadorV2) => item.id === id
        );
        if (ambassador && ambassador.id) {
          subscriptionModalRef?.current?.show(ambassador.id, ambassador);
        }

        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    gradeBands,
    roles,
    subscriptionStatuses,
    userPermissions,
    activeStatuses,
    grades,
    subscriptionModalRef,
    onToggleActive,
    onGridAction,
    onNewAmbassadorClick,
    updateFamilySubscriptionStatus,
    onSearchTextChange: () => {
      console.log('onSearchTextChange...');
    },
  };
};

export default useAmbassadorProfileListing;
