import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';

import StandardButton from 'app/components/button/StandardButton';
import ScreenNotification from 'app/components/screenNotification/ScreenNotification';
import PrivateLayout from 'app/layout/PrivateLayout';
import useEmailScheduleEditor from 'app/hooks/EmailScheduleEditor';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';
import { EmailTemplateSelectionSection } from './components/emailSchedules/EmailTemplateSelectionSection';
import { PopulationSelectionSection } from './components/emailSchedules/PopulationSelectionSection';
import { EmailScheduleLimitsSection } from './components/emailSchedules/EmailScheduleLimitsSection';
import { EmailFrequencySection } from './components/emailSchedules/EmailFrequencySection';
import { UserFrequencySection } from './components/emailSchedules/UserFrequencySection';
import { EmailSendTimeSection } from './components/emailSchedules/EmailSendTimeSection';
import './email-schedule-editor.scss';

const EmailScheduleEditor: React.FC<{}> = () => {
  const _editor: IEmailScheduleEditor = useEmailScheduleEditor();

  return (
    <PrivateLayout contentClassName='email-editor'>
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
        // validationSchema={staffSchema}
      >
        {({ values, isValid, dirty, errors, touched }) => {
          return (
            <>
              <Form>
                <Row className='my-5'>
                  <Col>
                    <h3 className='title'>Email Scheduler</h3>
                  </Col>
                  <Col>
                    <div className='top-button-container'>
                      <StandardButton
                        standardBtnId='article-editor-save-btn'
                        label='Save Updates'
                        variant='outlined'
                        type='submit'
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
                <ScreenNotification
                  label='Errors'
                  variant='danger'
                  styles={{ container: { marginTop: '2rem' } }}
                />
                <Row>
                  <Col>
                    <EmailTemplateSelectionSection
                      editor={_editor}
                      values={values}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PopulationSelectionSection
                      editor={_editor}
                      values={values}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EmailScheduleLimitsSection
                      editor={_editor}
                      values={values}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EmailFrequencySection editor={_editor} values={values} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <UserFrequencySection editor={_editor} values={values} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EmailSendTimeSection editor={_editor} values={values} />
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default EmailScheduleEditor;
