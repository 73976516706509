import { GRID_PAGE_SIZE } from 'app/constants/common';
import {
  IPaginationData,
  IPaginationParams,
  IPaginationResponse,
  IPaginationResponsev2,
} from 'app/models/api';
import { useEffect } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import useModifiedState from './ModifiedState';
import { useAppState, appStateSelectors } from 'app/state/AppState';
import { getProgramDomain } from 'app/utils/common';

const usePagination = <T extends object>({
  defaultParams = DEFAULT_PARAMS,
  listFn,
  deleteFn,
  config = {},
}: IPaginationProps<T>) => {
  const [state, dispatch] = useModifiedState<IPaginationState<T>>(initialState);
  const programId = useAppState(appStateSelectors.programId);

  const useV2Response = <T>(response: V1orV1Response<T>) => {
    if ('first_page_url' in response.data) {
      return true;
    }

    return false;
  };

  const _callApi = async (params: Partial<IPaginationParams> = {}) => {
    try {
      dispatch({ loading: true });
      const { searchKey, filters } = state;
      let listFnParams = {
        ...defaultParams,
        ...params,
        searchKey,
        ...filters,
      };

      console.log('listFnParams: ', listFnParams);

      const axiosConfig = {
        ...config,
        headers: {
          'X-Think12-Program-Id': getProgramDomain(),
          'X-Think12-AdminFacing': true,
        },
      };
      // const { data, totalItems } = await listFn(listFnParams, axiosConfig);
      const response = await listFn(listFnParams, axiosConfig);
      const isV2 = useV2Response(response);

      console.log('Response: ', response);
      dispatch({
        // data: data.data ? data.data : data,
        data: isV2
          ? (response.data as IPaginationData<T>).data
          : (response.data as T[]),
        loading: false,
        // totalItems,
        totalItems: response.total,
        currentPage: params.page,
      });
    } catch (error) {
      dispatch({ loading: false });
      console.log('🚀 ~ file: Pagination.ts ~ _callApi ~ error', error);
    }
  };

  useEffect(() => {
    _callApi(defaultParams);
  }, [state.filters]);

  const onPageChange = (page: number) => _callApi({ page });

  // const onSearchKeyChange = (searchKey: string) => dispatch({ searchKey });
  const onSearchKeyChange = (searchKey: string) => {
    console.log('searchKey: ', searchKey);
    dispatch({ searchKey });
  };

  const onSearch = () => _callApi({ page: 1 });

  const onFilterChange = (filters: Record<string, number[]>) => {
    dispatch({ filters });
  };

  const onSelectionChange = (selectedRows: number[]) =>
    dispatch({ selectedRows });

  const onDelete = async (id: number) => {
    try {
      await deleteFn?.(id);
      const page = Math.max(
        1,
        state.data.length === 1 ? state.currentPage - 1 : state.currentPage
      );
      _callApi({ page });
    } catch (error) {
      console.log('🚀 ~ file: Pagination.ts ~ onDelete ~ error', error);
    }
  };

  const updateData = (
    rowId: number,
    idAttrib: keyof T,
    newData: Partial<T>
  ) => {
    const data = state.data.map((d) =>
      (d[idAttrib] as unknown as number) === rowId ? { ...d, ...newData } : d
    );
    dispatch({ data });
  };

  const addData = (newData: T) => {
    dispatch({ data: [...state.data, newData] });
  };

  return {
    ...state,
    updateData,
    addData,
    onSelectionChange,
    onPageChange,
    onSearch,
    onDelete,
    onSearchKeyChange,
    onFilterChange,
  };
};

export default usePagination;

type V1orV1Response<T> = IPaginationResponse<T> | IPaginationResponsev2<T>;

interface IPaginationProps<T> {
  defaultParams?: IPaginationParams;
  listFn: (
    params?: IPaginationParams,
    config?: AxiosRequestConfig
  ) => Promise<IPaginationResponse<T>> | Promise<IPaginationResponsev2<T>>;
  deleteFn?: (id: number) => Promise<unknown>;
  config?: AxiosRequestConfig;
}

export interface IPaginationState<T> {
  data: T[];
  loading: boolean;
  totalItems: number;
  currentPage: number;
  searchKey: string;
  selectedRows: number[];
  filters: Record<string, number[]>;
}

const initialState: IPaginationState<any> = {
  data: [],
  loading: false,
  searchKey: '',
  filters: {},
  totalItems: 0,
  selectedRows: [],
  currentPage: 0,
};

const DEFAULT_PARAMS: IPaginationParams = {
  limit: GRID_PAGE_SIZE,
  page: 1,
};
