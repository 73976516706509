import React from 'react';

import { IIcon } from 'app/models/common';
import { IDropdownOption } from 'app/models/program';
import { ReactComponent as IcFather } from 'app/assets/icons/father.svg';
import { ReactComponent as IcMother } from 'app/assets/icons/mother.svg';
import { ReactComponent as IcDaughter } from 'app/assets/icons/daughter.svg';
import { ReactComponent as IcSon } from 'app/assets/icons/son.svg';
import { ReactComponent as IcCat } from 'app/assets/icons/cat.svg';
import { ReactComponent as IcDog } from 'app/assets/icons/dog.svg';
import { ReactComponent as IcCatDog } from 'app/assets/icons/cat-dog.svg';

export const GRID_PAGE_SIZE = 15;

export enum NAVIGATION_STATE {
  BACK_END_ADMIN = 'BACK_END_ADMIN',
}

export const alphabets = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const GENDERS = ['Male', 'Female', 'Other'];

export const AGES = new Array(100).fill(0).map((_, index) => `${index + 1}`);
export const CURRENT_YEAR = new Date().getFullYear();
export const YEARS = new Array(CURRENT_YEAR - (CURRENT_YEAR - 10))
  .fill(0)
  .map((_, index) => `${CURRENT_YEAR - index}`);

export const FAMILY_MEMBER_ICONS: IIcon[] = [
  {
    id: 1,
    icon: <IcFather />,
  },
  {
    id: 2,
    icon: <IcMother />,
  },
  {
    id: 3,
    icon: <IcDaughter />,
  },
  {
    id: 4,
    icon: <IcSon />,
  },
  {
    id: 5,
    icon: <IcCat />,
  },
  {
    id: 6,
    icon: <IcDog />,
  },
  {
    id: 7,
    icon: <IcCatDog />,
  },
];

export const TIME_OPTIONS: (IDropdownOption & { hour: number })[] = [
  { id: 1, value: '12:00 am', hour: 0 },
  { id: 2, value: '12:30 am', hour: 0 },
  { id: 3, value: '1:00 am', hour: 1 },
  { id: 4, value: '1:30 am', hour: 1 },
  { id: 5, value: '2:00 am', hour: 2 },
  { id: 6, value: '2:30 am', hour: 2 },
  { id: 7, value: '3:00 am', hour: 3 },
  { id: 8, value: '3:30 am', hour: 3 },
  { id: 9, value: '4:00 am', hour: 4 },
  { id: 10, value: '4:30 am', hour: 4 },
  { id: 11, value: '5:00 am', hour: 5 },
  { id: 12, value: '5:30 am', hour: 5 },
  { id: 13, value: '6:00 am', hour: 6 },
  { id: 14, value: '6:30 am', hour: 6 },
  { id: 15, value: '7:00 am', hour: 7 },
  { id: 16, value: '7:30 am', hour: 7 },
  { id: 17, value: '8:00 am', hour: 8 },
  { id: 18, value: '8:30 am', hour: 8 },
  { id: 19, value: '9:00 am', hour: 9 },
  { id: 20, value: '9:30 am', hour: 9 },
  { id: 21, value: '10:00 am', hour: 10 },
  { id: 22, value: '10:30 am', hour: 10 },
  { id: 23, value: '11:00 am', hour: 11 },
  { id: 24, value: '11:30 am', hour: 11 },
  { id: 25, value: '12:00 pm', hour: 12 },
  { id: 26, value: '12:30 pm', hour: 12 },
  { id: 27, value: '1:00 pm', hour: 13 },
  { id: 28, value: '1:30 pm', hour: 13 },
  { id: 29, value: '2:00 pm', hour: 14 },
  { id: 30, value: '2:30 pm', hour: 14 },
  { id: 31, value: '3:00 pm', hour: 15 },
  { id: 32, value: '3:30 pm', hour: 15 },
  { id: 33, value: '4:00 pm', hour: 16 },
  { id: 34, value: '4:30 pm', hour: 16 },
  { id: 35, value: '5:00 pm', hour: 17 },
  { id: 36, value: '5:30 pm', hour: 17 },
  { id: 37, value: '6:00 pm', hour: 18 },
  { id: 38, value: '6:30 pm', hour: 18 },
  { id: 39, value: '7:00 pm', hour: 19 },
  { id: 40, value: '7:30 pm', hour: 19 },
  { id: 41, value: '8:00 pm', hour: 20 },
  { id: 42, value: '8:30 pm', hour: 20 },
  { id: 43, value: '9:00 pm', hour: 21 },
  { id: 44, value: '9:30 pm', hour: 21 },
  { id: 45, value: '10:00 pm', hour: 22 },
  { id: 46, value: '10:30 pm', hour: 22 },
  { id: 47, value: '11:00 pm', hour: 23 },
  { id: 48, value: '11:30 pm', hour: 23 },
];
