import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useAuthState, authStateSelectors } from 'app/state/AuthState';
import ROUTES from 'app/constants/navigation';
import { FEATURES } from 'app/constants/features';
import { IRole } from 'app/models/common';
import './layout.scss';

const FeatureGate: React.FC<FeatureGateProps> = () => {
  const location = useLocation();
  const user = useAuthState(authStateSelectors.user);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const features = appConfig?.features || [];

  const _isFeatureActive = () => {
    const feature = FEATURES[location.pathname];
    return true;
    if (!feature) return true;
    return !!features.find((configuredFeature) => {
      return (
        configuredFeature.tag === feature &&
        configuredFeature.isActive &&
        configuredFeature.isEnabled
      );
    });
  };

  const _hasAccess = () => {
    return true;
    if (!user) return false;

    return (user?.roles as IRole[])?.some((role) =>
      ['Admin', 'Super_Admin'].includes(role.roleName)
    );
  };

  if (!localStorage.getItem('token')) {
    return <Navigate to={ROUTES.SIGN_IN} replace />;
  } else if (!_hasAccess()) {
    return <Navigate to={ROUTES.NO_ACCESS} replace />;
  } else if (_isFeatureActive()) {
    return <Outlet />;
  }

  return <Navigate to={ROUTES.FEATURE_NOT_ACTIVE} replace />;
};

export default FeatureGate;

interface FeatureGateProps {}
