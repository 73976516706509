import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { IParams } from 'app/models/api';
import { useAuthState } from 'app/state/AuthState';
import { camelToSnakeCase, getProgramDomain } from 'app/utils/common';

export class Api {
  private api: AxiosInstance;
  private omitAuthToken: boolean;

  public constructor(urlSuffix = '', omitAuthToken = false) {
    this.omitAuthToken = omitAuthToken;

    this.api = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL + urlSuffix,
      headers: {
        // @ts-ignore
        common: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        'X-Think12-Program-Id': getProgramDomain(),
        'X-Think12-AdminFacing': true,
      },
      validateStatus: (status) => status >= 200 && status <= 400,
    });

    this.api.interceptors.request.use((param: AxiosRequestConfig) => {
      const accessToken = localStorage.getItem('token');

      if (accessToken && !this.omitAuthToken) {
        param.headers!.Authorization = `Bearer ${accessToken}`;
      }

      return param;
    });

    this.api.interceptors.response.use(
      // (response: AxiosResponse) => response.data,
      (response: AxiosResponse) => {
        /**
         * TODO: If the response indicates that the token is no longer valid
         * remove the now invalid token, so any further navigation will
         * force the user back to the login screen (via FeatureGate).
         */
        if (response?.data?.status_code === 401) {
          localStorage.removeItem('token');
        }
        // return response.data;
        const v1Response = !!response?.data?.data;
        return v1Response ? response.data : response;
      },
      (error: Error) => {
        console.log('Error: ', error);
        return Promise.reject(error);
      }
    );
  }

  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config);
  }

  public request<T, R>(config: AxiosRequestConfig): Promise<R> {
    return this.api.request(config);
  }

  public get<R>(
    url: string,
    params?: IParams,
    config?: AxiosRequestConfig
  ): Promise<R> {
    params = params
      ? Object.entries(params)
          .filter(([key, value]) => !isEmpty(value))
          .map(([key, value]) =>
            Array.isArray(value) ? [key, value.join(',')] : [key, value]
          )
          .reduce(
            (p, [key, value]) => ({
              ...p,
              [key as string]: value,
            }),
            {}
          )
      : {};

    return this.api.get(url, { ...config, params });
  }

  public delete<T, R>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config);
  }

  public head<T, R>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.head(url, config);
  }

  public async post<D, R>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R> & { statusCode: number; message?: string }> {
    return this.api.post(url, data, config);
  }

  public put<D, R>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R> & { statusCode: number; message?: string }> {
    return this.api.put(url, data, config);
  }

  public patch<T, R>(
    url: string,
    data?: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.patch(url, data, config);
  }

  /**
   *
   * @param data Object of params
   * @returns
   */
  public encodeQueryParams(data?: IParams): string {
    return data
      ? '?' +
          Object.entries(data)
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  JSON.stringify(value ?? '')
                )}`
            )
            .join('&')
      : '';
  }

  private toFormData(data?: Record<string, any>) {
    const formData = new FormData();
    return data
      ? Object.entries(data).reduce((formData, [key, value]) => {
          formData.append(camelToSnakeCase(key), value);
          return formData;
        }, formData)
      : formData;
  }

  /**
   * For use when array based parameters are needed by the api.
   * @param key
   * @param value
   * @returns
   */
  private _formatQueryStringArray(key: string, value: any[]) {
    if (!Array.isArray(value)) {
      return '';
    }

    let query = new URLSearchParams();
    (value || []).forEach((item) => query.append(key, item));

    return query.toString();
  }
}
