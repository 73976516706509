import { IOutcome } from 'app/models/outcome';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import React from 'react';
import DataGrid from '../dataGrid/DataGrid';
import SelectorDropDown, { ISelectorDropDownProps } from './SelectorDropDown';

const OutcomeSelector: React.FC<IOutcomeSelectorProps> = ({
  eltId,
  variant,
  selected = 2,
  onChange,
}) => {
  const outcomes = useAppState(appStateSelectors.outcomes);

  const _onConfirm = (values: number[]) => onChange?.(values[0]);

  return (
    <SelectorDropDown<IOutcome>
      data={outcomes}
      eltId={eltId}
      label='Select School'
      labelAttribute='outcomeTitle'
      valueAttribute='id'
      placeholder='Select your school'
      searchPlaceholder='Search by school name'
      variant={variant}
      values={selected ? [selected] : undefined}
      onConfirm={_onConfirm}
      selectionType='single'
      initialFilter='A'
      gridHeight={500}
    >
      <DataGrid.Column<IOutcome>
        attributeName='outcomeTitle'
        header='School Name'
      />
      <DataGrid.Column<IOutcome>
        attributeName='url'
        header=''
        transform={(outcome) => (
          <img
            className='education-logo-item'
            src={outcome.url}
            alt={outcome.outcomeTitle}
          />
        )}
      />
      <DataGrid.Column<IOutcome> attributeName='outcomeState' header='State' />
    </SelectorDropDown>
  );
};

export default OutcomeSelector;

export interface IOutcomeSelectorProps
  extends Pick<ISelectorDropDownProps<IOutcome>, 'variant'> {
  eltId: string;
  selected?: number;
  onChange?: (id: number) => void;
}
