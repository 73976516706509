import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import RoundButton from 'app/components/button/RoundButton';
import ColorPicker from 'app/components/colorPicker/ColorPicker';
import { IConfigurationEditor } from 'app/hooks/ConfigurationEditor';
import { IMainProgramConfigBuilder } from 'app/models/config';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';

type ColorPaletteSection = {} & IConfigurationEditorSection;

const ColorPaletteSection: React.FC<ColorPaletteSection> = ({
  editor,
  values,
  touched,
  errors,
}) => {
  return (
    <Section
      title='Color Palette'
      sectionId='configuration-editor-color-palette'
      titleSize='md'
      className='configuration-editor-color-palette mt-4'
    >
      <Row>
        {[1, 2, 3].map((paletteNum) => {
          const colorValue = values[
            `color${paletteNum}` as 'color1' | 'color2' | 'color3'
          ]
            ? values[`color${paletteNum}` as 'color1' | 'color2' | 'color3']
            : null;

          return (
            <Col md={{ span: 2, offset: paletteNum > 1 ? 1 : 0 }}>
              <ColorPicker
                id={`color-picker-${paletteNum}`}
                onConfirm={(color, id) =>
                  editor?.onChange?.(`color${paletteNum}`)(color!)
                }
                label={`Color variable ${paletteNum}`}
                color={colorValue!}
              />
            </Col>
          );
        })}
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};

export default ColorPaletteSection;
