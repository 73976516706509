import React from "react";
import TextButton from "../button/TextButton";
import If from "../If";
import { getPageNums } from "./Pagination.utils";
import { ReactComponent as IcAngleLeft } from "app/assets/icons/angle-left.svg";
import "./pagination.scss";
import RoundButton from "../button/RoundButton";

const Pagination: React.FC<IPaginationProps> = ({
  id,
  totalPages,
  activePage,
  onPageClick,
}) => {
  const firstPage = 1;
  const lastPage = totalPages;
  const pageNums = getPageNums(totalPages, activePage);

  const _onPrevious = () => onPageClick?.(activePage - 1);
  const _onNext = () => onPageClick?.(activePage + 1);

  return (
    <div className="pagination">
      <RoundButton
        icon={<IcAngleLeft />}
        onClick={_onPrevious}
        disabled={activePage === 1}
        roundBtnId="pagination-previous-btn"
      />
      <div className="pages">
        <TextButton
          label={firstPage}
          active={activePage === firstPage}
          onClick={onPageClick}
          textBtnId={`pagination_${id}_${firstPage}`}
        />
        {/* Total pages less than or equal to 11, no ellipsis needed  */}
        <If condition={totalPages <= 11}>
          {pageNums.map((pageNum) => (
            <TextButton
              key={`pagination_${id}_${pageNum}`}
              label={pageNum}
              active={activePage === pageNum}
              onClick={onPageClick}
              textBtnId={`pagination_${id}_${pageNum}`}
            />
          ))}
        </If>

        {/* Total pages greater than 11, ellipsis needed based on active page */}
        <If condition={totalPages > 11}>
          <If condition={activePage > 6}>
            <TextButton
              label="..."
              className="cursor-default"
              textBtnId="pagination-more-left-btn"
            />
          </If>
          {pageNums.map((pageNum) => (
            <TextButton
              key={`pagination_${id}_${pageNum}`}
              label={pageNum}
              active={activePage === pageNum}
              onClick={onPageClick}
              textBtnId={`pagination_${id}_${pageNum}`}
            />
          ))}
          <If condition={totalPages - activePage > 5}>
            <TextButton
              label="..."
              className="cursor-default"
              textBtnId="pagination-more-right-btn"
            />
          </If>
        </If>
        <If condition={lastPage !== 1}>
          <TextButton
            label={lastPage}
            active={activePage === lastPage}
            onClick={onPageClick}
            textBtnId={`pagination_${id}_${lastPage}`}
          />
        </If>
      </div>
      <RoundButton
        onClick={_onNext}
        icon={<IcAngleLeft className="rotate-180" />}
        disabled={activePage === totalPages}
        roundBtnId="pagination-next-btn"
      />
    </div>
  );
};

export default Pagination;

interface IPaginationProps {
  id: string;
  totalPages: number;
  activePage: number;
  onPageClick?: (pageNumber: number) => void;
}
