import { NAVIGATION_STATE } from 'app/constants/common';
import { IUser, IUserV2 } from 'app/models/user';
import { emptyFunction } from 'app/utils/common';
import create from 'zustand';
import { createSelectors } from './state';

interface IAuthState {
  // user?: IUser;
  user?: IUserV2;
  navState: NAVIGATION_STATE;
  // setUser: (user: IUser) => void;
  setUser: (user: IUserV2) => void;
  setNavState: (navState: NAVIGATION_STATE) => void;
}

const initialState: IAuthState = {
  user: undefined,
  navState: NAVIGATION_STATE.BACK_END_ADMIN,
  setUser: emptyFunction,
  setNavState: emptyFunction,
};

export const useAuthState = create<IAuthState>((set, get) => ({
  ...initialState,
  setUser: (user) => set({ user }),
  setNavState: (navState) => set({ navState }),
}));

export const authStateSelectors = createSelectors(initialState);
