import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import ImageUploader, {
  ImageUploaderRef,
} from "app/components/imageUploader/ImageUploader";
import { IUploadResponse } from "app/models/common";
import Input from "app/components/input/Input";
import Section from "app/components/section/Section";
import useClubEditor from "app/hooks/ClubEditor";
import PrivateLayout from "app/layout/PrivateLayout";
import { Form, Formik, useFormikContext } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { debounce } from "lodash";
import "./club.scss";
import ClubLeader from "./components/ClubLeader";
import { useParams } from "react-router-dom";
import StandardButton from "app/components/button/StandardButton";
import { getProgramDomain, getApiDomain } from 'app/utils/common';
import { getClub, listFamily, updateClub } from "app/api/adminApis";
import RoundButton from "app/components/button/RoundButton";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import SearchBar from "app/components/searchBar/SearchBar";
import DatePicker from "react-datepicker";
import Checkbox from "app/components/checkbox/CheckBox";
import DropDown from "app/components/dropDown/DropDown";
import { IGradeBand } from "app/models/program";
import { appStateSelectors, useAppState } from "app/state/AppState";
import UploadPopup, {
  UploadPopUpRef,
} from "../sitePhotos/uploadPopup/UploadPopup";
import ClubEvent from "./components/ClubEvent";

import "react-datepicker/src/stylesheets/datepicker.scss";

const ClubEditor: React.FC = (props) => {
  const _editor = useClubEditor();
  const params = useParams();
  const gradeBands = useAppState(appStateSelectors.gradeBands);

  const [club, setClub] = useState<any>({});

  const [events, setEvents] = useState<Array<any>>([]);
  const [leaders, setLeaders] = useState<Array<any>>([]);
  const [families, setFamilies] = useState<Array<any>>([]);
  const [gradeBandIds, setGradeBandIds] = useState<Array<any>>([]);

  const [familySearch, setFamilySearch] = useState<string>();
  const [familyResults, setFamilyResults] = useState<Array<any>>([]);

  const [galleryUrls, setGalleryUrls] = useState<IUploadResponse[]>([]);

  useEffect(() => {
    if (params.id) {
      //Load existing club data
      getClub(parseInt(params.id)).then((res: any) => {
        setClub(res.data);
        setEvents(res.data.events);
        setLeaders(res.data.leaders);
        setFamilies(res.data.families);
        _editor.setData("id", res.data.id);
      });
    } else {
      if (events.length === 0) addEvent();
    }
  }, []);

  const addEvent = () => {
    events.push({
      name: "",
      location: "",
      event_date: new Date().toISOString(),
    });
    setEvents([...events]);
  };

  const removeEvent = (index: number) => {
    events.splice(index, 1);
    setEvents([...events]);
  };

  const updateEvent = (index: number, event: any) => {
    events[index] = event;
    setEvents([...events]);
  };

  const updateClub = (prop: string, value: any) => {
    club[prop] = value;
    setClub({ ...club });
  };

  const doFamilySearch = () => {
    listFamily({
      limit: 10,
      page: 0,
      'searchKey': familySearch,
    }).then((res: any) => {
      setFamilyResults(res.data);
      setFamilySearch("");
    });
  };

  const updateLeader = (position: number, data: any) => {
    leaders[position] = data;
    console.log(leaders);
    setLeaders([...leaders]);
  };

  const addFamily = (family: any) => {
    families.push(family);
    setFamilies([...families]);
  };

  const removeFamily = (family: any) => {
    families.splice(families.indexOf(family), 1);
    setFamilies([...families]);
  };

  const removeImage = () => {
    updateClub('image_id', null);
    updateClub('image', null);
  }

  const updateFormData = debounce(() => {
    _editor.setData("name", club.clubName);
    _editor.setData("clubName", club.clubName);
    _editor.setData("image_id", club.image_id);
    _editor.setData("about", club.about);
    _editor.setData("announcements", club.announcements);
    _editor.setData("num_students", club.num_students);
    _editor.setData("meeting_times", club.meeting_times);
    _editor.setData("start_time", club.start_time);
    _editor.setData("clubActiveStatus", club.clubActiveStatus);
    _editor.setData("featured", club.featured);

    _editor.setData("gallery", galleryUrls);
    _editor.setData("gradeBandIds", gradeBandIds);
    _editor.setData("leaders", leaders);
    _editor.setData("events", events);
    _editor.setData("families", families);
  }, 500);

  useEffect(() => {
    updateFormData();
  }, [leaders, events, families, club]);

  const galleryRef = useRef<ImageUploaderRef>(null);

  const profileUploaderRef = useRef<ImageUploaderRef>(null);
  const galleryUploadRef = useRef<UploadPopUpRef>(null);

  return (
    <PrivateLayout contentClassName="club-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {({ values, touched, errors, isValid, dirty }) => {
          return (
            <Form>
              <h3 className="title">Club Editor Page</h3>
              <Section
                sectionId="club-editor-general-info"
                title="General Information"
              >
                <Row>
                  <Col>
                    <Input
                      type="text"
                      value={club.clubName}
                      onChange={(e: any) =>
                        updateClub("clubName", e.target.value)
                      }
                      id="club-editor-name"
                      label="Club Name"
                      name="clubName"
                      variant="secondary"
                    />
                    <Input
                      type="text"
                      value={club.about}
                      onChange={(e: any) => updateClub("about", e.target.value)}
                      id="club-editor-about"
                      label="About Our Club"
                      name="about"
                      as=""
                      variant="secondary"
                      className="about"
                      maxLength={255}
                    />
                  </Col>

                  <Col className="club-editor-profile-image">
                    {(!club.image_id) ? <DragFileUpload
                      className="profile-image-upload"
                      id={`profile-image-upload`}
                      label="Drop your file(s)"
                      onDrop={(files: File[]) => {
                        profileUploaderRef.current?.upload("Club Profile", files[0]);
                      }}
                      buttonLabel="browse"
                      multiple
                      useNativeUploader={true}
                    /> : <div>
                      <img className="club-profile-image" src={(club.image) ? getApiDomain() + club.image.path : ""} onClick={() => removeImage()}/>
                    </div>}

                    <ImageUploader
                      ref={profileUploaderRef}
                      hideProgress={true}
                      onUploadComplete={(file: any) => {
                        updateClub("image_id", file.id);
                        updateClub("image", file);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Input
                      type="text"
                      value={club.num_students}
                      onChange={(e: any) =>
                        updateClub("num_students", e.target.value)
                      }
                      id="club-editor-numstudents"
                      label="Number of students"
                      name="num_students"
                      as="input"
                      variant="secondary"
                      className="students"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="text"
                      value={club.meeting_times}
                      onChange={(e: any) =>
                        updateClub("meeting_times", e.target.value)
                      }
                      id="club-editor-meetingtimes"
                      label="Meeting Times"
                      as="input"
                      variant="secondary"
                      className="meetingtimes"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="text"
                      value={club.start_time}
                      onChange={(e: any) =>
                        updateClub("start_time", e.target.value)
                      }
                      id="club-editor-starttime"
                      label="Start Time"
                      name="start_time"
                      as="input"
                      variant="secondary"
                      className="starttime"
                    />
                  </Col>
                  <Col md={3}>
                    <DropDown<IGradeBand>
                      label="Grade Band"
                      id="article-editor-grade-band"
                      labelAttribute="gradeBand"
                      options={gradeBands}
                      selected={gradeBandIds}
                      multiple
                      valueAttribute="id"
                      placeholder="Select Grade"
                      onOptionChange={(val) => setGradeBandIds(val)}
                    />
                  </Col>
                </Row>
              </Section>
              <hr />
              <Section sectionId="club-editor-staff" title="The Club Leaders">
                <Row>
                  <Col md={4}>
                    <ClubLeader
                      className="big"
                      leader={leaders[0]}
                      id="club-editor-staff-1"
                      size="lg"
                      onChange={(data) => updateLeader(0, data)}
                    />
                  </Col>
                  <Col md={3} className="mt-5">
                    <ClubLeader
                      leader={leaders[1]}
                      id="club-editor-staff-2"
                      onChange={(data) => updateLeader(1, data)}
                    />
                    <ClubLeader
                      leader={leaders[2]}
                      id="club-editor-staff-3"
                      onChange={(data) => updateLeader(2, data)}
                    />
                  </Col>
                </Row>
              </Section>

              <Section sectionId="club-editor-staff" title="Upcoming Events">
                {events.map((event, index) => {
                  return (
                    <ClubEvent key={index} event={event} index={index} 
                      onChange={(event) => updateEvent(index, event)}
                      onRemove={(event) => removeEvent(index)}/>
                  );
                })}
                <Row>
                  <Col sm={4}></Col>
                  <Col sm={8} className="add-event">
                    <RoundButton
                      onClick={() => addEvent()}
                      icon={<IcPlus />}
                      variant="primary"
                      roundBtnId={"add-event"}
                      label="Add a new event"
                    />
                  </Col>
                </Row>
              </Section>

              <Section sectionId="club-editor-announcements" title="Announcements">
              <Row>
                  <Col>
                    <Input
                      as="textarea"
                      value={club.announcements}
                      onChange={(e: any) => updateClub("announcements", e.target.value)}
                      id="club-editor-announcements"
                      label=""
                      name="about"
                      variant="secondary"
                      className="about"
                      maxLength={255}
                    />
                  </Col>
                </Row>
              </Section>

              <Section
                sectionId="club-editor-staff"
                title="Families that are part of the club"
              >
                Select the Families that are a part of this club
                <div className="letters">
                  {"ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                    .split("")
                    .map((letter, index) => {
                      return (
                        <div
                          key={index}
                          className="letter"
                          onClick={() => {
                            setFamilySearch(letter);
                            doFamilySearch();
                          }}
                        >
                          {letter}
                        </div>
                      );
                    })}
                </div>
                <SearchBar
                  searchBarId="families-search"
                  onChangeText={(val: string) => setFamilySearch(val)}
                  onSearch={() => doFamilySearch()}
                />
                <div className="families-table">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Family Name</th>
                        <th>Family Members</th>
                        <th>Grade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {familyResults.map((family, index) => {
                        let isMember = false;
                        families.forEach((fam) => {
                          if (fam.id === family.id) {
                            isMember = true;
                          }
                        })
                        return (
                          <tr key={index}>
                            <td>
                              <Checkbox
                                id="notification-check"
                                label=""
                                checked={isMember}
                                onChange={(e) =>
                                  !isMember
                                    ? addFamily(family)
                                    : removeFamily(family)
                                }
                              />
                            </td>
                            <td className="family-name">{family.last_name}</td>
                            <td>
                              {family.member_names.join(", ")}
                            </td>
                            <td>
                              {family.members.reduce(
                                (acc: string, member: any) => {
                                  member.grades?.forEach((grade: any) => {
                                    if (
                                      acc.indexOf(
                                        grade.grade_band.grade_band
                                      ) === -1
                                    ) {
                                      acc += grade.grade_band.grade_band + ", ";
                                    }
                                  });
                                  return acc;
                                },
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Section>

              <Section
                sectionId="club-editor-gallery"
                title="Gallery"
                className="club-editor-gallery"
              >
                <div className="club-editor-gallery">
                  <DragFileUpload
                    className="gallery-upload"
                    id={`gallery-upload`}
                    label="Drop your file(s)"
                    onDrop={() => {}}
                    onClick={() => galleryUploadRef.current?.show()}
                    buttonLabel="browse"
                    multiple
                  />

                  <UploadPopup
                    id="gallery-uploader"
                    mediaType="IMAGE"
                    autoUpload={true}
                    ref={galleryUploadRef}
                    onConfirm={(uploadedMedia: IUploadResponse) => {
                      if (uploadedMedia) {
                        const id = uploadedMedia.mediaId;
                        const tempImage = uploadedMedia.fileUrl;
                      }
                    }}
                  />
                </div>
              </Section>

              <Col md="auto">
                <div className="top-button-container">
                  <StandardButton
                    standardBtnId="article-editor-save-btn"
                    label="Save Updates"
                    variant="outlined"
                    type="submit"
                    disabled={!dirty || !isValid}
                  />
                </div>
              </Col>
            </Form>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default ClubEditor;
