import React from 'react';
import StandardButton from '../button/StandardButton';
import ProfileSelector, { IProfileSelectorProps } from './ProfileSelector';
import IcPlaceholder from 'app/assets/images/placeholder.png';
import useCommon from 'app/hooks/Common';
import './profile-selector.scss';

const EditProfileImage: React.FC<IEditProfileImageProps> = ({
  mode = 'horizontal',
  url = IcPlaceholder,
  id,
  onConfirm,
}) => {
  const _editor = useCommon();
  return (
    <>
      <div className={`edit-profile ${mode}`}>
        <img src={url ?? IcPlaceholder} alt='' />
        <div className={`edit-profile-btn`}>
          <StandardButton
            onClick={_editor.onClickUploadImage}
            label='Edit Profile Image'
            size='sm'
            type='button'
            standardBtnId='edit-profile-upload'
            variant='outlined'
          />
        </div>
      </div>
      <ProfileSelector
        ref={_editor.uploadImageModalRef}
        id={`${id}`}
        onConfirm={onConfirm}
        useNativeUploader
      />
    </>
  );
};

export default EditProfileImage;

interface IEditProfileImageProps extends IProfileSelectorProps {
  mode?: 'horizontal' | 'vertical';
  url?: string;
}
