import React, { useRef, useEffect } from "react";
import moment from "moment";
import StandardButton from "app/components/button/StandardButton";
// import ContentEditor from 'app/components/contentEditor/ContentEditor';
import ContentEditor from "app/components/contentEditor/HtmlContentEditor";
import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import DropDown from "app/components/dropDown/DropDown";
import Input from "app/components/input/Input";
import Switch from "app/components/switch/Switch";
import useArticleEditor from "app/hooks/ArticleEditor";
import PrivateLayout from "app/layout/PrivateLayout";
import useModifiedState from "app/hooks/ModifiedState";
import UploadPopup, { UploadPopUpRef } from "../sitePhotos/uploadPopup/UploadPopup";
import { IArticle, IArticleVersion } from "app/models/article";
import { ICategoryTag, IDesignation, IStatus, IUploadResponse } from "app/models/common";
import ArticlePreviewPopup from "./components/ArticlePreviewPopup";
import { IGradeBand, ISchoolType } from "app/models/program";
import { articleSchema } from "app/utils/validations";
import { getArticleVersions } from "app/api/adminApis";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as IcImage } from "app/assets/icons/images.svg";
import { ReactComponent as IcPreview } from "app/assets/icons/preview.svg";
import { useParams } from "react-router-dom";
import "./articles.scss";

const ArticleContentEditor: React.FC = (props) => {
  const _editor = useArticleEditor();
  const params = useParams();

  const [state, dispatch] = useModifiedState<IPreviousVersionsState>({
    versions: [],
    currentPage: 1,
  });

  useEffect(() => {
    params.id && _getVersions(+params.id);
    return () => {
      _editor.resetForm();
    };
  }, []);

  const _getVersions = async (id: number) => {
    try {
      const { data } = await getArticleVersions(id);
      const versionData = (data || [])
        .sort((a, b) => (+a.version > +b.version ? -1 : 1))
        .map((item, index) => {
          let formattedDate = "";
          try {
            formattedDate = moment(item.updatedDate).format("MM.DD.YYYY");
            formattedDate = ` · ${formattedDate}`;
          } catch (err) {
            console.log("Error formatting the version date: ", err);
          }

          return {
            ...item,
            dropdownLabel: `Version ${item.version}${formattedDate}`,
          };
        });

      dispatch({ versions: versionData });
    } catch (error) {
      console.log("🚀 ~ file: PreviousVersionsPopUp.tsx  ~ const_getVersions= ~ error", error);
    }
  };

  function getFeaturedImage(initialValues: IArticle, values: IArticle) {
    const defaultImage = values?.featuredImageUrl;
    const tmpImage = values.tmpFeaturedImage;
    const currentImage = tmpImage?.base64Str || defaultImage;

    return currentImage ? <img src={currentImage} style={{ width: "100%", borderRadius: 10 }} /> : <IcImage className="p-1 drop-icon" />;
  }

  function getSelectedVersion(values: IArticle) {
    return values.versionId || 0;
  }

  let article = null;
  if (state.versions) {
    article = state.versions[0];
  }
  return (
    <PrivateLayout contentClassName="article-preview">
		<h1>Article Preview</h1>
      {article ? (
        <div className="article-container">
		  <img src={article.featuredImageUrl} />
          <h3>{article.articleTitle}</h3>
          <div dangerouslySetInnerHTML={{__html:article.articleContent}}></div>
        </div>
      ) : (
        []
      )}
    </PrivateLayout>
  );
};

export default ArticleContentEditor;

interface IPreviousVersionsState {
  versions: IArticleVersion[];
  currentPage: number;
}
