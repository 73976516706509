const useAccordion = (title?: string) => {
  const permissionOptions = () => {
    let options: EDITOR_OPTIONS[] = [];
    switch (title) {
      case EDITOR_OPTIONS_TITLE.Standard_Emails:
        options = standardEmailOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Triggered_Emails:
        options = triggeredEmailOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Families:
        options = familyOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Ambassadors:
        options = familyOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Staff:
        options = familyOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Admins:
        options = familyOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Graduates:
        options = familyOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Club_Leaders:
        options = familyOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Articles:
        options = articleOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Q_A:
        options = Q_AOptions;
        break;
      case EDITOR_OPTIONS_TITLE.Outcomes:
        options = Outcome_options;
        break;
      case EDITOR_OPTIONS_TITLE.Homepage:
        options = homepage_options;
        break;
      case EDITOR_OPTIONS_TITLE.Configuration:
        options = configuration_options;
        break;
      case EDITOR_OPTIONS_TITLE.Email_Reporting:
        options = email_reporting;
        break;
      case EDITOR_OPTIONS_TITLE.Program_Reporting:
        options = program_reporting;
        break;
      case EDITOR_OPTIONS_TITLE.Integrations:
        options = integrations;
        break;
      case EDITOR_OPTIONS_TITLE.Data_Management:
        options = Upload_Data;
        break;
      default:
        options = [];
        break;
    }
    return options;
  };

  return {
    permissionOptions,
  };
};

export default useAccordion;

export enum EDITOR_OPTIONS {
  View_Standard_Emails = "View Standard Emails",
  Edit_Standard_Emails = "Edit Standard Emails",
  Approve_Standard_Emails = "Approve Standard Emails",
  Test_Standard_Emails = "Test Standard Emails",
  Schedule_Standard_Emails = "Schedule Standard Emails",
  View_Triggered_Emails = "View Triggered Emails",
  Edit_Triggered_Emails = "Edit Triggered Emails",
  Approve_Triggered_Emails = "Approve Triggered Emails",
  Test_Triggered_Emails = "Test Triggered Emails",
  Schedule_Triggered_Emails = "Schedule Triggered Emails",
  View = "View",
  Edit = "Edit",
  Create = "Create",
  Delete = "Delete",
  View_Articles = "View Articles",
  Edit_Articles = "Edit Articles",
  Delete_Articles = "Delete Articles",
  View_Article_Approvals = "View Article Approvals",
  Edit_Article_Approvals = "Edit Article Approvals",
  View_Q_A = "View Q&A",
  Edit_Q_A = "Edit Q&A",
  Delete_Q_A = "Delete Q&A",
  View_Q_A_Approvals = "View Q&A Approvals",
  Edit_Q_A_Approvals = "Edit Q&A Approvals",
  View_Outcomes = "View Outcomes",
  Edit_Outcomes = "  Edit Outcomes",
  Delete_Outcome_Sections = "  Delete Outcome Sections",
  Edit_Homepage = "Edit Homepage",
  View_Configurations = "View Configurations",
  Edit_All_Configurations = "  Edit All Configurations",
  Edit_Color_Configurations = "Edit Color Configurations",
  Edit_Program_Merge_Field_Configurations = "Edit Program Merge Field Configurations",
  Edit_Demographic_Configurations = "Edit Demographic Configurations",
  Edit_Admin_Contact_Configurations = "Edit Admin Contact Configurations",
  Edit_Program_Grade_Band_Configurations = "Edit Program Grade Band Configurations",
  Edit_Program_Detail_Configurations = "Edit Program Detail Configurations",
  Edit_Program_Feature_Configurations = "Edit Program Feature Configurations",
  Edit_Title_and_Photo_Configurations = "Edit Title and Photo Configurations",
  Edit_Activities_Configurations = "Edit Activities Configurations",
  View_Email_Reporting = "View Email Reporting",
  View_Program_Reporting = "View Program Reporting",
  View_Integrations = "View Integrations",
  Create_Integrations = "Create Integrations",
  Edit_Integrations = "Edit Integrations",
  Upload_Data = "Upload Data",
}

export enum EDITOR_OPTIONS_TITLE {
  Standard_Emails = "Standard Emails",
  Triggered_Emails = "Triggered Emails",
  Admins = "Admins",
  Families = "Families",
  Ambassadors = "Ambassadors",
  Staff = "Staff",
  Graduates = "Graduates",
  Club_Leaders = "Club Leaders",
  Articles = "Articles",
  Q_A = "Q&A",
  Outcomes = "Outcomes",
  Homepage = "Homepage",
  Configuration = "Configuration",
  Email_Reporting = "Email Reporting",
  Program_Reporting = "Program Reporting",
  Integrations = "Integrations",
  Data_Management = "Data Management",
}

const standardEmailOptions: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Standard_Emails,
  EDITOR_OPTIONS.Edit_Standard_Emails,
  EDITOR_OPTIONS.Approve_Standard_Emails,
  EDITOR_OPTIONS.Test_Standard_Emails,
  EDITOR_OPTIONS.Schedule_Standard_Emails,
];

const triggeredEmailOptions: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Triggered_Emails,
  EDITOR_OPTIONS.Edit_Triggered_Emails,
  EDITOR_OPTIONS.Approve_Triggered_Emails,
  EDITOR_OPTIONS.Test_Triggered_Emails,
  EDITOR_OPTIONS.Schedule_Triggered_Emails,
];

const familyOptions: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View,
  EDITOR_OPTIONS.Edit,
  EDITOR_OPTIONS.Create,
  EDITOR_OPTIONS.Delete,
];

const articleOptions: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Articles,
  EDITOR_OPTIONS.Edit_Articles,
  EDITOR_OPTIONS.Delete_Articles,
  EDITOR_OPTIONS.View_Article_Approvals,
  EDITOR_OPTIONS.Edit_Article_Approvals,
];

const Q_AOptions: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Q_A,
  EDITOR_OPTIONS.Edit_Q_A,
  EDITOR_OPTIONS.View_Q_A_Approvals,
  EDITOR_OPTIONS.Edit_Q_A_Approvals,
  EDITOR_OPTIONS.Delete_Q_A,
];

const Outcome_options: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Outcomes,
  EDITOR_OPTIONS.Edit_Outcomes,
  EDITOR_OPTIONS.Delete_Outcome_Sections,
];

const homepage_options: EDITOR_OPTIONS[] = [EDITOR_OPTIONS.Edit_Homepage];

const configuration_options: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Configurations,
  EDITOR_OPTIONS.Edit_All_Configurations,
  EDITOR_OPTIONS.Edit_Color_Configurations,
  EDITOR_OPTIONS.Edit_Program_Merge_Field_Configurations,
  EDITOR_OPTIONS.Edit_Demographic_Configurations,
  EDITOR_OPTIONS.Edit_Admin_Contact_Configurations,
  EDITOR_OPTIONS.Edit_Program_Grade_Band_Configurations,
  EDITOR_OPTIONS.Edit_Program_Detail_Configurations,
  EDITOR_OPTIONS.Edit_Program_Feature_Configurations,
  EDITOR_OPTIONS.Edit_Title_and_Photo_Configurations,
  EDITOR_OPTIONS.Edit_Title_and_Photo_Configurations,
  EDITOR_OPTIONS.Edit_Activities_Configurations,
];

const email_reporting: EDITOR_OPTIONS[] = [EDITOR_OPTIONS.View_Email_Reporting];
const program_reporting: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Program_Reporting,
];
const integrations: EDITOR_OPTIONS[] = [
  EDITOR_OPTIONS.View_Integrations,
  EDITOR_OPTIONS.Edit_Integrations,
  EDITOR_OPTIONS.Create_Integrations,
];
const Upload_Data: EDITOR_OPTIONS[] = [EDITOR_OPTIONS.Upload_Data];
