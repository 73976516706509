import { IAPIResponse, IAPIResponseV2 } from 'app/models/api';
import { ICommonAppData, IUploadResponse } from 'app/models/common';
import { IAppConfig } from 'app/models/config';
import { IOutcome } from 'app/models/outcome';
import { INotificationType } from 'app/models/program';
import { IPreference } from 'app/models/preference';

import { Api } from '.';

const unAuthenticatedApi = new Api('', true);
const api = new Api('');

/**
 * upload files
 */
export const upload = (
  files: File[],
  folderId: string,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
) => {
  const formData = files.reduce((formData, file) => {
    formData.append('file[]', file);
    return formData;
  }, new FormData());
  formData.append('folderId', folderId);
  return api.post<FormData, IUploadResponse[]>('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

// "name": "default",
// "image": imageFile,
// "owner_id": 1234,
// "owner_type": "Program",

export const uploadV2 = (
  files: File[],
  folderId: string,
  ownerId: number | null,
  ownerType: string | null,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
) => {
  if (!(ownerId && ownerType)) return { data: [] };

  const formData = files.reduce((formData, file) => {
    // formData.append('file[]', file);
    formData.append('name', file.name);
    formData.append('image', file);
    formData.append('folder_id', folderId);
    formData.append('owner_id', '' + ownerId);
    formData.append('owner_type', ownerType);
    return formData;
  }, new FormData());
  formData.append('folderId', folderId);
  return api.post<FormData, IUploadResponse[]>('/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};


export const uploadImage = (
  name: string,
  image: File,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
) => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('image', image);

  return api.post<FormData, IUploadResponse[]>('/images', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};


export const getCommonAppData = () =>
  unAuthenticatedApi.get<IAPIResponse<ICommonAppData>>('/seeder');

export const getAppConfig = () =>
  unAuthenticatedApi.get<IAPIResponse<IAppConfig>>('/configurations/admin');

export const getOutcomeList = () =>
  api.get<IAPIResponse<IOutcome[]>>('/outcomes');

export const getPreferenceList = () =>
  api.get<IAPIResponseV2<IPreference[]>>('/preferences');

export const getNotificationTypeList = () =>
  api.get<IAPIResponseV2<INotificationType[]>>('/notification-types');
