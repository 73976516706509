import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import Switch from 'app/components/switch/Switch';
import useTriggerEmailListing from 'app/hooks/TriggeredEmailListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import {
  // ICategoryTags,
  ICategoryTag,
  IDesignation,
  IPopulation,
  IStatus,
} from 'app/models/common';
import { ITriggeredDescription, ITriggeredEmail } from 'app/models/email';
import { IGradeBand } from 'app/models/program';
import { emptyFunction } from 'app/utils/common';
import React from 'react';
import './admin.scss';

const GRID_ID = 'admin-trigger-email-manager-listing';

const AdminTriggerEmailManager: React.FC = () => {
  const _adminTriggerEmailListing = useTriggerEmailListing();
  return (
    <PrivateLayout contentClassName='admin-trigger-email-listing'>
      <StandardButton
        label='+ New Triggered Email'
        variant='secondary'
        className='new-trigger-email-button'
        onClick={emptyFunction}
        standardBtnId='trigger-email-listing-new-btn'
      />
      <h3 className='title'>Admin Triggered Email Manager</h3>
      <SearchBar
        placeHolder='Search by description, keyword or email ID'
        searchBarId='admin-trigger-email-listing-search'
      />
      <div className='email-manager-listing-filter-section'>
        <Filter
          id='admin-trigger-email-filter'
          onFilterChange={_adminTriggerEmailListing.onFilterChange}
          selectedFilters={_adminTriggerEmailListing.filters}
        >
          <FilterOption<IGradeBand>
            labelAttribute='gradeBand'
            valueAttribute='id'
            name='grades'
            options={[]}
            placeholder='Grade Band'
          />
          <FilterOption<IDesignation>
            // labelAttribute='designation'
            labelAttribute='designationType'
            valueAttribute='id'
            name='designation'
            options={[]}
            placeholder='Designation'
          />
          <FilterOption<ICategoryTag>
            // labelAttribute='category'
            labelAttribute='categoryTag'
            valueAttribute='id'
            name='category'
            options={[]}
            placeholder='Category Tags'
          />
          <FilterOption<IPopulation>
            labelAttribute='population'
            valueAttribute='id'
            name='population'
            options={[]}
            placeholder='Population'
          />
          <FilterOption<IStatus>
            labelAttribute='status'
            valueAttribute='id'
            name='status'
            options={[]}
            placeholder='Approval Status'
          />
        </Filter>
      </div>
      <DataGrid<ITriggeredEmail>
        data={_adminTriggerEmailListing.data}
        gridId={GRID_ID}
        rowIdAttribute='emailId'
        currentPage={_adminTriggerEmailListing.currentPage}
        onPageChange={_adminTriggerEmailListing.onPageChange}
        totalItems={_adminTriggerEmailListing.totalItems}
        onAction={_adminTriggerEmailListing.onGridAction}
      >
        <DataGrid.Column<ITriggeredEmail>
          attributeName='emailId'
          header='Email ID'
        />
        <DataGrid.Column<ITriggeredEmail>
          attributeName='emailDescription'
          header='Email Description'
        />
        {/* <DataGrid.Column<ITriggeredEmail>
          attributeName='gradeBand'
          header='Grade Band'
        /> */}
        <DataGrid.Column<ITriggeredEmail>
          attributeName='designationType'
          header='Designation Type'
        />
        <DataGrid.Column<ITriggeredEmail>
          attributeName='categoryTags'
          header='Category Tags'
        />
        <DataGrid.Column<ITriggeredEmail>
          attributeName='approvalStatus'
          header='Approval Status'
        />
        <DataGrid.Column<ITriggeredEmail>
          attributeName='populationReceivingEmail'
          header='POPULATION RECEIVING EMAIL'
        />
        <DataGrid.Column<ITriggeredEmail>
          attributeName='isActive'
          header='ACTIVATE STATUS'
          transform={(email) => (
            <Switch
              checked={email.isActive}
              onChange={_adminTriggerEmailListing.onToggleActive(email)}
              switchId='admin-trigger-email-listing-live-btn'
            />
          )}
        />
        <DataGrid.Column<ITriggeredEmail>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<ITriggeredEmail>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
    </PrivateLayout>
  );
};

export default AdminTriggerEmailManager;
