import React from "react";
import "./button.scss";

const SquircleButton: React.FC<ISquircleButtonProps> = ({
  icon: Icon,
  onClick,
  squircleBtnId,
}) => {
  return (
    <button
      className="squircle-button"
      type="button"
      id={squircleBtnId}
      onClick={onClick}
    >
      <Icon />
    </button>
  );
};

export default SquircleButton;

interface ISquircleButtonProps {
  squircleBtnId: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
}
