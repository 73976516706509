import { IDropdownItem } from './DataUploadManager';
import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
  getEmailSchedule,
  listAllThink12EmailTemplates,
  createEmailSchedule,
  updateEmailSchedule,
} from 'app/api/adminApis';
import { IDropdownOption } from '../models/program';
import { useAppState } from './../state/AppState';
import { appStateSelectors } from './../state/AppState';
import {
  LIMIT_TYPE,
  IEmailManager,
  IEmailScheduleItem,
  IEmailScheduleItemBuilder,
} from 'app/models/email';
import { IGradeBand } from '../models/program';
import { processErrorResponse } from 'app/utils/common';
import useApi from './Api';
import usePagination from './Pagination';

interface IFrequencyOptionDetail extends IDropdownOption {
  label: string;
}

interface IFrequencyOptionBase {
  JUST_ONCE: IFrequencyOptionDetail;
  WEEKLY: IFrequencyOptionDetail;
  MONTHLY: IFrequencyOptionDetail;
}
interface IUserScheduleFrequency extends IFrequencyOptionBase {
  EVERY_SEND: IFrequencyOptionDetail;
}
interface IEmailScheduleFrequency extends IFrequencyOptionBase {
  DAILY: IFrequencyOptionDetail;
}

interface IRegistrationStatusOption extends IDropdownOption {
  registrationStatus: string;
}

const useEmailScheduleEditor = () => {
  const navigate = useNavigate();

  const [sendNow, setSendNow] = useState<boolean>(true);

  const params = useParams();

  const formRef = useRef<FormikProps<IEmailScheduleItemBuilder>>(null);

  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const gradeBands = useAppState(appStateSelectors.gradeBands);

  const emailScheduleFrequencyOptions: IEmailScheduleFrequency = {
    JUST_ONCE: { id: 1, label: 'Just once', value: 'once' },
    DAILY: { id: 2, label: 'Once a day', value: 'daily' },
    WEEKLY: { id: 3, label: 'Once a week', value: 'weekly' },
    MONTHLY: { id: 4, label: 'Once a month', value: 'monthly' },
  };

  const emailScheduleUserFrequencyOptions: IUserScheduleFrequency = {
    JUST_ONCE: { id: 1, label: 'Just once', value: 'once' },
    EVERY_SEND: { id: 2, label: 'On every send', value: 'every_send' },
    WEEKLY: { id: 3, label: 'Once a week', value: 'weekly' },
    MONTHLY: { id: 4, label: 'Once a month', value: 'monthly' },
  };

  const registrationStatuses: IRegistrationStatusOption[] = [
    {
      id: 1,
      registrationStatus: 'Register, Non-validated users',
      value: 'non-validated',
    },
    {
      id: 2,
      registrationStatus: 'Register, Validated users',
      value: 'validated',
    },
    {
      id: 3,
      registrationStatus: 'Register (Regardless of validation status)',
      value: 'all',
    },
  ];

  const timeZoneOptions: IDropdownOption[] = [
    { id: 1, value: 'AM' },
    { id: 2, value: 'PM' },
  ];

  const [_data, _error, _call] = useApi<IEmailScheduleItem>(
    getEmailSchedule,
    undefined,
    true,
    false
  );

  const paginatedTemplates = usePagination<IEmailManager>({
    listFn: listAllThink12EmailTemplates,
  });

  useEffect(() => {
    params.id && _call({ id: params.id });
  }, []);

  const onEmailTemplateSearch = (searchKey: string) => {
    // _getEmailTemplates({ searchKey });
  };

  const onSelect = (param: string) => (value: number | string | Date) => {
    formRef.current?.setFieldValue(param, value);
  };

  const onEmailTemplateSelection = (selected: number[]) => {
    formRef.current?.setFieldValue('emailTemplateIds', selected);
  };

  const onLimitChange =
    (type: LIMIT_TYPE) => (operation: string, value: number | string) => {
      console.log(type, operation, value);
      formRef.current?.setValues((prevValues) => ({
        ...prevValues,
        [type]: { operation: value, value: 0 },
      }));
    };

  const onGradeBandChange = (gradeBandId: number) => (checked: boolean) => {
    let updatedGradeBandIds = [
      ...(formRef.current?.values?.gradeBandIds || []),
    ];
    if (checked) {
      updatedGradeBandIds.push(gradeBandId);
    } else {
      const index = updatedGradeBandIds?.indexOf(gradeBandId);
      updatedGradeBandIds.splice(index, 1);
    }

    formRef.current?.setFieldValue('gradeBandIds', updatedGradeBandIds);
  };

  const onSelectSendOption = (option: string) => {
    const defaultStartTime =
      option !== 'Now'
        ? formRef.current?.values.startTime || moment().toISOString()
        : moment().toISOString();

    formRef.current?.setValues((state) => ({
      ...state,
      startTime: defaultStartTime,
      scheduleTime: option === 'Now' ? undefined : state.scheduleTime,
      sendNow: option === 'Now',
    }));
  };

  const _transformFrequency = (
    type: 'frequency' | 'userFrequency',
    params: IEmailScheduleItemBuilder
  ) => {
    const options: IUserScheduleFrequency | IEmailScheduleFrequency =
      type === 'frequency'
        ? emailScheduleFrequencyOptions
        : emailScheduleUserFrequencyOptions;
    const selected = (Object.values(options) as IFrequencyOptionDetail[]).find(
      (option: IFrequencyOptionDetail) => option.id === +params[type]
    );

    return selected?.value || 'once';
  };

  const _transformRegistrationStatus = (params: IEmailScheduleItemBuilder) => {
    const selected = registrationStatuses.find(
      (status) => status.id === params.registrationStatus
    );

    return selected ? selected.value : 'validated';
  };

  const _transformTime = (
    params: IEmailScheduleItemBuilder,
    type: 'startTime' | 'endTime'
  ) => {
    if (!params.startTime) return undefined;

    try {
      const date = moment(params[type]).format('Y-M-D');
      const time = moment(params.scheduleTime, 'hh:mm a').format('HH:mm:ss');
      return `${date}\T${time}`;
    } catch (err) {
      console.log(`Error formatting ${type} time string: ${err}`);
    }

    return undefined;
  };

  const formatParams = (params: IEmailScheduleItemBuilder) => {
    const formatted = { ...params };

    formatted.frequency = _transformFrequency('frequency', params);
    formatted.userFrequency = _transformFrequency('userFrequency', params);
    formatted.registrationStatus = _transformRegistrationStatus(params);
    formatted.startTime = _transformTime(params, 'startTime');
    formatted.endTime = _transformTime(params, 'endTime');

    return formatted;
  };

  const onSubmit = async (emailSchedule: IEmailScheduleItemBuilder) => {
    showLoader();
    hideNotification();

    const formattedParams = formatParams(emailSchedule);
    try {
      params?.id
        ? await updateEmailSchedule(+params.id, formattedParams)
        : await createEmailSchedule(formattedParams);
      navigate(-1);
    } catch (error) {
      processErrorResponse({ error, callback: showNotification });
      console.log(
        `Error ${
          params?.id ? 'updating' : 'creating'
        } email schedule: ${error} `
      );
    } finally {
      hideLoader();
    }
  };

  return {
    ..._data,
    formRef,
    initialValues,
    registrationStatuses,
    gradeBands,
    sendNow,
    timeZoneOptions,
    emailScheduleFrequencyOptions,
    emailScheduleUserFrequencyOptions,
    selectableEmailTemplates: paginatedTemplates.data,
    selectableEmailTemplatesLoading: paginatedTemplates.loading,
    // selecttableEmailTemplatesError: _emailTemplatesError,
    onSelect,
    onLimitChange,
    onGradeBandChange,
    onEmailTemplateSelection,
    onEmailTemplateSearch,
    onSelectSendOption,
    onSubmit,
  };
};

const initialValues: IEmailScheduleItem = {
  isActive: false,
  emailTemplateIds: [],
  registrationStatus: 'all',
  gradeBandIds: [],
  frequency: 1,
  userFrequency: 1,
  sendNow: true,
};

export interface IEmailScheduleEditor {
  formRef: React.RefObject<FormikProps<IEmailScheduleItemBuilder>>;
  initialValues: IEmailScheduleItem;
  registrationStatuses: IRegistrationStatusOption[];
  gradeBands: IGradeBand[];
  sendNow: boolean;
  timeZoneOptions: IDropdownOption[];
  emailScheduleFrequencyOptions: IEmailScheduleFrequency;
  emailScheduleUserFrequencyOptions: IUserScheduleFrequency;
  selectableEmailTemplates: IEmailManager[];
  selectableEmailTemplatesLoading?: boolean;
  selecttableEmailTemplatesError?: any;
  onSelect: (param: string) => (value: number | string | Date) => void;
  onLimitChange: (
    type: LIMIT_TYPE
  ) => (operation: string, value: number | string) => void;
  onGradeBandChange: (gradeBandId: number) => (checked: boolean) => void;
  onEmailTemplateSelection: (selected: number[]) => void;
  onEmailTemplateSearch: (searchKey: string) => void;
  onSelectSendOption: (option: string) => void;
  onSubmit: (emailSchedule: IEmailScheduleItemBuilder) => void;
}

export default useEmailScheduleEditor;
