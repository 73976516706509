import React, { useEffect } from "react";

const useOutsideClick = (
  ref: React.MutableRefObject<any>,
  handler?: Function,
  exceptions?: React.MutableRefObject<any>
) => {
  useEffect(() => {
    document.addEventListener("mousedown", _handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, [ref]);

  const _handleClickOutside = (e: MouseEvent) => {
    if (exceptions && exceptions.current?.contains(e.target)) {
      return;
    }
    if (!ref.current?.contains(e.target)) {
      handler?.();
    }
  };

  return {};
};

export default useOutsideClick;
