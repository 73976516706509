import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'lodash';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import RadioButtonGroup from 'app/components/radioButtonGroup/RadioButtonGroup';
import RadioButtonOption from 'app/components/radioButtonGroup/RadioButtonOption';
import {
  IEmailScheduleItem,
  IEmailScheduleLimitOption,
  LIMIT_TYPE,
} from 'app/models/email';

interface EmailScheduleLimitsSection {
  editor: any;
  values: IEmailScheduleItem;
  onRegistrationLimitChange?: (
    type: keyof IEmailScheduleLimitOption,
    value: any
  ) => void;
  onValidationLimitChange?: (
    type: keyof IEmailScheduleLimitOption,
    value: any
  ) => void;
  onLoginLimitChange?: (
    type: keyof IEmailScheduleLimitOption,
    value: any
  ) => void;
}

const LIMITS = [
  {
    value: 1,
    label: 'Is less than',
    type: 'registrationLimit',
    title: 'Number of days since registration',
  },
  {
    value: 2,
    label: 'Is greater than',
    type: 'validationLimit',
    title: 'Number of days since validation',
  },
  {
    value: 3,
    label: 'Is equal to',
    type: 'loginLimit',
    title: 'Number of days since last log in',
  },
];

const LIMIT_OPTIONS = [
  { value: 1, label: 'Is less than', symbol: '<' },
  { value: 2, label: 'Is greater than', symbol: '>' },
  { value: 3, label: 'Is equal to', symbol: '=' },
];

export const EmailScheduleLimitsSection = ({
  editor,
  values,
}: EmailScheduleLimitsSection) => {
  const _onOptionSelect = (param: LIMIT_TYPE) => (value: number) => {
    const option = LIMIT_OPTIONS.find((option) => option.value === +value);
    editor.onLimitChange(param)('operation', option?.symbol);
  };

  return (
    <Section
      title='Limit who will receive an email (optional)'
      sectionId='emailScheduleLimits'
      titleSize='md'
      className='email-schedule-limits mt-4'
    >
      <Row>
        {LIMITS.map((limitType) => {
          const type = limitType.type as LIMIT_TYPE;
          return (
            <Col>
              <h6 className='mt-5'>{limitType.title}</h6>
              <RadioButtonGroup<number>
                id='test'
                onSelect={_onOptionSelect(type)}
              >
                {LIMIT_OPTIONS.map(
                  (limitOption: {
                    value: number;
                    label: string;
                    symbol: string;
                  }) => {
                    const currentFormValue = get(values, `${type}.operation`);
                    const checked = currentFormValue === limitOption?.symbol;
                    return (
                      <RadioButtonOption
                        id={`email-schedule-limit-option-${limitOption.value}`}
                        checked={checked}
                        label={
                          <div className='limit-option'>
                            <div className='limit-option-label'>
                              {limitOption.label}
                            </div>
                            <Input
                              id={`${type}-value`}
                              variant='secondary'
                              label=''
                              placeholder={checked ? 'Number' : ''}
                              value={checked ? undefined : ''}
                              disabled={!checked}
                              name={checked ? `${type}.value` : 'unselected'}
                              type='number'
                              className='limit-option-value'
                              min={0}
                            />
                          </div>
                        }
                        value={limitOption.value}
                      />
                    );
                  }
                )}
              </RadioButtonGroup>
            </Col>
          );
        })}
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};
