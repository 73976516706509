import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';

import PrivateLayout from 'app/layout/PrivateLayout';
import Switch from 'app/components/switch/Switch';
import EditProfileImage from 'app/components/profileSelector/EditProfileImage';
import ConfirmPopup from 'app/components/confirmPopup/ConfirmPopup';
import ScreenNotification from 'app/components/screenNotification/ScreenNotification';
import StandardButton from 'app/components/button/StandardButton';
import { GeneralSection } from './components/GeneralSection';
import { EducationSection } from './components/EducationSection';
import { AboutSection } from './components/AboutSection';
import { ImageAndQuoteSection } from './components/ImageAndQuoteSection';
import { MoreAboutSection } from './components/MoreAboutSection';
import { ReviewsSection } from './components/ReviewsSection';
import useStaffEditor from 'app/hooks/StaffEditor';
import { IStaffBuilder } from 'app/models/staff';
import './staff.scss';

const StaffEditor: React.FC = () => {
  const _editor = useStaffEditor();

  return (
    <PrivateLayout contentClassName='staff-editor'>
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues as IStaffBuilder}
        onSubmit={_editor.onSubmit}
      >
        {({ values, isValid, dirty, errors, touched }) => {
          const params = {
            values,
            touched,
            errors,
            editor: _editor,
          };

          console.log({ values });
          return (
            <>
              <Form>
                <Row className='my-5'>
                  <Col>
                    <h3 className='title'>Staff Profile Editor</h3>
                  </Col>
                  <Col className='col-auto'>
                    <div className='staff-editor-toggle'>
                      <p>Standard</p>
                      <Switch
                        switchId='staff-editor-featured'
                        className='mx-2'
                        checked={!values.is_standard}
                        onChange={(checked: boolean) => {
                          _editor.accountTypePopupRef.current?.show({
                            description: `Plese confirm that you would like to change the type of user to ${
                              checked ? 'Featured' : 'Standard'
                            }`,
                          });
                        }}
                      />
                      <p>Featured</p>
                    </div>
                  </Col>
                </Row>
                <ScreenNotification
                  label='Errors'
                  variant='danger'
                  styles={{ container: { marginTop: '2rem' } }}
                />
                <Row>
                  <Col md='auto'>
                    <EditProfileImage
                      mode='vertical'
                      url={
                        values.profile_image?.tmpFileUrl ??
                        values.profile_image?.fileUrl ??
                        values.profile_image?.url ??
                        ''
                      }
                      id='staff-builder-profile'
                      onConfirm={_editor.onChangeImage('profile_image')}
                    />
                  </Col>
                  <Col>
                    <GeneralSection {...params} />

                    <EducationSection {...params} />

                    <AboutSection {...params} />

                    <ImageAndQuoteSection {...params} />

                    <MoreAboutSection {...params} />

                    <ReviewsSection {...params} />

                    <StandardButton
                      type='submit'
                      variant='secondary'
                      label='Save Changes'
                      disabled={!dirty || !isValid}
                      className='save-button'
                      standardBtnId='staff-builder-save-btn'
                    />
                  </Col>
                </Row>
              </Form>

              <ConfirmPopup
                id='staff-acccount-type'
                confirmTitle='Confirm staff type change'
                confirmDescription=''
                onConfirm={() =>
                  _editor.onChange('is_standard')(!values.is_standard)
                }
                ref={_editor.accountTypePopupRef}
              />
            </>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default StaffEditor;

{
  /* TODO this section need to be added later*/
}
{
  /* <p>Activities I like (Select at least 3)</p>
                      <ActivityContainer activites={[]} id='' /> */
}

{
  /* <Section
                  title="My Clubs"
                  sectionId="staffBuilderUpload"
                  titleSize="lg"
                  className="staff-builder-upload"
                  isEditable={true}
                >
                  <div>
                      No clubs added yet. Discover all the clubs that {_editor.schoolName} offers.
                    <a className="browse-clubs-link" href="#">
                      Browse Clubs here.
                    </a>
                  </div>
                </Section> */
}
