import React from "react";
import "./button.scss";

const TextButton = <T,>({
  textBtnId,
  label,
  active,
  size = "md",
  className = "",
  onClick,
}: ITextButtonProps<T>) => {
  const _onClick = () => onClick?.(label);

  return (
    <button
      onClick={_onClick}
      id={textBtnId}
      type="button"
      className={`text-button ${size} ${className} ${active ? "active" : ""}`}
    >
      {label}
    </button>
  );
};

export default TextButton;

interface ITextButtonProps<T> {
  textBtnId: string;
  label: T;
  active?: boolean;
  className?: string;
  size?: "sm" | "md";
  onClick?: (label: T) => void;
}
