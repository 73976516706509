import React, { useState, useImperativeHandle, useRef } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';

import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import Input from 'app/components/input/Input';
import StandardButton from 'app/components/button/StandardButton';
import { ILocation } from 'app/models/config';
import { getErrors } from 'app/utils/data';
import '../address-popup.scss';

const AddressPopup = React.forwardRef<AddressPopupRef, IAddressPopupProps>(
  ({ id, onLocationChange }, ref) => {
    const modalRef = useRef<T12ModalRef>(null);
    const [state, setState] = useState<ILocation | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        show: (location: ILocation) => {
          setState(location);
          modalRef.current?.show();
        },
        close: () => modalRef.current?.close(),
      }),
      []
    );

    const { line1, line2, city, subdivision, country, code } = state || {};
    return (
      <T12Modal
        modalId={id}
        hideFooter
        size='lg'
        ref={modalRef}
        title='Location Details'
        className='location-details-modal'
        showHeaderBorder
      >
        <Formik
          initialValues={{
            line1,
            line2,
            city,
            subdivision,
            country,
            code,
          }}
          onSubmit={() => {}}
        >
          {(formikProps) => {
            const { values, isValid, dirty, errors, touched } = formikProps;

            return (
              <Container>
                <Row>
                  <Col>
                    <Input
                      id='configuration-editor-details-program-name'
                      label='Line 1'
                      variant='secondary'
                      name='line1'
                      error={getErrors(touched, errors, 'school.name')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id='configuration-editor-details-program-abbreviation'
                      label='Line 2'
                      variant='secondary'
                      name='line2'
                      error={getErrors(touched, errors, 'school.abbreviation')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id='configuration-editor-details-program-abbreviation'
                      label='City'
                      variant='secondary'
                      name='city'
                      error={getErrors(touched, errors, 'school.abbreviation')}
                    />
                  </Col>
                  <Col>
                    <Input
                      id='configuration-editor-details-program-abbreviation'
                      label='State'
                      variant='secondary'
                      name='subdivision'
                      error={getErrors(touched, errors, 'school.abbreviation')}
                    />
                  </Col>
                  <Col>
                    <Input
                      id='configuration-editor-details-program-abbreviation'
                      label='Zip Code'
                      variant='secondary'
                      name='code'
                      error={getErrors(touched, errors, 'school.abbreviation')}
                    />
                  </Col>
                </Row>
                <div className='action-row'>
                  <StandardButton
                    standardBtnId='location-cancel-button'
                    size='sm'
                    label='Cancel'
                    onClick={() => modalRef.current?.close()}
                  />

                  <StandardButton
                    standardBtnId='location-confirm-button'
                    size='sm'
                    label='Confirm'
                    onClick={() => {
                      onLocationChange({
                        line1: values.line1,
                        line2: values.line2,
                        city: values.city,
                        subdivision: values.subdivision,
                        code: values.code,
                      });
                      modalRef?.current?.close?.();
                    }}
                  />
                </div>
              </Container>
            );
          }}
        </Formik>
      </T12Modal>
    );
  }
);

export default AddressPopup;

interface IAddressPopupProps {
  id: string;
  onLocationChange: (location: ILocation) => void;
}

export type AddressPopupRef = {
  show: (location: ILocation) => void;
  close: () => void;
} | null;
