import React from 'react';
import { NavLink } from 'react-router-dom';
import { appStateSelectors, useAppState } from 'app/state/AppState';

import { INavOptions } from 'app/models/common';
import ROUTES from 'app/constants/navigation';

const SideNavBarItem: React.FC<ISideNavBarProps> = ({
  icon: Icon,
  iconName,
  name,
  to,
  feature,
  showDivider,
  className = '',
  onClick,
}) => {
  const appConfig = useAppState(appStateSelectors.appConfig);
  const features = appConfig?.features || [];
  const id = `sidenavbar-item-${to?.replace('/', '') ?? name}`;
  const _onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!onClick) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onClick?.();
  };

  const _isFeatureActive = (feature?: string) => {
    if (!feature) return true;
    return true;
    return !!features.find((configuredFeature) => {
      return (
        configuredFeature.tag === feature &&
        configuredFeature.isActive &&
        configuredFeature.isEnabled
      );
    });
  };

  if (!_isFeatureActive(feature)) {
    return null;
  }

  return (
    <NavLink
      key={id}
      id={id}
      className={({ isActive }) =>
        `side-nav-bar-item ${className} ${isActive ? 'active' : ''} ${
          showDivider ? 'divider' : ''
        }`
      }
      onClick={_onClick}
      to={to ?? ROUTES.HOME}
    >
      {Icon && <Icon fill='#97a5c2' />}
      {/* {iconName && (
        <Iconly
          name={iconName}
          primaryColor='#97a5c2'
          size={28}
          style={{ justifySelf: 'center' }}
        />
      )} */}
      <span>{name}</span>
    </NavLink>
  );
};

export default SideNavBarItem;

interface ISideNavBarProps extends Omit<INavOptions, 'to'> {
  to?: ROUTES;
  feature?: string;
  className?: string;
  onClick?: () => void;
}
