import { useRef } from 'react';
import moment from 'moment';

import { useAppState, appStateSelectors } from 'app/state/AppState';
import { listMessages } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import { MessagePreviewPopUpRef } from 'app/screens/messages/MessagePreviewPopup';
import { IMessage } from 'app/models/messages';
import { ISelectedOption } from 'app/components/selectedOption/SelectedOptionContainer';
import usePagination from './Pagination';

const useMessageListing = () => {
  const roles = useAppState(appStateSelectors.roles);

  const _pagination = usePagination<IMessage>({
    listFn: listMessages,
  });

  // TODO: Sample data, remove this once api is integrated
  _pagination.data = sampleData;

  const messagesModalRef = useRef<MessagePreviewPopUpRef>(null);

  const onGridAction = (action: DATA_GRID_ACTIONS, messageId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIEW:
        console.log(
          'Previewing ',
          _pagination.data.filter((x) => x.messageId === messageId)[0]
        );
        messagesModalRef.current?.show(
          _pagination.data.filter((x) => x.messageId === messageId)[0]
        );
        break;

      default:
        break;
    }
  };

  const getCalendarFilterValues = () => {
    return {
      startDate: _pagination.filters?.startDate,
      endDate: _pagination.filters?.endDate,
    };
  };

  const getSelectedOptionsForCalendarFilter = (
    filters?: Record<string, any>
  ) => {
    if (!filters) return [{}] as ISelectedOption[];

    let options = [];
    if (filters.startDate) {
      options.push({
        id: -1,
        label: `From: ${moment(filters.startDate[0]).format('MM/DD/YY')}`,
        categ: 'startDate',
      });
    }

    if (filters.endDate) {
      options.push({
        id: -1,
        label: `To: ${moment(filters.endDate[0]).format('MM/DD/YY')}`,
        categ: 'endDate',
      });
    }

    return options as ISelectedOption[];
  };

  return {
    ..._pagination,
    roles,
    messagesModalRef,
    onGridAction,
    getCalendarFilterValues,
    getSelectedOptionsForCalendarFilter,
  };
};

export default useMessageListing;

const sampleData: IMessage[] = [
  {
    messageId: 123,
    senderName: 'Test',
    receiverName: 'mario',
    date: '11/10/2021',
    message:
      "Hello Jane,</br> We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year.</br></br>Thank you!",
    isRestricted: true,
  },
  {
    messageId: 124,
    senderName: 'Arun',
    receiverName: 'mario',
    date: '11/10/2021',
    message:
      "Hello Jane,We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year.Thank you!",
    isRestricted: false,
  },
  {
    messageId: 125,
    senderName: 'Test2',
    receiverName: 'mario',
    date: '11/10/2021',
    message:
      "Hello Jane,We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year.Thank you!",
    isRestricted: false,
  },
  {
    messageId: 126,
    senderName: 'Test3',
    receiverName: 'mario',
    date: '11/10/2021',
    message:
      "Hello Jane,We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. We just wanted thank you for suggesting a new book for my son. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year. I found it so interesting and best thing is that he can not put it down! He's learning so much in your class this year.Thank you!",
    isRestricted: false,
  },
];
