import { ReactComponent as IcSearchFilled } from "app/assets/icons/search-filled.svg";
import React from "react";
import "./data-grid.scss";

const EmptyComponent: React.FC = () => {
  return (
    <div className="grid-empty">
      <h5>No results found</h5>

      <IcSearchFilled />
    </div>
  );
};

export default EmptyComponent;
