import React from 'react';
import { DATA_GRID_ACTIONS } from './DataColumn';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';
import { ReactComponent as IcDelete } from 'app/assets/icons/delete.svg';
import { ReactComponent as IcPrevious } from 'app/assets/icons/previous.svg';
import { ReactComponent as IcPreview } from 'app/assets/icons/preview.svg';
import { ReactComponent as IcEmpty } from 'app/assets/icons/_empty.svg';
import { ReactComponent as IcEmailDuplicate } from 'app/assets/icons/email-duplicate.svg';
import { ReactComponent as IcEditSchedule } from 'app/assets/icons/edit-schedule.svg';
import { ReactComponent as IcUnsubscribe } from 'app/assets/icons/unsubscribe.svg';
import './data-grid.scss';

const DataAction = <T,>({
  action,
  rowId,
  gridId,
  onClick,
}: IDataActionProps<T>) => {
  const Icon = icons[action];
  const id = `${gridId}_${rowId}_${action}`;
  const _onClick = () => onClick?.(action, rowId);

  return (
    <button
      id={id}
      key={id}
      type='button'
      onClick={_onClick}
      className='data-grid-action'
    >
      <Icon />
      <span className='tooltip-text'>{action}</span>
    </button>
  );
};

export default DataAction;

interface IDataActionProps<T> {
  rowId: number;
  gridId: string;
  action: DATA_GRID_ACTIONS;
  onClick?: (action: DATA_GRID_ACTIONS, rowId: number) => void;
}

const icons: Record<
  DATA_GRID_ACTIONS,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [DATA_GRID_ACTIONS.DELETE]: IcDelete,
  [DATA_GRID_ACTIONS.EDIT]: IcEdit,
  [DATA_GRID_ACTIONS.PREVIEW]: IcPreview,
  [DATA_GRID_ACTIONS.PREVIOUS]: IcPrevious,
  [DATA_GRID_ACTIONS.DUPLICATE]: IcEmailDuplicate,
  [DATA_GRID_ACTIONS.EDIT_SCHEDULE]: IcEditSchedule,
  [DATA_GRID_ACTIONS.UNSUBSCRIBE]: IcUnsubscribe,
  [DATA_GRID_ACTIONS.CUSTOM]: IcEmpty,
};
