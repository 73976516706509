import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import If from "../If";
import "./section.scss";
import { ReactComponent as IcEdit } from "app/assets/icons/edit.svg";

const Section: React.FC<ISection> = ({
  title,
  titleSize = "md",
  isEditable,
  isCollapsible,
  children,
  className = "",
  sectionId,
  onRightButtonClick,
}) => {
  return (
    <div className={`section ${className}`}>
      <If condition={!isCollapsible}>
        <Row className="section-header">
          <Col className={`section-header-title ${titleSize}`}>{title}</Col>
          <If condition={isEditable}>
            <Col lg={2} className="section-header-btnbox">
              <button
                type="button"
                onClick={onRightButtonClick}
                id={sectionId}
                className="section-btn"
              >
                <IcEdit />
              </button>
            </Col>
          </If>
        </Row>
        <div className="section-children">{children}</div>
      </If>
      <If condition={isCollapsible}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <p className={`${titleSize}`}>{title}</p>
            </Accordion.Header>
            <Accordion.Body>{children}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </If>
    </div>
  );
};

export default Section;

interface ISection {
  sectionId: string;
  title: string;
  titleSize?: "sm" | "md" | "lg" | "xl";
  isEditable?: boolean;
  onRightButtonClick?: () => void;
  isCollapsible?: boolean;
  className?: string;
}
