import React, { useState, useCallback } from "react";
import useModifiedState from "../hooks/ModifiedState";
const useDataUploadManager = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [state, dispatch] = useModifiedState(initialState);
  const { file, selectedFileType, currentStep, totalSteps = 6 } = state;
  

  const onFileChange = (file: any) => {
    dispatch({file: file})
  };
  const onFileTypeSelected = (fileTypeId: any) => {
    fileTypes.map((item) => {
      if (item.id === fileTypeId) {
        dispatch({selectedFileType: fileTypeId})
      }
    });
  };
  
  const onIDSelected = () => {
    console.log("ID");
  };
  const onMailSelected = () => {
    console.log("Mail");
  };
  const onRadioSelected = () => {
    console.log("radio");
  };
  const nextStep = () => {
    if (currentStep <= totalSteps) {
      dispatch({
        currentStep: currentStep + 1,
      });
    }
  };
  
  const previousStep = () => {
    if (currentStep > 1) {
      dispatch({
        currentStep: currentStep - 1,
      });
    }
  }
  return {
    totalSteps,
    currentStep,
    fileTypes,
    importMethods,
    selectedFileType,
    accountID,
    mailType,
    file,
    columnManagerData,
    tableData,
    nextStep,
    previousStep,
    onFileChange,
    onFileTypeSelected,
    onRadioSelected,
    onIDSelected,
    onMailSelected,
    setErrors,
  };
};

export default useDataUploadManager;

export interface IDropdownItem {
  label: string;
  id?: number;
}
export interface IColumnManager {
  id: string;
  columnLabel: string;
  columnGuideLabel: string;
  data: IDropdownItem[];
}
export interface IRadioBoxItems {
  id: string;
  label: string;
  description: string;
  selected?: boolean;
}
export interface IStepperState {
  currentStep: number;
  totalSteps?: number;
}
export interface IFormState {
  currentStep: number;
  totalSteps?: number;
}
export interface ITableData {
  id: string;
  userName: string;
  password: string;
  aid: string;
  firstName: string;
  lastName: string;
  email: string;
}
export const initialState = {
  currentStep: 1,
  totalSteps: 5,
  file: null,
  selectedFileType: 0,
};


const fileTypes: IDropdownItem[] = [
  {
    label: "Student file with parent data",
    id: 1,
  },
  {
    label: "Parent with student data",
    id: 2,
  },
  {
    label: "Parent without student data",
    id: 3,
  },
  {
    label: "Staff",
    id: 4,
  },
];
const accountID: IDropdownItem[] = [
  {
    label: "User ID",
    id: 1,
  },
];
const mailType: IDropdownItem[] = [
  {
    label: "Email Address",
    id: 1,
  },
];
const importMethods: IRadioBoxItems[] = [
  {
    id: "1",
    label: "Add records at the end",
    description: "(adds everything in the file to the end of the database)",
  },
  {
    id: "2",
    label: "Deactivate existing and add new",
    description: "(clears out the data already there and adds all new)",
  },
  {
    id: "3",
    label: "Add and update",
    description: "(adds new rows and updates old rows)",
  },
  {
    id: "4",
    label: "Add new, replace existing and delete if missing",
    description:
      "(updates existing rows, adds new rows and removes if not in the file)",
  },
];
const columnManagerData: IColumnManager[] = [
  {
    id: "1",
    columnLabel: "Field 1",
    columnGuideLabel: "First Name",
    data: [],
  },
  {
    id: "2",
    columnLabel: "Field 2",
    columnGuideLabel: "Last Name",
    data: [],
  },
  {
    id: "3",
    columnLabel: "Field 3",
    columnGuideLabel: "Email",
    data: [],
  },
  {
    id: "4",
    columnLabel: "Field 4",
    columnGuideLabel: "Phone",
    data: [],
  },
  {
    id: "5",
    columnLabel: "Field 5",
    columnGuideLabel: "Account ID",
    data: [],
  },
  {
    id: "6",
    columnLabel: "Field 6",
    columnGuideLabel: "Family ID",
    data: [],
  },
  {
    id: "7",
    columnLabel: "Field 7",
    columnGuideLabel: "Grade",
    data: [],
  },
  {
    id: "8",
    columnLabel: "Field 8",
    columnGuideLabel: "School",
    data: [],
  },
  {
    id: "9",
    columnLabel: "Field 9",
    columnGuideLabel: "Type",
    data: [],
  },
];

const tableData: ITableData[] = [
  {
    id: "1",
    userName: "Username",
    password: "23lasfFAE",
    aid: "3453456",
    firstName: "First Name",
    lastName: "Last Name",
    email: "emailaddress@email.com",
  },
];
