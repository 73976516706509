import Input from "app/components/input/Input";
import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import { emptyFunction } from "app/utils/common";
import { Form, Formik, FormikProps } from "formik";
import React, { useImperativeHandle, useRef, useState } from "react";

const SwitchStatusPopup = React.forwardRef<
  SwitchStatusPopupRef,
  ISwitchStatusPopupProps
>(({ id, onConfirm }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const formRef = useRef<FormikProps<{ password: string }>>(null);
  const [rowId, setRowId] = useState<number>();
  useImperativeHandle(
    ref,
    () => ({
      show: (id) => {
        setRowId(id);
        modalRef.current?.show();
      },
    }),
    []
  );

  const _onConfirm = () => {
    const { password } = formRef.current!.values;
    onConfirm?.(rowId!, password);
  };

  return (
    <T12Modal
      modalId={id}
      ref={modalRef}
      title="Switch Status"
      confirmButtonLabel="Confirm"
      cancelButtonLabel="Cancel"
      showHeaderBorder
      onConfirm={_onConfirm}
    >
      <p>
        Please confirm that you would like to change the status of this program
        to "Live"
      </p>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={emptyFunction}
      >
        <Form>
          <Input
            id={`${id}_password`}
            label="Please enter your password to confirm this change"
            type="password"
            name="password"
          />
        </Form>
      </Formik>
    </T12Modal>
  );
});

export default SwitchStatusPopup;

interface ISwitchStatusPopupProps {
  id: string;
  onConfirm?: (id: number, password: string) => void;
}

export type SwitchStatusPopupRef = {
  show: (id: number) => void;
} | null;

const initialValues = {
  password: "",
};
