import { ReactComponent as IcImage } from 'app/assets/icons/images.svg';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import './drag-file-upload.scss';
import If from '../If';

const DragFileUpload: React.FC<IDragFileUploadProps> = ({
  id,
  label,
  multiple = false,
  className = '',
  buttonLabel,
  useNativeUploader = false,
  onDrop,
  onClick,
  setFile,
  existingImage,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple,
    noClick: true,
  });

  const _onClick = () => {
    if (useNativeUploader) {
      open();
    }

    onClick && onClick();
  };

  return (
    <section className={`drop-file-container ${className}`}>
      <div id={`${id}-drop-zone`} {...getRootProps({ className: 'dropzone' })}>
        {/* <button type='button' onClick={onClick ?? open} id={`${id}-click-zone`}> */}
        <button type='button' onClick={_onClick} id={`${id}-click-zone`}>
          <input id={`${id}-input-file-zone`} {...getInputProps()} />
          {!setFile && !existingImage && <IcImage className='p-1 drop-icon' />}
          {!!setFile && setFile}
          {!!existingImage && (
            <img src={existingImage} className='photo-selector-thumbnail' />
          )}
          <div className='drop-label'>
            {!setFile && <span>{label}</span>}
            <If condition={!!buttonLabel}>
              <label className='label-btn'>{`or ${buttonLabel}`}</label>
            </If>
          </div>
        </button>
      </div>
    </section>
  );
};

export default DragFileUpload;

interface IDragFileUploadProps {
  id: string;
  label: string;
  multiple?: boolean;
  className?: string;
  buttonLabel?: string;
  setFile?: string | JSX.Element | null;
  existingImage?: string;
  useNativeUploader?: boolean;
  onClick?: () => void;
  onDrop?: (files: File[]) => void;
}
