import React, { CSSProperties } from 'react';
import { capitalize } from 'lodash';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import Alert from 'react-bootstrap/Alert';

import './screen-notification.scss';

interface ScreenNotificationParams {
  label?: string;
  message?: string | JSX.Element | JSX.Element[];
  variant: 'success' | 'danger' | 'warning' | 'info';
  styles?: {
    container: CSSProperties;
  };
  children?: any;
}

const ScreenNotification: React.FC<ScreenNotificationParams> = ({
  label,
  variant,
  styles = {},
  children,
}) => {
  const notification = useAppState(appStateSelectors.notification);
  const incomingVariant = useAppState(appStateSelectors.notificationVariant);
  const derviedLabel = label
    ? label
    : incomingVariant
    ? capitalize(incomingVariant)
    : 'Notifications';

  return notification ? (
    <div className='screen-notification' style={styles?.container || {}}>
      <Alert variant={incomingVariant ?? variant}>
        <div className='notification-container'>
          <label className='notification-title'>{derviedLabel}</label>
          <div className='notification-contents'>
            {notification}
            {children && children}
          </div>
        </div>
      </Alert>
    </div>
  ) : null;
};

export default ScreenNotification;
