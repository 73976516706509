import React from "react";
import "./selected-option.scss";
import { ReactComponent as IcClose } from "../../assets/icons/close.svg";
import { ISelectedOption } from "./SelectedOptionContainer";

const SelectedOption: React.FC<ISelectedOptionProps> = ({
  id,
  containerId,
  label,
  categ,
  className = "",
  variant = "primary",
  onDelete,
}) => {
  const _onDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete?.(id, categ);
  };

  return (
    <div className={`selected-option ${variant} ${className}`}>
      <label>{label}</label>
      <IcClose
        className="close"
        id={`${containerId}_${id}`}
        onClick={_onDelete}
      />
    </div>
  );
};
export default SelectedOption;

interface ISelectedOptionProps extends ISelectedOption {
  containerId: string;
  className?: string;
  variant?: "primary" | "secondary";
  onDelete?: (id: number, categ?: string) => void;
}
