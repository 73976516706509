import RoundButton from 'app/components/button/RoundButton';
import Checkbox from 'app/components/checkbox/CheckBox';
import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import PrivateLayout from 'app/layout/PrivateLayout';
import { emptyFunction } from 'app/utils/common';
import { Formik, Form } from 'formik';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './graduate-profile-manager.scss';
import { ReactComponent as IcPlus } from 'app/assets/icons/plus.svg';
import StandardButton from 'app/components/button/StandardButton';
import useGraduateEditor from 'app/hooks/GraduateEditor';
import { graduateSchema } from 'app/utils/validations';
import ActivityContainer from 'app/components/activitySelector/ActivityContainer';
import EducationEditor from 'app/components/educationEditor/EducationEditor';
import EditProfileImage from 'app/components/profileSelector/EditProfileImage';
import DropDownBase from 'app/components/dropDown/DropDownBase';
import { YEARS } from 'app/constants/common';

const GraduateBuilder: React.FC = () => {
  const _editor = useGraduateEditor();
  return (
    <PrivateLayout contentClassName='new-graduate'>
      <h3 className='title'>Graduate gallery profile editor</h3>
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
        validationSchema={graduateSchema}
      >
        {({ values, isValid, dirty, errors, touched }) => (
          <Form>
            <Row>
              <Col md='auto'>
                <EditProfileImage
                  mode='vertical'
                  url={values.profileImageUrl}
                  id='graduate-builder-profile'
                  // onConfirm={_editor.onChange('profileImageUrl')}
                />
              </Col>
              <Col>
                <Section
                  title='General'
                  sectionId='graduate-builder-general'
                  titleSize='xl'
                  className='graduate-builder-general'
                  isEditable={true}
                >
                  <Row>
                    <Col>
                      <Input
                        id='graduate-builder-first-name'
                        variant='secondary'
                        label='Name*'
                        placeholder='Enter your name here'
                        name='firstName'
                        error={
                          touched.firstName && errors.firstName
                            ? errors.firstName
                            : ''
                        }
                      />
                    </Col>
                    <Col>
                      <Input
                        id='graduate-builder-last-name'
                        variant='secondary'
                        label='Last Name*'
                        placeholder='Enter your last name here'
                        name='lastName'
                        error={
                          touched.lastName && errors.lastName
                            ? errors.lastName
                            : ''
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7} lg={7}>
                      <Input
                        id='graduate-builder-email'
                        variant='secondary'
                        label='Email Address*'
                        name='email'
                        error={
                          touched.email && errors.email ? errors.email : ''
                        }
                      />
                    </Col>
                    <Col>
                      <DropDownBase<string>
                        id='graduate-builder-class'
                        options={YEARS}
                        labelOnly
                        selected={values.class}
                        placeholder='Select Year'
                        label='Class '
                        onOptionChange={_editor.onChange('class')}
                      />
                    </Col>
                  </Row>
                  <Checkbox
                    id='graduate-builder-email-consent'
                    label='Allow this email addresses to be visible in my profile'
                    checked={values.showEmail}
                    onChange={_editor.onChange('showEmail')}
                    className='graduate-builder-checkbox mt-0'
                  />
                  <Row>
                    <Col md={7} lg={7}>
                      <Input
                        id='graduate-builder-linkedIn'
                        variant='secondary'
                        label='LinkedIn URL'
                        name='linkedinUrl'
                        error={
                          touched.linkedinUrl && errors.linkedinUrl
                            ? errors.linkedinUrl
                            : ''
                        }
                      />
                    </Col>
                  </Row>

                  <hr className='mb-5 mt-4' />
                </Section>
                <Section
                  title='Education'
                  sectionId='graduate-builder-graduate-education'
                  titleSize='xl'
                  className='graduate-builder-education'
                  isEditable={true}
                >
                  {values.educations.map((education, index) => (
                    <EducationEditor
                      key={`graduate-builder-education-${index}`}
                      index={index}
                      label=''
                      variant='secondary'
                      selected={education.outcomeId}
                      onChange={_editor.onChange(
                        `educations[${index}].outcomeId`
                      )}
                    />
                  ))}
                  <RoundButton
                    icon={<IcPlus />}
                    variant='primary'
                    size='lg'
                    roundBtnId='graduate-builder-add-education-btn'
                    label='Add education'
                    onClick={_editor.addEducation}
                  />
                  <hr className='mb-5 mt-4' />
                </Section>
                <Section
                  title='About'
                  sectionId='graduate-builder-about'
                  titleSize='xl'
                  className='graduate-builder-about'
                  isEditable={true}
                >
                  <Input
                    id='graduate-builder-about-me'
                    variant='secondary'
                    as='textarea'
                    maxLength={1024}
                    className='graduate-builder-textarea'
                    label='About Me'
                    placeholder=''
                    name='aboutMeText'
                    error={
                      touched.aboutMe && errors.aboutMe ? errors.aboutMe : ''
                    }
                  />
                  <Input
                    id='graduate-builder-experience'
                    variant='secondary'
                    as='textarea'
                    maxLength={1024}
                    label={`My Experience at  ${_editor.schoolName}`}
                    placeholder=''
                    className='graduate-builder-textarea'
                    name='myExperience'
                    error={
                      touched.myExperience && errors.myExperience
                        ? errors.myExperience
                        : ''
                    }
                  />
                  <Input
                    id='graduate-builder-opinion'
                    variant='secondary'
                    as='textarea'
                    maxLength={1024}
                    label={`General opinion about ${_editor.schoolName}`}
                    placeholder=''
                    className='graduate-builder-textarea'
                    name='generalOpinion'
                    error={
                      touched.generalOpinion && errors.generalOpinion
                        ? errors.generalOpinion
                        : ''
                    }
                  />
                </Section>
                <Section
                  sectionId='graduate-editor-activitites-liked'
                  title='Activities I like (Select at least 3)'
                  titleSize='sm'
                  className='graduate-builder-activitites'
                >
                  <ActivityContainer
                    activites={[]}
                    id='graduate-builder-activities'
                  />
                </Section>
                <StandardButton
                  type='submit'
                  variant='secondary'
                  label='Save Changes'
                  disabled={!dirty || !isValid}
                  className='save-button'
                  standardBtnId='graduate-builder-save-btn'
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </PrivateLayout>
  );
};

export default GraduateBuilder;
