import React from 'react';
import If from '../If';
import './check-box.scss';

const Checkbox: React.FC<ICheckboxProps> = ({
  id,
  checked,
  className = '',
  label,
  onChange,
}) => {
  const _onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onChange?.(!checked);
  };

  return (
    <div className={`check-container ${className}`}>
      <a href='' id={id} onClick={_onClick}>
        <div className={`checkbox ${checked ? 'checked' : ''}`} />
      </a>
      <If condition={!!label}>
        <label htmlFor={id}>{label}</label>
      </If>
    </div>
  );
};

export default Checkbox;

export interface ICheckboxProps {
  id: string;
  checked?: boolean;
  label?: string;
  className?: string;
  onChange?: (checked: boolean) => void;
}
