import { url } from 'inspector';
import { get } from 'lodash';

import { IMediaUpload } from 'app/models/media';

export const emptyFunction = () => {};

export const arrayNumberGenerator = (length: number, fill = 1) =>
  length > 0
    ? new Array(length).fill(fill).map((value, index) => value + index)
    : [];

export const camelToSnakeCase = (text: string) =>
  text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const arrayToMap = <T>(
  array: T[],
  key: keyof T
): Record<string | number, T> =>
  array.reduce(
    (result, item) => ({
      ...result,
      [item[key] as unknown as string | number]: item,
    }),
    {} as Record<string | number, T>
  );

export const getBase64 = async (file: File): Promise<string> => {
  var reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      console.log(reader.result);
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject('Error generating Base 64 File string');
    };
  });
};

export const processErrorResponse = ({
  error,
  attemptedAction,
  callback,
}: {
  error: any;
  callback: (error: string) => void;
  attemptedAction?: string;
}) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const serverMessage = get(error, 'response.data.message', '');
    callback(`An error occurred: ${serverMessage}`);
  } else if (error.request) {
    // The request was made but no response was received
    const cause = attemptedAction || '';
    callback(`An error occurred communicating to the server: ${cause}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    callback(`An error occurred, please try again: ${error.message}`);
  }
};

export const ACTIVE_OPTIONS = [
  { id: 1, label: 'Active', isActive: true },
  { id: 2, label: 'Inactive', isActive: false },
];

export const SUBSCRIPTION_OPTIONS = [
  { id: 1, label: 'Notification', subscriptionStatus: 'notification' },
  { id: 2, label: 'Promotional', subscriptionStatus: 'promotional' },
  { id: 3, label: 'Transactional', subscriptionStatus: 'transactional' },
];

export const getProgramDomain = () => {
  const domain = window.location.host;
  const hostname = window.location.hostname;
  const isAdminUrl = /^.*?\.admin\..*/.test(domain);

  if (hostname === 'localhost' || hostname === 'think-12-1aa8a.web.app') {
    return process.env.REACT_APP_DEV_PROGRAM_DOMAIN || 'app.think-12.com';
  } else if (isAdminUrl) {
    return domain.replace(/\.admin/, '');
  }

  return domain;
};

export const getApiDomain = () => {
  let str = process.env.REACT_APP_BASE_URL;
  if (!str) {
    return "app.think-12.com"
  }
  const url = new URL(str);
  let result = url.protocol + "//" + url.hostname;
  if (url.port) {
    result += ":" + url.port;
  }
  return result;
}

export const mediaTypeByMime = (
  mimetype: string
): 'image' | 'video' | 'unknown' => {
  return /image\//.test(mimetype)
    ? 'image'
    : /video\//.test(mimetype)
    ? 'video'
    : 'unknown';
};

export const mediaIsImage = (
  url: string,
  mimetype?: string,
  isBase64: boolean = false
) => {
  if (mimetype) {
    return /image\//.test(mimetype);
  }

  return isBase64
    ? /$data\:image.*/.test(url)
    : /.*?\.(png|gif|jpg|jpeg)$/.test(url);
};

export const mediaIsVideo = (
  url: string,
  mimetype?: string,
  isBase64: boolean = false
) => {
  if (mimetype) {
    return /video\//.test(mimetype);
  }

  return isBase64
    ? /$data\:video.*/.test(url)
    : /.*?\.(mpeg|mpg|mp4|mpg4|qt|mov)$/.test(url);
};

export const formatMedia = (
  owner_id: number,
  owner_type: string,
  media?: IMediaUpload
): IMediaUpload => {
  if (!media) return {} as IMediaUpload;

  const updates = media.id
    ? { image_id: media.id }
    : {
        image: media.image!,
        folder_id: media.folder_id,
        owner_id,
        owner_type,
      };

  return updates;
};

export const addCollection = (
  collectionName: string,
  collection: any[],
  formData: FormData
) => {
  collection.forEach((item, index) => {
    formData.append(`${collectionName}[]`, `${index + 1}`);
  });

  return formData;
};

export const buildFormData = (
  formData: FormData,
  data: any,
  parentKey: string | null
) => {
  if (data && typeof data === 'object') {
    console.log('keys: ', Object.keys(data));
  }
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      if (key === 'school') {
        console.log(` Checking ${key} --- `);
      }
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else if (parentKey) {
    const value = data == null ? '' : data;
    console.log(` -- Appending ${parentKey} == ${value}...`);
    formData.append(parentKey, value);
  }
};
