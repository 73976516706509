import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import UploadPopupV2 from '../sitePhotos/uploadPopup/UploadPopupV2';
import { IUploadResponse } from 'app/models/common';
import { IMediaSelected } from 'app/models/media';
import AddressPopup, { AddressPopupRef } from './components/AddressPopup';
import DragFileUpload from 'app/components/dragFileUpload/DragFileUpload';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import { getErrors } from 'app/utils/data';

type ProgramDetailsSection = {} & IConfigurationEditorSection;

const ProgramDetailsSection: React.FC<ProgramDetailsSection> = ({
  editor,
  values,
  touched,
  errors,
}) => {
  const addressRef = useRef<AddressPopupRef>(null);

  function _formatSchoolAddress() {
    const location = values?.school?.location;
    if (!location) return '';

    return `${location.line1}\n${location.line2}\n${location.city}, ${location.subdivision}, ${location.code}`;
  }

  return (
    <Section
      title='Program Details'
      sectionId='configuration-editor-details'
      titleSize='md'
      className='configuration-editor-details mt-4'
    >
      <Row>
        <Col>
          <Input
            id='configuration-editor-details-program-name'
            label='Program Name'
            variant='secondary'
            name='name'
            error={getErrors(touched, errors, 'name')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-school-name'
            label='School Name'
            variant='secondary'
            name='school.name'
            error={getErrors(touched, errors, 'school.name')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-program-abbreviation'
            label='School Abbreviation'
            variant='secondary'
            name='school.abbreviation'
            error={getErrors(touched, errors, 'school.abbreviation')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            id='configuration-editor-details-school-url'
            label='Enter Program URL'
            variant='secondary'
            name='program_url'
            error={getErrors(touched, errors, 'program_url')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-school-tagline'
            label='Enter School Tagline'
            variant='secondary'
            name='school.tagline'
            error={getErrors(touched, errors, 'school.tagline')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-family-ambassador'
            label='Family Ambassador Name or Title'
            variant='secondary'
            name='family_ambassador_name'
            error={getErrors(touched, errors, 'family_ambassador_name')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            id='configuration-editor-details-program-phone'
            label='School Phone Number'
            variant='secondary'
            name='school.phone_number'
            error={getErrors(touched, errors, 'school.phone_number')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-program-contact-email'
            label='School Contact Email'
            variant='secondary'
            name='school.email'
            error={getErrors(touched, errors, 'school.email')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-program-mascot'
            label='School Mascot'
            variant='secondary'
            name='school.mascot'
            error={getErrors(touched, errors, 'school.mascot')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <UploadFile
            label='Upload School Logo'
            placeholder={(values.logo?.image as File)?.name || 'Upload'}
            uploadFileId={`configuration-editor-cc-file`}
            onChange={(file: File) => editor?.onChange?.('logo')(file)}
            onClick={() => editor?.uploadLogoRef?.current?.show()}
          /> */}
          <DragFileUpload
            id='configuration-editor-details-logo'
            label='Upload School Logo'
            existingImage={
              values.logo?.tmpFileUrl ||
              values.logo?.fileUrl ||
              values.logo?.url
            }
            onClick={() => editor?.uploadLogoRef?.current?.show()}
          />
          <UploadPopupV2
            id='configuration-editor-upload-logo'
            onConfirm={() => {}}
            ref={editor?.uploadLogoRef}
            mediaType='IMAGE'
            autoUpload={false}
            owner={{
              id: editor?.appConfig?.id,
              type: 'Program',
            }}
            onFileSelect={(
              type,
              file?: File,
              tmpFileStr?: string,
              folderId?: number,
              existingMedia?: IMediaSelected
            ) => {
              if (file && folderId) {
                editor?.onMediaUpload?.('logo', {
                  file,
                  tmpFileUrl: tmpFileStr ?? '',
                  folderId,
                });
              } else if (existingMedia) {
                editor?.onMediaUpload?.('logo', undefined, {
                  media: existingMedia,
                });
              }
            }}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-program-description'
            label='School Description'
            as='textarea'
            variant='secondary'
            name='description'
            value={values?.description ?? ''}
            error={getErrors(touched, errors, 'description')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-details-program-address'
            label='School Address'
            as='textarea'
            variant='secondary'
            name='school.location.line1'
            value={_formatSchoolAddress()}
            onClick={() => {
              const locationDetails = values?.school?.location || {};
              addressRef?.current?.show?.(locationDetails);
            }}
            error={getErrors(touched, errors, 'school.location.line1')}
          />
        </Col>
      </Row>
      <hr className='mt-5' />
      <AddressPopup
        id='configuration-editor-address-popup'
        ref={addressRef}
        onLocationChange={editor?.onAddressChange!}
      />
    </Section>
  );
};

export default ProgramDetailsSection;
