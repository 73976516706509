import React, { useState } from 'react';
import DropDown from '../dropDown/DropDown';
import If from '../If';
import { getFilterOptions, getSelectedOptions } from './Filter.utils';
import FilterIcon from './FilterIcon';
import { IFilterOptionProps } from './FilterOption';
import './filter.scss';
import SelectedOptionContainer from '../selectedOption/SelectedOptionContainer';

const Filter: React.FC<IFilterProps> = ({
  id,
  className = '',
  children,
  selectedFilters,
  onFilterChange,
}) => {
  const [filtersVisible, setFiltersVisible] = useState(true);

  const _onToggle = () =>
    setFiltersVisible((filtersVisible) => !filtersVisible);

  const _options = getFilterOptions(children);

  const _selectedOptions = getSelectedOptions(_options, selectedFilters);

  const _onOptionChange =
    (name: string, disableMultipleSelection: boolean = false) =>
    (ids: number[]) => {
      const value =
        disableMultipleSelection && ids.length > 0
          ? [ids[ids.length - 1]]
          : ids;
      onFilterChange?.({
        ...(selectedFilters ?? {}),
        [name]: value,
      });
    };

  // Remove any filters that are handled via custom filter selector components
  const _onDelete = (id: number, name = '') => {
    onFilterChange?.({
      ...(selectedFilters ?? {}),
      [name]:
        id !== -1
          ? selectedFilters?.[name].filter((_id) => _id !== id) ?? []
          : (undefined as any),
    });
  };

  return (
    <div className={`filter-container ${className}`}>
      <FilterIcon
        filterIconId={`${id}_toggle`}
        filtersVisible={filtersVisible}
        onClick={_onToggle}
      />
      <If condition={filtersVisible}>
        <div className='filters'>
          <div className='filters-options'>
            {_options.map((option) => {
              return (
                <DropDown
                  key={`${id}_${option.name}`}
                  {...option}
                  id={`${id}_${option.name}`}
                  multiple
                  variant='secondary'
                  selected={selectedFilters?.[option.name] ?? []}
                  onOptionChange={_onOptionChange(
                    option.name,
                    option.disableMultipleSelection
                  )}
                  onCustomComponentChange={(params: Record<string, any>) => {
                    onFilterChange?.({
                      ...(selectedFilters ?? {}),
                      ...params,
                    });
                  }}
                  values={option.getValues ? option.getValues() : undefined}
                />
              );
            })}
          </div>
          <div className='filters-selections'>
            <SelectedOptionContainer
              id={`${id}_selected_options`}
              options={_selectedOptions}
              onDelete={_onDelete}
            />
          </div>
        </div>
      </If>
    </div>
  );
};

export default Filter;

interface IFilterProps {
  id: string;
  className?: string;
  children:
    | React.ReactElement<IFilterOptionProps<any>>
    | React.ReactElement<IFilterOptionProps<any>>[];
  selectedFilters?: Record<string, number[]>;
  onFilterChange?: (selectedFilters: Record<string, number[]>) => void;
}
