import React from "react";
import "./stepper.scss";

const Stepper: React.FC<IStepperProps> = ({ currentStep, totalSteps }) => {
  return (
    <div className="wrapper">
      <div className="stepper">
        {Array(totalSteps)
          .fill(null)
          .map((val, count) => {
            return (
              <>
                <div
                  className={`stepper-item
                    ${count + 1 === currentStep ? " step-last-complete" : null}
                   ${count + 1 === currentStep && "step-complete"}`}
                >
                  <i className="stepper-circle">{count + 1}</i>
                  <span className="stepper-label"></span>
                </div>
                <div className="stepper-connector" aria-hidden="true">
                  <hr />
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};
export default Stepper;

interface IStepperProps {
  currentStep: number;
  totalSteps: number;
}
