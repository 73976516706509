import React from 'react';
import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import Switch from 'app/components/switch/Switch';
import useUserProfieListing from 'app/hooks/UserProfieListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IClassYear, IProgramId } from 'app/models/userProfile';
import { IActiveStatus } from 'app/models/common';
import { IUserV2 } from 'app/models/user';
import { formatUserNotificationPreferences } from 'app/utils/data';
import { ACTIVE_OPTIONS } from 'app/utils/common';
import AccountNotificationPopup from 'app/components/accountNotification/AccountNotificationPopup';
import { ICategoryTag, IStatus } from 'app/models/common';
import './user-profile-manager.scss';

const GRID_ID = 'user-profile-listing';

const UserProfileManager: React.FC = () => {
  const _userProfileListing = useUserProfieListing();

  console.log('Data: ', _userProfileListing.data);
  return (
    <PrivateLayout contentClassName='user-profile-listing'>
      <StandardButton
        label='+ New User Profile'
        variant='secondary'
        className='new-user-profile-button'
        onClick={_userProfileListing.onNewUserProfileClick}
        standardBtnId='user-profile-listing-new-btn'
      />
      <h3 className='title'>User account manager</h3>
      <SearchBar
        placeHolder='Search by user name, ID, email associated with the account'
        searchBarId='user-profile-listing-search'
        onChangeText={_userProfileListing.onSearchKeyChange}
        onSearch={_userProfileListing.onSearch}
      />
      {/* TODO: This is for sample only. Remove this after filter is created in other pages */}
      <Filter
        id='user-profile-listing-filter'
        onFilterChange={_userProfileListing.onFilterChange}
        selectedFilters={_userProfileListing.filters}
      >
        <FilterOption<IProgramId>
          labelAttribute='programId'
          valueAttribute='id'
          name='programId'
          options={[]}
          placeholder='Program ID'
        />
        <FilterOption<IClassYear>
          labelAttribute='classYear'
          valueAttribute='classYear'
          name='classYear'
          options={_userProfileListing.classYears}
          placeholder='Class Year'
        />
        <FilterOption<IActiveStatus>
          labelAttribute='label'
          valueAttribute='isActive'
          name='isActive'
          options={ACTIVE_OPTIONS}
          placeholder='Active Status'
          disableMultipleSelection
        />
      </Filter>
      <DataGrid<IUserV2>
        data={_userProfileListing.data}
        gridId={GRID_ID}
        rowIdAttribute='id'
        currentPage={_userProfileListing.currentPage}
        onPageChange={_userProfileListing.onPageChange}
        totalItems={_userProfileListing.totalItems}
        onAction={_userProfileListing.onGridAction}
      >
        <DataGrid.Column<IUserV2>
          attributeName='last_name'
          header='Last Name'
        />
        <DataGrid.Column<IUserV2>
          attributeName='program_id'
          header='Program Id'
        />
        <DataGrid.Column<IUserV2>
          attributeName='family_id'
          header='Family Id'
        />
        <DataGrid.Column<IUserV2>
          attributeName='preferences'
          header='SUBSCRIPTION STATUS'
          transform={(data: IUserV2) => formatUserNotificationPreferences(data)}
        />
        <DataGrid.Column<IUserV2>
          attributeName='attending_school_since'
          header='Class Year '
        />
        <DataGrid.Column<IUserV2>
          attributeName='is_enabled'
          header='ACTIVE STATUS'
          transform={(user) => (
            <Switch
              checked={user.is_enabled}
              onChange={_userProfileListing.onToggleActive(user)}
              switchId='user-profile-listing-live-btn'
            />
          )}
        />
        <DataGrid.Column<IUserV2>
          action={DATA_GRID_ACTIONS.UNSUBSCRIBE}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IUserV2>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
      <AccountNotificationPopup
        type='User'
        ref={_userProfileListing.subscriptionModalRef}
        id='user-acccount-subscription'
        onSubmit={_userProfileListing.updateUserSubscriptionStatus}
      />
    </PrivateLayout>
  );
};

export default UserProfileManager;
