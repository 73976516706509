import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import DropDown from 'app/components/dropDown/DropDown';
import { IStaffListItem } from 'app/models/staff';
import { IConfigurationEditor } from 'app/hooks/ConfigurationEditor';
import { IMainProgramConfigBuilder } from 'app/models/config';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';

const StaffSection: React.FC<IConfigurationEditorSection> = ({
  editor,
  values,
  touched,
  errors,
}) => {
  return (
    <Section
      title='Staff'
      sectionId='configuration-editor-staff'
      titleSize='md'
      className='configuration-editor-staff mt-4'
    >
      <Row>
        <Col>
          <Input
            id='configuration-editor-spotlight-title'
            label='Staff Spotlight Title'
            variant='secondary'
            name='staff_spotlight_title'
            error={
              touched.staff_spotlight_title && errors.staff_spotlight_title
                ? errors.staff_spotlight_title
                : ''
            }
          />
        </Col>
        <Col>
          <DropDown<IStaffListItem>
            id='configuration-editor-staff-member'
            label='Primary Featured Staff Member'
            labelAttribute='last_name'
            transformLabel={(option: IStaffListItem) =>
              `${option.first_name} ${option.last_name}`
            }
            options={editor?.staffMembers?.data || []}
            selected={values.primary_featured_staff_id}
            // valueAttribute='account_id'
            valueAttribute='id'
            placeholder='Please select'
            onOptionChange={(id: string | number) =>
              editor?.onChange?.('primary_featured_staff_id')(+id)
            }
          />
        </Col>
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};

export default StaffSection;
