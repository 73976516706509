import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useHref } from 'react-router-dom';
import { FormikProps } from 'formik';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { get } from 'lodash';

import {
  createEmailTemplate,
  updateEmailTemplate,
  getEmail,
  getAvailableEmailTemplates,
  getEmailTemplateContent,
  getMailgunTemplateContent,
} from 'app/api/adminApis';
import ROUTES from 'app/constants/navigation';
import { IGradeBand } from 'app/models/program';
import { ICategoryTag, IDesignation } from 'app/models/common';
import { IEmailBuilder, IEmailTemplate } from 'app/models/email';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { processErrorResponse, SUBSCRIPTION_OPTIONS } from 'app/utils/common';
import { EmailMergeFieldsPopupRef } from 'app/screens/email/components/emailTemplates/EmailTemplateMergeFields';

const useEmailEditor = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IEmailBuilder>>(null);
  const mergeTemplatePopupRef = useRef<EmailMergeFieldsPopupRef>(null);
  const [templates, setTemplates] = useState<IEmailTemplate[] | []>([]);

  const appConfig = useAppState(appStateSelectors.appConfig);
  const approvalStatuses = useAppState(appStateSelectors.contentApprovals);
  const contentCategories = useAppState(appStateSelectors.contentCategories);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const contentDesignations = useAppState(
    appStateSelectors.contentDesignations
  );
  const preferences = useAppState(appStateSelectors.preferences)?.filter(
    (pref) => pref.category === 'Contact'
  );
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);

  const params = useParams();

  const previewHref = useHref(ROUTES.EMAIL_PREVIEW);

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    params?.id && _getEmailDetails();
  }, [params]);

  const previewEmail = () => {
    const hostname = window.location.hostname;
    const domain = window.location.host;
    const protocol = hostname === 'localhost' ? 'http' : 'https';

    window.open(
      `${protocol}://${domain}/${previewHref}/${params?.id}`,
      '_blank'
    );
  };

  const _getTemplateContent = async (mailgunTemplateName: string) => {
    showLoader();
    try {
      const { data } = await getMailgunTemplateContent(mailgunTemplateName);
      const updatedContent = get(data, 'template.version');
      if (updatedContent) {
        formRef.current?.setValues((prevValues) => ({
          ...prevValues,
          content: updatedContent,
          mailgunTemplateName,
        }));
      }
      // setTemplates(data);
    } catch (error) {
      console.log(
        '🚀 ~ file: EmailBuilder.ts  ~ const_getEmailTemplates= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const _getEmailDetails = async () => {
    showLoader();
    try {
      const { data } = await getEmail(+params.id!);
      formRef.current?.setValues(_formatResponse(data));
    } catch (error) {
      console.log(
        '🚀 ~ file: EmailBuilder.ts  ~ const_getEmailDetails= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const _formatResponse = (data: IEmailBuilder) => {
    return {
      ...data,
      category_ids: (data.categories || []).map((cat) => cat.id),
      grade_band_ids: (data.grade_bands || []).map((gb) => gb.id),
      notification_type_id: data.type?.id,
    };
  };

  const onChange =
    (key: keyof IEmailBuilder | string) =>
    (value: boolean | string | null | number | number[]) => {
      formRef.current?.setFieldValue(key, value);
    };

  const onDropdownChange =
    <T extends ICategoryTag | IGradeBand>(
      type: 'category' | 'grade_band' | 'subscription_type'
    ) =>
    (ids: number[]) => {
      let collection;
      let param = '';
      switch (type) {
        case 'category':
          param = 'categories';
          collection = contentCategories as unknown as T;
          break;
        case 'grade_band':
          param = 'grade_bands';
          collection = gradeBands as unknown as T;
          break;
        default:
          collection = [];
          break;
      }
      const selected = (collection as any[]).filter((cat) =>
        ids.includes(cat.id)
      );
      // formRef.current?.setFieldValue(type, selected);
      formRef.current?.setValues((prevValues) => ({
        ...prevValues,
        [param]: selected,
        [`${type}_ids`]: selected.map((item) => item.id),
      }));
    };

  const onPreferenceChange = (id: string | number) => {
    const notificationType = preferences.find((pref) => pref.id === id);
    notificationType &&
      formRef?.current?.setFieldValue('notification_type_id', id);
  };

  const onMailgunTemplateChange = async (templateName: string | number) => {
    const templateContent = await _getTemplateContent(templateName as string);
  };

  const onTemplateContentChange = (_: any, editor: any) => {
    const editorText = editor.getContent();
    formRef?.current?.setValues((prevValues) => ({
      ...prevValues,
      body: editorText,
      content: editorText,
    }));
  };

  const onApprovalStatusChange = (approved: boolean) => {
    const updatedStatus = approvalStatuses.find((approvalStatus) =>
      approved
        ? approvalStatus.status === 'Approved'
        : approvalStatus.status !== 'Approved'
    );

    if (updatedStatus) {
      formRef.current?.setFieldValue(
        'content_approval_status_id',
        updatedStatus.id
      );
    }
  };

  const formatParams = (params: IEmailBuilder) => {
    let formatted = { ...params, programId: appConfig?.id };

    if (params.categories) {
      formatted.category_ids = params.categories.map((cat) => cat.id);
    }

    if (params.grade_bands) {
      formatted.grade_band_ids = params.grade_bands.map((gb) => gb.id);
    }

    return formatted;
  };

  const onSubmit = async (email: IEmailBuilder) => {
    showLoader();
    hideNotification();

    const emailParams = formatParams(email);
    try {
      await (params.id
        ? updateEmailTemplate(+params.id, emailParams)
        : createEmailTemplate(emailParams));
      navigate(-1);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log('🚀 ~ file: StaffEditor.ts ~ onSubmit ~ error', error);
    } finally {
      hideLoader();
    }
  };

  return {
    initialValues,
    formRef,
    mergeTemplatePopupRef,
    templates,
    contentCategories,
    contentDesignations,
    gradeBands,
    preferences,
    subscriptionOptions: SUBSCRIPTION_OPTIONS,
    previewEmail,
    onApprovalStatusChange,
    onMailgunTemplateChange,
    onTemplateContentChange,
    onPreferenceChange,
    onChange,
    onDropdownChange,
    onSubmit,
  };
};

export default useEmailEditor;

const initialValues: IEmailBuilder = {
  id: null,
  emailId: null,
  // title: '',
  name: '',
  description: '',
  subject: '',
  body: '',
  preHeaderText: '',
  content_approval_status_id: 2,
  // designationType: '',
  categoryTags: '',
  // approvalStatus: '',
  // approvalStatusId: 2,
  isActive: false,
};
