import { listGraduateProfile } from "app/api/adminApis";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import ROUTES from "app/constants/navigation";
import { IGraduateListItem } from "app/models/graduate";
import { useNavigate } from "react-router-dom";
import usePagination from "./Pagination";

const useGraduateProfileListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IGraduateListItem>({
    listFn: listGraduateProfile,
  });

  const onNewGraduateProfileClick = () =>
    navigate(ROUTES.GRADUATE_PROFILE_EDITOR);

  const onToggleActive =
    (profile: IGraduateListItem) => (checked: boolean) => {};

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate({
          pathname: ROUTES.GRADUATE_PROFILE_EDITOR,
          search: `id=${id}`,
        });
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    onNewGraduateProfileClick,
    onToggleActive,
    onGridAction,
  };
};

export default useGraduateProfileListing;
