import { IActivity } from "app/models/activity";
import React from "react";
import "./activity.scss";

const Activity: React.FC<IActivityProps> = ({
  eltId,
  id,
  url,
  activityTitle,
  selected = false,
  onSelectionChange,
}) => {
  const _onToggleSelection = () => onSelectionChange?.(id, selected);

  return (
    <button
      id={eltId}
      type="button"
      onClick={_onToggleSelection}
      className={`activity ${
        selected ? "active-check background-after-malachite" : "active-plus"
      }`}
    >
      <img src={url} alt={activityTitle} />
    </button>
  );
};

export default Activity;

interface IActivityProps extends IActivity {
  eltId: string;
  selected?: boolean;
  onSelectionChange?: (id: number, selected: boolean) => void;
}
