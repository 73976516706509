import { useRef } from 'react';
import {
  listUsers,
  updateUser,
  updateUserPreferences,
} from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { YEARS } from 'app/constants/common';
// import { IUserProfile } from 'app/models/userProfile';
import { IUserBuilder, IUserV2 } from 'app/models/user';
import { useNavigate } from 'react-router-dom';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { IAccountSubscriptionStatus } from 'app/models/accountNotification';
import { IPreference } from 'app/models/preference';
import { AccountNotificationPopupRef } from 'app/components/accountNotification/AccountNotificationPopup';
import usePagination from './Pagination';

const useUserProfieListing = () => {
  const navigate = useNavigate();
  const subscriptionModalRef = useRef<AccountNotificationPopupRef>(null);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const _pagination = usePagination<IUserV2>({
    listFn: listUsers,
  });
  const classYears = YEARS.map((classYear, id) => ({ id, classYear }));

  const onNewUserProfileClick = () => navigate(ROUTES.USER_PROFILE_EDITOR);

  const updateUserSubscriptionStatus = async (
    updatedPreferences: IPreference[],
    id: number
  ) => {
    try {
      showLoader();
      if (id) {
        // Update user preferences via Api endpoint
        const preference_ids = updatedPreferences.map(
          (preference) => preference.id
        );
        await updateUserPreferences(id, { preference_ids });

        // Update local user object
        _pagination.updateData(id, 'id', { preferences: updatedPreferences });
        return true;
      }
    } catch (error) {
      console.error('Error saving notification subscription settings', error);
      return false;
    } finally {
      hideLoader();
    }

    return false;
  };

  const onToggleActive = (userProfile: IUserV2) => async (checked: boolean) => {
    try {
      await updateUser(+userProfile.id, { is_enabled: checked });
      _pagination.updateData(userProfile.id, 'id', { is_enabled: checked });
    } catch (error) {
      console.log('🚀 ~ file: UserBuilder.ts ~ onSubmit ~ error', error);
    } finally {
      hideLoader();
    }
  };

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.USER_PROFILE_EDITOR}/${id}`);
        break;
      case DATA_GRID_ACTIONS.UNSUBSCRIBE:
        const user = _pagination.data.find((item: IUserV2) => item.id === id);
        if (user && user.id) {
          subscriptionModalRef?.current?.show(user.id, user);
        }

        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    onNewUserProfileClick,
    onToggleActive,
    onGridAction,
    updateUserSubscriptionStatus,
    subscriptionModalRef,
    classYears,
  };
};

export default useUserProfieListing;
