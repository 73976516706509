import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppState, appStateSelectors } from 'app/state/AppState';
import './email-template-preview.scss';

interface IEmailPreview {}

const EmailPreview: React.FC<IEmailPreview> = (props) => {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);

  const appConfig = useAppState(appStateSelectors.appConfig);

  const params = useParams();

  useEffect(() => {
    params.id && _populateIFrame(+params.id);
  }, [params.id]);

  function _populateIFrame(id: number) {
    const xhr = new XMLHttpRequest();
    const url = `${process.env.REACT_APP_BASE_URL}/admin/templates/${id}/preview`;
    const headers = [
      ['X-Think12-Program-Id', `${appConfig!.id}`],
      ['X-Think12-AdminFacing', 'true'],
    ];

    xhr.open('GET', url);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    headers.forEach(function (header) {
      xhr.setRequestHeader(header[0], header[1]);
    });
    xhr.send();

    function handler(response: any) {
      if (iFrameRef.current && response && response?.srcElement?.response) {
        iFrameRef.current.src = URL.createObjectURL(
          response.srcElement.response
        );
      }
    }
  }

  return (
    <div className='email-template-preview'>
      <iframe
        ref={(ref) => (iFrameRef.current = ref)}
        width='100%'
        height='100%'
        src=''
      />
    </div>
  );
};

export default EmailPreview;
