import React, { RefObject } from "react";
import If from "../If";
import "./button.scss";

const RoundButton: React.FC<IRoundButtonProps> = ({
  icon,
  className,
  size = "md",
  disabled,
  roundBtnId,
  innerRef,
  label,
  variant = "",
  onClick,
}) => {
  return (
    <div className={`round-button-container ${size} ${variant}`}>
      <button
        type="button"
        disabled={disabled}
        className={`round-button ${size} ${className}`}
        onClick={onClick}
        id={roundBtnId}
        ref={innerRef}
      >
        {icon}
      </button>
      <If condition={!!label}>
        <span className={`round-button-label ${size} ${className}`}>
          {label}
        </span>
      </If>
    </div>
  );
};

export default RoundButton;

interface IRoundButtonProps {
  roundBtnId: string;
  icon?: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary";
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  disabled?: boolean;
  label?: string;
  innerRef?: RefObject<HTMLButtonElement>;
  onClick?: () => void;
}
