import { ReactComponent as IcCheckCircle } from 'app/assets/icons/check-circle.svg';
import { ReactComponent as IcExclamationCircle } from 'app/assets/icons/exclamation-circle.svg';
import { ReactComponent as IcPreview } from 'app/assets/icons/preview.svg';
import { Field, FieldAttributes } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import If from '../If';
import RoundButton from '../button/RoundButton';
import { ReactComponent as IcClose } from 'app/assets/icons/close.svg';
import './input.scss';

const Input: React.FC<IInputProps> = ({
  children,
  variant = 'primary',
  className = '',
  label,
  required,
  togglePassword,
  success,
  onCancel,
  ...props
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const lengthRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const elt = document.getElementById(props.id);
    if (props.as === 'textarea') {
      elt?.addEventListener('input', _inputListener);
      // @ts-ignore
      lengthRef.current?.replaceChildren(props.maxLength);
    }
    return () => {
      if (props.as === 'textarea') {
        elt?.removeEventListener('input', _inputListener);
      }
      setVisible(false);
    };
  }, []);

  const _inputListener = (e: HTMLElementEventMap['input']) => {
    // @ts-ignore
    lengthRef.current?.replaceChildren(props.maxLength - e.target.value.length);
  };

  const _toggleVisibility = () => {
    setVisible((state) => !state);
  };

  const _getFieldType = () => {
    if (togglePassword) {
      return visible ? 'input' : 'password';
    }
    return props.type || '';
  };

  return (
    <div
      className={`input-container ${variant} ${className} ${
        onCancel ? 'with-close-icon' : ''
      }`}
    >
      {(label || onCancel) && (
        <div className='input-label-row'>
          {label && (
            <label
              className={`${required ? 'required' : ''}`}
              htmlFor={props.id}
            >
              {label}
            </label>
          )}
          {onCancel && (
            <RoundButton
              roundBtnId={`${props.id}-input-delete`}
              icon={<IcClose />}
              size='xs'
              className='input-cancel-button'
              onClick={() => onCancel(props.name)}
            />
          )}
        </div>
      )}
      <If condition={props.as === 'textarea' && props.maxLength}>
        <span className='remaining-length' ref={lengthRef} />
      </If>
      <If condition={props.as === 'text'}>
        <input
          className={`field ${!!props.error && 'field-error'}`}
          {...props}
        />
      </If>
      <If condition={props.as !== 'text'}>
        <div className='field-wrapper'>
          <Field
            className={`field ${!!props.error && 'field-error'}`}
            // onChange={props.as === "textarea" ? _onChange : props.onChange}
            {...props}
            type={_getFieldType()}
          >
            {props.as === 'select' ? children : null}
          </Field>
          {togglePassword && (
            <IcPreview
              onClick={_toggleVisibility}
              id='preview-icon'
              className='icon preview-icon'
            />
          )}
        </div>
      </If>
      <If condition={!!props.error}>
        <IcExclamationCircle className='icon' />
      </If>
      <If condition={!!success}>
        <IcCheckCircle className='icon' />
      </If>

      <If condition={!!props.error}>
        <span className='error'>{props.error}</span>
      </If>
    </div>
  );
};

export default Input;

interface IInputProps extends FieldAttributes<any> {
  id: string;
  variant?: 'primary' | 'secondary';
  className?: string;
  label: string;
  success?: boolean;
  togglePassword?: boolean;
}
