import React from 'react';
import { ICategoryTag } from 'app/models/common';
import { IEmailTemplateContent } from './../models/email';
import { useRef, useState } from 'react';
import { useNavigate, useLocation, useHref } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  listThink12EmailTemplates,
  getEmailTemplateContent,
  duplicateEmailTemplate,
  deleteEmailTemplate,
  testEmail,
} from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { IEmailManager } from 'app/models/email';
import { EmailManagerTestPopupRef } from 'app/screens/email/EmailManagerTestPopup';
import { EmailPreviewPopupRef } from 'app/screens/email/components/emailTemplates/EmailPreviewPopup';
import { useAppState, appStateSelectors } from 'app/state/AppState';
import usePagination from './Pagination';

export type TestSendStatus =
  | 'NOT_STARED'
  | 'IN_PROGRESS'
  | 'SUCCESS'
  | 'FAILED';

const useEmailManagerListing = () => {
  const navigate = useNavigate();
  const previewHref = useHref(ROUTES.EMAIL_PREVIEW);

  const _pagination = usePagination<IEmailManager>({
    listFn: listThink12EmailTemplates,
    deleteFn: deleteEmailTemplate,
  });

  const previewModalRef = useRef<EmailPreviewPopupRef>(null);
  const testEmailModalRef = useRef<EmailManagerTestPopupRef>(null);

  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const contentCategories = useAppState(appStateSelectors.contentCategories);
  const contentApprovals = useAppState(appStateSelectors.contentApprovals);
  const contentDesignations = useAppState(
    appStateSelectors.contentDesignations
  );

  const [testSendStatus, setTestSendStatus] =
    useState<TestSendStatus>('NOT_STARED');
  const [testSendErrorMsg, setTestSendErrorMsg] = useState<string | null>(null);

  const onNewEmailClick = () => navigate(`${ROUTES.EMAIL_EDITOR}`);

  const onDuplicate = async (id: number) => {
    try {
      const { data } = await duplicateEmailTemplate(id);
      data && _pagination.addData(data);
      return data;
    } catch (ex) {
      console.error(`Error duplicating email template!`);
    }
  };

  const onGridAction = async (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIEW:
        const hostname = window.location.hostname;
        const domain = window.location.host;
        const protocol = hostname === 'localhost' ? 'http' : 'https';

        window.open(`${protocol}://${domain}/${previewHref}/${id}`, '_blank');
        break;
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.EMAIL_EDITOR}/${id}`);
        break;
      case DATA_GRID_ACTIONS.DUPLICATE:
        await onDuplicate(id);
        break;
      case DATA_GRID_ACTIONS.DELETE:
        await _pagination.onDelete(id);
        break;
      default:
        break;
    }
  };

  const getContentDesignationLabel = (id?: number) => {
    return id
      ? contentDesignations.find((designation) => designation.id === id)
          ?.designationType || ''
      : '';
  };

  const getApprovalStatusLabel = (id?: number) => {
    return id
      ? contentApprovals.find((approval) => approval.id === id)?.status || ''
      : '';
  };

  const getCategoryLabels = (categories?: ICategoryTag[]) => {
    if (!categories || isEmpty(categories)) return '';

    return categories.map((category) => category.category_tag);
  };

  const onTestEmailClick = () => {
    testEmailModalRef.current?.show(_pagination.selectedRows[0]);
  };

  const onTestEmailSend = async (emailId: number, recipients: string[]) => {
    setTestSendStatus('IN_PROGRESS');
    showLoader();

    const recipientParams = (recipients || []).map((recipient) => ({
      email: recipient,
    }));

    try {
      const { status, message } = await testEmail(emailId, {
        recipients: recipientParams,
      });
      setTestSendStatus(status === 204 ? 'SUCCESS' : 'FAILED');
      setTestSendErrorMsg(
        status === 204
          ? 'Email Queued Successfully'
          : message || 'Error sending email'
      );

      if (status === 204) {
        setTimeout(() => {
          setTestSendStatus('NOT_STARED');
          setTestSendErrorMsg(null);
          testEmailModalRef.current?.close();
        }, 1000);
      }
    } catch (err) {
      setTestSendStatus('FAILED');
      setTestSendErrorMsg('Server error sending email');
    } finally {
      hideLoader();
    }
  };

  const onTestSendStatusReset = () => {
    setTestSendStatus('NOT_STARED');
  };

  return {
    ..._pagination,
    previewModalRef,
    contentCategories,
    contentDesignations,
    getContentDesignationLabel,
    getApprovalStatusLabel,
    getCategoryLabels,
    contentApprovals,
    testSendStatus,
    testSendErrorMsg,
    onNewEmailClick,
    onGridAction,
    onTestEmailClick,
    onTestEmailSend,
    testEmailModalRef,
  };
};

export default useEmailManagerListing;
