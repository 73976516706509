import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import RoundButton from 'app/components/button/RoundButton';
import RadioBox from 'app/components/radio-box/RadioBox';
import Checkbox from 'app/components/checkbox/CheckBox';
import BorderedCheckBox from 'app/components/checkbox/BorderedCheckBox';
import { IConfigurationEditor } from 'app/hooks/ConfigurationEditor';
import { IMainProgramConfigBuilder } from 'app/models/config';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';

type GradeBandsSection = {} & IConfigurationEditorSection;

const GradeBandsSection: React.FC<GradeBandsSection> = ({
  editor,
  values,
  touched,
  errors,
}) => {
  return (
    <Section
      title='Program Grade Bands'
      sectionId='configuration-editor-grade-bands'
      titleSize='md'
      className='configuration-editor-grade-bands mt-4'
    >
      <Row>
        {editor.gradeBands?.map((grade_band) => {
          return (
            <Col md={2}>
              <BorderedCheckBox
                id='configuration-editor-grade-band'
                label={grade_band.gradeBand}
                onChange={(checked: boolean) =>
                  editor?.onChangeGradeBand?.(grade_band.id, checked)
                }
                checked={(values.grade_band_ids || []).includes(grade_band.id)}
              />
            </Col>
          );
        })}
      </Row>
      <Row className='mt-5'>
        <Col>
          <Input
            id='configuration-editor-last-content-import'
            label='Last content import'
            variant='secondary'
            name='last_content_import'
            error={
              touched.last_content_import && errors.last_content_import
                ? errors.last_content_import
                : ''
            }
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-last-email-import'
            label='Last email import'
            variant='secondary'
            name='last_email_import'
            error={
              touched.last_email_import && errors.last_email_import
                ? errors.last_email_import
                : ''
            }
          />
        </Col>
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};

export default GradeBandsSection;
