import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTouched, FormikErrors } from 'formik';

import Section from 'app/components/section/Section';
import RoundButton from 'app/components/button/RoundButton';
import Input from 'app/components/input/Input';
import { IStaffBuilder } from 'app/models/staff';
import { ReactComponent as IcPlus } from 'app/assets/icons/plus.svg';

interface IReviewsSection {
  editor: any;
  touched: FormikTouched<IStaffBuilder>;
  errors: FormikErrors<IStaffBuilder>;
  values: IStaffBuilder;
}

export const ReviewsSection: React.FC<IReviewsSection> = ({
  editor,
  touched,
  errors,
  values,
}) => {
  return (
    <Section
      title='Reviews'
      sectionId='staffBuilderReviews'
      titleSize='lg'
      className='staff-builder-reviews'
      isEditable={true}
    >
      {(values.reviews ?? []).map((review, index) => {
        return (
          <Input
            key={review.tmp_id ?? review.id}
            id={`staff-builder-review-${index + 1}`}
            variant='secondary'
            as='textarea'
            maxLength={100}
            label='Review'
            placeholder='Share a positive comment or review about you'
            value={review.body ?? ''}
            onChange={editor.onChangeReview(review.tmp_id)}
          />
        );
      })}
      <RoundButton
        icon={<IcPlus />}
        variant='primary'
        size='lg'
        label='Add new review'
        onClick={editor.addReview}
        roundBtnId='staff-builder-add-btn'
      />
    </Section>
  );
};
