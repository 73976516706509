import StandardButton from 'app/components/button/StandardButton';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import useFamilyListing from 'app/hooks/FamilyListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import {
  IActiveStatus,
  IGrade,
  ISubscriptionStatus,
  IUserPermission,
  IRole,
} from 'app/models/common';
import { IPreference } from 'app/models/preference';
import { ACTIVE_OPTIONS, SUBSCRIPTION_OPTIONS } from 'app/utils/common';
import {
  formatSubScriptionStatusForFamilies,
  formatGradeBands,
  formatUserPermissions,
} from 'app/utils/data';
import { IGradeBand } from 'app/models/program';
import './family.scss';
import React from 'react';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import { IFamilyListItem, IFamilyListItemV2 } from 'app/models/family';
import Switch from 'app/components/switch/Switch';
import AccountNotificationPopup from 'app/components/accountNotification/AccountNotificationPopup';

const GRID_ID = 'family-listing';

const FamilyListing: React.FC = () => {
  const _familyListing = useFamilyListing();
  return (
    <PrivateLayout contentClassName='family-listing'>
      <StandardButton
        label='+ New Family Profile'
        variant='secondary'
        className='new-family-button'
        onClick={_familyListing.onNewFamilyClick}
        standardBtnId='family-listing-new-btn'
      />
      <h3 className='title'>Family Account Manager</h3>
      <SearchBar
        placeHolder='Search by user name, ID, email associated with the account'
        searchBarId='family-listing-search'
        onChangeText={_familyListing.onSearchKeyChange}
        onSearch={_familyListing.onSearch}
      />
      <Filter
        id='family-listing-filter'
        onFilterChange={_familyListing.onFilterChange}
        selectedFilters={_familyListing.filters}
      >
        <FilterOption<IPreference>
          labelAttribute='subcategory'
          valueAttribute='id'
          name='preference'
          options={_familyListing.preferences}
          placeholder='Subscription Status'
        />
        <FilterOption<IRole>
          labelAttribute='roleName'
          valueAttribute='id'
          name='userPermissions'
          options={_familyListing.roles}
          placeholder='User Permissions'
        />
        <FilterOption<IActiveStatus>
          labelAttribute='label'
          valueAttribute='isActive'
          name='isActive'
          options={ACTIVE_OPTIONS}
          placeholder='Active Status'
          disableMultipleSelection
        />
        <FilterOption<IGradeBand>
          labelAttribute='gradeBand'
          valueAttribute='id'
          name='gradeBandIds'
          options={_familyListing.gradeBands}
          placeholder='Grade Band'
        />
      </Filter>
      <DataGrid<IFamilyListItemV2>
        data={_familyListing.data}
        gridId={GRID_ID}
        loading={_familyListing.loading}
        confirmDeleteDescription='Please confirm that you would like to delete this family'
        rowIdAttribute='id'
        currentPage={_familyListing.currentPage}
        onPageChange={_familyListing.onPageChange}
        totalItems={_familyListing.totalItems}
        onAction={_familyListing.onGridAction}
      >
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='last_name'
          header='USER NAME'
          transform={(family: IFamilyListItemV2) => family.last_name || ''}
        />
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='programId'
          header='PROGRAM ID'
        />
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='schoolId'
          header='SCHOOL ID'
        />
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='subscriptionStatus'
          header='SUBSCRIPTION STATUS'
          transform={(data: IFamilyListItemV2) =>
            formatSubScriptionStatusForFamilies(data)
          }
        />
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='userPermissions'
          header='USER PERMISSIONS'
          transform={(family: IFamilyListItemV2) => {
            return formatUserPermissions(family?.members || []);
          }}
        />
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='gradeBand'
          header='GRADE BAND'
          transform={(family: IFamilyListItemV2) => {
            return formatGradeBands(family?.members);
          }}
        />
        <DataGrid.Column<IFamilyListItemV2>
          attributeName='is_enabled'
          header='Active Status'
          transform={(family) => (
            <Switch
              checked={family.is_enabled}
              onChange={_familyListing.onToggleActive(family)}
              switchId='family-listing-live-btn'
            />
          )}
        />
        <DataGrid.Column<IFamilyListItemV2>
          action={DATA_GRID_ACTIONS.UNSUBSCRIBE}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IFamilyListItemV2>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
      <AccountNotificationPopup
        type='Family'
        ref={_familyListing.subscriptionModalRef}
        id='family-acccount-subscription'
        onSubmit={_familyListing.updateFamilySubscriptionStatus}
      />
    </PrivateLayout>
  );
};

export default FamilyListing;
