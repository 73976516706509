import React from "react";

const If: React.FC<IIfProps> = ({ condition, children }) => {
  return condition ? <>{children}</> : null;
};

export default If;

interface IIfProps {
  condition?: boolean;
}
