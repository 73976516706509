import React from "react";
import "./button.scss";

const AdminAreaButton: React.FC<IAdminAreaButtonProps> = ({ id, onClick }) => {
  return (
    <button
      className="admin-area-button"
      type="button"
      onClick={onClick}
      id={id}
    >
      Admin Area
    </button>
  );
};

export default AdminAreaButton;

interface IAdminAreaButtonProps {
  id: string;
  onClick?: () => void;
}
