import { deleteAdmin, listAdmins } from "app/api/adminApis";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import ROUTES from "app/constants/navigation";
import { IAdminListItem } from "app/models/admin";
import { useNavigate } from "react-router-dom";
import usePagination from "./Pagination";

const useAdminListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IAdminListItem>({
    listFn: listAdmins,
    deleteFn: deleteAdmin,
  });

  const onNewAdminClick = () => navigate(ROUTES.ADMIN_EDITOR);

  const onToggleActive = (admin: IAdminListItem) => (checked: boolean) => {};

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.ADMIN_EDITOR}/${id}`);
        break;
      case DATA_GRID_ACTIONS.DELETE:
        _pagination.onDelete?.(id);
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    onToggleActive,
    onGridAction,
    onNewAdminClick,
  };
};

export default useAdminListing;
