import useDataUploadManager from "app/hooks/DataUploadManager";
import PrivateLayout from "app/layout/PrivateLayout";
import { Row, Col } from "react-bootstrap";
import StandardButton from "app/components/button/StandardButton";
import Stepper from "app/components/stepper/Stepper";
import UploadFilesManager from "./UploadFilesManager";
import ImportFilesManager from "./ImportFilesManager";
import ExistingRecordsManager from "./ExistingRecordsManager";
import ColumnsManager from "./ColumnsManager";
import ImportedTableManager from "./ImportedTableManager";
import ImportResultsManager from "./ImportResultsManager";

import React, { useEffect, useState, useCallback } from "react";
import "./dataUploadManager.scss";

const DataUploadManager: React.FC = () => {
  const _dataMangerHandle = useDataUploadManager();
  const [validation, setValidation] = useState<Array<any>>([
    () => { return true }
  ]);

  const [dataUpload, setDataUpload] = useState<any>(null);

  const switchSteps = () => {
    switch (_dataMangerHandle.currentStep) {
      case 1:
        return <UploadFilesManager setValidation={setValidation} setDataUpload={setDataUpload} dataUpload={dataUpload}/>;
        break;
      case 2:
        return <ImportFilesManager setValidation={setValidation} setDataUpload={setDataUpload} dataUpload={dataUpload}/>;
        break;
      case 3:
        return <ExistingRecordsManager setValidation={setValidation} setDataUpload={setDataUpload} dataUpload={dataUpload}/>;
        break;
      case 4:
        return <ColumnsManager setValidation={setValidation} setDataUpload={setDataUpload} dataUpload={dataUpload}/>;
        break;
      case 5:
        return <ImportedTableManager setValidation={setValidation} setDataUpload={setDataUpload} dataUpload={dataUpload}/>;
        break;
      case 6:
        return <ImportResultsManager setValidation={setValidation} setDataUpload={setDataUpload} dataUpload={dataUpload}/>;
        break;
      default:
        break;
    }
  };
  return (
    <PrivateLayout contentClassName="upload-manager col-md-10">
      <h3 className="title">Data Upload Manager</h3>
      <Row>
        <Stepper
          currentStep={_dataMangerHandle.currentStep}
          totalSteps={_dataMangerHandle.totalSteps}
        />
        <div style={{margin:'20px'}}></div>
        {switchSteps()}
      </Row>
      <Row
        className={`space-around ${
          _dataMangerHandle.currentStep === 1 ? "right-button" : ""
        }`}
      >
        {_dataMangerHandle.currentStep > 1 && _dataMangerHandle.currentStep !== _dataMangerHandle.totalSteps+1 ? (
          <Col md={3} className="flex-center left">
            <StandardButton
              type="button"
              variant="outlined"
              label="Back"
              className="back-button"
              standardBtnId="stepper-back-btn"
              onClick={(event: any) => {
                _dataMangerHandle.previousStep();
              }}
            />
          </Col>
        ) : (
          ""
        )}
        {_dataMangerHandle.currentStep < _dataMangerHandle.totalSteps ? (
          <Col className="flex-center right" md={{ span: 3 }} lg={{ span: 3 }}>
            <StandardButton
              type="button"
              variant="secondary"
              label="Next"
              className="next-button"
              standardBtnId="stepper-next-btn"
              onClick={(event: any) => {
                if (validation[0]()) {
                  _dataMangerHandle.nextStep();
                }
              }}
            />
          </Col>
        ) : ""}

        {_dataMangerHandle.currentStep === _dataMangerHandle.totalSteps ? (
          <Col className="flex-center right" md={{ span: 3 }} lg={{ span: 3 }}>
            <StandardButton
              type="button"
              variant="secondary"
              label="Finish"
              className="next-button"
              standardBtnId="stepper-next-btn"
              onClick={(event: any) => {
                console.log(validation);
                if (validation[0]()) {
                  _dataMangerHandle.nextStep();
                }
              }}
            />
          </Col>
        ) : ""}
      </Row>
    </PrivateLayout>
  );
};

export default DataUploadManager;
