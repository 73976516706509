import { useState } from 'react';
import DOMPurify from 'dompurify';

import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import React, { useImperativeHandle, useRef } from 'react';
import { IEmailManager, IEmailTemplateContent } from 'app/models/email';

const EmailPreviewPopup = React.forwardRef<
  EmailPreviewPopupRef,
  IEmailPreviewPopupProps
>(({ id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const [content, setContent] = useState<IEmailTemplateContent | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (email: IEmailTemplateContent) => {
        setContent(email);
        modalRef.current?.show();
      },
    }),
    []
  );

  const createMarkup = (content: string) => {
    const sanitized = sanitizeHtml(content);
    return { __html: `<p>${sanitized}</p>` };
  };

  const sanitizeHtml = (content: string) => {
    if (!content) return '';
    return DOMPurify.sanitize(content);
  };

  return (
    <T12Modal
      modalId={id}
      hideFooter
      size='lg'
      ref={modalRef}
      title='Email Preview'
      className='email-preview-popup'
      showHeaderBorder
    >
      <div className={`email-template-preview-container`}>
        {content && (
          <div
            // dangerouslySetInnerHTML={createMarkup(content?.content?.template)}
            dangerouslySetInnerHTML={createMarkup(content?.data)}
          />
        )}
      </div>
    </T12Modal>
  );
});

export default EmailPreviewPopup;

interface IEmailPreviewPopupProps {
  id: string;
}

export type EmailPreviewPopupRef = {
  // show: (email: IEmailManager) => void;
  show: (content: IEmailTemplateContent) => void;
} | null;
