import { listClubs, deleteClub, updateClub } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { IClub } from 'app/models/clubs';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';

import { getProgramDomain } from 'app/utils/common';

const useClubManagerListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IClub>({
    listFn: listClubs,
  });

  const gradeBands = useAppState(appStateSelectors.gradeBands);

  const onNewClubClick = () => navigate(ROUTES.CLUBS_EDITOR);

  const onToggleStatus = (club: IClub) => (checked: boolean) => {
    club.clubActiveStatus = checked;
    updateClub(club).then((res) => {
      _pagination.onPageChange(_pagination.currentPage)
    });
  };

  const onToggleFeatured = (club: IClub) => (checked: boolean) => {
    club.featured = checked;
    updateClub(club).then((res) => {
      _pagination.onPageChange(_pagination.currentPage)
    });
  };


  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    if (action === DATA_GRID_ACTIONS.PREVIEW) {
      const url = "//" + getProgramDomain() + '/club-page/' + rowId;
      window.open(url);
    }
    if (action === DATA_GRID_ACTIONS.EDIT) {
      navigate('/clubs-editor/' + rowId);
    }
    if (action === DATA_GRID_ACTIONS.DELETE) {
      deleteClub(rowId).then(() => {
        _pagination.onPageChange(_pagination.currentPage)
      });
    }
  };



  return {
    ..._pagination,
    gradeBands,
    onNewClubClick,
    onToggleStatus,
    onGridAction,
    onToggleFeatured,
  };
};

export default useClubManagerListing;
