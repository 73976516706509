import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ROUTES from 'app/constants/navigation';
import FeatureGate from 'app/layout/FeatureGate';
import AdminTriggerEmailManager from 'app/screens/admin/AdminTriggerEmailManager';
import EmailManager from 'app/screens/email/EmailManager';
import EmailPreview from 'app/screens/email/EmailPreview';
import EmailReporting from 'app/screens/email/EmailReporting';
import EmailScheduleManager from 'app/screens/email/EmailScheduleManager';
import AdminAccountEditor from 'app/screens/adminAccountManager/AdminAccountEditor';
import AdminAccountManager from 'app/screens/adminAccountManager/AdminAccountManager';
import ArticleContentEditor from 'app/screens/articles/ArticleContentEditor';
import ArticleContentManager from 'app/screens/articles/ArticleContentManager';
import ArticlePreview from 'app/screens/articles/ArticlePreview';
import AdminAnnouncementManager from 'app/screens/adminAnnouncement/AdminAnnouncementManager';
import AdminDashboard from 'app/screens/admin/AdminDashboard';
import AdminReporting from 'app/screens/admin/Reporting';
import ProgramBuilder from 'app/screens/program/ProgramBuilder';
import ProgramListing from 'app/screens/program/ProgramListing';
import DataUploadManager from 'app/screens/dataUploadManager/DataUploadManager';
import UserProfileListing from 'app/screens/userProfile/UserProfileManager';
import UserProfileEditor from 'app/screens/userProfile/UserProfileEditor';
import AmbassadorListing from 'app/screens/ambassador/AmbassadorProfileManager';
import GraduateProfileListing from 'app/screens/graduate/GraduateGalleryProfileManager';
import GraduateBuilder from 'app/screens/graduate/GraduateBuilder';
import NewTicket from 'app/screens/support/NewTicket';
import ClubManager from 'app/screens/club/ClubManager';
import ClubEditor from 'app/screens/club/ClubEditor';
import Messages from 'app/screens/messages/Messages';
import StaffEditor from 'app/screens/staff/StaffEditor';
import EmailTemplateEditor from 'app/screens/email/EmailTemplateEditor';
import EmailScheduleEditor from 'app/screens/email/EmailScheduleEditor';
import SitePhotoManager from 'app/screens/sitePhotos/SitePhotoManager';
import StaffListing from 'app/screens/staff/StaffListing';
import QAContentEditor from 'app/screens/questionAnswer/QAContentEditor';
import FamilyListing from 'app/screens/family/FamilyListing';
import FamilyAccountEditor from 'app/screens/family/FamilyAccountEditor';
import AmbassadorAccountEditor from 'app/screens/ambassador/AmbassadorAccountEditor';
import T12Admin from 'app/screens/signIn/T12Admin';
import QAContentManager from 'app/screens/questionAnswer/QAContentManager';
import ConfigurationEditor from 'app/screens/configuration/ConfigurationEditor';
import { EnableReporting } from 'app/api/reporting';
import Outcomes from 'app/screens/outcomes/Outcomes';
import InProgress from 'app/screens/inProgress/InProgress';
import NoAccess from 'app/screens/noAccess/NoAccess';

const BackEndAdminNavigations: React.FC = () => {
  EnableReporting(true);
  return (
    <Routes>
      <Route path='/' element={<T12Admin />} />
      <Route path={ROUTES.NO_ACCESS} element={<NoAccess />} />
      <Route path={ROUTES.EMAIL_PREVIEW} element={<EmailPreview />}>
        <Route path=':id' element={<EmailPreview />} />
      </Route>
      <Route element={<FeatureGate />}>
        <Route path={ROUTES.ADMIN_DASHBOARD} element={<AdminDashboard />} />
        <Route
          path={ROUTES.ARTICLE_CONTENT_MANAGER}
          element={<ArticleContentManager />}
        />
        <Route path={ROUTES.NEW_TICKET} element={<NewTicket />} />
        <Route path={ROUTES.CLUBS_MANAGER} element={<ClubManager />} />
        <Route path={ROUTES.CLUBS_EDITOR} element={<ClubEditor />}>
          <Route path=':id' element={<ClubEditor />} />
        </Route>
        <Route path={ROUTES.ADMIN_MANAGER} element={<AdminAccountManager />} />
        <Route path={ROUTES.ADMIN_EDITOR} element={<AdminAccountEditor />}>
          <Route path=':id' element={<AdminAccountEditor />} />
        </Route>
        <Route path={ROUTES.ARTICLE_EDITOR} element={<ArticleContentEditor />}>
          <Route path=':id' element={<ArticleContentEditor />} />
        </Route>
        <Route path={ROUTES.ARTICLE_PREVIEW} element={<ArticlePreview />}>
          <Route path=':id' element={<ArticlePreview />} />
        </Route>
        <Route path={ROUTES.Q_AND_A_MANAGER} element={<QAContentManager />} />
        <Route path={ROUTES.Q_AND_A_EDITOR} element={<QAContentEditor />}>
          <Route path=':id' element={<QAContentEditor />} />
        </Route>
        <Route path={ROUTES.PROGRAM_MANAGER} element={<ProgramListing />} />
        <Route
          path={ROUTES.USER_PROFILE_MANAGER}
          element={<UserProfileListing />}
        />
        <Route
          path={ROUTES.USER_PROFILE_EDITOR}
          element={<UserProfileEditor />}
        >
          <Route path=':id' element={<UserProfileEditor />} />
        </Route>
        <Route
          path={ROUTES.ADMIN_ANNOUNCEMENT_MANAGER}
          element={<AdminAnnouncementManager />}
        />
        <Route
          path={ROUTES.AMBASSADOR_PROFILE_MANAGER}
          element={<AmbassadorListing />}
        />
        <Route path={ROUTES.MESSAGES_MANAGER} element={<Messages />} />
        <Route path={ROUTES.NEW_PROGRAM} element={<ProgramBuilder />} />
        <Route path={ROUTES.EMAIL_MANAGER} element={<EmailManager />} />

        {/* <Route path={ROUTES.EMAIL_PREVIEW} element={<EmailPreview />}>
          <Route path=':id' element={<EmailPreview />} />
        </Route> */}

        <Route
          path={ROUTES.EMAIL_SCHEDULE_MANAGER}
          element={<EmailScheduleManager />}
        />
        <Route
          path={ROUTES.EMAIL_SCHEDULE_EDITOR}
          element={<EmailScheduleEditor />}
        >
          <Route path=':id' element={<EmailScheduleEditor />} />
        </Route>
        <Route path={ROUTES.EMAIL_REPORTING} element={<EmailReporting />} />
        <Route
          path={ROUTES.ADMIN_TRIGGERED_EMAIL_MANAGER}
          element={<AdminTriggerEmailManager />}
        />
        <Route path={ROUTES.PROGRAM_BUILDER} element={<ProgramBuilder />}>
          <Route path=':id' element={<ProgramBuilder />} />
        </Route>
        <Route path={ROUTES.STAFF_MANAGER} element={<StaffListing />} />
        <Route path={ROUTES.STAFF_EDITOR} element={<StaffEditor />}>
          <Route path=':id' element={<StaffEditor />} />
        </Route>
        <Route path={ROUTES.EMAIL_EDITOR} element={<EmailTemplateEditor />}>
          <Route path=':id' element={<EmailTemplateEditor />} />
        </Route>
        <Route path={ROUTES.PHOTOS_MANAGER} element={<SitePhotoManager />} />
        <Route path={ROUTES.FAMILY_MANAGER} element={<FamilyListing />} />
        <Route path={ROUTES.FAMILY_EDITOR} element={<FamilyAccountEditor />}>
          <Route path=':id' element={<FamilyAccountEditor />} />
        </Route>
        <Route
          path={ROUTES.AMBASSADOR_EDITOR}
          element={<AmbassadorAccountEditor />}
        >
          <Route path=':id' element={<AmbassadorAccountEditor />} />
        </Route>
        <Route
          path={ROUTES.GRADUATE_MANAGER}
          element={<GraduateProfileListing />}
        />
        <Route
          path={ROUTES.GRADUATE_PROFILE_EDITOR}
          element={<GraduateBuilder />}
        >
          <Route path=':id' element={<GraduateBuilder />} />
        </Route>
        <Route
          path={ROUTES.DATA_UPLOAD_MANAGER}
          element={<DataUploadManager />}
        />
        <Route path={ROUTES.PROGRAM_REPORTING} element={<AdminReporting />} />
        <Route path={ROUTES.ADMIN_REPORTING} element={<AdminReporting />} />
        <Route
          path={ROUTES.CONFIGURATION_MANAGER}
          element={<ConfigurationEditor />}
        />

        {/** Pages Under Construction */}
        <Route
          path={ROUTES.OUTCOMES_MANAGER}
          element={<Outcomes/>}
        />
        <Route
          path={ROUTES.HOMEPAGE_MANAGER}
          element={<InProgress label='HomePage Manager' />}
        />
        <Route
          path={ROUTES.FEATURE_NOT_ACTIVE}
          element={<InProgress label='Feature Not Active' />}
        />

        {/** Catchall */}
        <Route
          path='*'
          element={<Navigate to={ROUTES.ADMIN_DASHBOARD} replace />}
        />
      </Route>
    </Routes>
  );
};

export default BackEndAdminNavigations;
