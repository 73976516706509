export interface IMediaId {
  id?: string;
}

export interface IMediaFile {
  id: number;
  tags: string[] | null;
  altText?: string;
  dimensions?: string;
  fileName: string;
  fileType: string;
  url?: string;
}

export interface IMedia extends Omit<IMediaId, 'id'> {
  fileName: string;
  fileType: string;
  url?: string;
  altText?: string;
  dimensions?: string;
  tags?: string;
  folderId?: number;
  userId?: number;
  medias?: IMediaFile[];

  // V2
  id?: number;
  filename?: string;
}

/** Api V2 */
export interface IMediaUpload {
  id?: number;
  name?: string;
  url?: string;
  image?: File;
  fileUrl?: string;
  folder_id?: number;
  tmpFileUrl?: string;
  owner_id?: number;
  owner_type?: string;
}

export interface IMediaResponse {
  id: number;
  name: string;
  mimetype: string;
  width: number;
  height: number;
  filesize: number;
  created_at: string;
  updated_at: string;
  url: string;
}

export interface IMediaSelected extends IMediaResponse {
  id: number;
  fileName: string;
  filename: string;
}

export interface IFolderMediaResponse {
  images: IMediaResponse[];
  videos: IMediaResponse[];
}

export const IMAGE_MODES = {
  UPLOAD: 'UPLOAD',
  SELECT: 'SELECT',
};

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};
