import React from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateLayout from 'app/layout/PrivateLayout';
import StandardButton from 'app/components/button/StandardButton';
import ROUTES from 'app/constants/navigation';
import './no-access.scss';

interface INoAccess {}

const NoAccess: React.FC<INoAccess> = () => {
  const navigate = useNavigate();

  return (
    <PrivateLayout contentClassName='no-access-page'>
      <h4 className='title'>
        You currently have no access to the following content.
      </h4>
      <h5 className='title'>
        Please contact your program administrator to grant the required access
        if needed.
      </h5>

      <div className='no-access-action-row'>
        <StandardButton
          standardBtnId='t12-admin-signin-btn'
          size='sm'
          variant='primary'
          label='Back to Previous Page'
          onClick={() => navigate(-1)}
        />
        <StandardButton
          standardBtnId='t12-admin-signin-btn'
          size='sm'
          variant='secondary'
          label='Return to Login Page'
          onClick={() => navigate(ROUTES.SIGN_IN)}
        />
      </div>
    </PrivateLayout>
  );
};

export default NoAccess;
