import React from "react";

const BackgroundCircle: React.FC<ICircleProps> = ({
  color,
  top,
  left,
  radius,
  width,
}) => {
  return (
    <>
      <svg
        width={2 * radius}
        height={2 * radius}
        viewBox={`0 0 ${2 * radius} ${2 * radius}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute", top, left }}
      >
        <circle
          cx={radius}
          cy={radius}
          r={radius - width / 2}
          stroke={color}
          strokeOpacity="0.3"
          strokeWidth={width}
        />
      </svg>
    </>
  );
};

export default BackgroundCircle;

interface ICircleProps {
  color: string;
  top: string | number;
  left: string | number;
  radius: number;
  width: number;
}
