import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import useSitePhotoListing from "app/hooks/SitePhotoListing";
import { emptyFunction } from "app/utils/common";
import React, { useImperativeHandle, useRef } from "react";
import "./image-details.scss";
import { ReactComponent as IcDelete } from "app/assets/icons/delete.svg";

const ImageDetails = React.forwardRef<
  ImageDetailsPopUpRef,
  IImageDetailsPopupProps
>(({ onConfirm, imageDetails, id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        modalRef.current?.show();
      },
    }),
    []
  );
  const _sitePhotoListing = useSitePhotoListing();
  return (
    <T12Modal
      size="lg"
      modalId={id}
      ref={modalRef}
      title="Image Detail"
      className="details-image"
      confirmButtonLabel="Confirm"
      cancelButtonLabel="Cancel"
      showHeaderBorder
      onConfirm={emptyFunction}
    >
      <div className="image-details">
        <div className="image-details-view">
          <img src="" alt="" />
        </div>
        <div className="image-details-content">
          {/* map the data with key & value ??? */}
          <p>
            File Name:
            <span>diversity_students_school_2020.jpg</span>
          </p>
        </div>
        <div className="alt-text">
          <p className="alt-text-title">Alt Text:</p>
          <input type="text" />
        </div>
        <div className="file-url">
          <p className="file-url-title">File URL:</p>
          <input readOnly />
        </div>
        <button className="image-details-btn">
          <IcDelete />
          Delete permanently
        </button>
      </div>
    </T12Modal>
  );
});

export default ImageDetails;

interface IImageDetailsPopupProps {
  id: string;
  imageDetails: any;
  onConfirm: () => void;
}

export type ImageDetailsPopUpRef = {
  show: () => void;
} | null;
