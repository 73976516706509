import React from "react";
import { ReactComponent as IcT12Logo } from "app/assets/icons/t12-logo.svg";
import "./footer.scss";

const PrivateFooter: React.FC = () => {
  return (
    <div className="private-footer">
      <button type="button" className="contact-us-button">
        Contact Us
      </button>
      <label className="copyright-info">© Copyright ThinK-12 · 2022</label>
      <div className="powered-by-info">
        <span>Powered by</span>
        <IcT12Logo />
      </div>
    </div>
  );
};

export default PrivateFooter;
