import { IAdmin } from 'app/models/admin';
import { IArticle } from 'app/models/article';
import { IQA, IQATopicId } from 'app/models/qa';
import { IGraduate } from 'app/models/graduate';
import { IFamilyEditor } from 'app/models/family';
import { IProgramBuilder } from 'app/models/program';
import { IStaffBuilder } from 'app/models/staff';
import { ILogin, ISignup } from 'app/models/user';
import {
  mixed,
  array,
  boolean,
  number,
  object,
  ref,
  SchemaOf,
  string,
  lazy,
} from 'yup';

const emailValidation = () =>
  string()
    .email('Please enter valid email')
    .required('Email address is required');

/**
 * Password validation with regex with minimum 8 characters.
 * @param requiredMessage
 * @returns Password validation schema
 */
const passwordValidation = (requiredMessage = 'Password is required') =>
  string()
    // .matches(
    //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //   'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    // )
    .required(requiredMessage);

/**
 * Validates phone number of following format (999) 999-9999
 * @returns validations for US phone number
 */
const phoneNumberValidation = () =>
  string()
    .matches(/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, 'Invalid phone number')
    .required('Phone number required');

const confirmPasswordValidation = (reference: string) =>
  string()
    .when(reference, {
      is: (val: string) => val && val.length > 0,
      then: string().oneOf([ref(reference)], 'Both password need to be same'),
    })
    .required('Confirm password required');

export const loginValidationSchema: SchemaOf<ILogin> = object().shape({
  email: emailValidation(),
  password: passwordValidation(),
});

export const registrationSchema: SchemaOf<ISignup> = object().shape({
  name: string().required('Name required'),
  email: emailValidation(),
  password: passwordValidation(),
  confirmPassword: confirmPasswordValidation('password'),
  phoneNumber: phoneNumberValidation(),
});

export const programBuilderSchema: SchemaOf<
  Omit<IProgramBuilder, 'importGlobalArticles' | 'importGlobalEmails'>
> = object().shape({
  demographicIds: array().min(1),
  features: array(),
  gradeBandIds: array().min(1),
  programDescription: string().required('Required'),
  programName: string().required('Required'),
  programUrl: string()
    .matches(
      /[\w\d-]+[a-z0-9](.think-12.com)/g,
      "Please enter a valid url ending with '.think-12.com'"
    )
    .required('Required'),
  schoolName: string().required('Required'),
  schoolTypeIds: array().min(1),
  shortSchoolName: string().required('Required'),
});

export const articleSchema: SchemaOf<
  Omit<
    IArticle,
    | 'activeStatus'
    | 'articleUrl'
    | 'tmpFeaturedImage'
    | 'versionId'
    | 'programId'
  >
> = object().shape({
  approvalStatusId: number().required('Required'),
  articleContent: string().required('Required'),
  articleId: string(),
  articleTitle: string().required('Required'),
  categoryIds: array().min(1),
  contentDescription: string().required('Required'),
  designationId: number().required('Required'),
  gradeBandIds: array().min(1),
  schoolTypeIds: array().min(1),
  isActive: boolean(),
  mediaId: number().required('Featured image is required'),
  featuredImageUrl: string().required('Featured image is required'),
});

export const qaSchema: SchemaOf<
  Omit<
    IQA,
    'responsesStr' | 'programUsers' | 'topicId' | 'url' | 'quoteUser' | 'id'
  >
> = object().shape({
  gradeBandIds: array().min(0),
  categoryIds: array().min(0),
  quoteText: string().required(),
  quoteApprove: boolean().default(false),
  quoteUser: string().when('quoteUserId', (quoteUserId, schema) => {
    console.log(' -- considering ', quoteUserId);
    return !quoteUserId ? schema.required() : schema.nullable().optional();
  }),
  quoteUserId: mixed().optional(),
  // quoteUserId: mixed().when('quoteUser', (quoteUser, schema) => {
  //   return !quoteUser && schema.required();
  // }),
  quoteTitle: string().required(),
  responses: array(),
  // topic: string().required(),
  topic: string().optional(),
  responsesStr: string(),
  programUsers: array(),
  topicId: string(),
  activeStatus: boolean().default(false),
});

export const graduateSchema: SchemaOf<
  Omit<IGraduate, 'showEmail' | 'isActive' | 'profileImageUrl'>
> = object().shape({
  firstName: string().required('required'),
  lastName: string().required('required'),
  email: emailValidation().required('required'),
  class: string(),
  linkedinUrl: string(),
  profileImageUrl: string(),
  aboutMe: string(),
  myExperience: string(),
  generalOpinion: string(),
  educations: array(),
});
export const adminSchema: SchemaOf<Omit<IAdmin, 'activeStatus'>> =
  object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: emailValidation(),
    password: string().required('Required'),
    accountDesignationIds: array().min(1),
    verificationCode: string().required('Required'),
  });

// export const staffSchema: SchemaOf<
//   Omit<
//     IStaffBuilder,
//     | 'gradeBands'
//     | 'isStandard'
//     | 'showEmail'
//     | 'showOtherEmail'
//     | 'isActive'
//     | 'profileImageUrl'
//     | 'mediaUrl'
//     | 'reviews'
//     | 'programId'
//   >
// > = object().shape({
//   user: object().shape({
//     id: number().required(),
//     firstName: string(),
//     lastName: string(),
//     email: string(),
//   }),
//   firstName: string().required('Required'),
//   lastName: string().required('Required'),
//   title: string().required('Required'),
//   memberOfSchool: string().required('Required'),
//   programId: string(),
//   schoolId: string(),
//   gradeBandIds: array().min(1),
//   email: string()
//     .email('Please enter valid email')
//     .required('Email address is required'),
//   otherEmail: string().email('Please enter valid email'),
//   //TODO: need to update URL validation
//   personalWebsite: string(),
//   linkedinProfile: string(),
//   educations: array(),
//   favoriteBook: string(),
//   favoriteMovie: string(),
//   favoriteFood: string(),
//   favoriteHobbies: string(),
//   favoriteSport: string(),
//   favoriteWeekendPlan: string(),
//   reviewText: string(),
//   adviceAcademicSuccess: string(),
//   favoriteSchoolMemory: string(),
//   quoteText: string(),
//   myCommitments: string(),
//   aboutMe: string(),
//   notificationSubscription: boolean().default(false),
//   promotionalSubscription: boolean().default(false),
//   transactionalSubscription: boolean().default(false),
// });

export const familyEditorSchema: SchemaOf<
  Omit<
    IFamilyEditor,
    | 'id'
    | 'familyId'
    | 'icon'
    | 'isStandard'
    | 'showEmail'
    | 'showOtherEmail'
    | 'isActive'
    | 'albums'
    | 'profileImageUrl'
    | 'programId'
    | 'socialMediasAdditionals'
    | 'user'
  >
> = object().shape({
  firstName: string(),
  lastName: string().required('Required'),
  memberOfSchool: string().required('Required'),
  email: emailValidation().required('required'),
  otherEmail: emailValidation(),
  programId: string(),
  schoolId: string(),
  linkedinProfile: string(),
  facebookProfile: string(),
  twitterProfile: string(),
  otherLinks: string(),
  favoriteBook: string(),
  favoriteRestaurant: string(),
  favoriteFood: string(),
  favoriteHoliday: string(),
  favoriteSport: string(),
  perfectFamilyPlan: string(),
  favoriteVacationPlace: string(),
  favoriteMovie: string(),
  familyMembers: array(),
  educations: array(),
  socialMedias: array(),
  userId: number().nullable().required(),
  bio: string(),
  favoriteExperience: string(),
});
