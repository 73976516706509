import React from 'react';
import SelectedOption from './SelectedOption';

const SelectedOptionContainer: React.FC<ISelectedOptionContainerProps> = ({
  id,
  className = '',
  options,
  onDelete,
}) => {
  return (
    <div className={`selected-option-container ${className}`}>
      {options.map((option) => (
        <SelectedOption
          key={`${id}_${option.label}`}
          containerId={id}
          {...option}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

export default SelectedOptionContainer;

interface ISelectedOptionContainerProps {
  id: string;
  className?: string;
  options: ISelectedOption[];
  onDelete?: (id: number, categ?: string) => void;
}

export interface ISelectedOption {
  id: number;
  label: string;
  categ?: string;
}
