import React, { useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { find } from 'lodash';

import Checkbox from '../checkbox/CheckBox';
import {
  AccountType,
  IAccountSubscriptionStatus,
  IAccountSubscriptionStatusV2,
} from 'app/models/accountNotification';
import { useAppState, appStateSelectors } from 'app/state/AppState';
import { IPreference } from 'app/models/preference';
import StandardButton from 'app/components/button/StandardButton';
import './account-notification-card.scss';

const AccountNotificationCard: React.FC<IAccountNotificationCardProps> = ({
  type,
  account,
  onSubmit,
  onClose,
  className = '',
}) => {
  const formRef = useRef<FormikProps<IAccountSubscriptionStatusV2>>(null);

  const preferences = useAppState(appStateSelectors.preferences)?.filter(
    (pref) => pref.category === 'Contact'
  );
  const options = preferences.map((pref) => ({
    id: pref.id,
    label: pref.subcategory,
  }));

  const headPreferences =
    !!account && 'head' in account ? account?.head?.preferences ?? [] : [];

  const memberPreferences =
    !!account && 'members' in account
      ? account?.members?.map((member) => member.preferences).flat()
      : [];

  const userPreferences =
    !!account && 'preferences' in account
      ? account.preferences.filter((pref) => pref.category === 'Contact')
      : [];

  const accountWidePreferences = [
    ...headPreferences,
    ...memberPreferences,
    ...userPreferences,
  ];

  const initialValues = options.reduce((final, option) => {
    final[option.id] = !!find(
      accountWidePreferences,
      (pref) => pref && pref.id === option.id
    );

    return final;
  }, {} as { [pref: string]: boolean });

  const _mapSubscriptionSettingsToPreferences = (
    settings: IAccountSubscriptionStatusV2
  ) => {
    return preferences.filter(
      (preference) => preference.id in settings && settings[preference.id]
    );
  };

  const _onSubmit = async (
    subscriptionSettings: IAccountSubscriptionStatusV2
  ) => {
    console.log('Submitting...');
    if (account?.id) {
      const updatedPreferences =
        _mapSubscriptionSettingsToPreferences(subscriptionSettings);

      console.log('Submitting ', updatedPreferences);
      const shouldClose =
        (await onSubmit?.(updatedPreferences, account.id)) || true;
      shouldClose && onClose();
    }
  };

  const classPrefix = type.toLowerCase();

  return (
    <div
      className={`${classPrefix}-account-subscription-card-container ${className}`}
    >
      <label className='article-card-title'>
        Please confirm the type of messages you want to unsubscribe {} family
        from receiving
      </label>

      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={_onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <Form className='subscription-options-section'>
                {options
                  .sort((o1, o2) => (o1.label < o2.label ? -1 : 1))
                  .map((option) => {
                    return (
                      <Row className='checkbox-row'>
                        <Col>
                          <Checkbox
                            id={option.label}
                            label={option.label}
                            checked={values[option.id]}
                            onChange={(checked: boolean) =>
                              setFieldValue(`${option.id}`, checked)
                            }
                          />
                        </Col>
                      </Row>
                    );
                  })}
                <div className='question-options-btns'>
                  <StandardButton
                    label='Cancel'
                    variant='outlined'
                    onClick={onClose}
                    className={`subscription-cancel`}
                    standardBtnId={`subscription-cancel-btn`}
                  />
                  <StandardButton
                    label='Save'
                    variant='secondary'
                    type='submit'
                    className={`${classPrefix}-subscription-submit`}
                    standardBtnId={`${classPrefix}-subscription-submit-btn`}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default AccountNotificationCard;

interface IAccountNotificationCardProps {
  className?: string;
  type: 'Family' | 'Ambassador' | 'Staff' | 'User';
  account: AccountType;
  onClose: () => void;
  onSubmit?: (
    updatedPreferences: IPreference[],
    id: number
  ) => Promise<boolean>;
}
