import StandardButton from "app/components/button/StandardButton";
import ListedGallery from "app/components/listedGallery/ListedGallery";
import useSitePhotoListing from "app/hooks/SitePhotoListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { emptyFunction } from "app/utils/common";
import React from "react";
import CreateFolder from "./createFolder/createFolder";
import ImageDetails from "./imageDetails/ImageDetails";
import "./site-photo-manager.scss";
import UploadPopup from "./uploadPopup/UploadPopup";

const SitePhotoManager: React.FC = () => {
  const _photoListing = useSitePhotoListing();

  return (
    <PrivateLayout contentClassName="sitephoto-listing">
      <div className="sitephoto-listing-buttons">
        <StandardButton
          label="+ New Image"
          variant="outlined"
          className="sitephoto-image-button"
          onClick={_photoListing.onClickUpload}
          standardBtnId="sitephoto-upload-img-btn"
        />
        <StandardButton
          label="+ New Folder"
          variant="secondary"
          className="new-sitephoto-button"
          onClick={_photoListing.onClickCreateFolder}
          standardBtnId="sitephoto-listing-new-btn"
        />
      </div>
      <h3 className="title">Site photo manager</h3>
      <ListedGallery
        className=""
        folderList={[]}
        listCount={13}
        gridCount={7}
        showDelete
        totalItems={_photoListing.data.length}
        data={_photoListing.data}
      />
      <UploadPopup
        id="upload-image-popup"
        ref={_photoListing.uploadImagePopupRef}
        onConfirm={emptyFunction}
      />
      <CreateFolder
        id="create-folder-popup"
        ref={_photoListing.createFolderPopupRef}
        onConfirm={emptyFunction}
      />
      <ImageDetails
        id="image-details-popup"
        ref={_photoListing.imageDetailsPopupRef}
        imageDetails={undefined}
        onConfirm={emptyFunction}
      />
    </PrivateLayout>
  );
};

export default SitePhotoManager;
