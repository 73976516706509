import React from "react";
import Checkbox from "../checkbox/CheckBox";
import "./listed-gallery.scss";
import placeholder from "app/assets/images/banner.png";
import { emptyFunction } from "app/utils/common";

const SelectImage = <T,>({
  onSelectionChange,
  selectedImages,
  data,
  imageUrl,
}: ISelectImageProps<T>) => {
  return (
    <div className="select-image">
      <img
        src={imageUrl ?? placeholder}
        alt=""
        className="listing-photos-image"
      />
      <Checkbox id="select-image-check" checked onChange={emptyFunction} />
    </div>
  );
};

export default SelectImage;

interface ISelectImageProps<T> {
  onSelectionChange: (selectedImages: number[]) => void;
  selectedImages: number[];
  data: T;
  imageUrl: string;
}
