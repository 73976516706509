import { useRef } from 'react';
import {
  listStaffs,
  listStaffProfiles,
  updateStaff,
  updateStaffActiveStatus,
} from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { IStaffListItem, IStaffBuilder } from 'app/models/staff';
import { IAccountSubscriptionStatus } from 'app/models/accountNotification';
import { IPreference } from 'app/models/preference';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';
import { AccountNotificationPopupRef } from 'app/components/accountNotification/AccountNotificationPopup';

const useStaffListing = () => {
  const navigate = useNavigate();

  const subscriptionModalRef = useRef<AccountNotificationPopupRef>(null);
  const _pagination = usePagination<IStaffListItem>({
    listFn: listStaffProfiles,
  });

  const grades = useAppState(appStateSelectors.gradeBands);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const roles = useAppState(appStateSelectors.roles);
  const preferences = useAppState(appStateSelectors.preferences)?.filter(
    (pref) => pref.category === 'Contact'
  );

  const onNewStaffClick = () => navigate(ROUTES.STAFF_EDITOR);

  const updateStaffSubscriptionSettings = async (
    updatedPreferences: IPreference[],
    id: number
  ) => {
    try {
      showLoader();
      console.log({ updatedPreferences });
      console.log({ id });
      if (id) {
        await updateStaff(id, {
          preference_ids: updatedPreferences.map((pref) => pref.id),
        });
        _pagination.updateData(id, 'id', {
          preferences: updatedPreferences,
        } as Partial<IStaffListItem>);
        return true;
      }
    } catch (error) {
      console.error('Error saving notification subscription settings', error);
      return false;
    } finally {
      hideLoader();
    }

    // return false;

    // _pagination.updateData(id, 'id', subscriptionSettings as IStaffListItem);
    return true;
  };

  // TODO: need to fix the action
  const onToggleActive =
    (staff: IStaffListItem) => async (checked: boolean) => {
      console.log(' -- checked : ', checked);
      try {
        // const { data } = await updateStaffActiveStatus(staff.id, checked);
        const { data } = await updateStaff(staff.id, { is_enabled: checked });
        _pagination.updateData(staff.id, 'id', { is_enabled: checked });
      } catch (error) {
        console.log(
          '🚀 ~ file: StaffListing.ts ~ const_changeActiveStatus= ~ error',
          error
        );
      }
    };

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    console.log('onGridAction -- ', id);
    switch (action) {
      case DATA_GRID_ACTIONS.UNSUBSCRIBE:
        const staff = _pagination.data.find(
          (item: IStaffListItem) => item.id === id
        );
        staff && subscriptionModalRef?.current?.show(id, staff);
        break;
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.STAFF_EDITOR}/${id}`);
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    grades,
    roles,
    preferences,
    onNewStaffClick,
    onToggleActive,
    onGridAction,
    updateStaffSubscriptionSettings,
    subscriptionModalRef,
  };
};

export default useStaffListing;
