import React from 'react';
import { FormikTouched, FormikErrors } from 'formik';

import Section from 'app/components/section/Section';
import If from 'app/components/If';
import Input from 'app/components/input/Input';
import VideoPlay from 'app/components/videoPlay/VideoPlay';
import FileUploader from 'app/components/fileUploader/FileUploader';
import DragFileUpload from 'app/components/dragFileUpload/DragFileUpload';
import UploadPopup from 'app/screens/sitePhotos/uploadPopup/UploadPopupV2';
import { IStaffBuilder } from 'app/models/staff';
import { IMediaSelected, IMediaUpload } from 'app/models/media';
import { mediaIsImage, mediaIsVideo, mediaTypeByMime } from 'app/utils/common';

interface IImageAndQuoteSection {
  editor: any;
  touched: FormikTouched<IStaffBuilder>;
  errors: FormikErrors<IStaffBuilder>;
  values: IStaffBuilder;
}

export const ImageAndQuoteSection: React.FC<IImageAndQuoteSection> = ({
  editor,
  touched,
  errors,
  values,
}) => {
  function _getMedia() {
    // Handle whether media is selected from existing or recently uploaded
    const selected = values?.about_media as IMediaSelected;
    const uploaded = values?.about_media as IMediaUpload;
    const mimetype = selected?.mimetype ?? uploaded?.image?.type ?? '';
    const mediaType = mediaTypeByMime(mimetype);

    let url = '';
    if (selected?.url) {
      url = selected?.url ?? '';
    } else if (uploaded?.fileUrl) {
      url = uploaded?.url ?? '';
    } else if (uploaded?.tmpFileUrl) {
      url = uploaded?.tmpFileUrl ?? '';
    }

    return {
      isVideo: mediaType === 'video',
      isImage: mediaType === 'image',
      url,
    };
  }

  const { isVideo, isImage, url } = _getMedia();

  return (
    <Section
      title='Image or Video'
      sectionId='staffBuilderUpload'
      titleSize='lg'
      className='staff-builder-upload'
      isEditable={true}
      onRightButtonClick={() => {
        // !!values.about_media && editor.uploadPopupRef?.current?.show();
      }}
    >
      <div className='mb-5'>Add a representative video or image about you.</div>
      <If condition={!values.about_media}>
        <DragFileUpload
          className='image-or-video'
          label='Drop your file(s) here'
          buttonLabel='browse'
          id='imageOrVideo'
          onClick={() => {
            editor.uploadPopupRef?.current?.show();
          }}
          onDrop={() => console.log('On Drop')}
        />
      </If>
      <If condition={isVideo}>
        <VideoPlay
          videoPlayerId='staff-builder-video'
          videoURL={url}
          className='mb-5'
        />
      </If>
      <If condition={isImage}>
        <div className='staff-editor-image mb-5'>
          <img src={url} alt='staff-image' />
        </div>
      </If>
      <FileUploader
        ref={editor.uploaderRef}
        onUploadComplete={editor.onUploadComplete}
      />
      <Input
        id='staff-builder-quote-text'
        variant='secondary'
        as='textarea'
        maxLength={75}
        label='Quote text'
        placeholder=''
        name='quote'
      />

      <UploadPopup
        id={`representative-upload-default-photo`}
        onConfirm={() => {}}
        ref={editor.uploadPopupRef}
        autoUpload={false}
        owner={{
          id: editor?.appConfig?.id,
          type: 'Program',
        }}
        onFileSelect={(
          _,
          file?: File,
          tmpFileStr?: string,
          folderId?: number,
          existingMedia?: IMediaSelected
        ) => {
          if (file && folderId) {
            editor?.onChangeImage?.('about_media')({
              image: file,
              name: file.name,
              tmpFileUrl: tmpFileStr ?? '',
              folderId: folderId ?? '0',
            });
          } else if (existingMedia) {
            editor?.onChangeImage?.('about_media')(existingMedia);
          }
        }}
      />
    </Section>
  );
};
