import ROUTES from 'app/constants/navigation';
import { useState, useRef, useEffect } from 'react';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';

import { adminLogin } from 'app/api/authApis';
import { ILogin, ILoginv2, IUser } from 'app/models/user';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { authStateSelectors, useAuthState } from 'app/state/AuthState';

const useSignIn = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<ILogin>>(null);
  const setUser = useAuthState(authStateSelectors.setUser);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);

  const [loginError, setLoginError] = useState<string>('');

  const onT12AdminSignin = async (values: ILogin) => {
    showLoader();
    try {
      setLoginError('');
      const authParams: ILoginv2 = formatAuthParams(values);
      const response = await adminLogin(authParams);

      if (response?.status === 201 && response?.data?.token) {
        localStorage.setItem('token', response.data.token?.value);
        setUser(response.data?.user);
        navigate(ROUTES.ADMIN_DASHBOARD);
      } else if (response.statusCode === 400) {
        setLoginError(
          'Sorry, the email or password you entered does not match our records.'
        );
      }
    } catch (error) {
      console.log('🚀 ~ file: SignIn.ts ~ onT12AdminSignin ~ error', error);
    } finally {
      hideLoader();
    }
  };

  const formatAuthParams = (values: ILogin): ILoginv2 => {
    return {
      identity: {
        type: 'email',
        value: values.email,
      },
      secret: {
        type: 'password',
        value: values.password,
      },
    };
  };

  const onProgramSelected = (id: number) => {
    formRef.current?.setFieldValue('programId', id || null);
  };

  return {
    initialValues,
    formRef,
    onT12AdminSignin,
    onProgramSelected,
    loginError,
  };
};

export default useSignIn;

const initialValues: ILogin = {
  email: '',
  password: '',
};
