import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import useAdminListing from "app/hooks/AdminListing";
import PrivateLayout from "app/layout/PrivateLayout";
import {
  IActiveStatus,
  IAdminListItem,
  IGrade,
  ISubscriptionStatus,
  IUserPermission,
} from "app/models/admin";
import React from "react";
import "./admin-account-manager.scss";

const GRID_ID = "admin-account-listing";

const AdminAccountManager: React.FC = () => {
  const _adminListing = useAdminListing();
  return (
    <PrivateLayout contentClassName="admin-listing">
      <StandardButton
        label="+ New Admin"
        variant="secondary"
        className="new-admin-button"
        onClick={_adminListing.onNewAdminClick}
        standardBtnId="admin-listing-new-btn"
      />
      <h3 className="title">Admin Account Manager</h3>
      <SearchBar
        placeHolder="Search by user name, email, access permissions, or account designation"
        searchBarId="admin-listing-search"
        onSearch={_adminListing.onSearch}
        onChangeText={_adminListing.onSearchKeyChange}
      />
      <Filter
        id="admin-listing-filter"
        onFilterChange={_adminListing.onFilterChange}
        selectedFilters={_adminListing.filters}
      >
        <FilterOption<ISubscriptionStatus>
          labelAttribute="subscriptionStatus"
          valueAttribute="id"
          name="subscriptionStatus"
          options={[]}
          placeholder="subscription status"
        />
        <FilterOption<IUserPermission>
          labelAttribute="userPermissions"
          valueAttribute="id"
          name="userPermissions"
          options={[]}
          placeholder="user permissions"
        />
        <FilterOption<IActiveStatus>
          labelAttribute="activeStatus"
          valueAttribute="id"
          name="activeStatus"
          options={[]}
          placeholder="active status"
        />
        <FilterOption<IGrade>
          labelAttribute="grade"
          valueAttribute="id"
          name="grade"
          options={[]}
          placeholder="grade(s)"
        />
      </Filter>
      <DataGrid<IAdminListItem>
        data={_adminListing.data}
        gridId={GRID_ID}
        rowIdAttribute="adminId"
        currentPage={_adminListing.currentPage}
        onPageChange={_adminListing.onPageChange}
        totalItems={_adminListing.totalItems}
        onAction={_adminListing.onGridAction}
      >
        <DataGrid.Column<IAdminListItem>
          attributeName="userName"
          header="USER NAME"
        />
        <DataGrid.Column<IAdminListItem>
          attributeName="programId"
          header="PROGRAM ID"
        />
        <DataGrid.Column<IAdminListItem> attributeName="email" header="EMAIL" />
        <DataGrid.Column<IAdminListItem>
          attributeName="subscriptionStatus"
          header="SUBSCRIPTION STATUS"
        />
        <DataGrid.Column<IAdminListItem>
          attributeName="userPermissions"
          header="USER PERMISSIONS"
        />
        <DataGrid.Column<IAdminListItem>
          attributeName="activeStatus"
          header="ACTIVE STATUS"
          transform={(admin) => (
            <Switch
              checked={admin.activeStatus}
              onChange={_adminListing.onToggleActive(admin)}
              switchId="admin-listing-live-btn"
            />
          )}
        />
        <DataGrid.Column<IAdminListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
    </PrivateLayout>
  );
};

export default AdminAccountManager;
