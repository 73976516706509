import React from 'react';
import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import Switch from 'app/components/switch/Switch';
import useAmbassadorProfileListing from 'app/hooks/AmbassadorProfileListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IAmbassadorV2, IGrade, IUserPermission } from 'app/models/ambassador';
import { IActiveStatus, ISubscriptionStatus } from 'app/models/common';
import { IRole } from 'app/models/common';
import { IAmbassadorBase } from 'app/models/ambassador';
import { ACTIVE_OPTIONS, SUBSCRIPTION_OPTIONS } from 'app/utils/common';
import {
  formatSubScriptionStatusForFamilies,
  formatUserPermissions,
  formatGradeBands,
} from 'app/utils/data';
import { IGradeBand } from 'app/models/program';
import AccountNotificationPopup from 'app/components/accountNotification/AccountNotificationPopup';
import './ambassador-profile-manager.scss';

const GRID_ID = 'ambassador-account-listing';

const AmbassadorProfileManager: React.FC = () => {
  const _ambassadorProfileListing = useAmbassadorProfileListing();

  return (
    <PrivateLayout contentClassName='ambassador-listing'>
      <StandardButton
        label='+ New Ambassador Profile'
        variant='secondary'
        className='new-ambassador-button'
        onClick={_ambassadorProfileListing.onNewAmbassadorClick}
        standardBtnId='ambassador-listing-new-btn'
      />
      <h3 className='title'>Ambassador profile manager</h3>
      <SearchBar
        placeHolder='Search by user name, email, access permissions, or account designation'
        searchBarId='ambassador-listing-search'
        onChangeText={_ambassadorProfileListing.onSearchKeyChange}
        onSearch={_ambassadorProfileListing.onSearch}
      />
      <Filter
        id='ambassador-listing-filter'
        onFilterChange={_ambassadorProfileListing.onFilterChange}
        selectedFilters={_ambassadorProfileListing.filters}
      >
        <FilterOption<ISubscriptionStatus>
          labelAttribute='label'
          valueAttribute='subscriptionStatus'
          name='subscriptionStatus'
          options={SUBSCRIPTION_OPTIONS}
          placeholder='Subscription Status'
        />
        <FilterOption<IRole>
          labelAttribute='roleName'
          valueAttribute='id'
          name='userPermissions'
          options={_ambassadorProfileListing.roles}
          placeholder='User Permissions'
        />
        <FilterOption<IActiveStatus>
          labelAttribute='label'
          valueAttribute='isActive'
          name='isActive'
          options={ACTIVE_OPTIONS}
          placeholder='Active Status'
          disableMultipleSelection
        />
        <FilterOption<IGradeBand>
          labelAttribute='gradeBand'
          valueAttribute='id'
          name='gradeBandIds'
          options={_ambassadorProfileListing.gradeBands}
          placeholder='Grade Band'
        />
      </Filter>
      <DataGrid<IAmbassadorV2>
        data={_ambassadorProfileListing.data}
        gridId={GRID_ID}
        rowIdAttribute='id'
        currentPage={_ambassadorProfileListing.currentPage}
        onPageChange={_ambassadorProfileListing.onPageChange}
        totalItems={_ambassadorProfileListing.totalItems}
        onAction={_ambassadorProfileListing.onGridAction}
      >
        <DataGrid.Column<IAmbassadorV2>
          attributeName='last_name'
          header='USER NAME'
          transform={(family: IAmbassadorV2) => family.last_name || ''}
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='program_id'
          header='PROGRAM ID'
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='school_id'
          header='SCHOOL ID'
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='subscriptionStatus'
          header='SUBSCRIPTION STATUS'
          transform={(data: IAmbassadorV2) =>
            formatSubScriptionStatusForFamilies(data)
          }
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='userPermissions'
          header='USER PERMISSIONS'
          transform={(family: IAmbassadorV2) => {
            return formatUserPermissions(family?.members || []);
          }}
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='gradeBand'
          header='GRADE BAND'
          transform={(family: IAmbassadorV2) => {
            return formatGradeBands(family?.members);
          }}
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='attending_school_since'
          header='Class Year'
        />
        <DataGrid.Column<IAmbassadorV2>
          attributeName='is_enabled'
          header='ACTIVE STATUS'
          transform={(ambassador) => (
            <Switch
              checked={ambassador.is_enabled}
              onChange={_ambassadorProfileListing.onToggleActive(ambassador)}
              switchId='ambassador-listing-live-btn'
            />
          )}
        />
        <DataGrid.Column<IAmbassadorV2>
          action={DATA_GRID_ACTIONS.UNSUBSCRIBE}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IAmbassadorV2>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
      <AccountNotificationPopup
        type='Ambassador'
        ref={_ambassadorProfileListing.subscriptionModalRef}
        id='ambassador-acccount-subscription'
        onSubmit={_ambassadorProfileListing.updateFamilySubscriptionStatus}
      />
    </PrivateLayout>
  );
};

export default AmbassadorProfileManager;
