import { listPhotos } from "app/api/adminApis";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import { ISitePhoto } from "app/models/photos";
import { CreateFolderPopUpRef } from "app/screens/sitePhotos/createFolder/createFolder";
import { ImageDetailsPopUpRef } from "app/screens/sitePhotos/imageDetails/ImageDetails";
import { UploadPopUpRef } from "app/screens/sitePhotos/uploadPopup/UploadPopup";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useModifiedState from "./ModifiedState";
import usePagination from "./Pagination";

const useSitePhotoListing = (listCount?: number, gridCount?: number) => {
  const navigate = useNavigate();
  const [gridView, setGridView] = useState(false);
  const [fileName, setFileName] = useState("");
  const uploadImagePopupRef = useRef<UploadPopUpRef>(null);
  const createFolderPopupRef = useRef<CreateFolderPopUpRef>(null);
  const imageDetailsPopupRef = useRef<ImageDetailsPopUpRef>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const _pagination = usePagination<ISitePhoto>({
    listFn: listPhotos,
  });
  // TODO: Sample data, remove this once api is integrated
  _pagination.data = sampleData;

  // upload function -> upload modal

  const openFileSelector = () => {
    fileRef.current?.click();
  };
  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files![0];
    setFileName(file.name);
  };

  const onChangeGridView = () => {
    setGridView(!gridView);
  };

  const onClickUpload = () => {
    uploadImagePopupRef.current?.show();
  };

  const onClickCreateFolder = () => {
    createFolderPopupRef.current?.show();
  };

  const onClickImageDetails = () => {
    imageDetailsPopupRef.current?.show();
  };

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {};

  return {
    ..._pagination,
    fileName,
    fileRef,
    uploadImagePopupRef,
    gridView,
    createFolderPopupRef,
    imageDetailsPopupRef,
    openFileSelector,
    onFileSelected,
    onClickUpload,
    onGridAction,
    onChangeGridView,
    onClickCreateFolder,
    onClickImageDetails,
  };
};

export default useSitePhotoListing;

interface ISitePhotoListingProps {
  listCount?: number;
  gridCount?: number;
}

const sampleData: ISitePhoto[] = [
  {
    photoId: 1234,
    imageUrl: "",
    fileName: "Image Name",
    imageDiamensions: "500x500 px",
    uploadedTo: "Outcomes",
  },
];
