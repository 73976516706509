import React, { useEffect, useState } from "react";
import useDataUploadManager, {
  ITableData,
} from "../../hooks/DataUploadManager";
import DataGrid from "app/components/dataGrid/DataGrid";
import "./importTableManager.scss";
import { Row } from "react-bootstrap";
import ImportSummary from "./ImportSummary";

import { Api } from "app/api";
const api = new Api('');

interface ImportResultsManagerProps {
  setValidation: (validation: any) => void;
  setDataUpload: (dataUpload: any) => void;
  dataUpload: any;
}
const ImportResultsManager: React.FC<ImportResultsManagerProps> = (
  props: any
) => {
  const _dataMangerHandle = useDataUploadManager();

  const [startTime, setStartTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);

  const getRows = () => {
    let rows = 0;
    if (props.dataUpload && props.dataUpload.upload_preview) {
      return props.dataUpload.upload_preview.length;
    }
    return rows;
  };

  useEffect(() => {
    //start the upload when the component mounts
    if (props.dataUpload) {
      //scroll window to top
      window.scrollTo(0, 0);

      setStartTime(Date.now());
      const formData = new FormData();
      formData.append('confirmed', '1');
      api.post<FormData, any>('admin/data-upload/' + props.dataUpload.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        setTimeout(() => {
          setEndTime(Date.now());
        }, 500);
        props.setDataUpload(res.data);
      });
    }
  }, [])

  return (
    <Row className="column-content f18-l-28">
      <p className="content-desc mb-6">

        {startTime && !endTime && (<h4 style={{ margin: "1em" }}>Your data is being uploaded...</h4>)}

        {startTime && endTime && (<h4 style={{ margin: "1em" }}>Your data was uploaded successfully!</h4>)}

        {endTime && <div style={{ color: "#2E75FF" }}>
          <div>
            <ImportSummary dataUpload={props.dataUpload} />
          </div>
        </div>}
      </p>
    </Row>
  );
};

export default ImportResultsManager;
