import { IAPIResponse, IParams } from 'app/models/api';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useEffect, useState } from 'react';

const useApi = <R>(
  apiFn: (params?: IParams) => Promise<IAPIResponse<R>>,
  params?: IParams,
  enableLoader = true,
  immediate = true
) => {
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const [data, setData] = useState<R>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  useEffect(() => {
    immediate && call();
  }, []);

  const call = async (updatedParams: IParams | null = null) => {
    enableLoader && showLoader();
    setLoading(true);
    try {
      const { data } = await apiFn(updatedParams || params);
      setData(data);
    } catch (error) {
      const e = error as unknown as Error;
      setError(e.message);
    } finally {
      enableLoader && hideLoader();
      setLoading(false);
    }
  };

  return [data, error, call, loading] as [R, string, Function, boolean];
};

export default useApi;
