import React, { useEffect } from "react";
import useDataUploadManager from "../../hooks/DataUploadManager";
import RadioBox from "app/components/radio-box/RadioBox";
import { Row, Col } from "react-bootstrap";
import "./importFileManager.scss";

import { Api } from "app/api";
const api = new Api('');

interface ImportFilesManagerProps {
  setValidation: (validation: any) => void;
  setDataUpload: (dataUpload: any) => void;
  dataUpload: any;
}
const ImportFilesManager: React.FC<ImportFilesManagerProps> = (props: any) => {
  const _dataMangerHandle = useDataUploadManager();
  const [importType, setImportType] = React.useState<any>(null);
  const [importTypes, setImportTypes]= React.useState<any[]>([]);

  useEffect(() => {
    if (!importTypes.length) {
      loadImportTypes();
    }
  }, [props.dataUpload]);

  const loadImportTypes = () => {
    api.get("admin/data-upload/import-types/" + props.dataUpload.upload_type).then((res: any) => {
      let types: any[] = res.data;
      console.log(types);
      //give the types ids to be used in the dropdown
      types = types.map((type, index) => {
        return {
          id: index + 1,
          ...type,
        };
      });
      setImportTypes(types);
    });
  };
  
  useEffect(() => {
    if (props.setValidation) {
      props.setValidation([() => {
        return importType ? true : false;
      }]);
    }
  }, [importType])


  useEffect(() => {
    if (props.setDataUpload && props.dataUpload && importType) {
      const formData = new FormData();
      formData.append('import_type', importType.type);
      api.post<FormData, any>('admin/data-upload/' + props.dataUpload.id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        props.setDataUpload(res.data);
      });
    }
  }, [importType]);

  const chooseImportType = (label: any) => {
    const item = importTypes.find((item) => item.label === label);
    setImportType(item);
  };


  return (
    <Row className="import-content f18-l-28">
      <p className="content-desc mb-6">How do you want to import?</p>
      {importTypes.length ?
        importTypes.map((item) => {
          return (
            <Col md={3} lg={3}>
              <RadioBox
                description={item.description}
                label={item.label}
                key={item.id}
                radioBoxId={item.id}
                selected={importType?.id === item.id}
                onSelectItem={(label) => chooseImportType(label)}
              />
            </Col>
          );
        }) : []}
    </Row>
  );
};

export default ImportFilesManager;
