import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import ArticleCard from 'app/components/articleCard/ArticleCard';
import React, { useImperativeHandle, useRef } from 'react';
import { IArticleListItem, IArticle } from 'app/models/article';
import { useState } from 'react';

const ArticlePreviewPopup = React.forwardRef<
  ArticlePreviewPopupRef,
  IArticlePreviewPopupProps
>(({ id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const [state, setState] = useState<IArticleListItem | IArticle | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (article: IArticleListItem | IArticle) => {
        setState(article);
        modalRef.current?.show();
      },
    }),
    []
  );

  const {
    articleTitle = '',
    articleContent = '',
    featuredImageUrl = '',
  } = state || {};
  return (
    <T12Modal
      modalId={id}
      hideFooter
      size='lg'
      ref={modalRef}
      title='Article Preview'
      className='preview-popup'
      showHeaderBorder
    >
      <ArticleCard
        articleTitle={articleTitle}
        articleDescription={articleContent}
        imageUrl={featuredImageUrl}
      />
    </T12Modal>
  );
});

export default ArticlePreviewPopup;

interface IArticlePreviewPopupProps {
  id: string;
}

export type ArticlePreviewPopupRef = {
  show: (article: IArticleListItem | IArticle) => void;
} | null;
