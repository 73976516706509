import React, { useImperativeHandle, useRef, useState } from 'react';

import AccountNotificationCard from 'app/components/accountNotificationCard/AccountNotificationCard';
import {
  AccountType,
  IAccountSubscriptionStatus,
} from 'app/models/accountNotification';
import { IPreference } from 'app/models/preference';
import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';

const AccountNotificationPopup = React.forwardRef<
  AccountNotificationPopupRef,
  IAccountNotificationPopupProps
>(({ id, type, onSubmit }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);

  const [account, setAccount] = useState<AccountType>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (id: number, account: AccountType) => {
        setAccount(account);
        modalRef?.current?.show();
      },
    }),
    []
  );

  const onClose = () => {
    modalRef?.current?.close();
  };

  return (
    <T12Modal
      modalId={id}
      hideFooter
      size='lg'
      ref={modalRef}
      title='Unsubscribe account from receiving messages'
      className='subscription-options'
      showHeaderBorder
    >
      {!!id && (
        <AccountNotificationCard
          account={account}
          type={type}
          onSubmit={onSubmit}
          // onSubmit={() => {}}
          onClose={onClose}
        />
      )}
    </T12Modal>
  );
});

export default AccountNotificationPopup;

interface IAccountNotificationPopupProps {
  id: string;
  type: 'Family' | 'Ambassador' | 'Staff' | 'User';
  onSubmit: (updatedPreferences: IPreference[], id: number) => Promise<boolean>;
}

export type AccountNotificationPopupRef = {
  show: (id: number, account: AccountType) => void;
} | null;
