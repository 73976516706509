import React from 'react';
import { Col, Row } from 'react-bootstrap';

interface IField {
  label: string;
  model?: 'recipient' | 'program';
  field: string;
}

export const MergeFieldRow: React.FC<IField> = ({
  label,
  model = 'recipient',
  field,
}) => {
  function onCopy(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    navigator.clipboard.writeText(event.currentTarget.name);
  }

  const mergeFieldText = `{{$${model}->${field}}}`;
  return (
    <Row className='merge-field pt-1 pb-1'>
      <Col md={4}>{label}</Col>
      <Col className='merge-code' md={5}>
        {mergeFieldText}
      </Col>
      <Col md={2}>
        <button name={mergeFieldText} onClick={onCopy} className='copy-btn'>
          Copy
        </button>
      </Col>
    </Row>
  );
};
