import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IEmailReporting, ILastSendDate } from 'app/models/email';
import React from 'react';
import './email.scss';
import { ICategoryTag, IDesignation, IPopulation } from 'app/models/common';
import useEmailReportingListing from 'app/hooks/EmailReportingListing';
import LinkBlue from 'app/components/links/LinkBlue';

const GRID_ID = 'email-reporting-listing';

const EmailReporting: React.FC = () => {
  const _emailReportingListing = useEmailReportingListing();
  return (
    <PrivateLayout contentClassName='email-reporting-listing'>
      <h3 className='title'>Email Reporting</h3>
      <SearchBar
        placeHolder='Search by description, keyword or email ID'
        searchBarId='email-reporting-search'
      />
      <Filter
        id='email-reporting-listing-filter'
        onFilterChange={_emailReportingListing.onFilterChange}
        selectedFilters={_emailReportingListing.filters}
      >
        <FilterOption<IPopulation>
          labelAttribute='population'
          valueAttribute='id'
          name='population'
          options={[]}
          placeholder='Population'
        />
        <FilterOption<IDesignation>
          // labelAttribute="designation"
          labelAttribute='designationType'
          valueAttribute='id'
          name='designation'
          options={[]}
          placeholder='Designation'
        />
        <FilterOption<ICategoryTag>
          // labelAttribute="category"
          labelAttribute='categoryTag'
          valueAttribute='id'
          name='category'
          options={[]}
          placeholder='Category Tags'
        />
        <FilterOption<ILastSendDate>
          labelAttribute='lastSendDate'
          valueAttribute='id'
          name='lastSendDate'
          options={[]}
          placeholder='Date of Last Send'
        />
      </Filter>
      <DataGrid<IEmailReporting>
        data={_emailReportingListing.data}
        gridId={GRID_ID}
        rowIdAttribute='emailId'
        currentPage={_emailReportingListing.currentPage}
        onPageChange={_emailReportingListing.onPageChange}
        totalItems={_emailReportingListing.totalItems}
        onAction={_emailReportingListing.onGridAction}
      >
        <DataGrid.Column<IEmailReporting>
          attributeName='emailId'
          header='Email ID'
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='emailDescription'
          header='Email Description'
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='previewLink'
          header='Preview Link'
          transform={({ previewLink }) => (
            <LinkBlue target='_blank' href={previewLink}>
              Preview link
            </LinkBlue>
          )}
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='sendDate'
          header='Send Date'
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='criteria'
          header='Criteria'
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='emailsSent'
          header='Emails Sent'
          transform={({ emailsSent }) => (
            <LinkBlue target='_blank' href={emailsSent}>
              {emailsSent}
            </LinkBlue>
          )}
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='emailsOpened'
          header='Emails Opened'
          transform={({ emailsOpened }) => (
            <LinkBlue target='_blank' href={emailsOpened}>
              {emailsOpened}
            </LinkBlue>
          )}
        />
        <DataGrid.Column<IEmailReporting>
          attributeName='emailsClicked'
          header='Emails Clicked'
          transform={({ emailsClicked }) => (
            <LinkBlue target='_blank' href={emailsClicked}>
              {emailsClicked}
            </LinkBlue>
          )}
        />
      </DataGrid>
    </PrivateLayout>
  );
};

export default EmailReporting;
