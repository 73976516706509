import { listEmailReportings } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import { IEmailReporting } from 'app/models/email';
import usePagination from './Pagination';

const useEmailReportingListing = () => {
  const _pagination = usePagination<IEmailReporting>({
    listFn: listEmailReportings,
  });

  // TODO: Sample data, remove this once api is integrated
  _pagination.data = sampleData;

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {};

  return {
    ..._pagination,
    onGridAction,
  };
};

export default useEmailReportingListing;

const sampleData: IEmailReporting[] = [
  {
    emailId: '123456',
    emailDescription: 'Description here...',
    previewLink: 'https://www.google.com/',
    sendDate: '05/10/19',
    criteria: 'Popular Criteria',
    emailsSent: '3049512',
    emailsOpened: '1294',
    emailsClicked: '1049',
  },
];
