import { IGrade } from './../models/common';
import {
  ISubscriptionStatus,
  IUserPermission,
  IActiveStatus,
} from './../models/admin';
import {
  IProgramFeature,
  IDemographic,
  IGradeBand,
  ISchoolType,
  INotificationType,
  NotificationVariant,
} from 'app/models/program';
import { emptyFunction } from 'app/utils/common';
import {
  IAccountDesignation,
  ICategoryTag,
  ICommonAppData,
  IDesignation,
  IRole,
  IStatus,
} from 'app/models/common';
import { IPreference } from 'app/models/preference';
import create from 'zustand';
import { createSelectors } from './state';
import { IOutcome } from 'app/models/outcome';
import { IAppConfig } from 'app/models/config';

interface IAppState {
  actionCount: number;
  notification: string | JSX.Element | undefined;
  notificationVariant: NotificationVariant;
  roles: IRole[];
  demographics: IDemographic[];
  programFeatures: IProgramFeature[];
  gradeBands: IGradeBand[];
  /** ********* Added 6/9/22 ********* */
  categoryTags: ICategoryTag[];
  designations: IDesignation[];
  statuses: IStatus[];
  subscriptionStatuses: ISubscriptionStatus[];
  userPermissions: IUserPermission[];
  activeStatuses: IActiveStatus[];
  grades: IGrade[];
  programId: number | null;
  notificationTypes: INotificationType[];
  /** ********* Added 6/9/22 ********* */

  contentApprovals: IStatus[];
  contentCategories: ICategoryTag[];
  contentDesignations: IDesignation[];
  accountDesignations: IAccountDesignation[];
  schoolTypes: ISchoolType[];
  outcomes: IOutcome[];
  preferences: IPreference[];
  appConfig?: IAppConfig;
  setAppConfig: (appConfig: IAppConfig) => void;
  setCommonAppData: (data: ICommonAppData) => void;
  setOutcomes: (outcomes: IOutcome[]) => void;
  setProgramId: (programId: number) => void;
  // setNotificationTypes: (notificationTypes: INotificationType[]) => void;
  setPreferences: (preferences: IPreference[]) => void;
  showLoader: () => void;
  hideLoader: () => void;
  showNotification: (content?: string | JSX.Element) => void;
  showNotificationWithVariant: (
    notificationVariant: NotificationVariant,
    content?: string | JSX.Element
  ) => void;
  hideNotification: (content?: string | JSX.Element) => void;
}

const initialState: IAppState = {
  actionCount: 0,
  notification: '',
  notificationVariant: '',
  programFeatures: [],
  demographics: [],
  gradeBands: [],
  contentCategories: [],
  contentApprovals: [],
  contentDesignations: [],
  accountDesignations: [],
  roles: [],
  schoolTypes: [],
  outcomes: [],
  appConfig: undefined,
  setOutcomes: emptyFunction,
  setAppConfig: emptyFunction,
  setCommonAppData: emptyFunction,
  setProgramId: emptyFunction,
  // setNotificationTypes: emptyFunction,
  setPreferences: emptyFunction,
  showLoader: emptyFunction,
  hideLoader: emptyFunction,
  showNotification: emptyFunction,
  hideNotification: emptyFunction,
  showNotificationWithVariant: emptyFunction,
  /** Added 6/9/2022 ******************** */
  categoryTags: [],
  designations: [],
  statuses: [],
  subscriptionStatuses: [],
  userPermissions: [],
  activeStatuses: [],
  grades: [],
  programId: null,
  notificationTypes: [],
  preferences: [],
  /** Added 6/9/2022 ******************** */
};

export const useAppState = create<IAppState>((set, get) => ({
  ...initialState,
  showLoader: () => set({ actionCount: get().actionCount + 1 }),
  hideLoader: () =>
    set({ actionCount: get().actionCount < 0 ? 0 : get().actionCount - 1 }),
  setCommonAppData: (data) => set({ ...data }),
  // setNotificationTypes: (notificationTypes) => set({ notificationTypes }),
  setPreferences: (preferences) => set({ preferences }),
  setOutcomes: (outcomes) => set({ outcomes }),
  setAppConfig: (appConfig) => set({ appConfig }),
  setProgramId: (programId) => set({ programId }),
  showNotificationWithVariant: (
    notificationVariant: NotificationVariant,
    content?: string | JSX.Element
  ) => set({ notification: content, notificationVariant }),
  showNotification: (content?: string | JSX.Element) =>
    set({ notification: content }),
  hideNotification: (content?: string | JSX.Element) =>
    set({ notification: content, notificationVariant: '' }),
}));

export const appStateSelectors = createSelectors(initialState);
