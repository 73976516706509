import StandardButton from "app/components/button/StandardButton";
import DataGrid from "app/components/dataGrid/DataGrid";
import DropDown from "app/components/dropDown/DropDown";
import ImportGlobal from "app/components/importGlobal/ImportGlobal";
import Input from "app/components/input/Input";
import SelectedOptionContainer from "app/components/selectedOption/SelectedOptionContainer";
import Switch from "app/components/switch/Switch";
import useProgramBuilder from "app/hooks/ProgramBuilder";
import PrivateLayout from "app/layout/PrivateLayout";
import {
  IDemographic,
  IGradeBand,
  IProgramFeatureWithToggles,
  ISchoolType,
} from "app/models/program";
import { programBuilderSchema } from "app/utils/validations";
import { Form, Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import "./program.scss";

const ProgramBuilder: React.FC = () => {
  const _pgmBuilder = useProgramBuilder();

  return (
    <PrivateLayout contentClassName="new-program">
      <h3 className="title">Program Builder</h3>
      <Formik
        innerRef={_pgmBuilder.formRef}
        initialValues={_pgmBuilder.initialValues}
        onSubmit={_pgmBuilder.onSubmit}
        validationSchema={programBuilderSchema}
      >
        {({ values, isValid, dirty, errors, touched }) => (
          <Form>
            <Row>
              <Col>
                <Input
                  id="program-builder-name"
                  variant="secondary"
                  label="Program Name"
                  placeholder="Enter the program name here"
                  name="programName"
                  error={
                    touched.programName && errors.programName
                      ? errors.programName
                      : ""
                  }
                />
              </Col>
              <Col>
                <Input
                  id="program-builder-url"
                  label="Program Url"
                  placeholder="school.think-12.com"
                  variant="secondary"
                  name="programUrl"
                  error={
                    touched.programUrl && errors.programUrl
                      ? errors.programUrl
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  id="program-builder-school-name"
                  variant="secondary"
                  label="School Name"
                  placeholder="Enter school name"
                  name="schoolName"
                  error={
                    touched.schoolName && errors.schoolName
                      ? errors.schoolName
                      : ""
                  }
                />
              </Col>
              <Col>
                <Input
                  id="program-builder-url"
                  label="Short School Name"
                  placeholder="Enter short school name"
                  variant="secondary"
                  name="shortSchoolName"
                  error={
                    touched.shortSchoolName && errors.shortSchoolName
                      ? errors.shortSchoolName
                      : ""
                  }
                />
              </Col>
            </Row>
            <Input
              id="program-builder-description"
              label="Program Description"
              placeholder="Enter a short description of the program here"
              variant="secondary"
              name="programDescription"
              error={
                touched.programDescription && errors.programDescription
                  ? errors.programDescription
                  : ""
              }
            />
            <hr />
            <DataGrid<IProgramFeatureWithToggles>
              data={values.features}
              gridId="program-builder-features"
              rowIdAttribute="id"
            >
              <DataGrid.Column<IProgramFeatureWithToggles>
                attributeName="featureName"
                header="Program Feature"
              />
              <DataGrid.Column<IProgramFeatureWithToggles>
                attributeName="isActive"
                header="Active"
                transform={(feature) => (
                  <Switch
                    checked={feature.isActive}
                    onChange={_pgmBuilder.onFeatureToggled(
                      feature.id,
                      "isActive"
                    )}
                    switchId="program-builder-activeBtn"
                  />
                )}
              />
              <DataGrid.Column<IProgramFeatureWithToggles>
                attributeName="isEnabled"
                header="Enable"
                transform={(feature) => (
                  <Switch
                    checked={feature.isEnabled}
                    onChange={_pgmBuilder.onFeatureToggled(
                      feature.id,
                      "isEnabled"
                    )}
                    switchId="program-builder-enable-btn"
                  />
                )}
              />
            </DataGrid>
            <hr />

            <Row>
              <Col md={3}>
                <DropDown<IGradeBand>
                  label="Program Grade Band"
                  placeholder="Select Grade Band"
                  options={_pgmBuilder.gradeBands}
                  multiple
                  id="program-builder-grade-band"
                  valueAttribute="id"
                  labelAttribute="gradeBand"
                  selected={values.gradeBandIds}
                  onOptionChange={_pgmBuilder.onGradeBandsSelected}
                />
              </Col>
              <Col md={{ span: 3, offset: 3 }}>
                <DropDown<IDemographic>
                  label="Designate Demographics"
                  placeholder="Select Demographics"
                  options={_pgmBuilder.demographics}
                  multiple
                  id="program-builder-demographics"
                  valueAttribute="id"
                  labelAttribute="demographicName"
                  selected={values.demographicIds}
                  onOptionChange={_pgmBuilder.onDemographicsSelected}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <DropDown<ISchoolType>
                  label="School Type"
                  placeholder="Select School Type"
                  options={_pgmBuilder.schoolTypes}
                  multiple
                  id="program-builder-school-type"
                  valueAttribute="id"
                  labelAttribute="schoolType"
                  selected={values.schoolTypeIds}
                  onOptionChange={_pgmBuilder.onSchoolTypesSelected}
                />
              </Col>
            </Row>
            <SelectedOptionContainer
              id="program-builder-selected-options"
              options={_pgmBuilder.getSelectedOptions()}
              onDelete={_pgmBuilder.onSelectedOptionDelete}
            />
            <hr />
            <ImportGlobal
              id="program-builder-import-articles"
              contentType="Articles"
              contentCount={0}
              popupDescription="Import approved global articles associated with selected grade bands:"
              description="Import approved global articles associated with selected grade bands."
              buttonLabel="Import Articles Now"
              onConfirm={_pgmBuilder.onConfirmImportGlobals(
                "importGlobalArticles"
              )}
            />
            <hr />
            <ImportGlobal
              id="program-builder-import-emails"
              contentType="Emails"
              contentCount={0}
              popupDescription="Import global emails for these grade bands. They will be automatically imported into the new program upon creation."
              description="Import global emails for these grade bands. They will be automatically imported into the new program upon creation."
              buttonLabel="Import Emails Now"
              onConfirm={_pgmBuilder.onConfirmImportGlobals(
                "importGlobalEmails"
              )}
            />
            <StandardButton
              type="submit"
              variant="secondary"
              label="Create Program"
              disabled={!dirty || !isValid}
              className="create-button"
              standardBtnId="program-builder-create-btn"
            />
          </Form>
        )}
      </Formik>
    </PrivateLayout>
  );
};

export default ProgramBuilder;
