import React, { useState } from 'react';
import './email-input-chips.scss';

const EmailInputChips: React.FC<IEmailInputChips> = ({
  label,
  emailList,
  setEmailList,
}) => {
  // const [emailList, setEmailList] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event: any) => {
    if (['Enter', 'Tab', ','].includes(event.key)) {
      event.preventDefault();
      // TODO add email validation and sanitization
      setEmailList([...emailList, event.target.value]);
      setInputValue('');
    }

    if (
      ['Backspace'].includes(event.key) &&
      event.target.value === '' &&
      emailList.length !== 0
    ) {
      setEmailList(emailList.slice(0, -1));
    }
  };

  return (
    <div className='email-input-chips-container'>
      <label htmlFor='email-input-chips'>{label}</label>
      <div className='email-input-chips'>
        {emailList.map((email) => (
          <span className='email-chip'>{email}</span>
        ))}
        <input
          id='email-input-chips'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        ></input>
      </div>
    </div>
  );
};

export default EmailInputChips;

interface IEmailInputChips {
  label: string;
  emailList: string[];
  setEmailList: (emailList: string[]) => void;
}
