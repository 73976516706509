import { ReactComponent as IcDownArrow } from 'app/assets/icons/down-arrow.svg';
import useOutsideClick from 'app/hooks/OutsideClick';
import React, { useRef, useState } from 'react';
import If from '../If';
import { capitalize } from 'lodash';
import './drop-down.scss';

const DropDownBase = <T,>({
  id,
  options,
  label,
  labelOnly,
  placeholder,
  className = '',
  variant = 'primary',
  multiple = false,
  allSelectedLabel,
  noneSelectedLabel,
  selected,
  onOptionChange,
}: DropDownProps<T>) => {
  const [visible, setVisible] = useState(false);
  const itemsRef = useRef(null);
  const toggleRef = useRef(null);

  const _closeItems = () => setVisible(false);

  const _toggleItems = () => setVisible((visible) => !visible);

  useOutsideClick(itemsRef, _closeItems, toggleRef);

  const getMultiSelectedLabel = () => {
    if (multiple && selected) {
      const optionsSelected = selected as T[];
      const numberOfOptions = optionsSelected.filter(
        (option) => !!option
      ).length;

      if (numberOfOptions < 0) return placeholder;

      if (optionsSelected.length === options.length && allSelectedLabel) {
        return allSelectedLabel;
      } else if (optionsSelected.length === 0 && noneSelectedLabel) {
        return noneSelectedLabel;
      }

      return optionsSelected
        .map((option) => capitalize(option as unknown as string))
        .join(',');
    } else if (multiple && !selected) {
      return noneSelectedLabel || 'None';
    }
  };

  const _onClick = (id: T) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (multiple) {
      const _selected = selected as T[];
      const updatedValues = _isChecked(id)
        ? _selected?.filter?.((selectedId) => selectedId !== id)
        : [..._selected, id];
      (onOptionChange as (ids: T[]) => void)?.(updatedValues);
    } else {
      (onOptionChange as (id: T) => void)?.(id);
      _closeItems();
    }
  };

  const _isChecked = (id: T) => {
    const _selected = selected as T[];
    return multiple ? _selected?.includes(id) : selected === id;
  };
  // const _onDelete = (id: T) =>
  //   (onOptionChange as (ids: T[]) => void)?.(
  //     selected?.filter((selectedId) => selectedId !== id)
  //   );

  return (
    <div className={`t12-drop-down ${className}`}>
      <If condition={!!label}>
        <label htmlFor=''>{label}</label>
      </If>
      <button
        type='button'
        ref={toggleRef}
        onClick={_toggleItems}
        id={`${id}_toggle`}
        className={`toggle ${variant}`}
      >
        <If condition={variant === 'secondary'}>{placeholder}</If>
        <If condition={variant === 'primary'}>
          <If condition={!multiple}>{selected || placeholder}</If>
          <If condition={multiple}>{getMultiSelectedLabel()}</If>
        </If>
        <IcDownArrow />
      </button>
      <If condition={visible}>
        <div
          ref={itemsRef}
          className={`t12-drop-down-items ${!label ? 'no-label' : ''}`}
        >
          {options.map((option) => (
            <a
              href=''
              key={`${id}_item_${option}`}
              id={`${id}_item_${option}`}
              onClick={_onClick(option)}
            >
              <If condition={!labelOnly}>
                <div
                  className={`input ${multiple ? 'checkbox' : 'radio'} ${
                    _isChecked(option) ? 'checked' : ''
                  }`}
                />
                {/* <span>{transformLabel ? transformLabel(option) : option}</span> */}
                <span>{option}</span>
              </If>
              <If condition={!!labelOnly}>
                {/* <span>{transformLabel ? transformLabel(option) : option}</span> */}
                <span>{option}</span>
              </If>
            </a>
          ))}
        </div>
      </If>
    </div>
  );
};

export default DropDownBase;

export type DropDownProps<T> = IDropDownMultiProps<T> | IDropDownSingleProps<T>;

export interface IDropDownProps<T> {
  id: string;
  options: T[];
  label?: string;
  labelOnly?: boolean;
  placeholder?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
}

export interface IDropDownMultiProps<T> extends IDropDownProps<T> {
  multiple: true;
  allSelectedLabel?: string;
  noneSelectedLabel?: string;
  onOptionChange?: (selectedIds: T[]) => void;
  selected?: T[];
}

export interface IDropDownSingleProps<T> extends IDropDownProps<T> {
  multiple?: false;
  allSelectedLabel?: string;
  noneSelectedLabel?: string;
  onOptionChange?: (selectedId: T) => void;
  selected?: T;
}
