import { IAPIResponse } from 'app/models/api';
import { ICommonAppData, IUploadResponse } from 'app/models/common';
import { IAppConfig } from 'app/models/config';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Api } from '.';

const api = new Api('');

export const EnableReporting = function (admin: boolean) {
  let session = localStorage.getItem('session');
  if (!session) {
    //Create a random session ID
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
    let str = '';
    while (str.length < 64) {
      str += chars[Math.floor(Math.random() * chars.length)];
    }
    session = str;
    localStorage.setItem('session', session);
  }
  let location = useLocation();
  let lastLocation: string = '';
  useEffect(() => {
    if (location.pathname !== lastLocation) {
      lastLocation = location.pathname;
      api.post<any, any>('/reporting/events', {
        event: 'pageview',
        uri: location.pathname,
        admin: admin,
        session: session,
      });
    }
  }, [location]);
};

export const getReport = function (params: any): any {
  return api.get<any>('/reporting/events', params);
};
