import React, { useState } from 'react';
import { ReactComponent as IcClose } from 'app/assets/icons/close.svg';
import { ReactComponent as IcUser } from 'app/assets/icons/user.svg';
import { AGES, FAMILY_MEMBER_ICONS, GENDERS } from 'app/constants/common';
import { IIcon } from 'app/models/common';
import { IUser, IUserV2 } from 'app/models/user';
import { IFamilyMember } from 'app/models/family';
import { formatUserFullName } from 'app/utils/data';
import DropDownInput from 'app/components/dropDownInput/DropDownInput';
import UserAccountDropDownInput from '../userAccountDropdownInput/UserAccountDropdownInput';
import { Row, Col, Badge } from 'react-bootstrap';
import RoundButton from '../button/RoundButton';
import DropDown from '../dropDown/DropDown';
import DropDownBase from '../dropDown/DropDownBase';
import Input from '../input/Input';
import './family-members-editor.scss';

const FamilyMembersEditor: React.FC<IFamilyMemberEditor> = ({
  index,
  members,
  gender,
  age,
  options,
  excludeAccounts = [],
  iconId,
  onDelete,
  onChange,
  onUpdateMembers,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const familyMemberId = `family-member-edior-${index}`;
  const _onDelete = () => onDelete?.(index);

  return (
    <div className='family-member-container'>
      <Row className='align-items-center'>
        <Col md={4} className='family-member-user-dropdown-wrapper'>
          <UserAccountDropDownInput
            label={
              <div className='user-account-dropdown-label'>
                Name of Member {index + 1} *
                {members[index].id && <IcUser className='user-account-icon' />}
              </div>
            }
            value={members[index].first_name || ''}
            dataKey='id'
            scope='family'
            excludeAccounts={excludeAccounts}
            searchTerm={searchTerm}
            name={`members[${index}].first_name`}
            textField={(user: Partial<IUserV2>) => {
              if (typeof user === 'string') {
                return user;
              } else if (user && user.id) {
                return `${formatUserFullName(user)} - ${user.email}`;
              }
              return user;
            }}
            onSelect={(user: IUserV2) => {
              // const name = formatUserFullName(user);
              // onUpdateMembers && onUpdateMembers(index, name, user.id!);
              onUpdateMembers && onUpdateMembers(index, user);
            }}
            onSearch={(text: string, metadata: any) => {
              const { action } = metadata;
              if (action !== 'clear') {
                setSearchTerm(text);
                // onUpdateMembers && onUpdateMembers(index, text, null);
                onUpdateMembers && onUpdateMembers(index, null, text);
              }
            }}
            className='family-member-user-dropdown'
          />
        </Col>
        <Col>
          <DropDownBase
            label='Gender'
            id='family-member-gender'
            options={GENDERS}
            placeholder='Gender'
            selected={gender || undefined}
            labelOnly
            onOptionChange={onChange?.(`members[${index}].gender`)}
          />
        </Col>
        <Col md='auto'>
          <DropDownBase
            label='Age'
            id='family-member-age'
            options={AGES}
            selected={age}
            labelOnly
            placeholder='Age'
            onOptionChange={onChange?.(`members[${index}].age`)}
          />
        </Col>
        <Col>
          <DropDown<IIcon>
            label='Icon'
            id='family-member-icon'
            labelAttribute='icon'
            options={FAMILY_MEMBER_ICONS}
            selected={iconId || undefined}
            labelOnly
            valueAttribute='id'
            placeholder='Icon'
            onOptionChange={onChange?.(`members[${index}].icon_id`)}
          />
        </Col>
        <Col md='auto'>
          <div className='d-flex align-items-center'>
            <RoundButton
              roundBtnId={`${familyMemberId}-delete`}
              icon={<IcClose />}
              className='button'
              onClick={_onDelete}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FamilyMembersEditor;

interface IFamilyMemberEditor {
  index: number;
  members: Partial<IUserV2>[];
  gender?: string | null;
  excludeAccounts?: (number | undefined)[];
  age?: string;
  iconId?: number | null;
  options?: { id: number; name: string }[];
  onDelete?: (index: number) => void;
  onChange?: (key: string) => (value: string | number) => void;
  onUpdateMembers?: (
    // index: number,
    // memberName: string,
    // id: number | null
    index: number,
    user: IUserV2 | null,
    name?: string
  ) => void;
}
