import React from 'react';
import { Col, Row } from 'react-bootstrap';

import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import useEmailManagerListing from 'app/hooks/EmailManagerListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IEmailManager } from 'app/models/email';
import { ICategoryTag, IDesignation, IStatus } from 'app/models/common';
import EmailPreviewPopup from './components/emailTemplates/EmailPreviewPopup';
import EmailManagerTestPopup from './EmailManagerTestPopup';
import { ReactComponent as IcTestEmail } from 'app/assets/icons/test-email.svg';
import BulletList from 'app/components/butlletList/BulletList';
import { IGradeBand } from 'app/models/program';
import './email.scss';

const GRID_ID = 'email-manager-listing';

const EmailManager: React.FC = () => {
  const _emailListing = useEmailManagerListing();

  return (
    <PrivateLayout contentClassName='email-manager-listing'>
      <StandardButton
        label='+ New Email'
        variant='secondary'
        className='new-email-button'
        onClick={_emailListing.onNewEmailClick}
        standardBtnId='email-manager-listing-new-btn'
      />
      <h3 className='title'>Email Manager</h3>
      <SearchBar
        placeHolder='Search by description, keyword or email ID'
        searchBarId='email-manager-search'
        onChangeText={_emailListing.onSearchKeyChange}
        onSearch={_emailListing.onSearch}
      />
      <Row>
        <Col>
          <Filter
            id='email-manager-listing-filter'
            onFilterChange={_emailListing.onFilterChange}
            selectedFilters={_emailListing.filters}
          >
            <FilterOption<IDesignation>
              labelAttribute='designationType'
              valueAttribute='id'
              name='designationIds'
              options={_emailListing.contentDesignations}
              placeholder='Designation'
            />
            <FilterOption<ICategoryTag>
              labelAttribute='categoryTag'
              valueAttribute='id'
              name='categoryIds'
              options={_emailListing.contentCategories}
              placeholder='Category Tags'
            />
            <FilterOption<IStatus>
              labelAttribute='status'
              valueAttribute='id'
              name='statusIds'
              options={_emailListing.contentApprovals}
              placeholder='Status'
            />
          </Filter>
        </Col>
        <Col md='auto'>
          <StandardButton
            icon={IcTestEmail}
            label='Test'
            variant='outlined'
            size='sm'
            className='test-email-button'
            onClick={_emailListing.onTestEmailClick}
            standardBtnId='email-manager-listing-test-btn'
            disabled={_emailListing.selectedRows.length == 0}
          />
        </Col>
      </Row>
      <DataGrid<IEmailManager>
        data={_emailListing.data}
        gridId={GRID_ID}
        rowIdAttribute='id'
        currentPage={_emailListing.currentPage}
        onPageChange={_emailListing.onPageChange}
        totalItems={_emailListing.totalItems}
        onAction={_emailListing.onGridAction}
        selectionType='single'
        selectedRows={_emailListing.selectedRows}
        onSelectionChange={_emailListing.onSelectionChange}
      >
        <DataGrid.Column<IEmailManager>
          attributeName='name'
          header='Email Listing'
        />
        <DataGrid.Column<IEmailManager> attributeName='id' header='Email ID' />
        <DataGrid.Column<IEmailManager>
          attributeName='description'
          header='Email Description'
        />
        <DataGrid.Column<IEmailManager>
          attributeName='designationType'
          header='Designation Type'
          transform={(data: IEmailManager) =>
            _emailListing.getContentDesignationLabel(
              data?.content_designation_id
            )
          }
        />
        <DataGrid.Column<IEmailManager>
          attributeName='categoryTags'
          header='Category Tags'
          transform={(data: IEmailManager) => {
            return (
              <BulletList<ICategoryTag>
                items={data.categories || []}
                labelAttribute='category_tag'
              />
            );
          }}
        />
        <DataGrid.Column<IEmailManager>
          attributeName='approvalStatus'
          header='Approval Status'
          transform={(data: IEmailManager) =>
            _emailListing.getApprovalStatusLabel(
              data?.content_approval_status_id
            )
          }
        />
        <DataGrid.Column<IEmailManager>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IEmailManager>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IEmailManager>
          action={DATA_GRID_ACTIONS.DUPLICATE}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IEmailManager>
          action={DATA_GRID_ACTIONS.DELETE}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
      <EmailManagerTestPopup
        ref={_emailListing.testEmailModalRef}
        id='email-manager-test'
        onTestEmailSend={_emailListing.onTestEmailSend}
        testSendStatus={_emailListing.testSendStatus}
        testSendErrorMessage={_emailListing.testSendErrorMsg}
      />
      <EmailPreviewPopup
        ref={_emailListing.previewModalRef}
        id='email-preview'
      />
    </PrivateLayout>
  );
};

export default EmailManager;
