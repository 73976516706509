import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import StandardButton from "app/components/button/StandardButton";
import PrivateLayout from "app/layout/PrivateLayout";
import { getReport } from "app/api/reporting";
import { appStateSelectors, useAppState } from "app/state/AppState";
import { IGradeBand } from "app/models/program";
import { getUser } from "app/api/authApis";
import { IUser, IUserV2 } from "app/models/user";
import ReportingChart from "./../reporting/ReportingChart";
import ReportingSection from "./../reporting/ReportingSection";

import "./admin.scss";
import "react-datepicker/src/stylesheets/datepicker.scss";

import { ReactComponent as CalendarIcon } from "app/assets/icons/calander.svg";

const AdminReporting: React.FC = () => {
  const navigate = useNavigate();
  // const [user, setUser] = useState<IUser>();
  const [user, setUser] = useState<IUserV2>();
  const gradeBands = useAppState(appStateSelectors.gradeBands);

  const [userType, setUserType] = useState<string>("all");
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(6, "days").startOf('day').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().endOf('day').toDate());
  const [location, setLocation] = useState<string>("all");
  const [gradeBand, setGradeBand] = useState<string>("all");

  const [expandArticles, setExpandArticles] = useState<boolean>(false);
  const [expandFamily, setExpandFamily] = useState<boolean>(false);
  const [expandQa, setExpandQa] = useState<boolean>(false);

  const [reportData, setReportData] = useState<any>({
    overallEngagementGraph: [],
    engagementDetailsGraph: [],
    articlesGraph: [],
    familyGraph: [],
    qaGraph: [],
    outcomesGraph: [],
    articlesPages: [],
    familyPages: [],
    qaPages: [],
    outcomesPages: [],
  });

  useEffect(() => {
    getUser().then((res) => {
      setUser(res.data);
    });
  }, []);

  let updateData = function () {
    getReport({
      userType: userType,
      startDate: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      location: location,
      gradeBand: gradeBand,
    }).then((res: any) => {
      if (res.data) {
        setReportData(res.data);
      }
    });
  };

  useEffect(() => {
    updateData();
  }, [startDate, endDate, gradeBand, userType]);

  return (
    <PrivateLayout>
      <div className="admin-reporting">
        <div className="reporting-top">
          <h1>Program Reporting</h1>

          <div>
            <div className="tabs">
              <div
                className={"tab" + (userType == "all" ? " active" : "")}
                onClick={() => setUserType("all")}
              >
                All Program Users
              </div>
              <div
                className={"tab" + (userType == "validated" ? " active" : "")}
                onClick={() => setUserType("validated")}
              >
                Validated
              </div>
              <div
                className={"tab" + (userType == "registered" ? " active" : "")}
                onClick={() => setUserType("registered")}
              >
                Registered
              </div>
              <div
                className={"tab" + (userType == "anonymous" ? " active" : "")}
                onClick={() => setUserType("anonymous")}
              >
                Anonymous
              </div>
            </div>
          </div>
        </div>
        <div className="report-container">
          <div className="overall-engagement">
            <div className="left">
              <h1>
                Overall <br />
                <b>Engagement</b>
              </h1>
              <p>
                This is an accumulative listing of all engagement across&nbsp;
                {userType === "all" ? <b>all user types</b> : []}
                {userType === "registered" ? <b>all registered users</b> : []}
                {userType === "validated" ? <b>all validated users</b> : []}
                {userType === "anonymous" ? <b>all anonymous users</b> : []}
              </p>
            </div>
            <div className="right">
              <div className="item big-num">
                <span className="num">
                  {reportData.pageviewCount ? reportData.pageviewCount : 0}
                </span>
                <span className="small-text">
                  Total
                  <br />
                  Engagements
                </span>
              </div>
              <div className="small-nums">
                <div className="item">
                  <span className="num">{reportData.activeUserCount}</span>
                  <span className="small-text">Total Users Active</span>
                </div>
                <div className="item">
                  <span className="num">{reportData.totalUserCount}</span>
                  <span className="small-text">Total Users Accounts</span>
                </div>
                <div className="select-filters">
                  <div>
                    <select onChange={(e) => setGradeBand(e.target.value)}>
                      {gradeBands.sort().map((gradeBand: any) => {
                        return (
                          <option key={gradeBand.id} value={gradeBand.id}>
                            {gradeBand.gradeBand}
                          </option>
                        );
                      })}
                      <option value="all">All Grade Bands</option>
                    </select>
                  </div>
                  <div>
                    From:{" "}
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                    />
                    <CalendarIcon />
                  </div>
                  <div>
                    To:{" "}
                    <DatePicker
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                    />
                    <CalendarIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="engagement-graph">
            <div className="rotate-90">Total Engagements</div>
            {reportData.overallEngagementGraph ? (
              <ReportingChart
                data={reportData.overallEngagementGraph}
                type="Bar"
              />
            ) : (
              []
            )}
          </div>

          <div className="engagement-details">
            <h4>Engagement Details</h4>
            <div className="graph">
              {reportData.engagementDetailsGraph ? (
                <ReportingChart
                  data={reportData.engagementDetailsGraph}
                  type="Pie"
                />
              ) : (
                []
              )}
            </div>
            <div className="items">
              {reportData.engagementDetailsGraph.map((item: any, i: number) => {
                if (item.value === 0) return [];
                return (
                  <div className="item" key={i}>
                    <span>{item.value}</span> {item.label}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="areas-detail">
            <h4>Areas in Detail</h4>

            <ReportingSection
              graph={reportData.articlesGraph}
              pages={reportData.articlesPages}
              title="Article Views"
              count={reportData.articlesCount}
            />

            <ReportingSection
              graph={reportData.familyGraph}
              pages={reportData.familyPages}
              title="Family Network Views"
              count={reportData.familyCount}
            />

            <ReportingSection
              graph={reportData.qaGraph}
              pages={reportData.qaPages}
              title="Q & A Views"
              count={reportData.qaCount}
            />

            <ReportingSection
              graph={reportData.outcomesGraph}
              pages={reportData.outcomesPages}
              title="Outcomes Views"
              count={reportData.outcomesCount}
            />
          </div>
        </div>
      </div>
    </PrivateLayout>
  );
};
export default AdminReporting;
