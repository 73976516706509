import React from "react";
import "./switch.scss";

const Switch: React.FC<ISwitchProps> = ({
  checked,
  className = "",
  onChange,
  switchId,
}) => {
  const _onClick = () => onChange?.(!checked);

  return (
    <button
      className={`t12-switch ${className} ${checked ? "active" : ""}`}
      onClick={_onClick}
      id={switchId}
      type="button"
    >
      <div className="t12-switch-head" />
    </button>
  );
};

export default Switch;

interface ISwitchProps {
  switchId: string;
  checked?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}
