import { ReactComponent as IcDownArrow } from 'app/assets/icons/down-arrow.svg';
import useOutsideClick from 'app/hooks/OutsideClick';
import React, { useRef, useState } from 'react';
import If from '../If';
import SelectedOption from '../selectedOption/SelectedOption';
import './drop-down.scss';

const DropDown = <T,>({
  id,
  options,
  label,
  labelOnly,
  placeholder,
  labelAttribute,
  valueAttribute,
  transformLabel,
  transformValue,
  values,
  className = '',
  variant = 'primary',
  multiple = false,
  selected,
  disabled = false,
  onOptionChange,
  onCustomComponentChange,
  component: CustomComponent,
}: DropDownProps<T>) => {
  const [visible, setVisible] = useState(false);
  const itemsRef = useRef(null);
  const toggleRef = useRef(null);

  const _closeItems = () => setVisible(false);

  const _toggleItems = () => setVisible((visible) => !visible);

  useOutsideClick(itemsRef, _closeItems, toggleRef);

  const _onClick = (id: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (multiple) {
      const _selected = selected as number[];
      const updatedValues = _isChecked(id)
        ? _selected.filter((selectedId) => selectedId !== id)
        : [..._selected, id];
      (onOptionChange as (ids: number[]) => void)?.(updatedValues);
    } else {
      (onOptionChange as (id: number) => void)?.(id);
      _closeItems();
    }
  };

  const _isChecked = (id: number) =>
    multiple ? (selected as number[])?.includes(id) : selected === id;

  const _getSelectedId = () => (selected! as number[])?.[0];

  const _getSelectedLabel = (id: number) => {
    const option = options.find((option) =>
      transformValue
        ? transformValue(option) === id
        : (option[valueAttribute] as unknown as number) === id
    );

    return transformLabel && option
      ? transformLabel(option)
      : (option?.[labelAttribute] as unknown as string);
  };

  const _onDelete = (id: number) =>
    (onOptionChange as (ids: number[]) => void)?.(
      (selected as number[]).filter((selectedId) => selectedId !== id)
    );

  return (
    <div style={{ flexDirection: 'column', position: 'relative' }}>
      <div className={`t12-drop-down ${className}`}>
        <If condition={!!label}>
          <label htmlFor=''>{label}</label>
        </If>
        <button
          type='button'
          ref={toggleRef}
          onClick={_toggleItems}
          id={`${id}_toggle`}
          className={`toggle ${variant}${disabled ? ' disabled' : ''}`}
          disabled={disabled}
        >
          <If condition={variant === 'secondary'}>{placeholder}</If>
          <If condition={variant === 'primary'}>
            <If condition={multiple}>
              <If condition={!(selected as number[])?.length}>{placeholder}</If>
              <If condition={!!(selected as number[])?.length}>
                <SelectedOption
                  containerId={`${id}-selected-option`}
                  id={_getSelectedId()}
                  label={_getSelectedLabel(_getSelectedId()) ?? ''}
                  variant='secondary'
                  onDelete={_onDelete}
                />
                <If condition={(selected as number[])?.length > 1}>
                  ...
                  <div className='toggle-option-count'>
                    {(selected as number[])?.length - 1}
                  </div>
                </If>
              </If>
            </If>
            <If condition={!multiple}>
              {_getSelectedLabel(selected as number) ?? placeholder}
            </If>
          </If>
          <IcDownArrow className='toggle-arrow' />
        </button>
        <If condition={visible}>
          <If condition={!CustomComponent}>
            <div
              ref={itemsRef}
              className={`t12-drop-down-items ${!label ? 'no-label' : ''}`}
            >
              {options.map((option) => (
                <a
                  href=''
                  key={`${id}_item_${
                    transformValue
                      ? transformValue(option)
                      : option[valueAttribute]
                  }`}
                  id={`${id}_item_${
                    transformValue
                      ? transformValue(option)
                      : option[valueAttribute]
                  }`}
                  onClick={_onClick(
                    transformValue
                      ? (transformValue(option) as unknown as number)
                      : (option[valueAttribute] as unknown as number)
                  )}
                >
                  <If condition={!labelOnly}>
                    <div
                      className={`input ${multiple ? 'checkbox' : 'radio'} ${
                        _isChecked(
                          transformValue
                            ? (transformValue(option) as unknown as number)
                            : (option[valueAttribute] as unknown as number)
                        )
                          ? 'checked'
                          : ''
                      }`}
                    />
                    {/* <span>{option[labelAttribute]}</span> */}
                    <span>
                      {transformLabel
                        ? transformLabel(option)
                        : option[labelAttribute]}
                    </span>
                  </If>
                  <If condition={!!labelOnly}>
                    {/* <span>{option[labelAttribute]}</span> */}
                    <span>
                      {transformLabel
                        ? transformLabel(option)
                        : option[labelAttribute]}
                    </span>
                  </If>
                </a>
              ))}
            </div>
          </If>
        </If>
      </div>
      <If condition={!!CustomComponent && visible}>
        <div ref={itemsRef} className={`t12-drop-down-custom`}>
          {CustomComponent && (
            <CustomComponent
              values={values}
              onCustomComponentChange={(params: Record<string, any>) => {
                onCustomComponentChange && onCustomComponentChange(params);
              }}
              multiple
            />
          )}
        </div>
      </If>
    </div>
  );
};

export default DropDown;

export type DropDownProps<T> = IDropDownMultiProps<T> | IDropDownSingleProps<T>;

export interface IDropDownProps<T> {
  id: string;
  options: T[];
  label?: string;
  labelOnly?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  valueAttribute: keyof T;
  transformValue?: (option: T) => string | number;
  labelAttribute: keyof T;
  transformLabel?: (option: T) => string;
  values?: any;
  variant?: 'primary' | 'secondary';
  component?: React.ElementType;
  onCustomComponentChange?: (params: Record<string, any>) => void;
}

export interface IDropDownMultiProps<T> extends IDropDownProps<T> {
  multiple: true;
  onOptionChange?: (selectedIds: number[]) => void;
  selected?: number[] | string[];
}

export interface IDropDownSingleProps<T> extends IDropDownProps<T> {
  multiple?: false;
  onOptionChange?: (selectedId: string | number) => void;
  selected?: number | string;
}
