import React, { FC, useRef, useEffect, useLayoutEffect } from 'react';
import { Editor, IAllProps } from '@tinymce/tinymce-react';

import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopup';
import UploadPopup from 'app/screens/sitePhotos/uploadPopup/UploadPopup';
import { IUploadResponse } from 'app/models/common';
import { mediaIsImage, mediaIsVideo } from 'app/utils/common';
import './content-editor.scss';

declare const tinyMCE: any;

interface IProps extends IAllProps {
  height?: number | string;
  placeholder?: string;
  autoloadHtml?: boolean;
  html?: string;
  onMediaConfirm?: (uploadedMedia: IUploadResponse) => void;
}

const HtmlContentEditor: FC<IProps> = React.memo((props) => {
  const tinyMceRef = useRef<any>();
  const mediaPopupRef = useRef<UploadPopUpRef>(null);
  const htmlLoadedRef = useRef<boolean>(false);

  const height = props.height || 320;

  useEffect(() => {
    if (props.autoloadHtml && props.html && !htmlLoadedRef.current) {
      htmlLoadedRef.current = true;
      setTimeout(() => {
        tinyMceRef?.current?.insertContent(
          `<div class='mailgun-template-section'>${props.html}</div>`
        );
      }, 100);
    }
  }, [props]);

  const _onUpload = (uploadedMedia: IUploadResponse) => {
    const htmlEl: string | null = mediaIsImage(uploadedMedia.fileUrl)
      ? `<img src="${uploadedMedia.fileUrl}" style="max-width: 100%" /></img>`
      : mediaIsVideo(uploadedMedia.fileUrl)
      ? `<video style="max-width: 100%" controls><source src="${uploadedMedia.fileUrl}" /></video>`
      : null;

    if (htmlEl) {
      tinyMceRef?.current?.insertContent(`<div>${htmlEl}</div>`);
      props.onMediaConfirm && props.onMediaConfirm(uploadedMedia);
    }
  };

  return (
    <>
      <Editor
        id='t12-content-editor'
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        init={{
          height,
          menubar: false,
          content_style: '.mce-preview-object { border: none }',
          file_picker_types: 'file image media',
          extended_valid_elements: 'script[language|type|src],style',
          // protect: [/<style>[\s\S]*?<\/style>/g],
          plugins: [
            'autolink',
            'lists',
            'link',
            'preview',
            'anchor',
            'table',
            'paste',
            'visualblocks',
            'code',
            'insertdatetime',
            'media',
            'help',
            'wordcount',
          ],
          toolbar:
            'code | undo redo | formatselect | bold italic forecolor | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | customMedia | removeformat',
          link_default_target: '_blank',
          setup: (editor: any) => {
            tinyMceRef.current = editor;
            editor.ui.registry.addButton('customMedia', {
              icon: 'image',
              tooltip: 'Insert Media',
              onAction: (_: any) => mediaPopupRef?.current?.show(),
            });
          },
        }}
        {...props}
      />
      <UploadPopup
        id='article-content-media'
        onConfirm={_onUpload}
        ref={mediaPopupRef}
      />
    </>
  );
});

export default HtmlContentEditor;
