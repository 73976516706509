import { useRef } from 'react';
import {
  deleteFamily,
  listFamily,
  updateFamily,
  updateFamilyPreferences,
} from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { useAppState, appStateSelectors } from 'app/state/AppState';
import {
  IFamilyListItem,
  IFamilyListItemV2,
  IFamilyEditorV2,
  IFamilyAccountSubscriptionStatus,
} from 'app/models/family';
import { IUserV2 } from 'app/models/user';
import { IPreference } from 'app/models/preference';
import { IAccountSubscriptionStatusV2 } from 'app/models/accountNotification';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';
import { AccountNotificationPopupRef } from 'app/components/accountNotification/AccountNotificationPopup';

const useFamilyListing = () => {
  const navigate = useNavigate();
  const programId = useAppState(appStateSelectors.programId);
  const _pagination = usePagination<IFamilyListItemV2>({
    listFn: listFamily,
    deleteFn: deleteFamily,
  });
  const subscriptionModalRef = useRef<AccountNotificationPopupRef>(null);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const roles = useAppState(appStateSelectors.roles);
  const preferences = useAppState(appStateSelectors.preferences)?.filter(
    (pref) => pref.category === 'Contact'
  );

  const onNewFamilyClick = () => navigate(ROUTES.FAMILY_EDITOR);

  const updateFamilySubscriptionStatus = async (
    updatedPreferences: IPreference[],
    id: number
  ) => {
    try {
      showLoader();
      if (id) {
        // Update member subscriptions via Api endpoint
        const preference_ids = updatedPreferences.map(
          (preference) => preference.id
        );
        const response = await updateFamilyPreferences(id, {
          preference_ids: preference_ids || [],
        } as Partial<IFamilyEditorV2>);

        // Update local family object
        const family = _pagination.data.find((item) => item.id === id);
        if (family) {
          let updates = {} as Partial<IFamilyListItemV2>;

          if (family?.head) {
            const updatedHeadOfFamily = { ...family?.head };
            updatedHeadOfFamily.preferences = updatedPreferences;
            updates.head = updatedHeadOfFamily;
          }

          if (family?.members) {
            let updatedMembers = [...family?.members];
            updatedMembers = (updatedMembers || []).map((member) => {
              return {
                ...member,
                preferences: updatedPreferences,
              };
            });
            updates.members = updatedMembers;
          }

          _pagination.updateData(id, 'id', updates);
        }
        return true;
      }
    } catch (error) {
      console.error('Error saving notification subscription settings', error);
      return false;
    } finally {
      hideLoader();
    }

    return false;
  };

  const onToggleActive =
    (family: IFamilyListItemV2) => async (checked: boolean) => {
      const response = await updateFamily(family.id, { is_enabled: checked });
      console.log('Response: ', response);
      if (response?.data) {
        _pagination.updateData(family.id, 'id', {
          is_enabled: checked,
        });
      }
    };

  const onGridAction = (action: DATA_GRID_ACTIONS, id: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.FAMILY_EDITOR}/${id}`);
        break;
      case DATA_GRID_ACTIONS.DELETE:
        _pagination.onDelete(id);
        break;
      case DATA_GRID_ACTIONS.UNSUBSCRIBE:
        const family = _pagination.data.find(
          (item: IFamilyListItemV2) => item.id === id
        );
        family && subscriptionModalRef?.current?.show(family.id, family);
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    preferences,
    roles,
    gradeBands,
    onGridAction,
    updateFamilySubscriptionStatus,
    onNewFamilyClick,
    onToggleActive,
    subscriptionModalRef,
  };
};

export default useFamilyListing;
