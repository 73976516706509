import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Section from 'app/components/section/Section';
import DataGrid from 'app/components/dataGrid/DataGrid';
import RadioButtonGroup from 'app/components/radioButtonGroup/RadioButtonGroup';
import RadioButtonOption from 'app/components/radioButtonGroup/RadioButtonOption';
import Checkbox from 'app/components/checkbox/CheckBox';
import { IEmailManager, IEmailScheduleItemBuilder } from 'app/models/email';
import { IEmailScheduleEditor } from 'app/hooks/EmailScheduleEditor';

interface IPopulationSelectionSection {
  editor: IEmailScheduleEditor;
  values: IEmailScheduleItemBuilder;
}

export const PopulationSelectionSection: React.FC<
  IPopulationSelectionSection
> = ({ editor, values }) => {
  return (
    <Section
      title='Select the population you would like to send the email to'
      sectionId='emailScheduleFrequency'
      titleSize='md'
      className='email-schedule-population mt-1'
    >
      <Row>
        <Col>
          <h6>Registration Status</h6>
          <RadioButtonGroup<number>
            id='test'
            onSelect={editor.onSelect('registrationStatus')}
          >
            {editor.registrationStatuses.map(
              (registrationStatus: {
                id: number;
                registrationStatus: string;
              }) => {
                return (
                  <RadioButtonOption
                    id={`email-schedule-population-option-${registrationStatus.id}`}
                    checked={
                      +values.registrationStatus === +registrationStatus.id
                    }
                    label={registrationStatus.registrationStatus}
                    value={registrationStatus.id}
                  />
                );
              }
            )}
          </RadioButtonGroup>
        </Col>
        <Col>
          <h6>Grade Band</h6>
          {editor.gradeBands.map((gradeBand) => (
            <Checkbox
              id={`email-schedule-grade-band-${gradeBand}`}
              checked={values.gradeBandIds.includes(gradeBand.id)}
              onChange={editor.onGradeBandChange(gradeBand.id)}
              label={gradeBand.gradeBand}
            />
          ))}
        </Col>
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};
