import { IUserProfile } from "app/models/userProfile";
import { IAnnouncement } from "app/models/adminAnnouncement";
import { IOutcome } from "app/models/outcome";
import { IActivities } from "app/models/activities";
import { IAmbassador } from "app/models/ambassador";
import { AxiosRequestConfig } from 'axios';
import { IPreference } from 'app/models/preference';
import {
  IEmailManager,
  IEmailReporting,
  IEmailScheduleManager,
  IEmailScheduleItemBuilder,
  IEmailTemplate,
  ITriggeredEmail,
  IEmailTemplateTest,
} from 'app/models/email';
import { IAdminListItem, IAdmin } from 'app/models/admin';
import {
  IAPIResponse,
  IAPIResponseV2,
  IPaginationParams,
  IPaginationResponsev2,
  IPaginationResponse,
  IParams,
} from 'app/models/api';
import {
  IEmailTemplateContent,
  IEmailBuilder,
  IEmailScheduleItem,
} from 'app/models/email';
import {
  IMainProgramConfigBuilder,
  IMainProgramConfigResponse,
} from 'app/models/config';

import { IQA, IQAListItem, IQATopicId } from 'app/models/qa';
import { IMessage } from 'app/models/messages';
import {
  IArticle,
  IArticleId,
  // IArticles,
  IArticleListItem,
  IArticleVersion,
} from 'app/models/article';
import { IGraduate, IGraduateListItem } from 'app/models/graduate';
import { IClub } from 'app/models/clubs';
import { IStaffListItem, IStaffBuilder } from 'app/models/staff';
import { ISitePhoto } from 'app/models/photos';
import { IProgram, IProgramBuilder } from 'app/models/program';
import { Api } from '.';
// import { IQAListItem, IQA, IQATopicId } from "app/models/qa";
import {
  IFamilyListItem,
  IFamilyListItemV2,
  IFamilyEditor,
  IFamilyEditorV2,
  IFamilyAccountSubscriptionStatus,
} from 'app/models/family';
import { IFolder } from 'app/models/folders';
import { IMedia, IFolderMediaResponse } from 'app/models/media';
import { IUser, IUserV2, IUserBuilder } from 'app/models/user';
import {
  IAccountSubscriptionStatus,
  IAccountSubscriptionStatusV2,
} from 'app/models/accountNotification';
import { IRole } from 'app/models/common';
import { IAmbassadorV2 } from 'app/models/ambassador';

const api = new Api('/admin');
const nonAdminApi = new Api();

/**
 * listStaffs
 */
export const listStaffs = (
  params?: IPaginationParams,
  config?: AxiosRequestConfig
) =>
  nonAdminApi.get<IPaginationResponse<IStaffListItem>>(
    '/staff',
    params,
    config
  );

export const listStaffProfiles = (
  params?: IPaginationParams,
  config?: AxiosRequestConfig
) =>
  nonAdminApi.get<IPaginationResponse<IStaffListItem>>(
    '/staff/profiles',
    params,
    config
  );
// ) => api.get<IPaginationResponse<IStaffListItem>>('/staffs', params, config);
/**
 * listFamilies
 */
export const listFamily = (
  params?: IPaginationParams,
  config?: AxiosRequestConfig,
) => {
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({ page, limit });
  return nonAdminApi.get<IPaginationResponse<IFamilyListItemV2>>(
    `/families${paginationParams}`,
    { ...params, isStandard: true },
    config || {}
  );
};

export const listAmbassadorProfile = (
  params?: IPaginationParams,
  config?: AxiosRequestConfig
) => {
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({
    page,
    limit,
  });

  return api.get<IPaginationResponse<IAmbassadorV2>>(
    `/family${paginationParams}&filter[is_standard][is]=0`,
    { ...params, isStandard: false },
    config || {}
  );
};

export const deleteFamily = (id: number) => api.delete(`/family/${id}`);

export const createFamily = (family: IFamilyEditorV2) =>
  api.post<IFamilyEditorV2, IFamilyEditorV2>('/family', family);

export const updateFamily = (
  id: number,
  family: Partial<IFamilyEditor> | Partial<IFamilyListItemV2>
) =>
  nonAdminApi.put<
    Partial<IFamilyEditor> | Partial<IFamilyListItemV2>,
    Partial<IFamilyEditor> | Partial<IFamilyListItemV2>
  >(`/families/${id}`, family);

export const updateAmbassador = (id: number, params: Partial<IAmbassadorV2>) =>
  nonAdminApi.put<Partial<IAmbassadorV2>, IAmbassadorV2>(
    `/families/${id}`,
    params
  );

export const updateFamilyPreferences = (
  id: number,
  params: Partial<IFamilyEditorV2>
) =>
  nonAdminApi.put<Partial<IFamilyEditorV2>, IFamilyListItemV2 | IAmbassador>(
    `/families/${id}/members/preferences`,
    params
  );

export const getFamily = (id: number) =>
  nonAdminApi.get<IAPIResponse<IFamilyEditorV2>>(`/families/${id}`);

/**
 * listAdmins
 */
export const listAdmins = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IAdminListItem>>('/admins', params);

export const deleteAdmin = (id: number) => api.delete(`/admin-editor/${id}`);

export const createAdmin = (admin: IAdmin) =>
  api.post<IAdmin, IAdmin>('/admin-editor', admin);

export const updateAdmin = (id: number, admin: IAdmin) =>
  api.put<IAdmin, IAdmin>(`/admin-editor/${id}`, admin);

export const getAdmin = (id: number) =>
  api.get<IAPIResponse<IAdmin>>(`/admin-editor/${id}`);

/**
 * listUsers
 */
export const listUsers = (params?: IPaginationParams) => {
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({ page, limit });
  return api.get<IPaginationResponse<IUserV2>>(
    `/users${paginationParams}`,
    params
  );
};

export const getUser = (id: number) =>
  nonAdminApi.get<IAPIResponse<IUserV2>>(`/users/${id}`);

export const updateUser = (id: number, user: Partial<IUserBuilder>) =>
  nonAdminApi.put<Partial<IUserBuilder>, Partial<IUserV2>>(
    `/users/${id}`,
    user
  );

export const updateUserPreferences = (id: number, params: Partial<IUserV2>) =>
  nonAdminApi.put<Partial<IUserV2>, Partial<IUserV2>>(
    `/users/${id}/preferences`,
    params
  );

export const createUser = (user: IUserBuilder) =>
  nonAdminApi.post<Partial<IUserBuilder>, Partial<IUserV2>>(`/users`, user);

/**
 * listArticles
 */
// export const listArticles = (params?: IPaginationParams) =>
export const listArticlesContent = (
  params?: IPaginationParams,
  config?: AxiosRequestConfig
) => {
  console.log('List Article params: ', params);
  const { page, limit } = params || ({} as IPaginationParams);
  const paginationParams = api.encodeQueryParams({ page, limit });
  return api.get<IPaginationResponse<IArticleListItem>>(
    `/article-content${paginationParams}`,
    params,
    config
  );
};

export const createStaff = (staff: IStaffBuilder) =>
  api.post<IStaffBuilder, IStaffBuilder>('/staff', staff);

export const updateStaff = (id: number, staff: Partial<IStaffBuilder>) =>
  // api.put<IStaffBuilder, IStaffBuilder>(`/staff/${id}`, staff);
  nonAdminApi.put<Partial<IStaffBuilder>, IStaffBuilder>(`/staff/${id}`, {
    // user_id: id,
    ...staff,
  });

export const updateStaffProfile = (id: number, staff: FormData) =>
  // api.put<IStaffBuilder, IStaffBuilder>(`/staff/${id}`, staff);
  nonAdminApi.put<FormData, IStaffBuilder>(`/staff/profiles/${id}`, staff);

export const updateStaffActiveStatus = (id: number, isActive: boolean) =>
  api.put<Pick<IStaffListItem, 'isActive'>, Pick<IStaffListItem, 'isActive'>>(
    `/staff/${id}`,
    {
      isActive,
    }
  );

export const getStaff = (id: number) =>
  nonAdminApi.get<IAPIResponse<IStaffBuilder>>(`/staff/${id}`);
// api.get<IAPIResponse<IStaffBuilder>>(`/staff/${id}`);

export const getStaffProfiles = (id: number) =>
  nonAdminApi.get<IAPIResponse<IStaffBuilder>>(`/staff/profiles/${id}`);

export const deleteStaff = (id: number) => api.delete(`/staff/${id}`);

export const getArticleId = () =>
  api.get<IAPIResponse<IArticleId>>('/article-id');

export const deleteArticle = (id: number) =>
  api.delete(`/article-content/${id}`);

export const getArticleVersions = (articleId: number) =>
  api.get<IAPIResponse<IArticleVersion[]>>(`/article-versions/${articleId}`);

export const createArticle = (article: IArticle) =>
  api.post<IArticle, IArticle>('/article-content', article);

export const updateArticle = (id: number, article: Partial<IArticle>) =>
  api.put<Partial<IArticle>, IArticle>(`/article-content/${id}`, article);

export const getArticle = (id: number) =>
  api.get<IAPIResponse<IArticle>>(`/article-content/${id}`);

/**
 * listPhotos
 */
export const listPhotos = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<ISitePhoto>>('/photos', params);

/**
 * listPrograms
 */
export const listProgramAll = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IProgram>>('/programListing', params);

export const listPrograms = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IProgram>>('/programs', params);

export const createProgram = (program: IProgramBuilder) =>
  api.post<IProgramBuilder, IProgramBuilder>('/programs', program);

export const updateProgram = (id: number, program: IProgramBuilder) =>
  api.put<IProgramBuilder, IProgramBuilder>(`/programs/${id}`, program);

export const getProgram = (id: number) =>
  api.get<IAPIResponse<IProgramBuilder>>(`/programs/${id}`);

export const updateLiveStatus = (id: number, isLive: boolean) =>
  api.put<Pick<IProgram, 'isLive'>, Pick<IProgram, 'isLive'>>(
    `/programs/${id}`,
    { isLive }
  );

/**
 * Dec 2022 - Needed for Main Program Configurations editor
 */
export const getProgramConfigurations = (params?: IParams) =>
  nonAdminApi.get<IAPIResponse<IMainProgramConfigResponse>>(
    `/programs/${params?.id}`
  );

export const updateProgramConfigurations = (
  id: number,
  // program: IMainProgramConfigBuilder & { _method?: string }
  program: FormData
) => {
  return nonAdminApi.post<FormData, IMainProgramConfigBuilder>(
    `/programs/${id}`,
    program,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

/*
 * Get Available Users for program
 * Can be scoped to only get available staff or family profile users
 */
export const getAvailableUsersFor = (
  scope: 'staff' | 'family' | 'all' = 'staff'
) => {
  const suffix =
    scope === 'all'
      ? ''
      : scope === 'staff'
      ? `?filter[${scope}_profile][hasnt]`
      : '';
  return nonAdminApi.get<IAPIResponseV2<IUserV2[]>>(`users${suffix}`);
};

/**
 * listGraduateProfile
 */
export const listArticles = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IArticle>>('/articles', params);
// api.get<IPaginationResponse<IArticles>>('/articles', params);


/**
 * listAnnouncement
 */
export const listAdminAnnouncement = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IAnnouncement>>('/announcement', params);

export const listOutcome = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IOutcome>>('/activities', params);

export const listActivities = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IActivities>>('/activities', params);

export const listGraduateProfile = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IGraduateListItem>>('/graduates', params);

export const createGraduate = (graduate: IGraduate) =>
  api.post<IGraduate, IGraduate>('/graduate', graduate);

export const getGraduate = (id: number) =>
  api.get<IAPIResponse<IGraduate>>(`/graduate-gallery${id}`);

export const deleteGraduate = (id: number) => api.delete(`/graduate/${id}`);

export const updateGraduate = (id: number, graduate: IGraduate) =>
  api.put<IGraduate, IGraduate>(`/graduate/${id}`, graduate);

/**
 * listQuestionAndAnswer
 * listUser Messages
 */
export const listMessages = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IMessage>>('/messages', params);

/**
 * Email Template Management Apis
 */
export const listThink12EmailTemplates = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IEmailManager>>('/templates', params);

export const listAllThink12EmailTemplates = (params?: IParams) =>
  api.get<IPaginationResponsev2<IEmailManager>>(`/templates`, {
    ...params,
  });
// export const listAllThink12EmailTemplates = (params?: IParams) =>
//   api.get<IAPIResponse<IEmailManager[]>>(`/email`, { ...params, limit: 'all' });

export const getEmail = (id: number) =>
  nonAdminApi.get<IAPIResponse<IEmailBuilder>>(`notifications/templates/${id}`);

export const testEmail = (id: number, recipients: IEmailTemplateTest) =>
  nonAdminApi.post<IEmailTemplateTest, IAPIResponse<string>>(
    `/notifications/templates/${id}/tests`,
    recipients
  );

// Mailgun templates
export const getAvailableEmailTemplates = () =>
  api.get<IPaginationResponse<IEmailTemplate>>('/email/templates');

export const getEmailTemplate = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IEmailManager>>('/email', params);

const unauthApi = new Api('admin', true);
export const getEmailTemplateContent = (params?: IParams) =>
  unauthApi.get<IAPIResponse<string>>(`/templates/${params!.id}/preview`);

export const getMailgunTemplateContent = (templateName: string) =>
  api.get<IAPIResponse<IEmailTemplateContent>>(
    `/email/mailgun/${templateName}/content`
  );

export const duplicateEmailTemplate = (id: number) =>
  api.get<IAPIResponse<IEmailManager>>(`/email/${id}/duplicate`);

export const createEmailTemplate = (email: IEmailBuilder) =>
  api.post<IEmailBuilder, IEmailBuilder>(`/templates`, email);
// export const createEmailTemplate = (email: IEmailBuilder) =>
//   api.post<IEmailBuilder, IEmailBuilder>(`/email`, email);

export const updateEmailTemplate = (id: number, email: IEmailBuilder) =>
  nonAdminApi.put<IEmailBuilder, IEmailBuilder>(
    `/notifications/templates/${id}`,
    email
  );
// export const updateEmailTemplate = (id: number, email: IEmailBuilder) =>
//   api.put<IEmailBuilder, IEmailBuilder>(`/email/${id}`, email);

export const deleteEmailTemplate = (id: number) =>
  nonAdminApi.delete<number, IAPIResponse<number>>(
    `/notifications/templates/${id}`
  );
// api.delete<number, IAPIResponse<number>>(`/email/${id}`);

/**
 * listEmailScheduleManagers
 */
export const getEmailSchedule = (params: IParams | undefined) =>
  api.get<IAPIResponse<IEmailScheduleItem>>(`/email_schedule/${params?.id}`);

export const listEmailScheduleManagers = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IEmailScheduleManager>>(
    '/email_schedule',
    params
  );

export const getEmailSchedules = (params?: IPaginationParams) =>
  nonAdminApi.get<IPaginationResponse<IEmailScheduleManager>>(
    '/notifications/schedules',
    params
  );

export const createEmailSchedule = (params: IEmailScheduleItemBuilder) =>
  api.post<IEmailScheduleItemBuilder, IEmailScheduleItem>(
    '/email_schedule',
    params
  );

export const updateEmailSchedule = (
  id: number,
  params: Partial<IEmailScheduleItemBuilder>
) =>
  api.post<Partial<IEmailScheduleItemBuilder>, IEmailScheduleItem>(
    `/email_schedule/${id}`,
    params
  );
/**
 * listEmailReportings
 */
export const listEmailReportings = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IEmailReporting>>('/email/reporting', params);

/**
 * listTriggeredEmails
 */
export const listTriggeredEmails = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<ITriggeredEmail>>('/email/triggered', params);
export const listQA = (
  params?: IPaginationParams,
  config?: AxiosRequestConfig
) => api.get<IPaginationResponse<IQAListItem>>('/qa-content', params, config);

export const deleteQA = (id: number) => api.delete(`/qa-content/${id}`);

export const createQA = (article: IQA) =>
  api.post<IQA, IQA>('/qa-content', article);

export const updateQA = (id: number, question: Partial<IQA>) =>
  api.put<Partial<IQA>, IQA>(`/qa-content/${id}`, question);

export const getQA = (id: number) =>
  api.get<IAPIResponse<IQA>>(`/qa-content/${id}`);

export const getQATopicId = () =>
  api.get<IAPIResponse<IQATopicId>>('/topic-id');

/*
 * listPrograms
 */
export const listClubs = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<IClub>>('/clubs', params);

export const getClub = (id: number) =>
  api.get<IAPIResponse<IClub>>('/club/' + id);

export const updateClub = (club: any) =>
  api.put<Partial<IClub>, any>('/club/' + club.id, club);

export const deleteClub = (id: number) => api.delete('/club/' + id);

export const listOutcomeContent = (params?: IPaginationParams) =>
  api.get<IPaginationResponse<any>>('/outcome-content', params);

export const updateOutcomeContent = (params?: IPaginationParams) =>
  api.put('/outcome-content/' + params?.id, params);

export const deleteOutcomeContent = (params?: IPaginationParams) =>
  api.delete('/outcome-content/' + params?.id);

export const createOutcomeContent = (params?: IPaginationParams) =>
  api.post('/outcome-content', params);
  
/*
 * listFolders
 */
export const listFolders = () =>
  api.get<IAPIResponse<IFolder[]>>('/folders?media=true');

export const listFoldersV2 = () =>
  nonAdminApi.get<IAPIResponse<IFolder[]>>('/folders');

/*
 * listMediaInFolder
 */
export const listMedia = (params: IParams | undefined) =>
  api.get<IAPIResponse<IMedia[]>>(`folder/${params?.id}/medias`);

export const listMediaV2 = (params: IParams | undefined) =>
  nonAdminApi.get<IAPIResponse<IFolderMediaResponse>>(
    `folders/${params?.id}/media`
  );
