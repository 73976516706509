import { IRadioButtonOptionProps } from './RadioButtonOption';

export const getRadioButtonOptions = (
  children:
    | React.ReactElement<IRadioButtonOptionProps<any>>
    | React.ReactElement<IRadioButtonOptionProps<any>>[]
) => {
  if (Array.isArray(children)) {
    return children.map(({ props }) => props);
  } else {
    return [children.props];
  }
};
