import React from 'react';
import { FormikTouched, FormikErrors } from 'formik';

import Section from 'app/components/section/Section';
import EducationEditor from 'app/components/educationEditor/EducationEditor';
import RoundButton from 'app/components/button/RoundButton';
import { IStaffBuilder } from 'app/models/staff';
import { ReactComponent as IcPlus } from 'app/assets/icons/plus.svg';

interface IEducationSection {
  editor: any;
  touched: FormikTouched<IStaffBuilder>;
  errors: FormikErrors<IStaffBuilder>;
  values: IStaffBuilder;
}

export const EducationSection: React.FC<IEducationSection> = ({
  editor,
  touched,
  errors,
  values,
}) => {
  return (
    <Section
      title='Education'
      sectionId='staffBuilderEducation'
      titleSize='lg'
      className='staff-builder-education'
      isEditable={true}
    >
      {values.educations.map((education, index) => (
        <EducationEditor
          key={`staff-builder-education-${index}`}
          index={index}
          variant='secondary'
          selected={education.outcome_id}
          onChange={editor.onChange(`educations[${index}].outcome_id`)}
          onDelete={editor.onDeleteEducation}
        />
      ))}

      <RoundButton
        icon={<IcPlus />}
        className='add-icon'
        size='lg'
        variant='primary'
        label='Add new education'
        onClick={editor.addEducation}
        roundBtnId='staff-builder-add-btn'
      />
      <hr className='mb-5 mt-4' />
    </Section>
  );
};
