import { arrayNumberGenerator } from "app/utils/common";

export const getPageNums = (totalPages: number, activePage: number) => {
  if (totalPages < 11) {
    return arrayNumberGenerator(totalPages - 2, 2);
  }
  if (activePage < 7) {
    return arrayNumberGenerator(8, 2);
  } else if (totalPages - activePage < 6) {
    return arrayNumberGenerator(8, totalPages - 8);
  }
  return arrayNumberGenerator(7, activePage - 3);
};
