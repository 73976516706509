import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import RoundButton from "app/components/button/RoundButton";
import If from "app/components/If";
import React, { useState, useRef } from "react";
import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import Input from "app/components/input/Input";
import { Col, Row } from "react-bootstrap";
import DragFileUpload from "app/components/dragFileUpload/DragFileUpload";
import DatePicker from "react-datepicker";
import StandardButton from "app/components/button/StandardButton";
import SearchBar from "app/components/searchBar/SearchBar";
import "./club-components.scss";
import ImageUploader, {
  ImageUploaderRef,
} from "app/components/imageUploader/ImageUploader";
import SelectImage from "app/components/listedGallery/SelectImage";
import { getApiDomain } from "app/utils/common";

const ClubEvent: React.FC<IClubEventProps> = ({
  index,
  event,
  className = "",
  onChange,
  onRemove,
}) => {
  const updateEvent = (event: any, prop: string, val: any) => {
    event[prop] = val;
    if (onChange) onChange({ ...event });
  };
  const removeEvent = (event: any) => {
    if (onRemove) onRemove(event);
  };

  const removeImage = () => {
    updateEvent(event, "image", "");
    updateEvent(event, "image_id", "");
  }

  const uploaderRef = useRef<ImageUploaderRef>(null);

  return (
    <Row>
      <Col md={4} className="club-event-image-container">
        {event.image_id ? (
          <div>
            <img className="club-event-image" src={(event.image) ? getApiDomain() + event.image.path : ""} onClick={() => removeImage()}/>
          </div>
        ) : (
          <div>
            <DragFileUpload
              id="club-editor-image"
              label="Add a representative image of your club"
              onDrop={(files) => {
                uploaderRef.current?.upload("club_event", files[0]);
              }}
              useNativeUploader={true}
            />
            <ImageUploader
              ref={uploaderRef}
              hideProgress={true}
              onUploadComplete={(file: any) => {
                updateEvent(event, "image_id", file?.id);
                updateEvent(event, "image", file);
              }}
            />
          </div>
        )}
      </Col>
      <Col md={8} className="mt-5">
        <Row>
          <Col sm={6}>
            <Input
              id="event-name"
              type="text"
              value={event.name}
              label="Event Name"
              onChange={(e: any) => updateEvent(event, "name", e.target.value)}
              as="input"
              variant="secondary"
            />
          </Col>
          <Col sm={6}>
            <Input
              type="text"
              value={event.location}
              name={"event-" + index}
              id={"event-location-" + index}
              label="Location"
              onChange={(e: any) =>
                updateEvent(event, "location", e.target.value)
              }
              as="input"
              variant="secondary"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            Date
            <DatePicker
              selected={
                event.event_date ? new Date(event.event_date) : new Date()
              }
              onChange={(date: Date) => updateEvent(event, "event_date", date.toISOString())}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="remove-event">
            <StandardButton
              onClick={() => removeEvent(event)}
              standardBtnId="article-editor-save-btn"
              label="Remove Event"
              variant="outlined"
              type="submit"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClubEvent;

interface IClubEventProps {
  index: number;
  event: any;
  className?: string;
  onChange?: (event: any) => void;
  onRemove?: (event: any) => void;
}
