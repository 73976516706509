import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';

import Input from 'app/components/input/Input';
import Section from 'app/components/section/Section';
import RoundButton from 'app/components/button/RoundButton';
import { IConfigurationEditor } from 'app/hooks/ConfigurationEditor';
import { IMainProgramConfigBuilder } from 'app/models/config';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import { ReactComponent as IcEdit } from 'app/assets/icons/edit.svg';

type ManagementDetailsSection = {} & IConfigurationEditorSection;

const ManagementDetailsSection: React.FC<ManagementDetailsSection> = ({
  editor,
  values,
  touched,
  errors,
}) => {
  function _getErrors(path: string) {
    return getIn(touched, path) && getIn(errors, path)
      ? getIn(errors, path)
      : '';
  }

  return (
    <Section
      title='Program Management Details'
      sectionId='configuration-editor-management-details'
      titleSize='md'
      className='configuration-editor-management-details mt-4'
    >
      <Row>
        <Col>
          <Input
            id='configuration-editor-assigned_staff'
            label='Think 12 Staff Assigned'
            variant='secondary'
            name='think12_staff_assigned'
            error={_getErrors('think12_staff_assigned')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-owner_name'
            label='School Program Owner Name'
            variant='secondary'
            name='school.program_owner'
            error={_getErrors('school.program_owner')}
          />
        </Col>
        <Col>
          <Input
            id='configuration-editor-contract_dates'
            label='Contract Dates'
            variant='secondary'
            name='program_contract_dates'
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            xs={12}
            id='configuration-editor-chatbot-script'
            label='Chatbot Script'
            variant='secondary'
            name='scripts[0].body'
            as='textarea'
            rows={10}
            onChange={(e: any) =>
              editor?.onUpdateScript?.('Botsify', e.target.value)
            }
          />
        </Col>
      </Row>
      <hr className='mt-5' />
    </Section>
  );
};

export default ManagementDetailsSection;
