import React from "react";
import { Accordion } from "react-bootstrap";

const CustomAccordion: React.FC<IProps> = ({ header, children, onClick }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={onClick}>{header}</Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CustomAccordion;

interface IProps {
  header: string;
  onClick: () => void;
}
