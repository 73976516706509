import { ReactComponent as IcSearch } from 'app/assets/icons/search.svg';
import { ReactComponent as IcDownArrow } from 'app/assets/icons/down-arrow.svg';
import { ReactComponent as IcPlus } from 'app/assets/icons/plus.svg';
import { ReactComponent as IcClose } from 'app/assets/icons/close.svg';
import useOutsideClick from 'app/hooks/OutsideClick';
import React, { useRef, useState } from 'react';
import RoundButton from '../button/RoundButton';
import { DataColumnProps } from '../dataGrid/DataColumn';
import If from '../If';
import './drop-down.scss';
import AlphabeticFilter from '../filter/AlphabeticFilter';
import Input from '../input/Input';
import DataGrid, { IDataGridProps } from '../dataGrid/DataGrid';
import StandardButton from '../button/StandardButton';

const SelectorDropDown = <T,>({
  children,
  data,
  eltId,
  label,
  labelAttribute,
  placeholder,
  valueAttribute,
  multiple,
  searchPlaceholder,
  values = [],
  variant,
  selectionType,
  style = 'dropdown',
  onConfirm,
  initialFilter = '',
  gridHeight,
}: ISelectorDropDownProps<T>) => {
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [selectedValues, setSelectedValues] = useState(values);
  const itemsRef = useRef(null);
  const toggleRef = useRef(null);

  const _closeItems = () => {
    setFilter(initialFilter);
    setVisible(false);
  };

  const _toggleItems = () => setVisible((visible) => !visible);

  const _onConfirm = () => {
    _closeItems();
    onConfirm?.(selectedValues);
  };

  useOutsideClick(itemsRef, _closeItems, toggleRef);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFilter(e.target.value);

  return (
    <>
      <div className='t12-drop-down'>
        <If condition={style === 'dropdown'}>
          <Input
            id={`${eltId}_toggle`}
            ref={toggleRef}
            label={label}
            variant={variant}
            readOnly
            as='text'
            value={
              !multiple
                ? data.find(
                    (d) =>
                      (d[valueAttribute] as unknown as number) === values?.[0]
                  )?.[labelAttribute]
                : ''
            }
            placeholder={placeholder}
            onClick={_toggleItems}
          />
        </If>
        <If condition={style === 'roundButton'}>
          <RoundButton
            roundBtnId={`${eltId}_toggle`}
            label={label}
            innerRef={toggleRef}
            icon={<IcPlus />}
            variant={variant}
            onClick={_toggleItems}
          />
        </If>
        <If condition={style === 'link'}>
          <button
            ref={toggleRef}
            className='toggle-link'
            onClick={_toggleItems}
            id={`${eltId}_toggle`}
          >
            {label}
          </button>
        </If>
      </div>
      <If condition={visible}>
        <div
          ref={itemsRef}
          className={`selector ${style !== 'dropdown' ? 'no-label' : ''}`}
        >
          <h5 className='text-capitalize font-weight-600'>{placeholder}</h5>
          <RoundButton
            className='close-button'
            size='xs'
            icon={<IcClose width={8} className='color-primary' />}
            onClick={_closeItems}
            roundBtnId={`${eltId}-close-pop-up`}
          />
          <AlphabeticFilter
            eltId={`${eltId}-alphabetic-filter`}
            selected={filter}
            onSelectionChange={setFilter}
          />
          <div className='search-container'>
            <IcSearch />
            <input
              type='text'
              id={`${eltId}-search`}
              placeholder={searchPlaceholder}
              onChange={_onChange}
            />
          </div>
          <DataGrid<T>
            data={
              filter
                ? data.filter((d) =>
                    (d[labelAttribute] as unknown as string)
                      .toLowerCase()
                      .startsWith(filter.toLowerCase())
                  )
                : data
            }
            gridId={`${eltId}-data`}
            rowIdAttribute={valueAttribute}
            pageSize={data.length}
            selectionType={selectionType}
            onSelectionChange={setSelectedValues}
            selectedRows={selectedValues}
            gridHeight={gridHeight}
          >
            {children}
          </DataGrid>
          <div className='d-flex align-items-center justify-content-end'>
            <StandardButton
              standardBtnId={`${eltId}-cancel-btn`}
              variant='outlined'
              label='Cancel'
              size='sm'
              className='mx-3'
              onClick={_closeItems}
            />
            <StandardButton
              standardBtnId={`${eltId}-confirm-btn`}
              label='Confirm'
              variant='secondary'
              size='sm'
              onClick={_onConfirm}
            />
          </div>
        </div>
      </If>
    </>
  );
};

export default SelectorDropDown;

export interface ISelectorDropDownProps<T>
  extends Pick<IDataGridProps<T>, 'selectionType'> {
  eltId: string;
  data: T[];
  labelAttribute: keyof T;
  valueAttribute: keyof T;
  label: string;
  placeholder: string;
  searchPlaceholder?: string;
  values?: number[];
  multiple?: boolean;
  style?: 'dropdown' | 'roundButton' | 'link';
  variant?: 'primary' | 'secondary';
  children: React.ReactElement<DataColumnProps<T>>[];
  initialFilter?: string;
  gridHeight?: number;
  onConfirm?: (values: number[]) => void;
}
