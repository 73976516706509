import React from 'react';
import { IDropDownMultiProps, IDropDownProps } from '../dropDown/DropDown';
import { ISelectedOption } from '../selectedOption/SelectedOptionContainer';

const FilterOption = <T,>({}: IFilterOptionProps<T>) => {
  return <></>;
};

export default FilterOption;

/**
 * component - Provide a custom component (other than a dropdown) to display a filter
 * getSelectedOptions - Get selected filter criteria when using a custom filter component
 * getValues - Provide filter values to the custom filter component
 */
export interface IFilterOptionProps<T> extends Omit<IDropDownProps<T>, 'id'> {
  name: string;
  disableMultipleSelection?: boolean;
  component?: React.ElementType;
  getSelectedOptions?: (
    filters?: Record<string, number[]>
  ) => ISelectedOption[];
  getValues?: () => Record<string, any>;
}
