import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import React, { useImperativeHandle, useRef, useState } from "react";
import "./delete-popup.scss";

const DeletePopup = React.forwardRef<DeletePopUpRef, IDeletePopupProps>(
  ({ id, confirmDeleteDescription, onConfirm }, ref) => {
    const modalRef = useRef<T12ModalRef>(null);
    const [rowId, setRowId] = useState<number>();

    useImperativeHandle(
      ref,
      () => ({
        show: (id: number) => {
          setRowId(id);
          modalRef.current?.show();
        },
      }),
      []
    );

    const _onConfirm = () => onConfirm?.(rowId!);

    return (
      <T12Modal
        modalId={id}
        ref={modalRef}
        title="Confirm Delete"
        className="delete-popup"
        confirmButtonLabel="Confirm"
        cancelButtonLabel="Cancel"
        showHeaderBorder
        onConfirm={_onConfirm}
      >
        <div className="delete-modal-body">
          <p>{confirmDeleteDescription}</p>
          <p>This action cannot be undone</p>
        </div>
      </T12Modal>
    );
  }
);

export default DeletePopup;

interface IDeletePopupProps {
  id: string;
  confirmDeleteDescription: string;
  onConfirm?: (id: number) => void;
}

export type DeletePopUpRef = {
  show: (id: number) => void;
} | null;
