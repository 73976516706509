import React from 'react';
import moment from 'moment';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

interface Props {
  data: any;
  type: string;
  maxData?: number;
  children?: any;
  className?: string;
}
const ReportingChart: React.FC<Props> = (props: Props) => {
  let labels: Array<string> = [];
  let data: any = [];

  if (!props.data) {
    return <div></div>;
  }
  props.data.forEach((d: any) => {
    labels.push(moment.utc(d.label).local().format("Y-M-D H:m"));
    data.push(d.value);
  });

  let opts = {
    layout: {
      padding: {
        right: 5,
        left: 5,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem: any, data: any) {
            //break the label up into an array of lines to display
            const wordWrap = (str:string, length:number) => str.replace(
              new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1\n'
            );
            let str = wordWrap(tooltipItem.label, 30);
            let arr = str.split('\n');
            return arr;
          }
        }
      },
    },
  };

  let barData = {
    labels: labels,
    datasets: [
      {
        borderRadius: 50,
        label: 'Pageviews',
        backgroundColor: ['#89ADF6', '#104ECC', '#BFD5FF', '#6599FF', '#082A6D'],
        data: data,
      },
    ],
  };

  let pieData = {
    labels: labels,
    datasets: [
      {
        label: '',
        data: data,
        backgroundColor: ['#89ADF6', '#104ECC', '#BFD5FF', '#6599FF', '#082A6D'],
      },
    ],
  };
  return (
    <div className={'reporting-chart ' + ((props.className) ? props.className : '')}>
      {/* @ts-ignore */}
      {props.type === 'Bar' ? <Bar data={barData} options={opts} /> : []}
      {/* @ts-ignore */}
      {props.type === 'Pie' ? <Pie data={pieData} options={opts} /> : []}
    </div>
  );
};
export default ReportingChart;
