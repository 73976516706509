import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import QAVideoCard from 'app/components/adminQAVideoCard/AdminQAVideoCard';
import React, { useImperativeHandle, useRef } from 'react';
import { IQAListItem, IQA } from 'app/models/qa';
import { useState } from 'react';

const QuestionAnswerPreviewPopup = React.forwardRef<
  QuestionAnswerPreviewPopupRef,
  IQuestionAnswerPreviewPopupProps
>(({ id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const [state, setState] = useState<IQAListItem | IQA | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      show: (qaItem: IQAListItem) => {
        console.log('Item: ', qaItem);
        setState(qaItem);
        modalRef.current?.show();
      },
    }),
    []
  );

  const {
    topic,
    url,
    quoteText,
    quoteUser,
    quoteTitle,
    quoteUserId,
    responses,
  } = (state as IQA) || {};
  return (
    <T12Modal
      modalId={id}
      hideFooter
      size='lg'
      ref={modalRef}
      title='Article Preview'
      className='previous-versions'
      showHeaderBorder
    >
      <QAVideoCard
        topic={topic || ''}
        responses={responses}
        quoteText={quoteText}
        quoteUser={quoteUser}
        quoteTitle={quoteTitle}
        quoteUserId={quoteUserId}
      />
    </T12Modal>
  );
});

export default QuestionAnswerPreviewPopup;

interface IQuestionAnswerPreviewPopupProps {
  id: string;
}

export type QuestionAnswerPreviewPopupRef = {
  show: (article: IQAListItem) => void;
} | null;
