import { createProgram, getProgram, updateProgram } from 'app/api/adminApis';
import { ISelectedOption } from 'app/components/selectedOption/SelectedOptionContainer';
import {
  IProgramBuilder,
  IProgramFeatureWithToggles,
} from 'app/models/program';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useProgramBuilder = () => {
  const navigate = useNavigate();
  const programFeatures = useAppState(appStateSelectors.programFeatures);
  const demographics = useAppState(appStateSelectors.demographics);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const schoolTypes = useAppState(appStateSelectors.schoolTypes);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);

  const params = useParams();

  const formRef = useRef<FormikProps<IProgramBuilder>>(null);

  useEffect(() => {
    formRef.current?.setFieldValue(
      'features',
      programFeatures.map((feature) => ({
        ...feature,
        isEnabled: false,
        isActive: false,
      }))
    );
  }, [programFeatures, formRef.current]);

  useEffect(() => {
    params?.id && _getProgramDetails();
  }, [params]);

  const _getProgramDetails = async () => {
    showLoader();
    try {
      const { data } = await getProgram(+params.id!);
      formRef.current?.setValues(data);
    } catch (error) {
      console.log(
        '🚀 ~ file: ProgramBuilder.ts  ~ const_getProgramDetails= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async (
    values: IProgramBuilder,
    helpers: FormikHelpers<IProgramBuilder>
  ) => {
    showLoader();
    try {
      const { data } = await (params.id
        ? updateProgram(+params.id, values)
        : createProgram(values));
      navigate(-1);
    } catch (error) {
      console.log(
        '🚀 ~ file: ProgramBuilder.ts ~ useProgramBuilder ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const onGradeBandsSelected = (ids: number[]) =>
    formRef.current?.setFieldValue('gradeBandIds', ids);

  const onSchoolTypesSelected = (ids: number[]) =>
    formRef.current?.setFieldValue('schoolTypeIds', ids);

  const onDemographicsSelected = (ids: number[]) =>
    formRef.current?.setFieldValue('demographicIds', ids);

  const getSelectedOptions = (): ISelectedOption[] => {
    const {
      demographicIds = [],
      gradeBandIds = [],
      schoolTypeIds = [],
    } = formRef.current?.values ?? {};
    const demographicOptions: ISelectedOption[] = demographicIds.map((id) => ({
      id,
      label: demographics.find((demographic) => demographic.id === id)!
        .demographicName,
      categ: 'demographics',
    }));
    const gradeBandOptions: ISelectedOption[] = gradeBandIds.map((id) => ({
      id,
      label: gradeBands.find((gradeBand) => gradeBand.id === id)!.gradeBand,
      categ: 'grade',
    }));
    const schoolTypeOptions: ISelectedOption[] = schoolTypeIds.map((id) => ({
      id,
      label: schoolTypes.find((schoolType) => schoolType.id === id)!.schoolType,
      categ: 'schoolType',
    }));
    return [...gradeBandOptions, ...demographicOptions, ...schoolTypeOptions];
  };

  const onSelectedOptionDelete = (id: number, categ?: string) => {
    switch (categ) {
      case 'demographics':
        const { demographicIds = [] } = formRef.current?.values ?? {};
        onDemographicsSelected(
          demographicIds.filter((demographicId) => demographicId !== id)
        );
        break;
      case 'grade':
        const { gradeBandIds = [] } = formRef.current?.values ?? {};
        onGradeBandsSelected(
          gradeBandIds.filter((gradeBandId) => gradeBandId !== id)
        );
        break;
      case 'schoolType':
        const { schoolTypeIds = [] } = formRef.current?.values ?? {};
        onSchoolTypesSelected(
          schoolTypeIds.filter((schoolTypeId) => schoolTypeId !== id)
        );
        break;
      default:
        break;
    }
  };

  const onConfirmImportGlobals =
    (
      contentType: keyof Pick<
        IProgramBuilder,
        'importGlobalArticles' | 'importGlobalEmails'
      >
    ) =>
    () =>
      formRef.current?.setFieldValue(contentType, true);

  const onFeatureToggled =
    (
      id: number,
      toggle: keyof Omit<
        IProgramFeatureWithToggles,
        'id' | 'featureName' | 'featureType' | 'tag'
      >
    ) =>
    (checked: boolean) => {
      const { features } = formRef.current!.values;
      features.find((feature) => feature.id === id)![toggle] = checked;
      formRef.current?.setFieldValue('features', features);
    };

  return {
    initialValues: initialState,
    formRef,
    programFeatures,
    demographics,
    gradeBands,
    schoolTypes,
    onSubmit,
    onSchoolTypesSelected,
    onGradeBandsSelected,
    onDemographicsSelected,
    onSelectedOptionDelete,
    onConfirmImportGlobals,
    onFeatureToggled,
    getSelectedOptions,
  };
};

export default useProgramBuilder;

const initialState: IProgramBuilder = {
  programDescription: '',
  programName: '',
  programUrl: '',
  schoolName: '',
  shortSchoolName: '',
  gradeBandIds: [],
  demographicIds: [],
  schoolTypeIds: [],
  features: [],
  importGlobalArticles: false,
  importGlobalEmails: false,
};
