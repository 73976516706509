import React from 'react';

import PrivateLayout from 'app/layout/PrivateLayout';

type UnderConstructionPageProps = {
  label: string;
};

const InProgress: React.FC<UnderConstructionPageProps> = ({ label }) => {
  console.log('In progress with ', label);
  return (
    <PrivateLayout contentClassName='under-construction'>
      <h3 className='title'>{label}</h3>
    </PrivateLayout>
  );
};

export default InProgress;
