import React, { useState } from "react";
import "./listed-gallery.scss";
import Folder from "app/components/folder/Folder";
import { emptyFunction } from "app/utils/common";
import SearchBar from "app/components/searchBar/SearchBar";
import { ISitePhoto } from "app/models/photos";
import { ReactComponent as IcGridView } from "app/assets/icons/grid-view.svg";
import { ReactComponent as IcListView } from "app/assets/icons/list-view.svg";
import { ReactComponent as IcDelete } from "app/assets/icons/delete.svg";
import StandardButton from "../button/StandardButton";
import DataGrid from "../dataGrid/DataGrid";
import useSitePhotoListing from "app/hooks/SitePhotoListing";
import { DATA_GRID_ACTIONS } from "../dataGrid/DataColumn";
import If from "../If";
import SelectImage from "./SelectImage";

const GRID_ID = "admin-photo-listing";

const ListedGallery: React.FC<IListedGalleryProps> = ({
  className = "",
  folderList = [],
  listCount = 13,
  gridCount = 7,
  showDelete,
  totalItems,
  data,
}) => {
  const _photoListing = useSitePhotoListing(listCount, gridCount);

  return (
    <div className={`listing ${className}`}>
      <SearchBar
        placeHolder="Search by file name"
        searchBarId="listed-gallery-search"
      />
      <div className="listing-folders">
        {/* write condition to show open folder */}
        {folderList.map((folder) => (
          <Folder
            folderStateId="listed-gallery-folder"
            folderName="Folder Name"
            onClick={emptyFunction}
            open={true}
          />
        ))}
      </div>
      <If condition={_photoListing.gridView}>
        <div className="listing-view">
          <div className="listing-view-switch">
            <div className="listing-view-switch-icons">
              <IcGridView
                onClick={_photoListing.onChangeGridView}
                className={`listing-view-switch-icons-grid ${
                  _photoListing.gridView && "selected"
                }`}
              />
              <IcListView
                onClick={_photoListing.onChangeGridView}
                className={`listing-view-switch-icons-list ${
                  !_photoListing.gridView && "selected"
                }`}
              />
            </div>
            <StandardButton
              size="sm"
              label="Delete"
              icon={IcDelete}
              variant="outlined"
              className="listing-delete-button"
              onClick={emptyFunction}
              standardBtnId="listing-gallery-delete-btn"
            />
          </div>
        </div>
        {/* data listing */}
        <DataGrid<ISitePhoto>
          data={_photoListing.data}
          gridId={GRID_ID}
          rowIdAttribute="photoId"
          selectionType="multiple"
          onSelectionChange={_photoListing.onSelectionChange}
          currentPage={_photoListing.currentPage}
          onPageChange={_photoListing.onPageChange}
          totalItems={_photoListing.totalItems}
          onAction={_photoListing.onGridAction}
          selectedRows={_photoListing.selectedRows}
        >
          <DataGrid.Column<ISitePhoto>
            attributeName="imageUrl"
            header=""
            transform={(photo) => (
              <img
                src={photo.imageUrl}
                alt="image"
                className="listing-view-image"
              />
            )}
          />
          <DataGrid.Column<ISitePhoto>
            attributeName="fileName"
            header="FILE NAME"
          />
          <DataGrid.Column<ISitePhoto>
            attributeName="imageDiamensions"
            header="IMAGE DIMENSIONS"
          />
          <DataGrid.Column<ISitePhoto>
            attributeName="uploadedTo"
            header="UPLOADED TO"
          />
          <DataGrid.Column<ISitePhoto>
            action={DATA_GRID_ACTIONS.PREVIEW}
            attributeName="GRID.ACTIONS"
          />
          <DataGrid.Column<ISitePhoto>
            action={DATA_GRID_ACTIONS.DELETE}
            attributeName="GRID.ACTIONS"
          />
        </DataGrid>
      </If>
      <If condition={!_photoListing.gridView}>
        <div className="listing-view">
          <div className="listing-view-switch">
            <div className="listing-view-switch-icons">
              <IcGridView
                onClick={_photoListing.onChangeGridView}
                className={`listing-view-switch-icons-grid ${
                  _photoListing.gridView && "selected"
                }`}
              />
              <IcListView
                onClick={_photoListing.onChangeGridView}
                className={`listing-view-switch-icons-list ${
                  !_photoListing.gridView && "selected"
                }`}
              />
            </div>
            <StandardButton
              size="sm"
              label="Delete"
              icon={IcDelete}
              variant="outlined"
              className="listing-delete-button"
              onClick={emptyFunction}
              standardBtnId="listing-gallery-delete-btn"
            />
          </div>
        </div>
        {/* data listing */}
        <div className="listing-photos">
          {data.map((file) => (
            <SelectImage
              data={file}
              imageUrl=""
              onSelectionChange={_photoListing.onSelectionChange}
              selectedImages={_photoListing.selectedRows}
            />
          ))}
        </div>
      </If>
      <span className="listing-total">{`${totalItems} items`}</span>
    </div>
  );
};

export default ListedGallery;

interface IListedGalleryProps {
  className: string;
  folderList: string[];
  listCount: number;
  gridCount: number;
  showDelete: boolean;
  totalItems: number;
  data: ISitePhoto[];
}
