import React from 'react';

import Section from 'app/components/section/Section';
import { IConfigurationEditorSection } from 'app/hooks/ConfigurationEditor';
import PhotoCopyRow from './components/PhotoCopyRow';

type PhotosCopySection = {} & IConfigurationEditorSection;

const PhotosCopySection: React.FC<PhotosCopySection> = (props) => {
  const { editor, values, touched, errors } = props;

  return (
    <Section
      title='Photos & Copy'
      sectionId='configuration-editor-photo-copy'
      titleSize='md'
      className='configuration-editor-photo-copy mt-4'
    >
      <PhotoCopyRow
        editor={editor}
        values={values}
        subLabel='School Welcome Image'
        imageAttribute='homepage_hero_image'
        imageRef={editor.uploadWelcomeImageRef}
        touched={touched}
        errors={errors}
      />
      <PhotoCopyRow
        editor={editor}
        values={values}
        subLabel='Profile Backup Image'
        imageAttribute='default_user_profile_image'
        imageRef={editor.uploadProfileBackupImageRef}
        touched={touched}
        errors={errors}
      />

      <PhotoCopyRow
        editor={editor}
        values={values}
        label='Tips and advice'
        elements={[
          { id: 'article-header-copy', label: 'Header Copy' },
          { id: 'article-subheader-copy', label: 'Subheader Copy' },
        ]}
        touched={touched}
        errors={errors}
      />
      <PhotoCopyRow
        editor={editor}
        values={values}
        label='Q&A Videos'
        elements={[
          { id: 'qa-header-copy', label: 'Header Copy' },
          { id: 'qa-subheader-copy', label: 'Subheader Copy' },
        ]}
        touched={touched}
        errors={errors}
      />
      <PhotoCopyRow
        editor={editor}
        values={values}
        label='Family Network'
        elements={[
          { id: 'family-header-copy', label: 'Header Copy' },
          { id: 'family-subheader-copy', label: 'Subheader Copy' },
        ]}
        touched={touched}
        errors={errors}
        imageAttribute='family_hero_image'
        imageRef={editor.uploadFamilyImageRef}
      />
      <PhotoCopyRow
        editor={editor}
        values={values}
        label='Staff Directory'
        elements={[
          { id: 'staff-header-copy', label: 'Header Copy' },
          { id: 'staff-subheader-copy', label: 'Subheader Copy' },
        ]}
        touched={touched}
        errors={errors}
        imageAttribute='staff_hero_image'
        imageRef={editor.uploadStaffImageRef}
      />
      <PhotoCopyRow
        editor={editor}
        values={values}
        label='Club Hub'
        elements={[
          { id: 'club-header-copy', label: 'Header Copy' },
          { id: 'club-subheader-copy', label: 'Subheader Copy' },
        ]}
        touched={touched}
        errors={errors}
      />
      <PhotoCopyRow
        editor={editor}
        values={values}
        label='Outcomes'
        elements={[
          { id: 'outcomes-header-copy', label: 'Header Copy' },
          { id: 'outcomes-subheader-copy', label: 'Subheader Copy' },
        ]}
        touched={touched}
        errors={errors}
        imageAttribute='outcomes_hero_image'
        imageRef={editor.uploadOutcomeImageRef}
      />
      <hr className='mt-5' />
    </Section>
  );
};

export default PhotosCopySection;
