import AccordionView from "app/screens/adminAccountManager/accordionView/AccordionView";
import Input from "app/components/input/Input";
import Switch from "app/components/switch/Switch";
import PrivateLayout from "app/layout/PrivateLayout";
import { Form, Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import "./admin-account-manager.scss";
import DropDown from "app/components/dropDown/DropDown";
import { IAccountDesignation, IDesignation } from "app/models/common";
import useAdminEditor from "app/hooks/AdminEditor";
import { adminSchema } from "app/utils/validations";

const AdminAccountEditor: React.FC = () => {
  const _editor = useAdminEditor();
  return (
    <PrivateLayout contentClassName="admin-editor">
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
        validationSchema={adminSchema}
      >
        {({ values, isValid, dirty, errors, touched }) => (
          <Form>
            <div className="admin-editor-switch">
              <h3 className="title">Admin Account Editor</h3>
              <div className="admin-editor-toggle">
                <p>Active Status</p>
                <Switch
                  checked={values.activeStatus}
                  onChange={_editor.onActiveStatusChange}
                  switchId="admin-editor-live-btn"
                />
              </div>
            </div>
            <div className="admin-editor-form">
              <Row>
                <Col>
                  <Input
                    id="admin-editor-name"
                    variant="secondary"
                    label="Name"
                    name="firstName"
                    error={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : ""
                    }
                  />
                </Col>
                <Col>
                  <Input
                    id="admin-editor-lastName"
                    label="Last Name"
                    variant="secondary"
                    name="lastName"
                    error={
                      touched.lastName && errors.lastName ? errors.lastName : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="admin-editor-email"
                    variant="secondary"
                    label="Email Address"
                    name="email"
                    error={touched.email && errors.email ? errors.email : ""}
                  />
                </Col>
                <Col>
                  <Input
                    id="admin-editor-password"
                    variant="secondary"
                    label="Password"
                    name="password"
                    error={
                      touched.password && errors.password ? errors.password : ""
                    }
                  />
                </Col>
                <Col>
                  <DropDown<IAccountDesignation>
                    label="Account Designation"
                    id="admin-editor-account-designation"
                    labelAttribute="accountDesignation"
                    multiple
                    options={_editor.accountDesignations}
                    selected={values.accountDesignationIds}
                    valueAttribute="id"
                    placeholder="Select Designation"
                    onOptionChange={_editor.onSelectionChange(
                      "accountDesignationIds"
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} xl={4}>
                  <Input
                    id="admin-editor-verification-code"
                    variant="secondary"
                    label="Verification Code"
                    name="verificationCode"
                    error={
                      touched.verificationCode && errors.verificationCode
                        ? errors.verificationCode
                        : ""
                    }
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
      <div className="admin-editor-permissions">
        <p className="admin-editor-permissions-title">Access Permissions</p>
        {/* accordions */}
        <div className="admin-editor-permissions-options">
          <AccordionView />
        </div>
      </div>
    </PrivateLayout>
  );
};

export default AdminAccountEditor;
