import React, { useState } from "react";
import "./activity.scss";
import Activity from "./Activity";
import { IActivity } from "app/models/activity";

const ActivityContainer: React.FC<IActivityContainerProps> = ({
  id,
  activites,
  selectedActivities = [],
  onSelectionChange,
}) => {
  const _onSelectionChange = (id: number, selected: boolean) =>
    onSelectionChange?.(
      selected
        ? [...selectedActivities, id]
        : selectedActivities.filter(
            (selectedActivity) => selectedActivity !== id
          )
    );

  return (
    <div className="activity-container">
      {activites.map((activity) => (
        <Activity
          eltId={`${id}-${activity.activityTitle}-${activity.id}`}
          {...activity}
          onSelectionChange={_onSelectionChange}
          selected={selectedActivities.includes(activity.id)}
        />
      ))}
    </div>
  );
};
export default ActivityContainer;

interface IActivityContainerProps {
  activites: IActivity[];
  id: string;
  selectedActivities?: number[];
  onSelectionChange?: (selectedActivities: number[]) => void;
}
