import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import Switch from 'app/components/switch/Switch';
import useQAListing from 'app/hooks/QAListing';
import QuestionAnswerPreviewPopup from './components/QuestionAnswerPreviewPopup';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IQAListItem } from 'app/models/qa';
import { ICategoryTag, IStatus } from 'app/models/common';
import { IGradeBand } from 'app/models/program';
import React from 'react';
import './qa.scss';

const GRID_ID = 'qa-account-listing';

const QAContentManager: React.FC = () => {
  const _qaListing = useQAListing();
  return (
    <PrivateLayout contentClassName='qa-listing'>
      <StandardButton
        label='+ New Q&amp;A Topic'
        variant='secondary'
        className='new-qa-button'
        onClick={_qaListing.onNewQAClick}
        standardBtnId='qa-listing-new-btn'
      />
      <h3 className='title'>Q&amp;A Content Manager</h3>
      <SearchBar
        placeHolder='Search by topics, title or content ID'
        searchBarId='qa-listing-search'
        onChangeText={_qaListing.onSearchKeyChange}
        onSearch={_qaListing.onSearch}
      />
      <Filter
        id='article-listing-filter'
        onFilterChange={_qaListing.onFilterChange}
        selectedFilters={_qaListing.filters}
      >
        <FilterOption<IGradeBand>
          labelAttribute='gradeBand'
          valueAttribute='id'
          name='gradeBandIds'
          options={_qaListing.gradeBands}
          placeholder='Grade Band'
        />

        <FilterOption<ICategoryTag>
          labelAttribute='categoryTag'
          valueAttribute='id'
          name='categoryIds'
          options={_qaListing.categoryTags}
          placeholder='Category Tags'
        />
        <FilterOption<IStatus>
          labelAttribute='status'
          valueAttribute='id'
          name='statusIds'
          options={_qaListing.statuses}
          placeholder='Status'
        />
      </Filter>
      <DataGrid<IQAListItem>
        data={_qaListing.data}
        gridId={GRID_ID}
        rowIdAttribute='id'
        loading={_qaListing.loading}
        currentPage={_qaListing.currentPage}
        onPageChange={_qaListing.onPageChange}
        totalItems={_qaListing.totalItems}
        onAction={_qaListing.onGridAction}
        confirmDeleteDescription='Please confirm that you would like to delete this question/topic.'
      >
        <DataGrid.Column<IQAListItem>
          attributeName='topic'
          header='Question or Topic'
        />
        <DataGrid.Column<IQAListItem>
          attributeName='topicId'
          header='Content Id'
        />
        <DataGrid.Column<IQAListItem>
          attributeName='gradeBands'
          header='Grade Band'
        />

        <DataGrid.Column<IQAListItem>
          attributeName='categoryTags'
          header='Category Tags'
        />
        <DataGrid.Column<IQAListItem>
          attributeName='approveStatus'
          header='Approval Status'
        />
        <DataGrid.Column<IQAListItem>
          attributeName='activeStatus'
          header='Active Status'
          transform={(question) => {
            return (
              <Switch
                checked={question.activeStatus}
                onChange={_qaListing.onToggleActive(question)}
                switchId='question-listing-live-btn'
              />
            );
          }}
        />
        <DataGrid.Column<IQAListItem>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IQAListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IQAListItem>
          action={DATA_GRID_ACTIONS.DELETE}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
      <QuestionAnswerPreviewPopup
        ref={_qaListing.previewPopupRef}
        id='qa-listing-preview'
      />
    </PrivateLayout>
  );
};
export default QAContentManager;
