import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import React, { useImperativeHandle, useRef } from "react";
import { IMessage } from "app/models/messages";
import { useState } from "react";
import "./messages.scss";

const MessagePreviewPopup = React.forwardRef<
  MessagePreviewPopUpRef,
  IMessagePreviewPopUpProps
>(({ id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const [state, setState] = useState<IMessage>();
  useImperativeHandle(
    ref,
    () => ({
      show: (message: IMessage) => {
        setState(message);
        modalRef.current?.show();
      },
    }),
    []
  );

  return (
    <T12Modal
      modalId={id}
      hideFooter
      size="lg"
      ref={modalRef}
      title="Previous Versions"
      className="previous-versions"
      showHeaderBorder
    >
      <div className="message-popup-content">
        <label className="popup-label">Sending User</label>
        <div className="popup-field">{state?.senderName}</div>
        <label className="popup-label">Receiving User</label>
        <div className="popup-field">{state?.receiverName}</div>
        <label className="popup-label">Date Sent</label>
        <div className="popup-field">{state?.date}</div>
        <p
          className="message-item"
          dangerouslySetInnerHTML={{ __html: state?.message ?? "" }}
        ></p>
      </div>
    </T12Modal>
  );
});

export default MessagePreviewPopup;

interface IMessagePreviewPopUpProps {
  id: string;
}

export type MessagePreviewPopUpRef = {
  show: (message: IMessage) => void;
} | null;
