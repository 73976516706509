import React from 'react';
import { isEmpty } from 'lodash';

import './bullet-list.scss';

interface IBulletList<T> {
  items: T[];
  labelAttribute?: keyof T;
}

const BulletList = <T,>({ items, labelAttribute }: IBulletList<T>) => {
  if (!items || isEmpty(items)) return null;

  return (
    <ul className='bullet-list'>
      {items.map((item) => {
        const label = labelAttribute ? item[labelAttribute] : item;
        return <li className='bullet-list-item'>{label}</li>;
      })}
    </ul>
  );
};

export default BulletList;
