import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';

import { useConfigurationEditor } from 'app/hooks/ConfigurationEditor';
import SearchBar from 'app/components/searchBar/SearchBar';
import StandardButton from 'app/components/button/StandardButton';
import ScreenNotification from 'app/components/screenNotification/ScreenNotification';
import StaffSection from './StaffSection';
import ColorPaletteSection from './ColorPaletteSection';
import ProgramDetailsSection from './ProgramDetailsSection';
import ProgramDemographicsSection from './DemographicsSection';
import ManagementDetailsSection from './ManagementDetailsSection';
import GradeBandsSection from './GradeBandsSection';
import FeatureSection from './FeatureSection';
import PhotosCopySection from './PhotosCopySection';
import PrivateLayout from 'app/layout/PrivateLayout';

import './configuration-editor.scss';

interface ConfigurationEditor {}

const ConfigurationEditor: React.FC<{}> = () => {
  const _editor = useConfigurationEditor();

  return (
    <PrivateLayout contentClassName='configuration-editor'>
      <Formik
        innerRef={_editor.formRef}
        initialValues={_editor.initialValues}
        onSubmit={_editor.onSubmit}
      >
        {(formikProps) => {
          const { values, isValid, dirty, errors, touched } = formikProps;

          console.log({ values });
          return (
            <>
              <Form>
                <Row className='my-5'>
                  <Col>
                    <h3 className='title'>Main Program Configuration</h3>
                  </Col>
                  <Col>
                    <div className='top-button-container'>
                      <StandardButton
                        standardBtnId='configuration-editor-save-btn'
                        label='Save Changes'
                        variant='primary'
                        type='submit'
                        disabled={false}
                      />
                    </div>
                  </Col>
                </Row>
                <ScreenNotification
                  variant='danger'
                  styles={{ container: { marginTop: '2rem' } }}
                />
                <Row>
                  <SearchBar
                    placeHolder='Search by functionality'
                    searchBarId='configuration-functionality-search'
                    onChangeText={_editor.onFunctionalitySearch}
                    liveSearch
                  />
                </Row>
                <Row>
                  <StaffSection editor={_editor} {...formikProps} />
                </Row>
                <Row>
                  <ColorPaletteSection editor={_editor} {...formikProps} />
                </Row>
                <Row>
                  <ProgramDetailsSection editor={_editor} {...formikProps} />
                </Row>
                {/* <Row>
                  <ProgramDemographicsSection
                    editor={_editor}
                    {...formikProps}
                  />
                </Row> */}
                <Row>
                  <ManagementDetailsSection editor={_editor} {...formikProps} />
                </Row>
                <Row>
                  <GradeBandsSection editor={_editor} {...formikProps} />
                </Row>
                {/* <Row>
                  <ProgramDetailsSection editor={_editor} {...formikProps} />
                </Row> */}
                <Row>
                  <FeatureSection editor={_editor} {...formikProps} />
                </Row>
                <Row>
                  <PhotosCopySection editor={_editor} {...formikProps} />
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </PrivateLayout>
  );
};

export default ConfigurationEditor;
