import { IMediaUpload } from './../models/media';
import { IAppConfig } from 'app/models/config';
import { IDemographic, IProgramFeature } from './../models/program';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useHref } from 'react-router-dom';
import { FormikProps, FormikTouched, FormikErrors } from 'formik';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { capitalize, get } from 'lodash';

import {
  IMainProgramConfigBuilder,
  IMainProgramConfigResponse,
} from './../models/config';
import ROUTES from 'app/constants/navigation';
import {
  IGradeBand,
  IProgramFeatureResponse,
  IProgramFeatureBuilder,
  IProgramFeatureConnection,
} from 'app/models/program';
import { ILocation, IThirdPartyJS } from 'app/models/config';
import { ICategoryTag, IDesignation } from 'app/models/common';
import { IEmailBuilder, IEmailTemplate } from 'app/models/email';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { processErrorResponse, SUBSCRIPTION_OPTIONS } from 'app/utils/common';
import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopup';
import { IUploadResponse } from 'app/models/common';
import { IMediaResponse, IMediaSelected } from './../models/media';
import usePagination, { IPaginationState } from './Pagination';
import { IPaginationResponse } from './../models/api';
import useApi from './Api';
import { IStaffListItem } from 'app/models/staff';
import {
  listStaffs,
  updateProgramConfigurations,
  getProgramConfigurations,
} from 'app/api/adminApis';
import { EmailMergeFieldsPopupRef } from 'app/screens/email/components/emailTemplates/EmailTemplateMergeFields';

export interface IConfigurationEditorSection {
  editor: Partial<IConfigurationEditor>;
  values: IMainProgramConfigBuilder;
  touched: FormikTouched<IMainProgramConfigBuilder>;
  errors: FormikErrors<IMainProgramConfigBuilder>;
}

export interface IConfigurationEditor {
  staffMembers: IPaginationState<IStaffListItem>;
  initialValues: IMainProgramConfigBuilder;
  appConfig: IAppConfig;
  gradeBands: IGradeBand[];
  demographics: IDemographic[];
  allAvailableFeatures: IProgramFeature[];
  programFeatures: IProgramFeature[];
  // uploadImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadLogoRef: React.MutableRefObject<UploadPopUpRef>;
  uploadFamilyImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadStaffImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadOutcomeImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadWelcomeImageRef: React.MutableRefObject<UploadPopUpRef>;
  uploadProfileBackupImageRef: React.MutableRefObject<UploadPopUpRef>;
  onFunctionalitySearch: (text: string) => void;
  onChange: (
    key: keyof IMainProgramConfigBuilder | string
  ) => (value: boolean | string | number | number[] | File) => void;
  onChangeGradeBand: (id: number, checked: boolean) => void;
  onFeatureChange: (
    featureId: number,
    type: 'active' | 'permitted',
    state: number
  ) => void;
  onAddressChange: (location: ILocation) => void;
  onMediaUpload: (
    attribute: keyof IMainProgramConfigBuilder,
    uploaded?: {
      file: File;
      tmpFileUrl: string;
      folderId: number;
    },
    attached?: {
      media: IMediaSelected;
    },
    remove?: boolean
  ) => void;
  onUpdateScript: (provider: string, body: string) => void;
  onSubmit: (email: IMainProgramConfigBuilder) => Promise<void>;
}

export const useConfigurationEditor = () => {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IMainProgramConfigBuilder>>(null);
  const uploadLogoRef = useRef<UploadPopUpRef>(null);
  const uploadFamilyImageRef = useRef<UploadPopUpRef>(null);
  const uploadStaffImageRef = useRef<UploadPopUpRef>(null);
  const uploadOutcomeImageRef = useRef<UploadPopUpRef>(null);
  const uploadWelcomeImageRef = useRef<UploadPopUpRef>(null);
  const uploadProfileBackupImageRef = useRef<UploadPopUpRef>(null);

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const demographics = useAppState(appStateSelectors.demographics);
  const allAvailableFeatures = useAppState(appStateSelectors.programFeatures);
  const programFeatures = useAppState(appStateSelectors.programFeatures);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const showNotificationWithVariant = useAppState(
    appStateSelectors.showNotificationWithVariant
  );
  const hideNotification = useAppState(appStateSelectors.hideNotification);

  const [responseNotification, setResponseNotification] = useState<{
    label?: 'string';
    variant?: 'string';
  }>({});

  const staffMembers = usePagination({ listFn: listStaffs });

  const [currentConfigs, error, call, loading] = useApi(
    getProgramConfigurations,
    {},
    true,
    false
  );

  useEffect(() => {
    getCurrentProgramConfigurations();
    return () => hideNotification();
  }, []);

  useEffect(() => {
    if (currentConfigs) {
      prepopulateForm(currentConfigs);
    }
  }, [currentConfigs]);

  const getCurrentProgramConfigurations = () => {
    call({ id: appConfig?.id });
  };

  const prepopulateForm = (configs: Partial<IMainProgramConfigResponse>) => {
    const formattedGradeBandIds = formatGradeBandResponse(configs);
    const formattedMedia = formatMediaResponse(
      configs
    ) as Partial<IMainProgramConfigResponse>;
    console.log({ configs });
    console.log({ formattedMedia });

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      ...configs,
      features: configs.features
        // ?.filter((feature) => feature.is_togglable)
        ?.map((feature) => ({
          ...feature,
          is_active: feature.is_active ? 1 : 0,
          is_enabled: feature.is_enabled ? 1 : 0,
          is_togglable: feature.is_togglable ? 1 : 0,
          is_permitted: feature.is_permitted ? 1 : 0,
        })),
      grade_band_ids: formattedGradeBandIds,
    }));
  };

  const formatMediaResponse = (
    configs: Partial<IMainProgramConfigResponse> = {}
  ) => {
    if (!configs) return [];

    return [
      'logo',
      'welcome_image',
      'homepage_hero_image',
      'profile_backup_image',
      'default_user_profile_image',
      'family_hero_image',
      'staff_hero_image',
      'outcomes_hero_image',
    ]
      .filter(
        (attribute) => !!configs[attribute as keyof IMainProgramConfigResponse]
      )
      .map((attribute) => {
        const imgAttribute = attribute as keyof IMainProgramConfigResponse;
        if (configs[imgAttribute]) {
          const media = configs[imgAttribute] as IMediaResponse;
          const formatted: IMediaUpload = {
            id: media.id ?? undefined,
            url: media.url ?? '',
          };
          return { [imgAttribute]: formatted };
        }
      })
      .reduce((final, attribute) => {
        if (!attribute || !final) return {};

        const key = Object.keys(
          attribute!
        )?.[0] as keyof IMainProgramConfigBuilder;
        final[key] = attribute[key];
        return final;
      }, {} as { [label: string]: IMediaUpload });
  };

  const formatGradeBandResponse = (
    configs: Partial<IMainProgramConfigResponse> = {}
  ) => {
    return (configs.grade_bands || []).map((gb) => gb.id);
  };

  const onFunctionalitySearch = () => {};

  const onChange =
    (key: keyof IMainProgramConfigBuilder | string) =>
    (value: boolean | string | number | number[] | File) => {
      formRef.current?.setFieldValue(key, value);
    };

  const onChangeGradeBand = (id: number, checked: boolean) => {
    let updatedGradeBandIds = [
      ...(formRef.current?.values.grade_band_ids || []),
    ];

    updatedGradeBandIds = updatedGradeBandIds.includes(id)
      ? updatedGradeBandIds.filter((gradeBandId) => gradeBandId !== id)
      : [...updatedGradeBandIds, id];

    formRef.current?.setFieldValue('grade_band_ids', updatedGradeBandIds);
  };

  const onFeatureChange = (
    id: number,
    type: 'permitted' | 'active',
    checked: number
  ) => {
    const existingFeatures = [...(formRef?.current?.values?.features || [])];
    const updatedFeatures = existingFeatures.map((feature) => {
      if (feature.id === id) {
        feature[`is_${type}`] = checked;
      }
      return feature;
    });
    formRef?.current?.setFieldValue('features', updatedFeatures);
  };

  const onAddressChange = (location: ILocation) => {
    formRef?.current?.setValues((prevValues) => ({
      ...prevValues,
      school: {
        ...(prevValues.school || {}),
        location: {
          ...(prevValues?.school?.location || {}),
          ...location,
        },
      },
    }));
  };

  const onMediaUpload = (
    attribute: keyof IMainProgramConfigBuilder,
    uploaded?: {
      file: File;
      tmpFileUrl: string;
      folderId: number;
    },
    attached?: {
      media: IMediaSelected;
    },
    remove?: boolean
  ) => {
    const { file, tmpFileUrl, folderId } = uploaded ?? {};
    const { media } = attached ?? {};

    const updates = media
      ? { image_id: media.id, ...(media as IMediaUpload) }
      : {
          image: file,
          folder_id: folderId,
          tmpFileUrl: tmpFileUrl ?? undefined,
        };

    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      [attribute]: remove ? undefined : updates,
    }));
  };

  const onUpdateScript = (provider: string, body: string) => {
    const scripts =
      formRef.current?.values.scripts?.[0] ??
      ({
        program_id: appConfig!.id,
        name: capitalize(provider),
        provider: capitalize(provider),
        is_enabled: true,
      } as IThirdPartyJS);

    scripts.body = body;
    // scripts.body = body.replace(/[\n\r]+/g, '');

    formRef.current?.setFieldValue('scripts', [scripts]);
  };

  const formatMedia = (media?: IMediaUpload): IMediaUpload => {
    if (!media) return {} as IMediaUpload;

    const updates = media.id
      ? { image_id: media.id }
      : {
          image: media.image!,
          folder_id: media.folder_id,
          owner_id: appConfig?.id!,
          owner_type: 'Program',
        };

    return updates;
  };

  const formatScripts = (scripts?: IThirdPartyJS[]) => {
    if (!scripts || (scripts && scripts.length === 0)) return undefined;

    return scripts.map((script) => ({
      ...script,
      body: script.body.replace(/[\n\r]+/g, ''),
    }));
  };

  const addCollection = (
    collectionName: string,
    collection: any[],
    formData: FormData
  ) => {
    collection.forEach((item, index) => {
      formData.append(`${collectionName}[]`, `${index + 1}`);
    });

    return formData;
  };

  const buildFormData = (
    formData: FormData,
    data: any,
    parentKey: string | null
  ) => {
    if (data && typeof data === 'object') {
      console.log('keys: ', Object.keys(data));
    }
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        if (key === 'school') {
          console.log(` Checking ${key} --- `);
        }
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else if (parentKey) {
      const value = data == null ? '' : data;
      console.log(` -- Appending ${parentKey} == ${value}...`);
      formData.append(parentKey, value);
    }
  };

  const formatParams = (params: IMainProgramConfigBuilder) => {
    const formatted = { ...params };

    const grade_bands = formatted.grade_band_ids ?? [];
    delete formatted.grade_band_ids;

    // formatted.scripts = formatScripts(formatted.scripts);
    formatted.logo = formatMedia(formatted.logo);
    formatted.staff_hero_image = formatMedia(formatted.staff_hero_image);
    formatted.family_hero_image = formatMedia(formatted.family_hero_image);
    formatted.outcomes_hero_image = formatMedia(formatted.outcomes_hero_image);

    const formData = new FormData();
    buildFormData(formData, formatted, null);

    addCollection('grade_band_ids', grade_bands, formData);
    formData.append('_method', 'PUT');

    return formData;
  };

  const onSubmit = async (config: IMainProgramConfigBuilder) => {
    showLoader();
    hideNotification();

    try {
      const programId = appConfig?.id;
      const paramAsFormData = formatParams(config);

      updateProgramConfigurations(programId!, paramAsFormData as FormData);

      // showNotification(`Program Configuration updated successfully`);
      showNotificationWithVariant(
        'success',
        `Program Configuration updated successfully`
      );
      setTimeout(() => {
        hideNotification();
      }, 3000);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log(
        '🚀 ~ file: ConfigurationEditor.ts ~ onSubmit ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  return {
    staffMembers,
    initialValues,
    formRef,
    uploadLogoRef,
    uploadFamilyImageRef,
    uploadStaffImageRef,
    uploadOutcomeImageRef,
    uploadWelcomeImageRef,
    uploadProfileBackupImageRef,
    gradeBands,
    demographics,
    programFeatures,
    onFunctionalitySearch,
    onChange,
    onChangeGradeBand,
    onFeatureChange,
    onAddressChange,
    onMediaUpload,
    onUpdateScript,
    onSubmit,
    appConfig,
  };
};

const initialValues: IMainProgramConfigBuilder = {
  id: undefined,
};
