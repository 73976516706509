import React from 'react';
import moment from 'moment';

import StandardButton from 'app/components/button/StandardButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import SearchBar from 'app/components/searchBar/SearchBar';
import CalendarFilter from 'app/components/calendar/CalendarFilter';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IEmailScheduleManager, IScheduleDate } from 'app/models/email';
import { IGradeBand } from 'app/models/program';
import {
  ICategoryTag,
  IDesignation,
  IPopulation,
  IActiveStatus,
} from 'app/models/common';
import useEmailScheduleManagerListing from 'app/hooks/EmailScheduleManagerListing';
import Switch from 'app/components/switch/Switch';
import { formatGradeBand, formatCategoryTags } from 'app/utils/data';
import { ACTIVE_OPTIONS } from 'app/utils/common';
import { ReactComponent as RepeatIcon } from 'app/assets/icons/repeat.svg';
import './email.scss';

const GRID_ID = 'email-schedule-manager-listing';

const EmailScheduleManager: React.FC = () => {
  const _emailScheduleListing = useEmailScheduleManagerListing();

  console.log({ data: _emailScheduleListing });
  return (
    <PrivateLayout contentClassName='email-schedule-manager-listing'>
      <StandardButton
        label='+ Schedule New Email'
        variant='secondary'
        className='new-schedule-email-button'
        onClick={_emailScheduleListing.onScheduleNewEmailClick}
        standardBtnId='email-schedule-manager-listing-new-btn'
      />
      <h3 className='title'>Email Schedule Manager</h3>
      <SearchBar
        placeHolder='Search by description, keyword or email ID'
        searchBarId='email-schedule-manager-search'
        onChangeText={_emailScheduleListing.onSearchKeyChange}
        onSearch={_emailScheduleListing.onSearch}
      />
      <Filter
        id='email-schedule-manager-listing-filter'
        onFilterChange={_emailScheduleListing.onFilterChange}
        selectedFilters={_emailScheduleListing.filters}
      >
        <FilterOption<IGradeBand>
          labelAttribute='gradeBand'
          valueAttribute='id'
          name='gradeBandIds'
          options={_emailScheduleListing.gradeBands}
          placeholder='Grade Band'
        />
        <FilterOption<IDesignation>
          // labelAttribute="designation"
          labelAttribute='designationType'
          valueAttribute='id'
          name='designationIds'
          options={_emailScheduleListing.contentDesignations}
          placeholder='Designation'
        />
        <FilterOption<ICategoryTag>
          // labelAttribute="category"
          labelAttribute='categoryTag'
          valueAttribute='id'
          name='categoryIds'
          options={_emailScheduleListing.categoryTags}
          placeholder='Category Tags'
        />
        {/* <FilterOption<IScheduleDate>
          labelAttribute='scheduleDate'
          valueAttribute='id'
          name='scheduleDate'
          options={[]}
          placeholder='Schedule Date'
          component={CalendarFilter}
          getValues={_emailScheduleListing.getCalendarFilterValues}
          getSelectedOptions={
            _emailScheduleListing.getSelectedOptionsForCalendarFilter
          }
        /> */}
        <FilterOption<IPopulation>
          labelAttribute='population'
          valueAttribute='id'
          name='population'
          options={_emailScheduleListing.populations}
          placeholder='Population'
        />
        <FilterOption<IActiveStatus>
          labelAttribute='label'
          valueAttribute='isActive'
          name='status'
          options={ACTIVE_OPTIONS}
          placeholder='Status'
        />
      </Filter>
      <DataGrid<IEmailScheduleManager>
        data={_emailScheduleListing.data}
        gridId={GRID_ID}
        rowIdAttribute='id'
        currentPage={_emailScheduleListing.currentPage}
        onPageChange={_emailScheduleListing.onPageChange}
        totalItems={_emailScheduleListing.totalItems}
        onAction={_emailScheduleListing.onGridAction}
      >
        <DataGrid.Column<IEmailScheduleManager>
          attributeName='notification_template_id'
          header='Email ID'
        />
        {/* <DataGrid.Column<IEmailScheduleManager>
          attributeName='emailTemplate'
          header='Email Description'
          transform={(data) => data.emailTemplate.title}
        /> */}
        {/* <DataGrid.Column<IEmailScheduleManager>
          attributeName='gradeBands'
          header='Grade Band'
          transform={(data) => formatGradeBand(data.gradeBands)}
        /> */}
        {/* <DataGrid.Column<IEmailScheduleManager>
          attributeName='designationType'
          header='Designation Type'
          transform={(data) =>
            data.emailTemplate.designationType?.designationType
          }
        /> */}
        {/* <DataGrid.Column<IEmailScheduleManager>
          attributeName='categoryTags'
          header='Category Tags'
          transform={(data) =>
            formatCategoryTags(data.emailTemplate?.categoryTags)
          }
        /> */}
        {/* <DataGrid.Column<IEmailScheduleManager>
          attributeName='approvalStatus'
          header='Approval Status'
          transform={(data) => data.emailTemplate?.approvalStatus}
        /> */}
        <DataGrid.Column<IEmailScheduleManager>
          attributeName='next_delivery_at'
          header='Send Date'
          transform={(data) => {
            return (
              <div className='email-schedule-send-date'>
                {moment(data.next_delivery_at).format('MM/DD/YYYY')}
                {data.delivery_frequency !== 'Once' && (
                  <RepeatIcon className='email-schedule-repeat-icon' />
                )}
              </div>
            );
          }}
        />
        {/* <DataGrid.Column<IEmailScheduleManager>
          attributeName='population'
          header='Population'
        /> */}
        <DataGrid.Column<IEmailScheduleManager>
          attributeName='notification_template'
          header='Approval Status'
          transform={(schedule) => {
            const approved = _emailScheduleListing.approvalStatuses.find(
              (status) => status.status === 'Approved'
            );
            return (
              <Switch
                checked={
                  schedule.notification_template.content_approval_status_id ===
                  approved?.id
                }
                // onChange={_emailScheduleListing.onToggleActivateStatus(
                //   schedule
                // )}
                switchId='email-schedule-manager-activate-status-btn'
              />
            );
          }}
        />
        <DataGrid.Column<IEmailScheduleManager>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IEmailScheduleManager>
          action={DATA_GRID_ACTIONS.EDIT_SCHEDULE}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
    </PrivateLayout>
  );
};

export default EmailScheduleManager;
