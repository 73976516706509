import { listTriggeredEmails } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import { ITriggeredEmail } from 'app/models/email';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';

const useTriggerEmailListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<ITriggeredEmail>({
    listFn: listTriggeredEmails,
  });

  // _pagination.data = sampleData;

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {};

  const onToggleActive = (email: ITriggeredEmail) => (checked: boolean) => {};

  return {
    ..._pagination,
    onGridAction,
    onToggleActive,
  };
};

export default useTriggerEmailListing;
