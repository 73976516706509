import React from "react";
import { Row, Col } from "react-bootstrap";
import DropDown from "app/components/dropDown/DropDown";
import useDataUploadManager, {
  IDropdownItem,
} from "../../hooks/DataUploadManager";
import "./existingRecordsManager.scss";

interface ExistingRecordsManagerProps {
  setValidation: (validation: any) => void;
  setDataUpload: (dataUpload: any) => void;
  dataUpload: any;
}
const ExistingRecordsManager: React.FC<ExistingRecordsManagerProps> = (
  props: any
) => {
  const _dataMangerHandle = useDataUploadManager();

  return (
    <div>
      <div className="existing-records-content f18-l-28">
        <p className="content-desc mb-6">
          Please select one or more fields to match existing records on:
        </p>
        <Row className="flex-va-middle mb-4 f18-l-28">
          <Col xs="6" md="2" lg="2">
            Account ID to be based on
          </Col>
          <Col xs="6" md="4" lg="4">
            <DropDown<IDropdownItem>
              label=""
              placeholder="User ID"
              options={_dataMangerHandle.accountID}
              id="existing-records-content-idTypes"
              valueAttribute="id"
              labelAttribute="label"
              selected={_dataMangerHandle.selectedFileType}
              onOptionChange={_dataMangerHandle.onIDSelected}
            />
          </Col>
        </Row>
        <Row className="flex-va-middle mb-4 f18-l-28">
          <Col xs="6" md="2" lg="2">
            Email to be based on
          </Col>
          <Col xs="6" md="4" lg="4">
            <DropDown<IDropdownItem>
              label=""
              placeholder="Email Address"
              options={_dataMangerHandle.mailType}
              id="existing-records-content-mailTypes"
              valueAttribute="id"
              labelAttribute="label"
              selected={_dataMangerHandle.selectedFileType}
              onOptionChange={_dataMangerHandle.onMailSelected}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ExistingRecordsManager;
