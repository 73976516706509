import React from "react";
import "./filter.scss";
import { ReactComponent as IcFilter } from "app/assets/icons/filter-slider.svg";

const FilterIcon: React.FC<IFilterIconProps> = ({
  filtersVisible,
  onClick,
  filterIconId,
}) => {
  return (
    <>
      {filtersVisible ? (
        <button
          type="button"
          id={filterIconId}
          className="filter-icon-visible"
          onClick={onClick}
        >
          <IcFilter />
        </button>
      ) : (
        <button
          type="button"
          id={filterIconId}
          className="filter-icon"
          onClick={onClick}
        >
          <IcFilter />
        </button>
      )}
    </>
  );
};

export default FilterIcon;

interface IFilterIconProps {
  filterIconId: string;
  onClick: () => void;
  filtersVisible: boolean;
}
