import React from "react";
import ModalHeaderTab, { IModalHeaderTab } from "./ModalHeaderTab";
import "./tabs.scss";

const ModalHeaderTabs: React.FC<IModalHeaderTabsProps> = ({
  id,
  activeTab,
  tabs,
  onTabChange,
}) => {
  return (
    <div className="t12-modal-header-tab-container">
      {tabs.map((tab) => (
        <ModalHeaderTab
          key={`${id}_${tab.id}`}
          {...tab}
          active={activeTab === tab.id}
          onClick={onTabChange}
        />
      ))}
    </div>
  );
};

export default ModalHeaderTabs;

interface IModalHeaderTabsProps {
  id: string;
  activeTab: string;
  tabs: IModalHeaderTab[];
  onTabChange: (id: string) => void;
}
