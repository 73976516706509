import { createAdmin, getAdmin, updateAdmin } from "app/api/adminApis";
import { IAdmin } from "app/models/admin";
import { useAppState, appStateSelectors } from "app/state/AppState";
import { FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useAdminEditor = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IAdmin>>(null);
  const params = useParams();

  const accountDesignations = useAppState(
    appStateSelectors.accountDesignations
  );

  useEffect(() => {
    params?.id && _getAdminDetails();
  }, [params]);

  const _getAdminDetails = async () => {
    try {
      const { data } = await getAdmin(+params.id!);
      formRef.current?.setValues(data);
    } catch (error) {
      console.log(
        "🚀 ~ file: AdminBuilder.ts  ~ const_getAdminDetails= ~ error",
        error
      );
    }
  };

  const onSelectionChange =
    (key: keyof Pick<IAdmin, "accountDesignationIds">) =>
    (value: number | number[]) =>
      formRef.current?.setFieldValue(key, value);

  const onActiveStatusChange = (checked: boolean) =>
    formRef.current?.setFieldValue("activeStatus", checked);

  const onSubmit = async (admin: IAdmin) => {
    try {
      const {} = await (params.id
        ? updateAdmin(+params.id, admin)
        : createAdmin(admin));
      navigate(-1);
    } catch (error) {
      console.log("🚀 ~ file: ArticleEditor.ts ~ onSubmit ~ error", error);
    }
  };

  return {
    formRef,
    accountDesignations,
    onSelectionChange,
    initialValues: initialState,
    onActiveStatusChange,
    onSubmit,
  };
};

const initialState: IAdmin = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  accountDesignationIds: [],
};

export default useAdminEditor;
