import React from "react";
import "./profile-image.scss";

const ProfileImage: React.FC<IProfileImageProps> = ({ imageUrl }) => (
  <img src={imageUrl} alt="profileImg" className="profile-image" />
);

export default ProfileImage;

interface IProfileImageProps {
  imageUrl: string;
}
