import React from "react";

const ModalHeaderTab: React.FC<IModalHeaderTabProps> = ({
  id,
  label,
  icon: Icon,
  active,
  onClick,
}) => {
  const _onClick = () => onClick?.(id);

  return (
    <button
      onClick={_onClick}
      className={`t12-modal-header-tab ${active ? "active" : ""}`}
      id={id}
      type="button"
    >
      <Icon />
      <label>{label}</label>
    </button>
  );
};

export default ModalHeaderTab;

interface IModalHeaderTabProps extends IModalHeaderTab {
  active?: boolean;
  onClick?: (id: string) => void;
}

export interface IModalHeaderTab {
  id: string;
  label: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}
