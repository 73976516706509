import { intervalToDuration } from "date-fns";

export const formatSeconds = (time: number = 0) => {
  const { minutes, seconds } = intervalToDuration({
    start: 0,
    end: time * 1000,
  });
  return (!!minutes ? `${minutes} min` : "").concat(`${seconds} sec`);
};

export const generateYearsArrayBetween = (startYear = 2000) => {
  const endDate = new Date().getFullYear();
  let years = [];
  for (var i = startYear; i <= endDate; i++) {
    years.push(startYear);
    startYear++;
  }
  return years.reverse();
};
