import React, { useEffect, useState } from "react";
import PrivateLayout from "app/layout/PrivateLayout";
import StandardButton from "app/components/button/StandardButton";
import RoundButton from "app/components/button/RoundButton";
import { debounce } from "lodash";

import { createOutcomeContent, listOutcomeContent, updateOutcomeContent, deleteOutcomeContent } from "app/api/adminApis";

import CustomHtmlEditor from "app/components/outcomesEditor/OutcomesCustomHtml";
import { ReactComponent as IcHtml } from "app/assets/icons/angle-left.svg";
import { ReactComponent as IcImage } from "app/assets/icons/image.svg";
import { ReactComponent as IcGraph } from "app/assets/icons/outcome.svg";
import { ReactComponent as IcBook } from "app/assets/icons/book.svg";
import { ReactComponent as IcUser } from "app/assets/icons/usercircle.svg";
import { ReactComponent as IcPlus } from "app/assets/icons/plus.svg";
import { ReactComponent as IcTrash } from "app/assets/icons/delete.svg";

const Outcomes: React.FC = () => {
  const sectionTypes = [
    { content_type: "custom_html", label: "Custom HTML", disabled: false, icon: <IcHtml /> },
    { content_type: "logo", label: "Logo", disabled: true, icon: <IcImage /> },
    { content_type: "graphs", label: "Graphs", disabled: true, icon: <IcGraph /> },
    { content_type: "images", label: "Images", disabled: true, icon: <IcImage /> },
    { content_type: "student_stories", label: "Student Stories", disabled: true, icon: <IcBook /> },
    { content_type: "graduate_gallery_profile", label: "Graduate Gallery Profile", disabled: true, icon: <IcUser /> },
  ];

  const [sectionData, setSectionData] = useState<{ content_type: string; content: string;}[]>([]);

  useEffect(() => {
    loadAll();
  }, [])

  const loadAll = () => {
    listOutcomeContent().then((response) => {
      setSectionData(response.data);
    });
  };

  const handleAddSection = () => {
    createOutcomeContent().then((response) => {
      loadAll();
    });
  };

  const handleRemoveSection = (section: any) => {
    deleteOutcomeContent(section).then((response) => {
      loadAll();
    });
  };

  const handleEditorChange = (sectionId: number, content: string) => {
    const updatedSectionData = [...sectionData];
    updatedSectionData[sectionId].content = content;
    setSectionData(updatedSectionData);
  };

  const handleTypeChange = (sectionId: number, newType: string) => {
    if (sectionData[sectionId].content_type !== newType) {
      const updatedSectionData = [...sectionData];
      updatedSectionData[sectionId].content_type = newType;
      setSectionData(updatedSectionData);
    }
  };

  const handleSave = debounce((section: any) => {
    updateOutcomeContent(section).then((response) => {
      console.log(response);
    });
  }, 400);

  const handleDelete = (section: any) => {
    console.log(section);
    deleteOutcomeContent(section).then((response) => {
      console.log(response);
    });
  };

  return (
    <PrivateLayout>
      <h1 style={{marginBottom:'50px'}}>Outcomes Editor Page</h1>
      <div className="outcomes-editor">
      {sectionData.length === 0 && (
          <div style={{ textAlign: "left", paddingBottom: "20px" }}>
            Click the button below to add a new section to your outcomes page.
          </div>
        )}
        {sectionData.map((_, sectionId) => (
          <div key={sectionId}>
            <div className="section-type-container"> 
              {sectionTypes.map((type) => (
                <div
                  key={type.content_type}
                  className={`tab ${
                    sectionData[sectionId].content_type === type.content_type ? "active" : ""
                  } ${type.disabled ? "disabled" : ""}`}
                  onClick={() =>
                    !type.disabled && handleTypeChange(sectionId, type.content_type)
                  }
                >
                  <div className="icon-container">{type.icon}</div>
                  <div className="type-text">{type.label}</div>
                  {sectionData[sectionId].content_type === type.content_type && (
                    <div
                      style={{
                        width: 0,
                        height: 0,
                        borderLeft: "25px solid transparent",
                        borderRight: "25px solid transparent",
                        borderBottom: "25px solid #4273f6",
                        position: "absolute",
                        bottom: "-55px",
                      }}
                    ></div>
                  )}
                </div>
              ))}
            </div>
            {sectionData[sectionId].content_type === "custom_html" && (
              <CustomHtmlEditor
                value={sectionData[sectionId].content}
                onEditorChange={(content) =>
                  handleEditorChange(sectionId, content)
                }
              />
            )}
            <div style={{paddingTop:'20px', paddingBottom:'20px', display:'flex', justifyContent: "flex-end"}}>
            <StandardButton
                    standardBtnId="outcomes-editor-save-btn"
                    label="Save Section"
                    variant="secondary"
                    type="submit"
                    onClick={() => handleSave(sectionData[sectionId])}
                  />
            </div>
            <div style={{marginTop:'-30px', paddingBottom:'100px', display:'flex', justifyContent: "center"}}>
            <RoundButton
              onClick={() => handleRemoveSection(sectionData[sectionId])}
              icon={<IcTrash color={"#fff"} />}
              variant="secondary"
              roundBtnId={"remove-section"}
              label="Remove this section"
            />
            </div>
          </div>
        ))}

        <div style={{paddingTop:'10px', display:'flex', justifyContent: "center"}}>
        <RoundButton
          onClick={handleAddSection}
          icon={<IcPlus />}
          variant="primary"
          roundBtnId={"add-event"}
          label="Add a new section"
        />
        </div>
      </div>
    </PrivateLayout>
  );
};

export default Outcomes;
