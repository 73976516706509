import {
  deleteArticle,
  listArticles,
  updateArticle,
  listArticlesContent,
} from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import ROUTES from 'app/constants/navigation';
import { IArticle } from 'app/models/article';
import { IArticleListItem } from 'app/models/article';
import { PreviousVersionsPopUpRef } from 'app/screens/articles/components/PreviousVersionsPopUp';
import { ArticlePreviewPopupRef } from 'app/screens/articles/components/ArticlePreviewPopup';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useRef } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import usePagination from './Pagination';

const useArticlesListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IArticleListItem>({
    listFn: listArticlesContent,
    deleteFn: deleteArticle,
  });

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const contentCategories = useAppState(appStateSelectors.contentCategories);
  const designations = useAppState(appStateSelectors.contentDesignations);
  const contentApprovals = useAppState(appStateSelectors.contentApprovals);

  const previousModalRef = useRef<PreviousVersionsPopUpRef>(null);
  const previewModalRef = useRef<ArticlePreviewPopupRef>(null);

  const onNewArticleClick = () => navigate(ROUTES.ARTICLE_EDITOR);

  const onToggleActive =
    (article: IArticleListItem) => async (checked: boolean) => {
      const response = await updateArticle(article.id, {
        activeStatus: checked,
      });
      if (response) {
        _pagination.updateData(article.id, 'id', {
          activeStatus: checked,
        });
      }
    };

  const onGridAction = (
    action: DATA_GRID_ACTIONS,
    id: number,
    item: any = null
  ) => {
    switch (action) {
      case DATA_GRID_ACTIONS.PREVIOUS:
        previousModalRef.current?.show(id);
        break;
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.ARTICLE_EDITOR}/${id}`);
        break;
      case DATA_GRID_ACTIONS.DELETE:
        _pagination.onDelete?.(id);
        break;
      case DATA_GRID_ACTIONS.PREVIEW:
        const previewItem =
          item || _pagination.data.filter((x) => x.id === id)[0];
        previewModalRef.current?.show(previewItem);
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    gradeBands,
    contentCategories,
    designations,
    contentApprovals,
    previousModalRef,
    previewModalRef,
    onNewArticleClick,
    onToggleActive,
    onGridAction,
  };
};

export default useArticlesListing;
