import Input from "app/components/input/Input";
import T12Modal, { T12ModalRef } from "app/components/modal/T12Modal";
import useSitePhotoListing from "app/hooks/SitePhotoListing";
import { emptyFunction } from "app/utils/common";
import { Form, Formik } from "formik";
import React, { useImperativeHandle, useRef } from "react";
import "./create-folder.scss";

const createFolder = React.forwardRef<
  CreateFolderPopUpRef,
  ICreateFolderPopupProps
>(({ onConfirm, id }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        modalRef.current?.show();
      },
    }),
    []
  );
  const _sitePhotoListing = useSitePhotoListing();
  return (
    <T12Modal
      size="lg"
      modalId={id}
      ref={modalRef}
      title="Create New Folder"
      className="new-folder"
      confirmButtonLabel="Confirm"
      cancelButtonLabel="Cancel"
      showHeaderBorder
      onConfirm={emptyFunction}
    >
      <Formik innerRef={null} initialValues={{}} onSubmit={emptyFunction}>
        {({ values, errors }) => (
          <Form>
            <div className="create-folder">
              <div className="folder-details">
                <Input
                  id="create-folder-name"
                  variant="secondary"
                  label="Folder Name"
                  placeholder="Enter folder name"
                  name="folderName"
                />
              </div>
              <div className="folder-placement">
                <p className="folder-placement-title">Placement folder</p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </T12Modal>
  );
});

export default createFolder;

interface ICreateFolderPopupProps {
  id: string;
  onConfirm: () => void;
}

export type CreateFolderPopUpRef = {
  show: () => void;
} | null;
