import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProps } from 'formik';

import { useAppState, appStateSelectors } from 'app/state/AppState';
import { createGraduate, getGraduate, updateGraduate } from 'app/api/adminApis';
import { IEducation } from 'app/models/common';
import { IGraduate } from 'app/models/graduate';

const useGraduateEditor = () => {
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IGraduate>>(null);

  const appConfig = useAppState(appStateSelectors.appConfig);

  const params = useParams();

  useEffect(() => {
    params?.id && _getGraduateDetails();
  }, [params]);

  const onChange =
    (key: keyof Pick<IGraduate, 'showEmail' | 'profileImageUrl'> | string) =>
    (value: boolean | string | number) =>
      formRef.current?.setFieldValue(key, value);

  const addEducation = () => {
    formRef.current?.setFieldValue('educations', [
      ...formRef.current.values.educations,
      { ...education },
    ]);
  };

  const _getGraduateDetails = async () => {
    try {
      const { data } = await getGraduate(+params.id!);
      formRef.current?.setValues(data);
    } catch (error) {
      console.log(
        '🚀 ~ file: GraduateBuilder.ts  ~ const_getGraduateDetails= ~ error',
        error
      );
    }
  };

  const onSubmit = async (graduate: IGraduate) => {
    try {
      const {} = await (params.id
        ? updateGraduate(+params.id, graduate)
        : createGraduate(graduate));
      navigate(-1);
    } catch (error) {
      console.log('🚀 ~ file: ArticleEditor.ts ~ onSubmit ~ error', error);
    }
  };

  return {
    formRef,
    initialValues: initialState,
    onChange,
    addEducation,
    onSubmit,
    schoolName: appConfig?.schoolShortName || appConfig?.schoolName,
  };
};

export default useGraduateEditor;

const education: IEducation = { degree: '', name: '', outcomeId: undefined };

const initialState: IGraduate = {
  firstName: '',
  lastName: '',
  email: '',
  linkedinUrl: '',
  aboutMe: '',
  myExperience: '',
  generalOpinion: '',
  educations: [{ ...education }],
};
