import React from 'react';
import ReactPlayer from 'react-player';
import { ReactComponent as IcPlay } from 'app/assets/icons/play.svg';
import './video-play.scss';

const VideoPlay: React.FC<IVideoPlayProps> = ({
  videoURL,
  previewImage,
  height = '215px',
  width = 'auto',
  customStyles = { borderRadius: '1.25rem' },
  className = '',
  videoPlayerId,
}) => {
  return (
    <div className={`${className}`}>
      <ReactPlayer
        width={width}
        height={height}
        id={videoPlayerId}
        playIcon={
          <div className={`play-icon`}>
            <IcPlay />
          </div>
        }
        controls
        light={previewImage ? previewImage : false}
        url={videoURL}
        style={customStyles}
        config={{
          file: {
            attributes: {
              style: { height: '100%', objectFit: 'cover', ...customStyles },
            },
          },
        }}
      />
    </div>
  );
};

export default VideoPlay;

interface IVideoPlayProps {
  videoPlayerId: string;
  videoURL: string;
  previewImage?: string | null;
  height?: string;
  width?: string;
  customStyles?: Object;
  className?: string;
}
