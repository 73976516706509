import StandardButton from "app/components/button/StandardButton";
import { DATA_GRID_ACTIONS } from "app/components/dataGrid/DataColumn";
import DataGrid from "app/components/dataGrid/DataGrid";
import Filter from "app/components/filter/Filter";
import FilterOption from "app/components/filter/FilterOption";
import SearchBar from "app/components/searchBar/SearchBar";
import Switch from "app/components/switch/Switch";
import useGraduateProfileListing from "app/hooks/GraduateProfileListing";
import PrivateLayout from "app/layout/PrivateLayout";
import { IGraduateListItem } from "app/models/graduate";
import { IStatus, IYears } from "app/models/common";
import React from "react";
import "./graduate-profile-manager.scss";

const GRID_ID = "graduate-profile-listing";

const GraduateGalleryProfileManager: React.FC = () => {
  const _graduateProfileListing = useGraduateProfileListing();
  return (
    <PrivateLayout contentClassName="graduate-profile-listing">
      <StandardButton
        label="+ New Graduate Profile"
        variant="secondary"
        className="new-graduate-profile-button"
        onClick={_graduateProfileListing.onNewGraduateProfileClick}
        standardBtnId="graduate-profile-listing-new-btn"
      />
      <h3 className="title">Graduate gallery profile manager</h3>
      <SearchBar
        placeHolder="Search by topics, title or content ID"
        searchBarId="graduate-profile-listing-search"
      />
      <Filter
        id="graduate-profile-listing-filter"
        onFilterChange={_graduateProfileListing.onFilterChange}
        selectedFilters={_graduateProfileListing.filters}
      >
        <FilterOption<IYears>
          labelAttribute="year"
          valueAttribute="id"
          name="years"
          options={[]}
          placeholder="Class Year"
        />
        <FilterOption<IStatus>
          labelAttribute="status"
          valueAttribute="id"
          name="status"
          options={[]}
          placeholder="Active Status"
        />
      </Filter>
      <DataGrid<IGraduateListItem>
        data={_graduateProfileListing.data}
        gridId={GRID_ID}
        rowIdAttribute="profileId"
        currentPage={_graduateProfileListing.currentPage}
        onPageChange={_graduateProfileListing.onPageChange}
        totalItems={_graduateProfileListing.totalItems}
        onAction={_graduateProfileListing.onGridAction}
      >
        <DataGrid.Column<IGraduateListItem>
          attributeName="userName"
          header="User Name"
        />
        <DataGrid.Column<IGraduateListItem>
          attributeName="class"
          header="Class Year"
        />

        <DataGrid.Column<IGraduateListItem>
          attributeName="isActive"
          header="Active Status"
          transform={(profile) => (
            <Switch
              checked={profile.isActive}
              onChange={_graduateProfileListing.onToggleActive(profile)}
              switchId="profile-listing-live-btn"
            />
          )}
        />
        <DataGrid.Column<IGraduateListItem>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName="GRID.ACTIONS"
        />
        <DataGrid.Column<IGraduateListItem>
          action={DATA_GRID_ACTIONS.EDIT}
          attributeName="GRID.ACTIONS"
        />
      </DataGrid>
    </PrivateLayout>
  );
};
export default GraduateGalleryProfileManager;
