import React from "react";
import { ReactComponent as IcArrowLeft } from "app/assets/icons/arrow-left.svg";
import { ReactComponent as IcT12Logo } from "app/assets/icons/t12-logo.svg";
import SquircleButton from "../button/SquircleButton";
import "./header.scss";

const PrivateHeader: React.FC = () => {
  return (
    <div className="private-header">
      <IcT12Logo className="private-header-logo" />
      <div className="back-button-container">
        <SquircleButton
          icon={IcArrowLeft}
          squircleBtnId="private-header-squircle-btn"
        />
        <label className="back-button-container-label">
          Return to Front-End View
        </label>
      </div>
    </div>
  );
};

export default PrivateHeader;
