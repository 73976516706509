import { ProfileSelectorPopUpRef } from "app/components/profileSelector/ProfileSelector";
import { useRef } from "react";

const useCommon = () => {
  const uploadImageModalRef = useRef<ProfileSelectorPopUpRef>(null);

  const onClickUploadImage = () => {
    uploadImageModalRef.current?.show();
  };
  return {
    uploadImageModalRef,
    onClickUploadImage,
  };
};

export default useCommon;
