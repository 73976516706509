import React from 'react';
import { Col, Row } from 'react-bootstrap';

import OutcomeSelector, {
  IOutcomeSelectorProps,
} from '../dropDown/OutcomeSelector';
import If from '../If';
import Input from '../input/Input';
import { IFamilyEditorV2 } from 'app/models/family';
import { IUserV2 } from 'app/models/user';
import DropDownBase from 'app/components/dropDown/DropDownBase';
import DropDown from '../dropDown/DropDown';
import RoundButton from 'app/components/button/RoundButton';
import { ReactComponent as IcClose } from 'app/assets/icons/close.svg';
import './education-editor.scss';

const EducationEditor: React.FC<IEducationEditorProps> = ({
  index,
  variant,
  values,
  label,
  selected,
  onChange,
  onUserSelect,
  onDelete,
}) => {
  const educationId = 'education-editor';

  type UserWithIndex = IUserV2 & { indexId: number };
  function _getFamilyMembers(): IUserV2[] {
    const head = values?.head
      ? values.head
      : // ? { indexId: 1, ...values.head }
        ({} as UserWithIndex);
    const members = (values?.members || []).map((member, index) => ({
      // indexId: index + 2,
      ...member,
    })) as UserWithIndex[];

    return [head, ...members];
  }

  function _getSelectedUser() {
    // return members
  }

  const familyMembers = _getFamilyMembers();
  return (
    <div className='education-editor-container'>
      <If condition={!!label}>
        <div className='education-member'>{`${label} ${index + 1}`}</div>
      </If>
      <Row className='align-items-center'>
        <Col>
          <Input
            id={`${educationId}-degree`}
            label='Degree'
            variant='secondary'
            name={`educations[${index}].degree`}
          />
        </Col>
        <Col className='education-name-entry'>
          {/* <DropDown<IUserV2>
            id={`education-name-entry-${index}`}
            labelAttribute='first_name'
            valueAttribute='first_name'
            transformValue={(user: IUserV2) => {
              const prefex = user.nonAccountId ? 'nonaccount' : 'account';
              const id = user.nonAccountId ? user.nonAccountId : user.id;
              return `${prefex}-${id}`;
            }}
            transformLabel={(value: IUserV2) => {
              console.log({ value });
              return `${value.first_name} ${value.last_name}`;
            }}
            options={familyMembers}
            placeholder='Select User'
            label='Family Member'
            labelOnly
            onOptionChange={(entry: string | number) => {
              // const user = familyMembers[index];
              onUserSelect?.(index, entry);
            }}
          /> */}
          <Input
            id={`${educationId}-name`}
            label='Name'
            variant='secondary'
            name={`educations[${index}].name`}
          />
        </Col>
        <Col>
          <OutcomeSelector
            eltId={`${educationId}-outcome`}
            variant={variant}
            selected={selected}
            onChange={onChange}
          />
        </Col>
        <Col md='auto'>
          <div className='d-flex align-items-center'>
            <RoundButton
              roundBtnId={`${index}-education-delete`}
              icon={<IcClose />}
              className='button'
              onClick={() => onDelete && onDelete(index)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EducationEditor;

interface IEducationEditorProps
  extends Pick<IOutcomeSelectorProps, 'variant' | 'selected' | 'onChange'> {
  values?: IFamilyEditorV2;
  index: number;
  label?: string;
  onUserSelect?: (index: number, user: string | number) => void;
  onDelete?: (index: number) => void;
}
