import { formatSeconds } from "app/utils/date";
import React from "react";
import "./progress-bar.scss";

const ProgressBar: React.FC<IProgressBarProps> = ({
  label,
  percent,
  secondsLeft,
  className = "",
  horizontal = false,
}) => {
  return (
    <div
      className={`t12-progress-container ${
        horizontal ? "horizontal" : ""
      } ${className}`}
    >
      <div className="t12-progress">
        <div className="filler" style={{ width: `${percent}%` }} />
      </div>
      <div className="details-container">
        <h6 className="font-weight-600">{label}</h6>
        <h6 className="details">
          {percent}% · {formatSeconds(secondsLeft)} remaining
        </h6>
      </div>
    </div>
  );
};

export default ProgressBar;

export interface IProgressBarProps {
  label: string;
  percent: number;
  secondsLeft: number;
  className?: string;
  horizontal?: boolean;
}
