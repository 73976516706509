import {
  createStaff,
  getStaff,
  getStaffProfiles,
  updateStaffProfile,
  updateStaff,
} from 'app/api/adminApis';
import { FileUploaderRef } from 'app/components/fileUploader/FileUploader';
import { IEducation, IUploadResponse } from 'app/models/common';
import { IStaffBuilder, IStaffProfile } from 'app/models/staff';
import { IMediaUpload } from 'app/models/media';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { processErrorResponse } from 'app/utils/common';
import { FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopup';
import { UploadPopUpRef } from 'app/screens/sitePhotos/uploadPopup/UploadPopupV2';
import { ConfirmPopupRef } from 'app/components/confirmPopup/ConfirmPopup';
import { IUser, IUserV2 } from 'app/models/user';
import * as AppUtils from 'app/utils/common';

const useStaffEditor = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<IStaffBuilder>>(null);
  const uploaderRef = useRef<FileUploaderRef>(null);
  // const uploaderRef = useRef<UploadPopUpRef>(null);
  const uploadPopupRef = useRef<UploadPopUpRef>(null);
  const accountTypePopupRef = useRef<ConfirmPopupRef>(null);
  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const showNotification = useAppState(appStateSelectors.showNotification);
  const hideNotification = useAppState(appStateSelectors.hideNotification);
  const appConfig = useAppState(appStateSelectors.appConfig);
  const params = useParams();

  useEffect(() => {
    return () => hideNotification();
  }, []);

  useEffect(() => {
    params?.id && _getStaffDetails();
  }, [params]);

  const _getStaffDetails = async () => {
    showLoader();
    try {
      const { data } = await getStaffProfiles(+params.id!);
      formRef.current?.setValues({
        ...data,
        profile_image: {
          ...data.profile_image,
          fileUrl: data?.profile_image?.url ?? undefined,
        },
        reviews: (data.reviews ?? []).map((review, index) => ({
          ...review,
          tmp_id: index,
        })),
        about_media: data.about_media
          ? {
              ...data.about_media,
              fileUrl: data?.about_media?.url,
            }
          : undefined,
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: StaffBuilder.ts  ~ const_getStaffDetails= ~ error',
        error
      );
    } finally {
      hideLoader();
    }
  };

  const onAccountSelect = (user: Partial<IUserV2>) => {
    formRef.current?.setValues((prevValues) => ({
      ...prevValues,
      staff_profile: {
        ...prevValues.staff_profile,
        user,
        user_id: user.id!,
      },
    }));
  };

  const onSelectionChange =
    (key: keyof Pick<IStaffBuilder, 'grade_band_ids'>) =>
    (value: number | number[]) =>
      formRef.current?.setFieldValue(key, value);

  const onFeaturedStatusChange = (checked: boolean) => {
    formRef.current?.setFieldValue('is_standard', !checked);
  };

  const onChange = (key: string) => (value: boolean | string | number) => {
    formRef.current?.setFieldValue(key, value);
  };

  const onChangeImage = (key: string) => (media: IMediaUpload) => {
    console.log(key);
    console.log(media);
    formRef.current?.setFieldValue(key, media);
  };

  const onChangeSocialMedia = (e: any) => {
    const social_media_accounts = {
      url: e.target.value,
      provider: 'LinkedIn',
    };
    formRef.current?.setFieldValue(
      'social_media_accounts',
      social_media_accounts
    );
  };

  const onChangeAboutEntry = (name: string, e: any) => {
    const updatedEntries = [...(formRef.current?.values?.about ?? [])];
    let existingIndex = -1;
    for (let i = 0; i < updatedEntries.length; i++) {
      if (updatedEntries[i].name === name) {
        existingIndex = i;
      }
    }

    if (existingIndex !== -1) {
      updatedEntries[existingIndex] = {
        ...updatedEntries[existingIndex],
        value: e.target.value,
      };
    } else {
      updatedEntries.push({ name, value: e.target.value });
    }

    formRef?.current?.setFieldValue('about', updatedEntries);
  };

  const onChangeReview = (index: number) => (e: any) => {
    const updatedReviews = [...(formRef.current?.values?.reviews ?? [])];
    let existingIndex = -1;
    for (let i = 0; i < updatedReviews.length; i++) {
      if (updatedReviews[i].tmp_id === index) {
        existingIndex = i;
      }
    }

    if (existingIndex !== -1) {
      console.log(' -- adding to existing -- ');
      updatedReviews[existingIndex] = {
        ...updatedReviews[existingIndex],
        body: e.target.value,
      };
    } else {
      console.log(' -- adding new entry -- ');
      updatedReviews.push({ tmp_id: index, body: e.target.value });
    }

    formRef.current?.setFieldValue('reviews', updatedReviews);
  };

  const addEducation = () => {
    formRef.current?.setFieldValue('educations', [
      ...(formRef.current.values.educations || []),
      { ...education },
    ]);
  };

  const onDeleteEducation = (index: number) => {
    const updated = [...(formRef.current?.values.educations ?? [])];
    updated.splice(index, 1);

    formRef.current?.setFieldValue('educations', updated);
  };

  const addReview = () => {
    const currentReviews = formRef.current?.values?.reviews || [];
    formRef.current?.setFieldValue('reviews', [
      ...currentReviews,
      { tmp_id: currentReviews.length },
    ]);
  };

  const onVideoSelected = (uploadedMedia: IUploadResponse) => {
    // TODO: Finish onVideoSelected...
    // if (uploadedMedia) {
    //   formRef.current?.setFieldValue('mediaUrl', uploadedMedia.fileUrl);
    // }
  };

  const onUploadComplete = (response: IUploadResponse[]) => {
    // TODO: Finish onVideoSelected...
    // formRef.current?.setFieldValue('mediaUrl', response[0].fileUrl);
  };

  const formatMedia = (media?: IMediaUpload): IMediaUpload => {
    if (!media) return {} as IMediaUpload;

    const updates = media.id
      ? { image_id: media.id }
      : {
          image: media.image!,
          folder_id: media.folder_id,
          owner_id: appConfig?.id!,
          owner_type: 'Program',
        };

    return updates;
  };

  const formatParams = (params: IStaffBuilder) => {
    const formatted: IStaffBuilder = { ...params };

    // Images
    formatted.about_media = AppUtils.formatMedia(
      params?.id,
      'Staff',
      formatted.about_media
    );

    // Form data
    const formData = new FormData();
    AppUtils.buildFormData(formData, formatted, null);

    // Collections
    const grade_bands = formatted.grade_band_ids ?? [];
    const educations = formatted.educations ?? [];
    const reviews = formatted.reviews ?? [];
    delete (formatted as any).grade_band_ids;
    delete (formatted as any).educations;
    delete (formatted as any).reviews;

    AppUtils.addCollection('grade_band_ids', grade_bands, formData);
    AppUtils.addCollection('reviews', reviews, formData);
    AppUtils.addCollection('educations', educations, formData);

    formData.append('_method', 'PUT');
    return formData;
  };

  const onSubmit = async (staff: IStaffBuilder) => {
    showLoader();
    hideNotification();
    try {
      const {} = await (params.id
        ? updateStaffProfile(+params.id, formatParams(staff))
        : createStaff(staff));

      navigate(-1);
    } catch (error: any) {
      processErrorResponse({ error, callback: showNotification });
      console.log('🚀 ~ file: StaffEditor.ts ~ onSubmit ~ error', error);
    } finally {
      hideLoader();
    }
  };

  return {
    // initialValues: initialState(appConfig ? appConfig.id : null),
    initialValues,
    schoolName: appConfig?.schoolShortName || appConfig?.schoolName,
    formRef,
    uploaderRef,
    uploadPopupRef,
    accountTypePopupRef,
    gradeBands,
    addEducation,
    onChange,
    onChangeImage,
    onChangeSocialMedia,
    onChangeAboutEntry,
    onChangeReview,
    onDeleteEducation,
    onSubmit,
    onAccountSelect,
    onSelectionChange,
    onFeaturedStatusChange,
    onVideoSelected,
    onUploadComplete,
    addReview,
  };
};

export default useStaffEditor;
const education: IEducation = { degree: '', name: '', outcomeId: undefined };

const initialValues: Partial<IStaffBuilder> = {
  first_name: '',
  last_name: '',
  title: '',
  // grade_bands: [],
  // reviews: [],
  educations: [{ ...education }],
  is_standard: true,
};
