import React from 'react';
import { useNavigate } from 'react-router-dom';

import SideNavBarItem from './SideNavBarItem';
import ROUTES from 'app/constants/navigation';
import { NAVIGATION_STATE } from 'app/constants/common';
import { logout } from 'app/api/authApis';
import { INavOptions } from 'app/models/common';
import { authStateSelectors, useAuthState } from 'app/state/AuthState';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { ReactComponent as IcArticles } from 'app/assets/icons/articles.svg';
import { ReactComponent as IcVideoCamera } from 'app/assets/icons/video-camera.svg';
import { ReactComponent as IcFamily } from 'app/assets/icons/family.svg';
import { ReactComponent as IcUserExecutive } from 'app/assets/icons/user-executive.svg';
import { ReactComponent as IcConfiguration } from 'app/assets/icons/configuration.svg';
import { ReactComponent as IcClubs } from 'app/assets/icons/clubs.svg';
import { ReactComponent as IcLock } from 'app/assets/icons/lock.svg';
import { ReactComponent as IcOutcomes } from 'app/assets/icons/outcome.svg';
import { ReactComponent as IcHome } from 'app/assets/icons/home.svg';
import { ReactComponent as IcGraduate } from 'app/assets/icons/lock.svg';
import { ReactComponent as IcPhotos } from 'app/assets/icons/photo.svg';
import { ReactComponent as IcSend } from 'app/assets/icons/send.svg';
import { ReactComponent as IcReporting } from 'app/assets/icons/reporting.svg';
import { ReactComponent as IcProgram } from 'app/assets/icons/program.svg';
import { ReactComponent as IcMessages } from 'app/assets/icons/messages.svg';
import { ReactComponent as IcUploadMgr } from 'app/assets/icons/upload.svg';
import { ReactComponent as IcLogout } from 'app/assets/icons/logout.svg';
import './navbar.scss';

const SideNavBar: React.FC = () => {
  const setNavState = useAuthState(authStateSelectors.setNavState);
  const showLoader = useAppState(appStateSelectors.showLoader);
  const hideLoader = useAppState(appStateSelectors.hideLoader);
  const navigate = useNavigate();

  const _logout = async () => {
    showLoader();
    try {
      await logout();
    } catch (error) {
      console.log('Error in logout', error);
    } finally {
      const token = localStorage.getItem('token');
      localStorage.removeItem('token');
      navigate(ROUTES.SIGN_IN);
      hideLoader();
    }
  };

  return (
    <div className='side-nav-bar'>
      {items.map((item) => (
        <SideNavBarItem key={`sidenavbar-item-${item.to}`} {...item} />
      ))}
      <SideNavBarItem
        name='Logout'
        className='divider'
        icon={IcLogout}
        onClick={_logout}
      />
    </div>
  );
};

export default SideNavBar;

const items: INavOptions[] = [
  {
    name: 'Admin Dashboard',
    to: ROUTES.ADMIN_DASHBOARD,
    icon: IcHome,
  },
  {
    name: 'Articles',
    to: ROUTES.ARTICLE_CONTENT_MANAGER,
    icon: IcArticles,
    feature: 'article',
  },
  {
    name: 'Q & A',
    to: ROUTES.Q_AND_A_MANAGER,
    icon: IcVideoCamera,
    feature: 'qa',
  },
  {
    name: 'Family',
    to: ROUTES.FAMILY_MANAGER,
    icon: IcFamily,
    feature: 'family',
  },
  {
    name: 'Staff',
    to: ROUTES.STAFF_MANAGER,
    icon: IcUserExecutive,
    feature: 'staff',
  },
  {
    name: 'Configuration',
    to: ROUTES.CONFIGURATION_MANAGER,
    icon: IcConfiguration,
    feature: 'configuration',
  },
  {
    name: 'Graduate',
    to: ROUTES.GRADUATE_MANAGER,
    icon: IcGraduate,
    feature: 'graduate',
  },
  {
    name: 'Ambassador',
    to: ROUTES.AMBASSADOR_PROFILE_MANAGER,
    icon: IcOutcomes,
    feature: 'ambassador',
  },
  {
    name: 'Clubs',
    to: ROUTES.CLUBS_MANAGER,
    icon: IcClubs,
    showDivider: true,
    feature: 'club',
  },
  {
    name: 'Admin',
    to: ROUTES.ADMIN_MANAGER,
    icon: IcLock,
    feature: 'admin',
  },
  {
    name: 'Outcomes',
    to: ROUTES.OUTCOMES_MANAGER,
    icon: IcOutcomes,
    feature: 'outcome',
  },
  {
    name: 'Homepage',
    to: ROUTES.HOMEPAGE_MANAGER,
    icon: IcHome,
    feature: 'homepage',
  },
  {
    name: 'User Account Manager',
    to: ROUTES.USER_PROFILE_MANAGER,
    icon: IcHome,
    feature: 'user_profile',
  },
  {
    name: 'Photos Library',
    to: ROUTES.PHOTOS_MANAGER,
    icon: IcPhotos,
    feature: 'photo_library',
  },
  // TODO: Need to group Email navigation
  {
    name: 'Email',
    to: ROUTES.EMAIL_MANAGER,
    icon: IcSend,
    feature: 'email',
  },
  {
    name: 'Email Schedule Manager',
    to: ROUTES.EMAIL_SCHEDULE_MANAGER,
    icon: IcSend,
    feature: 'email_schedule',
  },
  {
    name: 'Email Reporting',
    to: ROUTES.EMAIL_REPORTING,
    icon: IcSend,
    feature: 'email_reporting',
  },
  {
    name: 'Admin Triggered Email Manager',
    to: ROUTES.ADMIN_TRIGGERED_EMAIL_MANAGER,
    icon: IcSend,
    feature: 'email_trigger',
  },
  {
    name: 'Program Reporting',
    to: ROUTES.PROGRAM_REPORTING,
    icon: IcReporting,
    feature: 'program_reporting',
  },
  {
    name: 'Program Listing',
    to: ROUTES.PROGRAM_MANAGER,
    icon: IcProgram,
    feature: 'program_listing',
  },
  {
    name: 'Announcements',
    to: ROUTES.ADMIN_ANNOUNCEMENT_MANAGER,
    icon: IcUploadMgr,
    feature: 'announcement',
  },
  {
    name: 'Data Upload Manager',
    to: ROUTES.DATA_UPLOAD_MANAGER,
    icon: IcUploadMgr,
    feature: 'data_upload',
  },
  {
    name: 'Messages',
    to: ROUTES.MESSAGES_MANAGER,
    icon: IcMessages,
    feature: 'message',
  },
];
