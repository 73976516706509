import { useRef } from 'react';
import { deleteQA, updateQA, listQA } from 'app/api/adminApis';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import { QuestionAnswerPreviewPopupRef } from 'app/screens/questionAnswer/components/QuestionAnswerPreviewPopup';
import ROUTES from 'app/constants/navigation';
import { IQAListItem, IQA } from 'app/models/qa';
import { appStateSelectors, useAppState } from 'app/state/AppState';
import { useNavigate } from 'react-router-dom';
import usePagination from './Pagination';

const useQAListing = () => {
  const navigate = useNavigate();
  const _pagination = usePagination<IQAListItem>({
    listFn: listQA,
    deleteFn: deleteQA,
  });

  const gradeBands = useAppState(appStateSelectors.gradeBands);
  const categoryTags = useAppState(appStateSelectors.contentCategories);
  const statuses = useAppState(appStateSelectors.contentApprovals);

  const previewPopupRef = useRef<QuestionAnswerPreviewPopupRef>(null);

  const onNewQAClick = () => navigate(ROUTES.Q_AND_A_EDITOR);

  const onToggleActive =
    (question: IQAListItem) => async (checked: boolean) => {
      const response = await updateQA(question.id, {
        activeStatus: checked,
      });
      if (response) {
        _pagination.updateData(question.id, 'id', {
          activeStatus: checked,
        });
      }
    };

  const onGridAction = (action: DATA_GRID_ACTIONS, rowId: number) => {
    switch (action) {
      case DATA_GRID_ACTIONS.DELETE:
        _pagination.onDelete(rowId);
        break;
      case DATA_GRID_ACTIONS.EDIT:
        navigate(`${ROUTES.Q_AND_A_EDITOR}/${rowId}`);
        break;
      case DATA_GRID_ACTIONS.PREVIEW:
        const qa = _pagination.data.find((item) => item.id === rowId);
        qa && previewPopupRef.current?.show(qa);
        break;
      default:
        break;
    }
  };

  return {
    ..._pagination,
    gradeBands,
    onNewQAClick,
    onToggleActive,
    onGridAction,
    categoryTags,
    statuses,
    previewPopupRef,
  };
};

export default useQAListing;
