import React from "react";
import "./link-blue.scss";

const LinkBlue: React.FC<ILinkProps> = ({
  className,
  target,
  href,
  children,
}) => {
  return (
    <a
      // TODO need to remove id if style conflict is resolved
      id="linkBlue"
      className={`link-blue ${className}`}
      href={href}
      target={target}
    >
      {children}
    </a>
  );
};

export default LinkBlue;

interface ILinkProps {
  className?: string;
  target?: string;
  href: string;
}
