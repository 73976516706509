import Switch from "app/components/switch/Switch";
import React from "react";
import "./style.scss";

const AccordionListItem: React.FC<IAccordionListItemProps> = ({ options }) => {
  return (
    <div>
      {options.map((item, idx) => (
        <div className="permission-option">
          {item}
          <Switch switchId={`email_${idx}`} />
        </div>
      ))}
    </div>
  );
};

export default AccordionListItem;

interface IAccordionListItemProps {
  options: string[];
}
