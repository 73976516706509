import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import SearchBar from 'app/components/searchBar/SearchBar';
import { ReactComponent as IcFlag } from 'app/assets/icons/flag.svg';
import Filter from 'app/components/filter/Filter';
import FilterOption from 'app/components/filter/FilterOption';
import CalendarFilter from 'app/components/calendar/CalendarFilter';
import useMessageListing from 'app/hooks/MessageListing';
import PrivateLayout from 'app/layout/PrivateLayout';
import { IGradeBand, IMessage } from 'app/models/messages';
import { IRole } from 'app/models/common';
import MessagePreviewPopup from './MessagePreviewPopup';
import React from 'react';
import './messages.scss';

const GRID_ID = 'admin-messages-listing';
const Messages: React.FC = () => {
  const _messageListing = useMessageListing();
  return (
    <PrivateLayout contentClassName='messages-listing'>
      <h3 className='title'>User Messages Admin</h3>
      <SearchBar
        placeHolder='Search by keyword, name of sending/receiver user, or reported status'
        searchBarId='messages-search'
        onSearch={_messageListing.onSearch}
      />
      <Filter
        id='program-listing-filter'
        onFilterChange={_messageListing.onFilterChange}
        selectedFilters={_messageListing.filters}
      >
        <FilterOption<IRole>
          labelAttribute='roleName'
          valueAttribute='id'
          name='sentTo'
          options={_messageListing.roles}
          placeholder='Sent to'
        />
        <FilterOption<any>
          labelAttribute='scheduleDate'
          valueAttribute='id'
          name='sendDate'
          options={[]}
          placeholder='Date Sent'
          component={CalendarFilter}
          getValues={_messageListing.getCalendarFilterValues}
          getSelectedOptions={
            _messageListing.getSelectedOptionsForCalendarFilter
          }
        />
        <FilterOption<{ id: number; status: string }>
          labelAttribute='status'
          valueAttribute='status'
          name='status'
          options={[
            { id: 1, status: 'Yes' },
            { id: 2, status: 'No' },
          ]}
          placeholder='Reported status'
        />
      </Filter>
      <DataGrid<IMessage>
        data={_messageListing.data}
        gridId={GRID_ID}
        rowIdAttribute='messageId'
        currentPage={_messageListing.currentPage}
        onPageChange={_messageListing.onPageChange}
        totalItems={_messageListing.totalItems}
        onAction={_messageListing.onGridAction}
      >
        <DataGrid.Column<IMessage>
          attributeName='senderName'
          header='SENDING USER'
        />
        <DataGrid.Column<IMessage>
          attributeName='receiverName'
          header='RECEIVING USER'
        />
        <DataGrid.Column<IMessage> attributeName='date' header='DATE SENT' />
        <DataGrid.Column<IMessage>
          attributeName='isRestricted'
          header='REPORTED FLAG'
          transform={(message) => (message.isRestricted ? <IcFlag /> : '')}
        />
        <DataGrid.Column<IMessage>
          action={DATA_GRID_ACTIONS.PREVIEW}
          attributeName='GRID.ACTIONS'
        />
        <DataGrid.Column<IMessage>
          action={DATA_GRID_ACTIONS.DELETE}
          attributeName='GRID.ACTIONS'
        />
      </DataGrid>
      <MessagePreviewPopup
        ref={_messageListing.messagesModalRef}
        id='article-listing-previous-versions'
      />
    </PrivateLayout>
  );
};

export default Messages;
