import React from 'react';

import CheckBox, { ICheckboxProps } from './CheckBox';
import './bordered-check-box.scss';

type BorderedCheckBox = {} & ICheckboxProps;

const BorderedCheckBox: React.FC<BorderedCheckBox> = (props) => {
  return (
    <div className='bordered-check-box'>
      <CheckBox {...props} />
    </div>
  );
};

export default BorderedCheckBox;
