import React, { useRef, useState, useEffect } from 'react';
import T12Modal, { T12ModalRef } from '../modal/T12Modal';
import './color-picker.scss';
import { ReactComponent as IcEdit } from '../../assets/icons/edit.svg';
import { SketchPicker } from 'react-color';
import RoundButton from '../button/RoundButton';

const ColorPicker: React.FC<IColorPickerProps> = ({
  id,
  color,
  onColorChange,
  onConfirm,
  label,
}) => {
  const initialColor = color;
  const [tmpColor, setTmpColor] = useState<string | null>(initialColor);

  useEffect(() => {
    if (color && color !== tmpColor) {
      setTmpColor(color);
    }
  }, [color]);

  const ref = useRef<T12ModalRef>(null);

  const handleEdit = () => ref.current?.show();

  const onChangeColor = (color: IColorObjectProps) => {
    setTmpColor(color.hex);
    onColorChange?.(color.hex, id);
  };

  const onConfirmColor = () => {
    tmpColor && onConfirm(tmpColor, id);
    ref.current?.close();
  };

  const onCancelColor = () => {
    setTmpColor(initialColor);
    ref.current?.close();
  };

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='font-weight-400 d-flex align-items-center'>{label}</div>
        <RoundButton
          roundBtnId={`${id}-edit-click}`}
          icon={<IcEdit />}
          className='action-circle'
          onClick={handleEdit}
        />
      </div>
      <div className='d-flex justify-content-between py-3 align-items-center'>
        <div
          className={`color-circle ${!tmpColor && 'no-color'}`}
          style={{ backgroundColor: tmpColor ?? 'white' }}
        ></div>
        <div className='color-code-box'>{tmpColor ?? 'None'}</div>
      </div>
      <T12Modal
        modalId={`${id}-color-modal`}
        cancelButtonLabel='No'
        confirmButtonLabel='Yes'
        onConfirm={onConfirmColor}
        onCancel={onCancelColor}
        onHide={onCancelColor}
        ref={ref}
        title={`Select Color`}
        showHeaderBorder
        closeOnConfirm={false}
        className='color-picker-modal'
      >
        <SketchPicker
          color={tmpColor ?? '#2e75ff'}
          onChangeComplete={onChangeColor}
        />
      </T12Modal>
    </>
  );
};

export default ColorPicker;

interface IColorObjectProps {
  hex: string;
}
interface IColorPickerProps {
  color: string | null;
  onColorChange?: (color?: string, id?: string) => void;
  onConfirm: (color?: string, id?: string) => void;
  label: string;
  id: string;
}
