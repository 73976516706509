import React from "react";
import T12Modal, { T12ModalRef } from "../modal/T12Modal";
import "./import-global.scss";

const ImportContentPopUp = React.forwardRef<
  T12ModalRef,
  IImportContentPopUpProps
>(({ popupDescription, contentCount, contentType, id, onConfirm }, ref) => {
  return (
    <T12Modal
      modalId={id}
      cancelButtonLabel="No"
      confirmButtonLabel="Yes"
      onConfirm={onConfirm}
      ref={ref}
      title={`Global ${contentType}`}
      showHeaderBorder
    >
      <p>{popupDescription}</p>
      <div className="count-info">
        <h4>{contentCount}</h4>
        <p>{contentType} will be imported</p>
      </div>
      <p>Would you like to import this content now?</p>
    </T12Modal>
  );
});

export default ImportContentPopUp;

export interface IImportContentPopUpProps {
  contentType: string;
  popupDescription: string;
  contentCount: number;
  id: string;
  onConfirm?: () => void;
}
