import RoundButton from 'app/components/button/RoundButton';
import { DATA_GRID_ACTIONS } from 'app/components/dataGrid/DataColumn';
import DataGrid from 'app/components/dataGrid/DataGrid';
import T12Modal, { T12ModalRef } from 'app/components/modal/T12Modal';
import useModifiedState from 'app/hooks/ModifiedState';
import { IArticleVersion } from 'app/models/article';
import { ReactComponent as IcAngleLeft } from 'app/assets/icons/angle-left.svg';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import '../articles.scss';
import { getArticleVersions } from 'app/api/adminApis';
import { format } from 'date-fns';

const PAGE_SIZE = 7;

const PreviousVersionsPopUp = React.forwardRef<
  PreviousVersionsPopUpRef,
  IPreviousVersionsPopUpProps
>(({ id, onVersionPreview }, ref) => {
  const modalRef = useRef<T12ModalRef>(null);
  const [state, dispatch] = useModifiedState<IPreviousVersionsState>({
    versions: [],
    currentPage: 1,
  });

  const _getVersions = async (id: number) => {
    4;
    try {
      const { data } = await getArticleVersions(id);
      const sortedVersions = (data || []).sort((a, b) =>
        +a.version > +b.version ? -1 : 1
      );
      dispatch({ versions: sortedVersions });
    } catch (error) {
      console.log(
        '🚀 ~ file: PreviousVersionsPopUp.tsx  ~ const_getVersions= ~ error',
        error
      );
    }
  };

  const _onNext = () =>
    dispatch((state) => ({
      currentPage: state.currentPage + 1,
    }));

  const _onPrev = () =>
    dispatch((state) => ({ currentPage: state.currentPage - 1 }));

  const _noHide = () => dispatch({ versions: [], currentPage: 1 });

  useImperativeHandle(
    ref,
    () => ({
      show: (id: number) => {
        _getVersions(id);
        dispatch({ currentPage: 1 });
        modalRef.current?.show();
      },
    }),
    []
  );

  return (
    <T12Modal
      modalId={id}
      hideFooter
      size='lg'
      ref={modalRef}
      title='Previous Versions'
      className='previous-versions'
      showHeaderBorder
      onHide={_noHide}
    >
      <DataGrid<IArticleVersion>
        data={state.versions.slice(
          (state.currentPage - 1) * PAGE_SIZE,
          state.currentPage * PAGE_SIZE
        )}
        currentPage={state.currentPage}
        gridId={`${id}_grid`}
        rowIdAttribute='version'
        onAction={(action, id) => {
          const article = (state.versions || []).find(
            (version) => +version.version === +id
          );
          onVersionPreview && onVersionPreview(action, id, article);
        }}
      >
        <DataGrid.Column<IArticleVersion>
          attributeName='articleTitle'
          header='Article Title'
        />
        <DataGrid.Column<IArticleVersion>
          attributeName='version'
          header='Article Version'
        />
        <DataGrid.Column<IArticleVersion>
          attributeName='updatedDate'
          header='Last Updated'
          transform={(version) =>
            format(new Date(version.updatedDate), 'MM/dd/yyyy')
          }
        />
        <DataGrid.Column<IArticleVersion>
          attributeName='GRID.ACTIONS'
          action={DATA_GRID_ACTIONS.PREVIEW}
        />
      </DataGrid>
      <div className='page-controls'>
        <RoundButton
          icon={<IcAngleLeft />}
          size='sm'
          disabled={state.currentPage === 1}
          onClick={_onPrev}
          variant='secondary'
          roundBtnId='previous-versions-back'
        />
        <RoundButton
          size='sm'
          onClick={_onNext}
          disabled={
            state.currentPage === Math.ceil(state.versions.length / PAGE_SIZE)
          }
          icon={<IcAngleLeft className='rotate-180' />}
          variant='secondary'
          roundBtnId='previous-versions-next'
        />
      </div>
    </T12Modal>
  );
});

export default PreviousVersionsPopUp;

interface IPreviousVersionsPopUpProps {
  id: string;
  onVersionPreview?: (
    action: DATA_GRID_ACTIONS,
    id: number,
    item?: any
  ) => void;
}

export type PreviousVersionsPopUpRef = {
  show: (id: number) => void;
} | null;

interface IPreviousVersionsState {
  versions: IArticleVersion[];
  currentPage: number;
}
